import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GLOBAL } from 'src/app/app.globals';
import { FileUploadService } from './file-upload.service';
export class FileDoc {
  file: File | object;
  isUploaded ? = false;
  documentTypeCode?: number;
  url?: string;
  ID?: number;
}

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @Input() multiple = true;
  @Input() documentTypes;
  @Output() filesChanged: EventEmitter<FileDoc[]> = new EventEmitter();
  public hasBaseDropZoneOver = false;
  allowedFileTypes = '';
  @Input() files: FileDoc[] = [];
  newFiles: File[] = [];
  // 5 mb is max size for file
  maxSizeAllowed: number;
  invalidFileType: boolean;
  invalidFileTypeMessage = '';

  constructor(
    public fileUploadService: FileUploadService
  ) { }

  ngOnInit() {
    this.allowedFileTypes = GLOBAL.ALLOWED_FILE_TYPES;
    this.maxSizeAllowed = GLOBAL.ALLOWED_FILE_SIZE * 1024;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = true;
  }

  public fileOverAnother(e: any): void {
    this.hasBaseDropZoneOver = false;
  }

  updateFilesArray(newFiles) {
    Array.from(newFiles).forEach((file: File) => {
      this.files.push({
        file
      });
    });
    this.filesChanged.emit(this.files);
    this.fileUploadService.files = this.files;
  }

  fileUploadedFromInput(event) {
    this.invalidFileType = false;
    const newFiles = event.target.files;
    if (newFiles && newFiles.length > 0) {
      if (this.checkValidFileType(newFiles)) {
        this.updateFilesArray(newFiles);
      } else {
        this.invalidFileType = true;
      }
    }
  }

  checkValidFileType(files): boolean {
    const name = files[0].name;
    const fileExtension = name.split('.').length === 2 ? `.${name.split('.')[1]}` : '';
    if (!this.allowedFileTypes.includes(fileExtension)) {
      this.invalidFileTypeMessage = 'Only .jpeg, .png, .pdf and .docx file are allowed.';
      return false;
    }
    if (!this.checkValidFileSize(files)) {
      this.invalidFileTypeMessage = 'Max file size allowed is 5MB.';
      return false;
    }

    return true;
  }

  checkValidFileSize(files): boolean {
    const size = files[0].size;
    return size / 1024 < this.maxSizeAllowed;
  }

  checkForAllowedFileTypes(newFiles) {
  }

  fileUploadedFromDrop(event) {
    // const newFiles = event.dataTransfer.files;
    // this.updateFilesArray(newFiles);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.filesChanged.emit(this.files);
  }

  updateDocType() {
    this.filesChanged.emit(this.files);
  }

}
