import { Component, OnInit, ViewChild } from '@angular/core';
import { AgNumberCountComponent, AgActionCellRendererComponent } from '../../core/components';
import { Router } from '@angular/router';
import { WorkOrdersService } from './work-orders.service';
import * as moment from 'moment';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { WorkOrderGroupRowComponent } from './work-order-group-row/work-order-group-row.component';
import { WorkOrderCellRendererComponent } from './work-order-cell-renderer/work-order-cell-renderer.component';
import { SharedService } from 'src/app/core/services';
import { GridApi, GridOptions } from 'ag-grid-community';
import { GLOBAL } from 'src/app/app.globals';
@Component({
  selector: 'app-work-orders',
  templateUrl: './work-orders.component.html',
  styleUrls: ['./work-orders.component.css']
})
export class WorkOrdersComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  ForContractorLogin = true;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  groupingMeta = ['CompanyName'];

  contractorType = 1;
  columnDefs: any;
  rowData = [];
  numberOfRowsPerPage = 10;
  pageList = [
    10, 20, 50, 100
  ];
  gridAPI: GridApi;
  rowClassRules;
  showSpinner: boolean;

  // gridOptions = <GridOptions>{
  //   context: {
  //     componentParent: this
  //   },
  //   getRowHeight: (params) => {
  //     return 37;
  //   },
  //   // getRowWidth: (params) => {
  //   //   return 40;
  //   // },
  //   onGridReady: (params) => {
  //     this.gridAPI = params.api;
  //     params.api.sizeColumnsToFit();
  //   },
  //   onGridSizeChanged: (params) => {
  //     params.api.sizeColumnsToFit();
  //   },
  //   icons: {
  //     sortNone: '<i class="fa fa-sort"/>',
  //     sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
  //     sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
  //   },
  //   pagination: true,
  //   paginationPageSize: this.numberOfRowsPerPage,
  //   headerHeight: 50,
  // };

  constructor(private router: Router,
    // tslint:disable-next-line:align
    private workOrderService: WorkOrdersService,
    private sharedService: SharedService) { }

  ngOnInit() {

    const rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (rolecode === 119)
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = false;
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.ForContractorLogin = true;
    }
    this.createColumnDef();
    // this.rowClassRules = {
    //   'group-row': (params) => {
    //     return params.data.groupLevel === 0;
    //   },
    // };
    this.getWorkOrderList(this.contractorType);
  }

  getWorkOrderList(contractorType: number) {

    this.workOrderService.getAllWorkOrders(contractorType).subscribe((res: []) => {
      if (res.length > 0) {
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, res);
        this.rowData = tempRowData;
      } else {
        const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, null);
        this.rowData = tempRowData;
        this.showSpinner = false;
      }
    });
  }

  onTabChange(event) {
    this.getWorkOrderList(event.nextId);
  }

  editWorkOrder(rowData) {
    this.router.navigate([`/contractor/edit-work-order/${rowData.WorkOrder_Code}`]);
  }

  deleteWorkOrder(rowData) {
    if (confirm('Are you sure to delete this work order?')) {
      this.workOrderService.deleteWorkOrder(rowData.WorkOrder_Code).subscribe((res: any) => {
        if (res.Data === 1) {
          this.rowData = this.rowData.filter(item => item.WorkOrder_Code !== rowData.WorkOrder_Code);
          this.openAlert('Success!!!', 'success', 'Work order is deleted successfully.');
        } else {
          this.openAlert('Error!!!', 'error', 'Failed to delete work order.');
        }
      });
    }
  }

  private openAlert(title: string, type: any, message: string): void {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`,
      timer: 2000
    };
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  private createColumnDef(): void {
    this.columnDefs = [
      {
        headerName: '#', field: '', width: 30,
        sortable: false, suppressMenu: true, cellRenderer: this.rowIndexCellRenderer, pinned: 'left',
      },
      {
        headerName: 'Contractor Name',
        field: 'CompanyName',
        width: 300, pinned: 'left',
        hide: false,
        colSpan: (params) => {
          return params.data.parent ? 12 : 1;
        },
        cellRendererFramework: WorkOrderGroupRowComponent,
        cellRendererParams: {
          groupingData: this.groupingMeta
        }
      },
      {
        headerName: 'Plant Name', field: 'ParentPlantName',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Work Order', field: 'WorkOrder_Code',
        width: 100, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      // {
      //   headerName: 'Contractor Name', field: 'CompanyName', width: 250,
      //   filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
      //   cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      // },
      {
        headerName: 'Order Owner', field: 'Owner', width: 150,
        filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Department', field: 'DepartmentName', width: 100,
        filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Work Order No', field: 'WorkOrderNo', width: 200,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Start Date', field: 'OrderStartDate', width: 100,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellRenderer: (params) => {
          if (!params.data.parent) {
            return moment(params.value).format('DD/MM/YYYY');
          }
        },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'End Date', field: 'OrderEndDate', width: 100,
        filter: true, filterParams: { applyButton: true, clearButton: true }, sortable: true,
        cellRenderer: (params) => {
          if (!params.data.parent) {
            return moment(params.value).format('DD/MM/YYYY');
          }
        },
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Person', field: 'Person', width: 80,
        filterParams: { applyButton: true, clearButton: true }, filter: true, sortable: true,
        cellStyle: { textAlign: 'center' }, headerClass: 'align-center'
      },
      {
        headerName: 'Action', field: '', width: 100,
        headerComponentParams: { enableSorting: false },
        cellRendererFramework: AgActionCellRendererComponent
      }
    ];
  }

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px">${params.data.rowIndex}</div>`;
    }
    return '<div></div>';
  }

  updateNumberOfRowsPerPage() {
    this.gridAPI.paginationSetPageSize(this.numberOfRowsPerPage);
    this.gridAPI.sizeColumnsToFit();
  }

  onEdit(params) {
    console.log('edit');
  }

  onDelete(params) {
    console.log('delete');
  }
}
