import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedDataService } from '../../services/shared-data.service';
import { filter, map, tap } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})


export class SidebarComponent implements OnInit, OnDestroy {
  menuListDataSubscription: Subscription;
  openedMenu = '';
  openedSubMenu = 'MPM';

  public sideBarItems: any = [];

  constructor(
    private sharedDataService: SharedDataService
  ) {
    this.menuListDataSubscription = this.sharedDataService.getMenuList().subscribe((menuList: any) => {
      const leftBarMenus = menuList.filter(item => item.MenuType === 1);
      if (leftBarMenus.length > 0) {
        this.sideBarItems = leftBarMenus[0].Menus;
        this.sideBarItems.forEach((menu: any) => {
          menu.menuIcon = menu.MenuIcon ? menu.MenuIcon : 'icon-saDC-hold';
        });
      }
      console.log(this.sideBarItems);
      setTimeout(() => {

        this.verticalScrollMenus();
      }, 500);
    });
  }

  ngOnInit() {

    // this.staticMenuItems();

    // setTimeout(() => {

    //   this.verticalScrollMenus();
    // }, 500);
  }

  toggleMenu(menuName: string) {
    if (this.openedMenu === '' || this.openedMenu !== menuName) {
      this.openedMenu = menuName;
    } else {
      this.openedMenu = '';
    }
  }

  toggleSubMenu(menuName: string) {
    if (this.openedSubMenu === '' || this.openedSubMenu !== menuName) {
      this.openedSubMenu = menuName;
    } else {
      this.openedSubMenu = '';
    }
  }

  verticalScrollMenus() {
    const $container = $('#sidebarWrapper');
    const $blocks = $('#items');
    const heightContainer = $container.outerHeight();
    const scrolledHeight = $container[0].scrollHeight;
    let mousePos = 0;
    let hDiff = 0;

    $container.mousemove(function (e) {
      // console.log('move',e);
      hDiff = (scrolledHeight / heightContainer) - 1;

      mousePos = e.pageY - this.offsetTop;
      if ($blocks.height() > e.currentTarget.clientHeight && mousePos > 0) {
        setTimeout(() => {
          $blocks.css({ marginTop: - mousePos * hDiff });
        }, 10);
      }
    });
  }

  staticMenuItems() {
    this.sideBarItems = [
      {
        MenuName: 'dashboard',
        Title: 'Dashboard',
        menuIcon: 'fa fa-pie-chart',
        subMenu: [
          {
            MenuName: 'yearly',
            Title: 'Yearly',
            link: '/dashboard/yearly'
          }, {
            MenuName: 'monthly',
            Title: 'Monthly',
            link: '/dashboard/monthly'
          }
        ]
      }, {
        MenuName: 'contractor',
        Title: 'Contractor',
        menuIcon: 'icon-Employee2',
        subMenu: [
          {
            MenuName: 'contractors',
            Title: 'Contractor',
            link: '/contractor/contractors'
          }, {
            MenuName: 'workOrders',
            Title: 'Work Orders',
            link: '/contractor/work-orders'
          }, {
            MenuName: 'ecPolicies',
            Title: 'E.C. Policies',
            link: '/contractor/ec-policies'
          }, {
            MenuName: 'licences',
            Title: 'Licenses',
            link: '/contractor/licences'
          }
        ]
      }, {
        MenuName: 'labour',
        Title: 'Labour',
        menuIcon: 'icon-Labour5',
        subMenu: [
          {
            MenuName: 'labours',
            Title: 'Contract Man',
            link: '/labour/labours'
          }, {
            MenuName: 'gatePassRequest',
            Title: 'Gate Pass Requests',
            link: '/labour/gate-pass-requests'
          }, {
            MenuName: 'bulkCanellation',
            Title: 'Bulk Cancellation',
            link: '/labour/bulk-cancellation'
          }
        ]
      },
      // {
      //   MenuName: 'hrClearance',
      //   Title: 'HR Clearance',
      //   menuIcon: 'fa icon-target-of-audience',
      //   link: '/hrc/list'
      // },
      // {
      //   MenuName: 'grievance',
      //   Title: 'Grievance',
      //   menuIcon: 'fa icon-Work-Order',
      //   // link: '/grievance',
      //   subMenu: [
      //     {
      //       MenuName: 'grievances',
      //       Title: 'Grievances',
      //       link: '/grievance/grievances'
      //     }]
      // },
      // {
      //   MenuName: 'communication',
      //   Title: 'Communication',
      //   menuIcon: 'icon-Megaphone1',
      //   link: '/communication'
      // },
      // {
      //   MenuName: 'calender',
      //   Title: 'Calender',
      //   menuIcon: 'icon-calendar-and-clock',
      //   subMenu: [
      //     {
      //       MenuName: 'calendars',
      //       Title: 'Calender',
      //       link: '/calendar/calendars'
      //     }, {
      //       MenuName: 'complianceTasks',
      //       Title: 'Compliance Task Calander',
      //       link: '/calendar/compliance-tasks'
      //     }
      //   ]
      // },
      // {
      //   MenuName: 'attendance',
      //   Title: 'Attendance',
      //   menuIcon: 'icon-calendar',
      //   subMenu: [
      //     {
      //       MenuName: 'MPM',
      //       Title: 'Manual Punch Management',
      //       subMenu: [
      //         {
      //           MenuName: 'employeeWise',
      //           Title: 'Employee Wise',
      //           link: '/attendance/employee-attendance'
      //         }, {
      //           MenuName: 'contractorWise',
      //           Title: 'Contractor Wise',
      //           link: '/attendance/contractor-attendance'
      //         }
      //       ]
      //     },
      // {
      //   MenuName: 'attendanceApproval',
      //   Title: 'Approval',
      //   link: '/attendance/approve-attendance'
      // },
      // {
      //   MenuName: 'weekOffChange',
      //   Title: 'Week Off Changes',
      //   link: '/attendance/weekoff-changes'
      // },
      // {
      //   MenuName: 'labourweekOffChange',
      //   Title: 'Labour Week Off Changes',
      //   link: '/attendance/labour-weekoff-changes'
      // }
      //   ]
      // },
      {
        MenuName: 'user-management',
        Title: 'User Management',
        menuIcon: 'icon-businessman',
        subMenu: [
          {
            MenuName: 'Module-Role',
            Title: 'Module Role Management',
            link: '/user-management/module-role'
          }, {
            MenuName: 'module-page',
            Title: 'Module Wise Page',
            link: '/user-management/module-page'
          }, {
            MenuName: 'Page-action',
            Title: 'Page Wise Action',
            link: '/user-management/page-action'
          }
        ]
      },
      // {
      //   MenuName: 'yearly-budget',
      //   Title: 'Yearly Budget',
      //   menuIcon: 'icon-businessman',
      //   link: '/budget/yearly-budget'
      // },
      {
        MenuName: 'man-power',
        Title: 'Man Power',
        menuIcon: 'fa fa-pie-chart',
        subMenu: [
          {
            MenuName: 'man-power',
            Title: 'man-power',
            link: '/man-power/man-power',
          },
          {
            MenuName: 'daily-man-power-location-assign',
            Title: 'Daily Man Power Location',
            link: '/man-power/man-power-location-assign',
          }
        ]
      },
      // {
      //   MenuName: 'Assign-Department_training',
      //   Title: 'Assign-Department_training',
      //   menuIcon: '/assign-department-training',
      // },
      // {
      //   MenuName: 'company-commons-master',
      //   Title: 'Company Commons Master',
      //   menuIcon: 'icon-calendar',
      //   subMenu: [
      //     {
      //       MenuName: 'shift-master',
      //       Title: 'Shift-Master',
      //       link: '/company-commons-master/shift-master',
      //     },
      //     {
      //       MenuName: 'shift-group',
      //       Title: 'Shift-Group',
      //       link: '/company-commons-master/shift-group',
      //     },
      //     {
      //       MenuName: 'plant-master',
      //       Title: 'Plant',
      //       link: '/company-commons-master/plant-master',
      //     },
      //     {
      //       MenuName: 'holiday-master',
      //       Title: 'Holiday',
      //       link: '/company-commons-master/holiday-master',
      //     },
      //     {
      //       MenuName: 'punch-from-reader',
      //       Title: 'PunchFromReader',
      //       link: '/clpms-reports/punch-from-reader',
      //     }
      //   ]
      // },

      // {
      //   MenuName: 'man-power-yearly',
      //   Title: 'Man Power Yearly',
      //   menuIcon: 'icon-businessman',
      //   link: '/man-power-yearly/man-power-yearly'
      // },
      // {
      //   MenuName: 'report-builder',
      //   Title: 'Report builder',
      //   menuIcon: 'icon-dashboard-pie',
      //   link: '/report/report-builder'
      // },
      // {
      //   MenuName: 'Shift',
      //   Title: 'Shift',
      //   menuIcon: 'icon-Labour5',
      //   subMenu: [
      //     {
      //       MenuName: 'ShiftGroup',
      //       Title: 'Shift Group',
      //       link: '/shift/shift-group'
      //     }
      //   ]
      // },
      {
        MenuName: 'Reports',
        Title: 'Reports',
        menuIcon: 'icon-dashboard-pie',
        subMenu: [
          {
            MenuName: 'EntryForTheDay',
            Title: 'Entry For The Day',
            link: '/reports/entryforday'
          },
          {
            MenuName: 'DailyAttendance',
            Title: 'Daily Attendance',
            link: '/reports/daily-attendance-rpt'
          },
          {
            MenuName: 'DailyAttendanceUnitWiset',
            Title: 'Daily Attendance UnitWise',
            link: '/reports/entryforthedaydailyunitwisehrs'
          },
          {
            MenuName: 'MenPowerAllocation Report',
            Title: 'MenPowerAllocation Report',
            link: '/reports/manpowerreqallocationsummary'
          },
          {
            MenuName: 'TimeCardReport',
            Title: 'Time Card Report',
            link: '/reports/timecard-report'
          },
          {
            MenuName: 'TimeCardPlantWiseReport',
            Title: 'Time Card Plant Wise Report',
            link: '/reports/timecard-plantwise-report'
          },
          {
            MenuName: 'PunchFromReaderReport',
            Title: 'Punch From Reader Report',
            link: '/reports/punchfromreaderreport'
          }
        ]
      }
    ];
  }

  ngOnDestroy() {
    this.menuListDataSubscription.unsubscribe();
  }
}
