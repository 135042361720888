import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulewisePageComponent } from './modulewise-page/modulewise-page.component';
import { PagewiseActionComponent } from './pagewise-action/pagewise-action.component';
import { ModuleRoleManagementComponent } from './module-role-management/module-role-management.component';
import { CreateUserInfoComponent } from './create-user-info/create-user-info.component';
import { UserListComponent } from './user-list/user-list.component';
import { AuthGuardService } from '../guards/auth-guard.service';

const routes: Routes = [
  {
    path: 'module-page',
    component: ModulewisePageComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'page-action',
    component: PagewiseActionComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'module-role',
    component: ModuleRoleManagementComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'create-user',
    component: CreateUserInfoComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'user-list',
    component: UserListComponent,
    canActivate: [AuthGuardService]
  }, {
    path: 'edit-user/:id',
    component: CreateUserInfoComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
