import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewChecked, OnDestroy, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Checklist, WizardStep } from 'src/app/core/components';
import { UserManagementService } from '../user-management.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/core/services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-create-user-info',
  templateUrl: './create-user-info.component.html',
  styleUrls: ['./create-user-info.component.css']
})
export class CreateUserInfoComponent implements OnInit, AfterViewChecked, OnDestroy, AfterViewInit {

  formChangesSubscription: Subscription;
  @ViewChild('userInfoForm') userInfoForm: NgForm;
  checkList: Checklist[] = [];
  wizardSteps: WizardStep[] = [];
  roleMasterData = [];
  designationMasterData = [];
  organizationMasterData = [];
  organizationAll = [];
  organizationCopy = [];
  editMode = false;
  errcuserConfirmpassword = false;
  erruserpassword = false;
  selectedOrg = [];
  userCode = '';
  languageData = [];
  timezoneData = [];
  accessPermissionTree = [];
  designation = [];
  userId: string;
  userIdP: any;
  organizationcode: any;
  companycode: any;
  plantcode: any;
  departmentcode: any;
  designationcode: any;
  rolecode: any;
  departmentSectionList = [];
  selectedRoles = [];
  selectedRoleCodes = [];


  constructor(
    private userManagementService: UserManagementService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }

  wizardValidation() {
    let flag = false;
    if (this.userInfoForm.controls.userName) {
      flag = this.userInfoForm.controls.userName.valid
        && this.userInfoForm.controls.firstName.valid
        && this.userInfoForm.controls.email.valid
        && this.userInfoForm.controls.userpassword.valid
        && this.userInfoForm.controls.userConfirmpassword.valid
        && this.userInfoForm.controls.mobileNo.valid
        // && this.designation.length > 0
        && this.userInfoForm.form.controls.role.value !== ''
        && this.userInfoForm.form.controls.organization.value !== '';
    } else {
      flag = false;
    }
    return flag;
  }

  stepChange($event) {
    if ($event.nextStep === 1) {
      this.selectedRoles = this.roleMasterData.filter(item => this.selectedRoleCodes.indexOf(item.Role_Code) > -1);
    }
  }

  ngAfterViewInit() {
    this.userIdP = this.route.snapshot.paramMap.get('id');
    this.getUserDetails(this.userIdP);
    this.getDepartmentSectionList();
    this.wizardSteps = [
      {
        id: 'userInfo',
        name: 'User info',
        isValid: () => {
          return this.wizardValidation();
        }
      }
      // ,
      // {
      //   id: 'departmentPermission',
      //   name: 'Department Access & Permission',
      //   isValid: () => { return true; },
      // },
      // {
      //   id: 'accessPermission',
      //   name: 'Access & Permission',
      //   isValid: () => { return true; },
      // }
    ];

    setTimeout(() => {
      this.checkList = [
        {
          id: 1,
          text: 'User Info',
          open: true,
          childrens: [
            {
              text: 'User Name is required',
              title: 'User Name',
              desc: 'User Name is required.',
              isValid: () => {
                return (this.userInfoForm.controls.userName.valid);
              },
              hasError: () => {
                return ((this.userInfoForm.controls.userName.dirty || this.userInfoForm.controls.userName.touched)
                  && this.userInfoForm.controls.userName.invalid);
              },
              isMandatory: true,
              isVisible: true,
              step: '1',
            },
            {
              text: 'First Name is required',
              title: 'First Name',
              desc: 'First Name is required.',
              isValid: () => {
                return (this.userInfoForm.controls.firstName.valid);
              },
              hasError: () => {
                return ((this.userInfoForm.controls.firstName.dirty || this.userInfoForm.controls.firstName.touched)
                  && this.userInfoForm.controls.firstName.invalid);
              },
              isMandatory: true,
              isVisible: true,
              step: '1',
            },
            {
              text: 'Password is required',
              title: 'Password',
              desc: 'Password is required.',
              isValid: () => {
                return (this.userInfoForm.controls.userpassword.valid);
              },
              hasError: () => {
                return ((this.userInfoForm.controls.userpassword.dirty || this.userInfoForm.controls.userpassword.touched)
                  && this.userInfoForm.controls.userpassword.invalid);
              },
              isMandatory: true,
              isVisible: true,
              step: '1',
            },
            {
              text: 'Confirm Password is required',
              title: 'Confirm Password',
              desc: 'Confirm Password is not match.',
              isValid: () => {
                return (this.userInfoForm.controls.userConfirmpassword.valid && !this.errcuserConfirmpassword);
              },
              hasError: () => {
                return ((this.userInfoForm.controls.userConfirmpassword.dirty || this.userInfoForm.controls.userConfirmpassword.touched)
                  && this.userInfoForm.controls.userConfirmpassword.invalid || this.errcuserConfirmpassword);
              },
              isMandatory: true,
              isVisible: true,
              step: '1',
            },
            {
              text: 'Email is required',
              title: 'Email Id',
              desc: 'Please provide valid Email Id.',
              isValid: () => { return this.userInfoForm.controls.email.valid; },
              hasError: () => {
                return ((this.userInfoForm.controls.email.dirty || this.userInfoForm.controls.email.touched)
                  && this.userInfoForm.controls.email.invalid);
              },
              isMandatory: true,
              isVisible: true,
              step: '1',
            },
            {
              text: 'Mobile Number',
              title: 'Mobile',
              desc: 'Please provide 10 digit Mobile number.',
              isValid: () => { return this.userInfoForm.controls.mobileNo.valid; },
              hasError: () => {
                return ((this.userInfoForm.controls.mobileNo.dirty || this.userInfoForm.controls.mobileNo.touched)
                  && this.userInfoForm.controls.mobileNo.invalid);
              },
              isMandatory: false,
              isVisible: true,
              step: '1',
            },
            // {
            //   text: 'Designation is required',
            //   title: 'Designation',
            //   desc: 'Designation is required.',
            //   isValid: () => {
            //     // tslint:disable-next-line:max-line-length
            //     return this.designation.length > 0;
            //   },
            //   hasError: () => {
            //     return this.designation.length === 0;
            //   },
            //   isMandatory: true,
            //   isVisible: true,
            //   step: '1',
            // },
            {
              text: 'Role is required',
              title: 'Role',
              desc: 'Role is required.',
              isValid: () => {
                return (this.userInfoForm.form.value.role && this.userInfoForm.form.value.role.length > 0);
              },
              hasError: () => {
                return ((this.userInfoForm.controls.role.dirty || this.userInfoForm.controls.role.touched)
                  && this.userInfoForm.form.value.role.length === 0);
              },
              isMandatory: true,
              isVisible: true,
              step: '1',
            },
            {
              text: 'Organization is required',
              title: 'Organization',
              desc: 'Organization is required.',
              isValid: () => {
                return (this.userInfoForm.form.value.organization && this.userInfoForm.form.value.organization.length > 0);
              },
              hasError: () => {
                return ((this.userInfoForm.controls.organization.dirty || this.userInfoForm.controls.organization.touched)
                  && this.userInfoForm.form.value.organization.length === 0);
              },
              isMandatory: true,
              isVisible: true,
              step: '1',
            }
          ],
        }
      ];
    }, 500);

  }

  getUserDetails(userIdP: any) {
    // tslint:disable-next-line: indent
    this.userManagementService.getUserInformationById(userIdP).subscribe((res: any) => {
      if (res.length > 0) {

        const userinfo = res.Data;
        if (!userinfo) {
          return;
        }
        this.userInfoForm = userinfo;
      }

    });
  }
  designationError() {
    let flag = false;
    if (this.userInfoForm.controls.designation.dirty || this.userInfoForm.controls.designation.touched) {
      flag = !this.designationValidation();
    } else {
      flag = false;
    }
    return flag;
  }

  designationValidation() {
    let flag = false;
    if (this.userInfoForm.form.controls.designation.value === '' ||
      this.userInfoForm.form.controls.designation.value === null) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  }

  async ngOnInit() {
    this.formChangesSubscription = this.userInfoForm.form.valueChanges.subscribe((form) => {
      if (form['mobileNo'] === '') {
        form['mobileNo'] = null;
      }
    });

    await this.userManagementService.getUserRoleList().subscribe((roleData: any) => {
      this.roleMasterData = roleData;
    });

    await this.userManagementService.getOrganizationList().subscribe((organizationData: any) => {

      this.organizationMasterData = organizationData;  // New KH 31-05-2020 Tree DropDown Temp Comment
      // this.organizationAll = organizationData;      // Old KH 31-05-2020  Temp Comment
      // this.bindOrganizationEdit();                  // Old KH 31-05-2020  Temp Comment
    });

    await this.userManagementService.getDesignationList().subscribe((designationData: any) => {
      this.designationMasterData = designationData;
    });

    await this.userManagementService.getUserCode().subscribe((userCode: any) => {
      this.userCode = userCode;
    });

    await this.userManagementService.getLanguageData().subscribe((data: any) => {
      this.languageData = data;
    });

    await this.userManagementService.getTimeZoneData().subscribe((data: any) => {
      this.timezoneData = data;
    });

    this.userManagementService.actionPermission().subscribe((data: any) => {
      this.accessPermissionTree = this.bindModuleList(data);
    });

    this.userId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.userId) {
      this.editMode = true;
      await this.userManagementService.getUserInformationById(this.userId)
        .subscribe((response: any) => {
          if (response.length > 0) {
            this.userResMpping(response[0]);
          }
        });
    }
  }

  bindModuleList(accessPermissionData) {
    const moduleData = [];
    if (accessPermissionData.modules.length) {
      accessPermissionData.modules.forEach((element) => {
        const pageListData = element.pages;
        const obj = Object.assign({}, {
          moduleId: element.moduleId, moduleName: element.moduleName
        });
        // tslint:disable-next-line:max-line-length
        moduleData.push({ value: element.moduleId, text: element.moduleName, checked: false, children: this.bindPageList(pageListData, obj, accessPermissionData.actions) });
      });
    }
    return moduleData;
  }

  bindPageList(pageListData, moduleObj, actionList) {
    const pageListModel = [];
    if (pageListData.length > 0) {
      pageListData.forEach((element) => {
        const selectedActionId = element.selectedActionIds;
        const pageObj = Object.assign({}, moduleObj);
        pageObj['pageId'] = element.pageId;
        pageObj['pageName'] = element.pageName;
        // tslint:disable-next-line:max-line-length
        pageListModel.push({ value: element.pageId, text: element.pageName, moduelId: moduleObj.moduleId, checked: false, children: this.bindActionList(selectedActionId, pageObj, actionList) });
      });
    }
    return pageListModel;
  }

  bindActionList(selectedActionIds, pageObj, actionList) {
    const actionListData = [];
    if (actionList.length > 0) {
      actionList.forEach((element) => {
        const flag = selectedActionIds.indexOf(element.actionId) > -1 ? true : false;
        const pageAction = Object.assign({}, pageObj);
        pageAction['actionId'] = element.actionId;
        actionListData.push({ value: pageAction, text: element.actionName, checked: flag, children: [] });
      });
    }
    return actionListData;
  }

  onFilterChange(value: string) {
    console.log('filter:', value);
  }

  bindOrganizationEdit() {
    this.organizationMasterData = this.bindDataOrganization(this.organizationAll);
    this.organizationCopy = this.organizationMasterData;
  }

  bindDataOrganization(filteredData) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.CompanyViews;
      if (subDataFilter.length > 0) {
        // tslint:disable-next-line:max-line-length
        convertedData.push({ id: val.Organization_Code, organizationCode: val.Organization_Code, name: val.Name, currentname: 'organization', isActive: false, selected: false, children: this.bindDataCompany(subDataFilter, val.Organization_Code) });
      } else {
        // tslint:disable-next-line:max-line-length
        convertedData.push({ id: val.Organization_Code, organizationCode: val.Organization_Code, name: val.Name, currentname: 'organization', isActive: false, selected: false });
      }
    });
    return convertedData;
  }

  bindDataCompany(filteredData, organizationCode) {
    const convertedData = [];
    filteredData.forEach((val: any, key) => {
      const subDataFilter = val.Plantviews;
      if (subDataFilter.length > 0) {
        // tslint:disable-next-line:max-line-length
        convertedData.push({
          id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
          // tslint:disable-next-line:object-literal-shorthand
          organizationCode: organizationCode, currentname: 'Company', isActive: false,
          selected: false, children: this.bindDataPlants(subDataFilter, organizationCode, val.Company_Code)
        });
      } else {
        // tslint:disable-next-line:max-line-length
        convertedData.push({
          id: val.Company_Code, companyCode: val.Company_Code, name: val.Name,
          // tslint:disable-next-line:object-literal-shorthand
          organizationCode: organizationCode, currentname: 'Company', isActive: false, selected: false
        });
      }
    });
    return convertedData;
  }

  bindDataPlants(filteredData, organizationCode, companyCode) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      const subDataFilter = val.DepartmentViews;
      if (subDataFilter.length > 0) {
        // tslint:disable-next-line:max-line-length
        convertedData.push({
          id: val.Plant_Code, plantCode: val.Plant_Code, name: val.Name,
          // tslint:disable-next-line:object-literal-shorthand
          organizationCode: organizationCode, companyCode: companyCode, currentname: 'Plant',
          isActive: false, selected: false,
          children: this.bindDataDepartments(subDataFilter, organizationCode, companyCode, val.Plant_Code)
        });
      } else {
        // tslint:disable-next-line:max-line-length
        convertedData.push({
          id: val.Plant_Code, plantCode: val.Plant_Code, name: val.Name,
          // tslint:disable-next-line:object-literal-shorthand
          organizationCode: organizationCode, companyCode: companyCode, currentname: 'Plant', isActive: false, selected: false
        });
      }
    });
    return convertedData;
  }

  bindDataDepartments(filteredData, organizationCode, companyCode, plantCode) {
    const convertedData = [];
    filteredData.forEach((val, key) => {
      // tslint:disable-next-line:max-line-length
      convertedData.push({
        id: val.Department_Code, departmentCode: val.Department_Code, name: val.Name,
        // tslint:disable-next-line:object-literal-shorthand
        organizationCode: organizationCode, companyCode: companyCode, plantCode: plantCode,
        currentname: 'Department', isActive: false, selected: false
      });
    });
    return convertedData;
  }

  userResMpping(data) {
    this.userCode = data.UserCode;
    this.userInfoForm.form.controls.userName.setValue(data.UserName);
    this.userInfoForm.form.controls.firstName.setValue(data.FirstName);
    this.userInfoForm.form.controls.middleName.setValue(data.MiddleName);
    this.userInfoForm.form.controls.lastName.setValue(data.LastName);
    this.userInfoForm.form.controls.userpassword.setValue(data.Password);
    this.userInfoForm.form.controls.userConfirmpassword.setValue(data.Password);
    this.userInfoForm.form.controls.email.setValue(data.AlternetEmailID);
    this.userInfoForm.form.controls.mobileNo.setValue(data.MobileNo);
    this.userInfoForm.form.controls.alias.setValue(data.Alias);
    this.userInfoForm.form.controls.language.setValue(data.Language_Code);
    this.userInfoForm.form.controls.designation.setValue(data.Designation_Code);
    this.userInfoForm.form.controls.TimeZone_Code.setValue(data.TimeZone_Code);
    this.userInfoForm.form.controls.isOrganization.setValue(data.IsOrganizationUser);
    if (data.Role && data.Role.length > 0) {
      this.userInfoForm.form.controls.role.setValue(data.Role);
    }
    if (data.IsOrganizationUser === 1) {
      this.userInfoForm.form.controls.isOrganization.setValue(true);
    }
    this.organizationcode = data.Organization_Code;
    this.companycode = data.Company_Code;
    this.plantcode = data.Plant_Code;
    this.departmentcode = data.Department_Code;
    this.designationcode = data.Designation_Code;
    this.rolecode = data.Role_Code;

    this.userInfoForm.form.controls.organization.setValue(data.UserDepartmentSections);
    data.UserDepartmentSections.forEach((val, key) => {
      this.fillDataOrg(this.organizationCopy, val);
    });
  }

  fillDataOrg(organizationBind, selectedData) {
    organizationBind.forEach((item, key) => {
      if (item.children != null) {
        this.fillDataOrg(item.children, selectedData);
      }
      if (item.organizationCode === selectedData.Organization_Code) {
        if (item.companyCode === selectedData.Company_Code) {
          if (item.plantCode === selectedData.Plant_Code) {
            if (item.departmentCode === selectedData.Department_Code) {
              item.selected = true;
              item.isActive = true;
            }
            if (selectedData.Department_Code == null) {
              item.selected = true;
              item.isActive = true;
            }

          }
          if (selectedData.Plant_Code == null) {
            item.selected = true;
            item.isActive = true;
          }
        }
        if (selectedData.Company_Code == null) {
          item.selected = true;
          item.isActive = true;
        }
      }
    });
  }

  async onSubmit(formData, redirect = true, reset = false) {
    const user = {
      UserCode: this.userCode,
      UserName: formData.userName,
      FirstName: formData.firstName,
      MiddleName: formData.middleName,
      LastName: formData.lastName,
      Password: formData.userpassword,
      AlternetEmailID: formData.email,
      MobileNo: formData.mobileNo,
      Alias: formData.alias,
      Language_Code: formData.language,
      Designation_Code: formData.designation,
      TimeZone_Code: formData.TimeZone_Code,
      IsOrganizationUser: formData.isOrganization,
    };

    user['role'] = formData.role;
    user['UserDepartmentSections'] = [];
    this.selectedOrg.filter((val) => {
      const userDeprt = {};
      userDeprt['Organization_Code'] = val[0].organizationCode;
      userDeprt['Company_Code'] = val[0].companyCode;
      userDeprt['Plant_Code'] = val[0].plantCode;
      userDeprt['Department_Code'] = val[0].departmentCode;
      userDeprt['User_Code'] = this.userCode;
      user['UserDepartmentSections'].push(userDeprt);
    });
    if (this.editMode) {
      await this.userManagementService.updateUser(user).subscribe((result: any) => {
        if (result === 'Success') {
          this.router.navigate(['user-list']);
        }
      });
    } else {
      await this.userManagementService.addUser(user).subscribe((result: any) => {
        if (reset) {
          this.clearForm();
          setTimeout(() => {
            this.userManagementService.getUserCode().subscribe((userCode: any) => {
              this.userCode = userCode;
            });
          }, 200);
        }
        if (result.Data.Message === 'UserInformation Added Successfully!') {
          this.router.navigate(['user-list']);
        }
      });
    }
  }

  clearForm() {
    const userCode = this.userCode;
    this.userInfoForm.reset();
    setTimeout(() => {
      this.userCode = userCode;
    }, 200);
  }

  saveAndNew() {
    this.onSubmit(this.userInfoForm.value, false, true);
  }

  updateAndNew() {
    this.onSubmit(this.userInfoForm.value, false, false);
  }

  userpasswordChange() {
    const userInfoForm = this.userInfoForm.form.value;
    if (userInfoForm.userpassword !== userInfoForm.userpassword) {
      this.erruserpassword = true;
    } else {
      this.erruserpassword = false;
    }
  }

  confirmpasswordChange(event) {
    const userInfoForm = this.userInfoForm.form.value;
    if (userInfoForm.userpassword !== userInfoForm.userConfirmpassword) {
      this.errcuserConfirmpassword = true;
    } else {
      this.errcuserConfirmpassword = false;
    }
  }

  async onSelecteOrg(data) {
    let items = [];
    if (data && data.length > 0) {
      data.filter((res) => {
        items = this.findAll(res, this.organizationCopy);
      });
      this.selectedOrg.push(items);
      this.selectedOrg = _.uniqWith(this.selectedOrg, _.isEqual);
    }
  }

  findAll(data, items) {
    let found;
    let result = [];
    items.filter((res) => {
      if (res.id === data.id && res.name === data.name) {
        result.push(res);
      } else if (_.isArray(res.children)) {
        found = this.findAll(data, res.children);
        if (found.length) {
          result = result.concat(found);
        }
      }
    });
    return result;
  }

  onAutoGeneratePassword() {
    const pswd = this.sharedService.generateRandomPassword(10);
    this.userInfoForm.form.controls['userpassword'].setValue(pswd);
    this.userInfoForm.form.controls['userConfirmpassword'].setValue(pswd);
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.formChangesSubscription.unsubscribe();
  }

  getDepartmentSectionList(): void {
    this.userManagementService.getDepartmentSectionList().subscribe((res) => {
      this.departmentSectionList = res;
    });
  }

  onChangeRoles(roles): void {
    this.selectedRoleCodes = roles;
  }
}
