import { Injectable } from '@angular/core';
import { ChartUtilService } from './chart-util.service';

declare var d3: any;
declare var dc: any;
declare var chroma: any;
declare var d3pie: any;

interface Config {
  crossfilter: any;
  filterGroup: string;
  chartId: string;
  groupByProperty: string;
  reduceProperty: string;
  width: number;
  height: number;

  // Optional
  crossfilter2?: any;
  legend?: boolean; // = false;
  legendValue?: boolean; // = false;
  legendPerc?: boolean; // = false;
  label?: boolean; // = false;
  labelValue?: boolean; // = false;
  labelPerc?: boolean; // = false;
  redius?: number;
  innerRadius?: number; // = 30;
  cx?: number;
  cy?: number;
  slicesCap?: number; // = 4;
  externalLabels?: boolean; // = false;
  externalRadiusPadding?: number; // = 30;
  renderLabel?: boolean; // = false;
  dispatch?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PieChartService {

  constructor(
    private chartUtil: ChartUtilService
  ) { }

  getnerateChart(config: Config, dimension, group) {
    const chart = dc.pieChart(config.chartId, config.filterGroup);

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        },
        top: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(group);

    chart
      .width(config.width)
      .height(config.height)
      .slicesCap(config.slicesCap ? config.slicesCap : 4)
      .innerRadius(config.innerRadius ? config.innerRadius : 0)
      // .renderLabel(config.renderLabel ? config.renderLabel : false)
      .drawPaths(true)
      // .externalLabels(20) // Lable Distance set from pie chart
      .dimension(dimension)
      .group(nonEmptyData)
      .on('pretransition', (pieChart) => {
        pieChart.selectAll('.pie-slice title').text((d) => {
          let text = d.data.key;
          if (config.labelValue) {
            text = `${text}: ${d.data.value}`;
          }

          // if (config.labelPerc) {
          //   text = `${text} (${this.chartUtil.roundPercentage((d.endAngle - d.startAngle) / Math.PI * 50)}%)`;
          // }

          return text;
        });

        pieChart.selectAll('.pie-slice.pie-label').text((d) => {
          let text = d.data.key;
          if (config.labelValue) {
            text = `${d.data.value}`;
          }

          if (config.labelPerc) {
            text = `${this.chartUtil.roundPercentage((d.endAngle - d.startAngle) / Math.PI * 50)}%`;
          }

          return text;
        });

        if (config.externalLabels) {
          this.setPieChartLabel(pieChart);
        }
      });

    if (config.cx) {
      chart.cx(config.cx);
    }

    if (config.cy) {
      chart.cy(config.cy);
    }

    if (config.legend) {
      chart.legend(dc.legend().x(50).y(10).itemWidth(70).horizontal(false).legendText((d) => {
        let text = d.name;
        if (config.legendValue) {
          text = `${text}`;
        }

        // if (config.legendPerc) {
        //   const totalValue = d3.sum(group.all(), d => d.value);
        //   text = `${text} (${this.chartUtil.roundPercentage((d.data / totalValue) * 100)}%)`;
        // }

        return text;
      }));
    }

    if (config.externalLabels) {
      chart.externalLabels(config.externalLabels)
        .externalRadiusPadding(config.externalRadiusPadding ? config.externalRadiusPadding : 30);
    }

    chart.render();
    return chart;
  }

  getPieChart(config: Config) {
    const dimension = config.crossfilter.dimension((d) => {
      return d[config.groupByProperty];
    });
    const group = dimension.group().reduceCount((d) => {
      return d[config.reduceProperty];
    });

    return this.getnerateChart(config, dimension, group);
  }

  getRowChartShiftWisePresentStatus(config: Config) {
    const dimension = config.crossfilter.dimension((d: any) => {
      return d.Shift;
    });
    const group = dimension.group().reduceCount((d: any) => {
      let cnt = 0;
      if (d.AttendanceStatus === 'P' && d.AttendanceStatus !== 'NF') {
        cnt++;
      }
      return cnt;
    });

    return this.getnerateChart(config, dimension, group);
  }

  getPieTotalRequirementVsShortageChart(config: Config) {
    const dimension = config.crossfilter.dimension((d: any) => {
      return 'Total';
    });
    const shortageGroup = dimension.group().reduceSum((d) => {
      return d.TotalShortage;
    });

    const requirementGroup = dimension.group().reduceSum((d) => {
      return d.TotalReq;
    });

    function combine_groups(groups) {
      return {
        all: () => {
          const parts = Object.keys(groups).map((gk) => {
            return groups[gk].all().map((kv) => {
              return { key: [gk], value: kv.value };
            });
          });
          return Array.prototype.concat.apply([], parts);
        },
        top: () => {
          const parts = Object.keys(groups).map((gk) => {
            return groups[gk].all().map((kv) => {
              return { key: [gk], value: kv.value };
            });
          });
          return Array.prototype.concat.apply([], parts);
        }
      };
    }

    const group = combine_groups({
      'Total Shortage': shortageGroup,
      'Total Requirement': requirementGroup,
    });

    return this.getnerateChart(config, dimension, group);
  }

  getPieChartQualificationNotNull(config: Config) {
    const dimension = config.crossfilter.dimension((d) => {
      if (d.Qulification !== null) {
        return d[config.groupByProperty];
      }
    });
    const group = dimension.group().reduceCount((d) => {
      return d[config.reduceProperty];
    });

    return this.getnerateChart(config, dimension, group);
  }

  getPartitionPieChart(ndx1) {
    const width = 200;
    const height = 200;
    const radius = (Math.min(width, height) / 2) - 10;

    const x = d3.scale.linear()
      .range([0, 2 * Math.PI]);

    const y = d3.scale.sqrt()
      .range([0, radius]);

    const pieDimension = ndx1.dimension((d: any) => {
      return 'Total';
    });
    const shortageGroup = pieDimension.group().reduceSum((d) => {
      return d.TotalShortage;
    });

    const deployedGroup = pieDimension.group().reduceSum((d) => {
      return d.ActualDeployed;
    });

    const myData = {
      name: 'Total',
      children: [{
        name: 'Requirement',
        children: [{
          name: 'Present',
          value: deployedGroup.all()[0].value
        }, {
          name: 'Shortage',
          value: shortageGroup.all()[0].value
        }]
      }]
    };

    const valueSum = deployedGroup.all()[0].value + shortageGroup.all()[0].value;

    const allItems = ['Requirement', 'Present', 'Shortage'];
    const myColors = myScale(allItems.length);

    const partition = d3.layout.partition()
      .value((d) => {
        return d.value;
      });

    const arc = d3.svg.arc()
      .startAngle((d) => {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x)));
      })
      .endAngle((d) => {
        return Math.max(0, Math.min(2 * Math.PI, x(d.x + d.dx)));
      })
      .innerRadius((d) => {
        return Math.max(0, y(d.y));
      })
      .outerRadius((d) => {
        return Math.max(0, y(d.y + d.dy));
      });

    const svg = d3.select('#chart').append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('id', 'container') // added
      .append('g')
      .attr('transform', `translate(${width / 2},${(height / 2)})`);

    // Add the mouseleave handler to the bounding circle.
    d3.select('#container').on('mouseleave', mouseleave); // added
    drawLegend();

    let totalValue = 0;

    const path = svg.selectAll('path')
      .data(partition.nodes(myData))
      .enter().append('path')
      .attr('d', arc)
      .style('fill', (d) => {
        if (d.parent == null) {
          return '#FAFAFA';
        }
        return myColors[allItems.indexOf(d.name)];
      })
      .on('click', click)
      .on('mouseover', mouseover) // added
      .append('title')
      .text((d) => {
        const percentage = +(100 * d.value / valueSum).toPrecision(3);
        let percentageString = `${percentage}%`;
        if (percentage < 0.1) {
          percentageString = '< 0.1%';
        }
        return `${d.name} \n${d.value} (${percentageString})`;
      })
      .classed('tooltip', true);

    totalValue = path.node().__data__.value;

    // Fade all but the current sequence, and show it in the breadcrumb trail.
    function mouseover(d) {
      const percentage = +(100 * d.value / totalValue).toPrecision(3);
      let percentageString = `${percentage}%`;
      if (percentage < 0.1) {
        percentageString = '< 0.1%';
      }

      const sequenceArray = getAncestors(d);

      // Fade all the segments.
      svg.selectAll('path')
        .style('opacity', 0.3);

      // Then highlight only those that are an ancestor of the current segment.
      svg.selectAll('path')
        .filter((node) => {
          return (sequenceArray.indexOf(node) >= 0);
        })
        .style('opacity', 1);
    }

    // Given a node in a partition layout, return an array of all of its ancestor
    // nodes, highest first, but excluding the root.
    function getAncestors(node) {
      const path = [];
      let current = node;
      while (current.parent) {
        path.unshift(current);
        current = current.parent;
      }
      return path;
    }

    // Restore everything to full opacity when moving off the visualization.
    function mouseleave(d) {

      // Hide the breadcrumb trail
      svg.select('#trail')
        .style('visibility', 'hidden');

      // Deactivate all segments during transition.
      // svg.selectAll('path').on('mouseover', null);

      // Transition each segment to full opacity and then reactivate it.
      svg.selectAll('path')
        .style('opacity', 1);

    }

    function click(d) {
      svg.transition()
        .duration(750)
        .tween('scale', () => {
          const xd = d3.interpolate(x.domain(), [d.x, d.x + d.dx]);
          const yd = d3.interpolate(y.domain(), [d.y, 1]);
          const yr = d3.interpolate(y.range(), [d.y ? 20 : 0, radius]);
          return (t) => {
            x.domain(xd(t));
            y.domain(yd(t)).range(yr(t));
          };
        })
        .selectAll('path')
        .attrTween('d', (d) => {
          return () => {
            return arc(d);
          };
        });
    }

    svg.select(self.frameElement).style('height', `${height}px`);

    function myScale(steps) {
      let cols;
      let t;
      let colors = '#337AB7, deeppink, orange'.replace(/(, *| +)/g, ',').split(',');
      if (steps === 1) {
        return [colors[0]];
      }
      colors = chroma.bezier(colors);
      const cs = chroma.scale(colors).mode('lab').correctLightness(true);
      cols = [];
      const ref = (() => {
        let k;
        let results;
        results = [];
        for (let i = k = 0, ref = steps; 0 <= ref ? k < ref : k > ref; i = 0 <= ref ? ++k : --k) {
          results.push(i / (steps - 1));
        }
        return results;
      })();
      for (let j = 0, len = ref.length; j < len; j++) {
        t = ref[j];
        cols.push(cs(t).hex());
      }
      return cols;
    }

    function drawLegend() {

      // Dimensions of legend item: height, spacing, radius of rounded rect. width will be set dynamically
      const li = {
        h: 30,
        s: 3,
        r: 3,
        w: 100,
      };

      const legend = d3.select('#legend').append('svg:svg')
        .attr('width', li.w)
        .attr('height', d3.keys(myColors).length * (li.h + li.s));

      const labelVsColors = {};

      for (let i = 0; i < allItems.length; i++) {
        labelVsColors[allItems[i]] = myColors[i];
      }

      const g = legend.selectAll('g')
        .data(d3.entries(labelVsColors))
        .enter().append('svg:g')
        .attr('transform', (d, i) => {
          return `translate(0, ${i * (li.h + li.s)})`;
        });

      g.append('svg:rect')
        .attr('rx', li.r)
        .attr('ry', li.r)
        .attr('width', li.w)
        .attr('height', li.h)
        .style('fill', (d) => {
          return d.value;
        }).on('mouseover', (d) => {
          const nodes = flatten(myData);
          const n = nodes.find((d1) => { return (d1.name === d.key); });
          mouseover(n);
        }).on('mouseleave', mouseleave);

      g.append('svg:text')
        .attr('x', li.w / 2)
        .attr('y', li.h / 2)
        .attr('dy', '0.35em')
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .text((d) => {
          return d.key;
        });
    }

    function flatten(root) {
      const nodes = [];
      let i = 0;

      function recurse(node) {
        if (node.children) node.children.forEach(recurse);
        if (!node.id) node.id = ++i;
        nodes.push(node);
      }

      recurse(root);
      return nodes;
    }

  }


  getD3pieChart() {
    new d3pie('newPieChart', {
      size: {
        canvasHeight: 500,
        canvasWidth: 500,
        pieInnerRadius: 0,
        pieOuterRadius: null
      },
      data: {
        content: [
          { label: 'Elephants', value: 1 },
          { label: 'Motmots', value: 2 },
          { label: 'Pikas', value: 3 },
          { label: 'Jays', value: 2 },
          { label: 'Rhubarb', value: 5 },
          { label: 'Tennis', value: 2 },
          { label: 'Chickens', value: 1 }
        ]
      },
      tooltips: {
        enabled: true,
        type: 'placeholder',
        string: '{label}: {percentage}%',
        styles: {
          fadeInSpeed: 500,
          backgroundColor: '#00cc99',
          backgroundOpacity: 0.8,
          color: '#ffffcc',
          borderRadius: 4,
          font: 'verdana',
          fontSize: 20,
          padding: 20
        }
      }
    });
  }

  setPieChartLabel(pieChart) {
    const width = pieChart.width();
    const height = pieChart.height();
    function midAngle(d) {
      return d.startAngle + (d.endAngle - d.startAngle) / 2;
    }

    const key = function (d) {
      return d.data.key;
    };

    const pie = d3.layout.pie()
      .sort(null)
      .value((d) => {
        return d.value;
      });

    const radius = Math.min(width, height) / 2;
    const outerArc = d3.svg.arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    const arc = d3.svg.arc()
      .outerRadius(radius * 0.8)
      .innerRadius(radius * 0.4);

    const svg = pieChart.select('svg');

    /* ------- TEXT LABELS -------*/

    const text = svg.select('.pie-label-group').selectAll('text')
      .data(pie(pieChart.data()), key);

    text.enter()
      .append('text')
      .attr('dy', '.35em')
      .text((d) => {
        return d.data.label;
      });

    text.transition().duration(1000)
      // tslint:disable-next-line
      .attrTween('transform', function (d) {
        this._current = this._current || d;
        const interpolate = d3.interpolate(this._current, d);
        this._current = interpolate(0);
        return (t) => {
          const d2 = interpolate(t);
          const pos = outerArc.centroid(d2);
          pos[0] = radius * (midAngle(d2) < Math.PI ? 1 : -1);
          return `translate(${pos})`;
        };
      })
      // tslint:disable-next-line
      .styleTween('text-anchor', function (d) {
        this._current = this._current || d;
        const interpolate = d3.interpolate(this._current, d);
        this._current = interpolate(0);
        return (t) => {
          const d2 = interpolate(t);
          return midAngle(d2) < Math.PI ? 'start' : 'end';
        };
      });

    text.exit()
      .remove();

    const polyline = svg.select('g').selectAll('polyline')
      .data(pie(pieChart.data()), key);

    polyline.enter()
      .append('polyline');

    polyline.transition().duration(1000)
      // tslint:disable-next-line
      .attrTween('points', function (d) {
        this._current = this._current || d;
        const interpolate = d3.interpolate(this._current, d);
        this._current = interpolate(0);
        return (t) => {
          const d2 = interpolate(t);
          const pos = outerArc.centroid(d2);
          pos[0] = radius * 0.95 * (midAngle(d2) < Math.PI ? 1 : -1);
          return [arc.centroid(d2), outerArc.centroid(d2), pos];
        };
      });

    polyline.exit()
      .remove();
  }


  // New Chart
  getPlantWisePresentStatus(config: Config) {
    const dimension = config.crossfilter.dimension((d: any) => {
      return d.PlantName;
    });
    const group = dimension.group().reduceCount((d) => {
      return d.ActualDeployed;
    });

    return this.getnerateChart(config, dimension, group);
  }
  getShiftWisePresentStatusChart(config: Config) {
    const dimension = config.crossfilter.dimension((d: any) => {
      if (d.AttendanceStatus === 'P' && d.AttendanceStatus !== 'NF') {
        return d.Shift;
      }

    });
    const dimension2 = config.crossfilter2.dimension((d: any) => {
      return d.ShiftGName;
    });
    const group = dimension.group().reduceCount((d) => {
      let cnt = 0;
      if (d.AttendanceStatus === 'P' && d.AttendanceStatus !== 'NF') {
        cnt++;
      }
      return cnt;

    });

    return this.getnerateChart(config, mirror_dimension([dimension, dimension2]), group);
  }

  getShiftWiseShortageStatus(config: Config) {
    const dimension1 = config.crossfilter.dimension((d: any) => {
      return d.ShiftGName;
    });
    const dimension2 = config.crossfilter2.dimension((d: any) => {
      return d.Shift;
    });
    const group = dimension1.group().reduceSum((d) => {
      if (d.IsShortageClear === 0) {
        return d.TotalShortage;
      }

      return -d.ActualDeployed;
    });

    return this.getnerateChart(config, mirror_dimension([dimension1, dimension2]), group);
  }
  getFunctionWisePresentStatus(config: Config) {
    const dimension = config.crossfilter.dimension((d: any) => {
      return d.WorkFunction;
    });
    const group = dimension.group().reduceCount((d) => {
      return d.ULC;
    });

    return this.getnerateChart(config, dimension, group);
  }

  getDrillDownPieChartRequirementShortage(config: Config) {

    const dimensions = [
      // config.crossfilter.dimension((d) => {
      //   if (d.Site !== null) {
      //     return d.Site;
      //   }
      // }),
      config.crossfilter.dimension(d => d.PlantName),
      config.crossfilter.dimension(d => d.UnitName),
      config.crossfilter.dimension(d => d.DeptName),

      // config.crossfilter.dimension((d) => {
      //   if (d.AttendanceStatus === 'P') {
      //     return d.Site;
      //   }
      // }),
      // config.crossfilter.dimension((d) => {
      //   if (d.AttendanceStatus === 'P') {
      //     return d.PlantName;
      //   }
      // }),
      // config.crossfilter.dimension((d) => {
      //   if (d.AttendanceStatus === 'P') {
      //     return d.UnitName;
      //   }
      // }),
      // config.crossfilter.dimension(d => d.PlantName),
      // config.crossfilter.dimension(d => d.UnitName),
      // config.crossfilter.dimension((d) => {
      //   if (d.DeptName !== 'NF' && d.AttendanceStatus === 'P') {
      //     return d.DeptName;
      //   }
      // }),

    ];

    let dimensionIndex = 0;

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        },
        top: () => {
          return sourceGroup.all().filter((d) => {
            return d.value !== 0;
          });
        }
      };
    }

    const dimension = dimensions[dimensionIndex];
    const group = this.getGroupReduceSumForDrilldownPresent(dimension, dimensionIndex);
    // const group = dimension.group().reduceCount((d) => {
    //   return d.ActualDeployed;
    // });

    const chart = this.getnerateChart(config, dimension, group);
    chart.on('filtered', (chart, filter) => {
      if (filter === null || dimensionIndex >= dimensions.length - 1) {
        return;
      }

      dimensionIndex++;
      const dimension = dimensions[dimensionIndex];
      const group = this.getGroupReduceSumForDrilldownPresent(dimension, dimensionIndex);
      // const group = dimension.group().reduceCount((d) => {
      //   return d.ActualDeployed;
      // });

      chart
        .group(removeEmptyData(group))
        .dimension(dimension)
        .filterAll();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Back`, (chart) => {
      if (dimensionIndex <= 0) {
        return;
      }
      dimensionIndex--;
      const dimension = dimensions[dimensionIndex];
      const group = this.getGroupReduceSumForDrilldownPresent(dimension, dimensionIndex);
      // const group = dimension.group().reduceCount((d) => {
      //   return d.ActualDeployed;
      // });

      chart
        .group(removeEmptyData(group))
        .dimension(dimension)
        .filterAll();

      chart.render();
    });

    config.dispatch.on(`${config.chartId.replace('#', '')}Reset`, (chart) => {
      dimensionIndex = 0;
      const dimension = dimensions[dimensionIndex];
      const group = this.getGroupReduceSumForDrilldownPresent(dimension, dimensionIndex);
      // const group = dimension.group().reduceCount((d) => {
      //   return d.ActualDeployed;
      // });

      chart
        .group(removeEmptyData(group))
        .dimension(dimension)
        .filterAll();

      chart.render();
    });

    chart.render();
    return chart;
  }

  getGroupReduceSumForDrilldownPresent(dimension, index) {
    // return dimension.group().reduceCount((d) => {
    //   return d.ActualDeployed;
    // });
    return dimension.group().reduceSum((d) => {
      let cnt = 0;
      if (index === 3) { // DeptName wise
        if (d.DeptName !== 'NF' && d.AttendanceStatus === 'P') {
          cnt += d.ActualDeployed;
        }
        if (d.DeptName === 'NF' && d.AttendanceStatus === 'P') {
          cnt++;
        }
      } else {
        if (d.AttendanceStatus === 'P') {
          // cnt += d.ActualDeployed;
          cnt++;
        }
      }
      return cnt;
    });
  }
}

function mirror_dimension(dims) {
  function mirror(fname) {
    return function (v) {
      dims.forEach((dim) => {
        dim[fname](v);
      });
    };
  }
  return {
    filter: mirror('filter'),
    filterExact: mirror('filterExact'),
    filterRange: mirror('filterRange'),
    filterFunction: mirror('filterFunction')
  };
}
