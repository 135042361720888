import { Injectable } from '@angular/core';
import { STATE_CODE } from '../state-code';
import { indiaStatePaths } from './../state-map.data';

declare var d3: any;
declare var dc: any;
declare var topojson: any;

@Injectable({
  providedIn: 'root'
})
export class IndiaMapService {

  constructor() { }

  generatemap(ndx1, data) {
    const dimension = ndx1.dimension((d) => {
      return d.State;
    });
    const group = dimension.group().reduceCount((d) => {
      return d.ULC;
    });

    const projection = d3.geo.mercator();
    const path = d3.geoPath()
      .projection(projection)
      .pointRadius(2);

    // const t = projection.translate(); // the projection's default translation
    // const s = projection.scale() // the projection's default scale

    const usChart = dc.geoChoroplethChart('#statesvg', 'group1');
    // usChart.legendables(() => {
    //   console.log('legendables ---------> ');
    //   return group.all().map((d, i) => {
    //     const legendable = { name: d.key, color: '', data: d.value, others: d.others, chart: usChart };
    //     legendable.color = usChart.colorCalculator()(d.value);
    //     return legendable;
    //   });
    // });
    usChart.width(570)
      .height(590)
      .dimension(dimension)
      .group(group)
      // .colors(['#ffffd9', '#edf8b1', '#c7e9b4', '#7fcdbb', '#41b6c4', '#1d91c0', '#225ea8', '#253494', '#081d58'])
      .colors(['#E2F2FF', '#C4E4FF', '#9ED2FF', '#81C5FF', '#6BBAFF', '#51AEFF', '#36A2FF', '#1E96FF', '#0089FF', '#0061B5'])
      .colorDomain([0, 200])
      .colorCalculator((d) => { return '#A1BFDE'; })
      // .colorCalculator((d) => { return d ? usChart.colors()(d) : '#A1BFDE'; })
      .overlayGeoJson(data.features, 'state', (d) => {
        return d.id.toUpperCase();
      })
      .projection(projection)
      .valueAccessor((kv) => {
        return kv.value;
      })
      .legend(dc.legend().x(0).y(10).itemHeight(13).gap(5))
      .title((d) => {
        const value = d.value ? d.value : 0;
        if (value) {
          // const stateValues = group.all();
          // const totalValue = d3.sum(stateValues, d => d.value);
          return `${d.key}: ${d.value ? d.value : 0}`; //  (${Math.round((value / totalValue) * 100)}%)
        }

        return `${d.key}: 0`;
      });

    projection.scale(1000);
    projection.translate([-1140, 670]);

    usChart.render();

    usChart.on('postRedraw', () => {
      console.log('Map postRedraw called');
      this.updateMapUI(usChart, data, path, group);
    });

    this.updateMapUI(usChart, data, path, group);
  }

  updateMapUI(usChart, data, path, group) {
    setTimeout(() => {
      const stateValues = group.all().filter(item => item.value > 0).sort((a, b) => b.value - a.value);
      const totalValue = d3.sum(stateValues, d => d.value);
      usChart.select('svg g[spiky-custom-label]').remove();
      const g = usChart.select('svg').append('g');
      g.attr('spiky-custom-label', 'true');
      g.selectAll('.subunit-label')
        .data(data.features)
        .enter()
        .append('text')
        .attr('class', 'subunit-label')
        .attr('transform', (d) => { return `translate(${path.centroid(d)})`; })
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .style('font-size', '.6em')
        .style('text-shadow', '0px 0px 2px #fff')
        .style('text-transform', 'uppercase')
        .text((d) => {
          const findState = stateValues.find(tmp => tmp.key && d.id && tmp.key.toLowerCase() === d.id.toLowerCase());
          if (findState) {
            return `${findState.key}: ${findState.value}`; // (${Math.round((findState.value / totalValue) * 100)}%)
          }
          return d.id;
        });

      const legend = usChart.select('svg .dc-legend');
      legend.selectAll('*').remove();
      legend
        .selectAll(null)
        .data(stateValues)
        .enter()
        .append('rect')
        .attr('width', 12)
        .attr('height', 12)
        .style('fill', '#a1bfde')
        .attr('x', 0)
        .attr('y', (d, i) => {
          let extraSpace = 0;
          if (i > 11) {
            extraSpace = 60;
          }
          return (i * 18) + extraSpace;
        });
      legend
        .selectAll(null)
        .data(stateValues)
        .enter()
        .append('text').attr('x', 20).style('font-size', '12px').attr('alignment-baseline', 'middle')
        .attr('y', (d, i) => {
          let extraSpace = 0;
          if (i > 11) {
            extraSpace = 60;
          }
          return (i * 18) + 5 + extraSpace;
        })
        .text((d) => {
          const code = STATE_CODE[d.key];
          return `${code ? code : d.key}: ${d.value} (${Math.round((d.value / totalValue) * 100)}%)`;
        });

      legend
        .selectAll(null)
        .data([totalValue])
        .enter()
        .append('text').attr('x', 400).style('font-size', '16px').attr('alignment-baseline', 'middle')
        .attr('y', 10)
        .text((d) => {
          return `Total: ${totalValue}`;
        });
    }, 250);
  }

  generatemap2(ndx1, data) {
    const dimension = ndx1.dimension((d) => {
      return d.State;
    });
    const group = dimension.group().reduceCount((d) => {
      return d.ULC;
    });

    // console.log('group state', group.all());

    const width = 570;
    const height = 590;

    const projection = d3.geoMercator();

    const path = d3.geoPath()
      .projection(projection)
      .pointRadius(2);

    const svg = d3.select('#statesvg').append('svg')
      .attr('width', width)
      .attr('height', height);

    const g = svg.append('g');

    function centerZoom(data) {

      const o = topojson.mesh(data, data.objects.polygons, (a, b) => { return a === b; });

      projection
        .scale(1)
        .translate([0, 0]);

      const b = path.bounds(o);
      const s = 1 / Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height);
      const t = [(width - s * (b[1][0] + b[0][0])) / 2, (height - s * (b[1][1] + b[0][1])) / 2];

      const p = projection
        .scale(s)
        .translate(t);

      return o;
    }

    function drawOuterBoundary(data, boundary) {

      g.append('path')
        .datum(boundary)
        .attr('d', path)
        .attr('class', 'subunit-boundary')
        .style('fill', 'none')
        .attr('stroke', '#3a403d');

    }

    function drawSubUnits(data) {

      const subunits = g.selectAll('.subunit')
        .data(topojson.feature(data, data.objects.polygons).features)
        .enter().append('path')
        .attr('class', 'subunit')
        .attr('d', path)
        .style('stroke', '#3a403d')
        .style('fill', '#005DAA')
        .style('stroke-width', '1px')
        .style('opacity', 0.5);

      return subunits;
    }

    function drawSubUnitLabels(data) {

      g.selectAll('.subunit-label')
        .data(topojson.feature(data, data.objects.polygons).features)
        .enter().append('text')
        .attr('class', 'subunit-label')
        .attr('transform', (d) => { return `translate(${path.centroid(d)})`; })
        .attr('dy', '.35em')
        .attr('text-anchor', 'middle')
        .style('font-size', '.5em')
        .style('text-shadow', '0px 0px 2px #fff')
        .style('text-transform', 'uppercase')
        .text((d) => {
          const stateValues = group.all();
          const findState = stateValues.find(tmp => tmp.key.toLowerCase() === d.properties.st_nm.toLowerCase());
          if (findState) {
            const totalValue = d3.sum(stateValues, d => d.value);
            return `${findState.key}: ${findState.value} (${Math.round((findState.value / totalValue) * 100)}%)`;
          }
          return d.properties.st_nm;
        });
    }

    const boundary = centerZoom(data);
    drawSubUnits(data);
    drawSubUnitLabels(data);
    drawOuterBoundary(data, boundary);
  }

  generatemap1(ndx1) {
    const iStates: any = {};
    iStates.draw = (id, data, toolTip) => {

      function mouseOver(d) {

        d3.select('#tooltip').transition().duration(200).style('opacity', .9);
        d3.select('#tooltip').html(toolTip(d.n, data[d.id]))
          .style('left', `${d3.event.layerX}px`)
          .style('top', `${d3.event.layerY}px`);
      }

      function mouseOut() {

        d3.select('#tooltip').transition().duration(500).style('opacity', 0);
      }

      const svg = d3.select(id)
        .append('svg')
        .attr('width', '570')
        .attr('height', '590')
        .append('g');

      const eS = svg.selectAll('.state')
        .data(indiaStatePaths)
        .enter()
        .append('g');

      eS.append('path')
        .attr('class', 'state')
        .attr('d', (d) => {
          return d.d;
        })
        .style('fill', '#005DAA')
        // .style('fill', (d) => {
        //   if (data[d.id]) {
        //     return data[d.id].color;
        //   }
        //   return null;
        // })
        .attr('data-legend', (d) => {
          if (data[d.id]) {
            return `${d.n} (${data[d.id].Value})`;
          }
          return `${d.n} (0)`;
        })
        .on('mousemove', mouseOver).on('mouseout', mouseOut);

      eS.append('text')
        .attr('fill', 'black')
        // tslint:disable-next-line
        .attr('transform', function (d) {
          const bbox = this.previousSibling.getBBox();
          // console.log('bbox', this.previousSibling);
          // console.log('bbox.x + bbox.width / 2', bbox.x + bbox.width / 2);
          // console.log('bbox.y + bbox.height / 2', bbox.y + bbox.height / 2);
          return `translate(${(bbox.x + bbox.width / 2)}, ${(bbox.y + bbox.height / 2)})`;
        })
        .attr('text-anchor', 'middle')
        .attr('dy', '.35em')
        .text((d) => {
          return d.id;
        });
    };

    const stateDimension = ndx1.dimension(d => d.State);
    const stateULCGroup = stateDimension.group().reduceCount(d => d.ULC);

    const sampleData = {};	/* Sample random data. */
    // ['AP', 'AR', 'AS', 'BR', 'CT', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KL', 'MP', 'MH',
    //   'MN', 'ML', 'MZ', 'NL', 'OR', 'PB', 'RJ', 'SK', 'TN', 'TR', 'UP', 'UT', 'WB']
    stateULCGroup.all().forEach((d) => {
      const found = indiaStatePaths.find(path => path.n.toLowerCase() === d.key.toLowerCase());

      if (found) {
        sampleData[found.id] = {
          count: d.value
        };
      }
    });

    /* draw states on id #statesvg */
    iStates.draw('#statesvg', sampleData, this.tooltipHtml);

    // d3.select(self.frameElement).style('height', '200px');
  }

  tooltipHtml = (n, d) => {	/* function to create html content string in tooltip div. */
    return `<h4>${n}</h4><table>
  <tr><td>Count: </td><td>${(d ? d.count : 0)}</td></tr>
  </table>`;
  }

}
