import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { SidebarService } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services';
import { DataSharedService } from '../../data-shared.service';
import { Ng4LoadingSpinnerService } from '..';
import { LoginService } from 'src/app/login/login.service';
import { SharedDataService } from '../../services/shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuItemType } from '../../models/core.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { LogHelperService } from '../../services/log-helper.service';
import { UserManagementService } from 'src/app/user-management/user-management.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('rightMenuBar') public rightMenuBar: ElementRef<any>;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  changepasswordform = new FormGroup({
    UserID: new FormControl(''),
    oldpassword: new FormControl(''),
    newpassword: new FormControl(''),
    confirmpassword: new FormControl('')
  });

  public reportMenus: any = [];
  public commonMasterMenus: any = [];
  public configurationMenus: any = [];
  public settingMenus: any = [];
  public applicationGroupMenus: any = [];
  public topbarDropDownMenus: any = [];
  public userData: any;
  openedMenu = '';
  openedSubMenu = '';
  today = new Date();
  rightMenu = false;
  // tslint:disable-next-line:variable-name
  UserID: number;

  private destroy: Subject<any>;
  public modalRef: any;
  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private sharedService: SharedService,
    private loginService: LoginService,
    private userManagementService: UserManagementService,
    private sharedDataService: SharedDataService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService,
  ) {
    this.destroy = new Subject();
  }

  @HostListener('document:click', ['$event.path'])
  public onGlobalClick(targetElementPath: any) {
    if(!targetElementPath) return;
    const elementRefInPath: any = targetElementPath.find(e => e === this.rightMenuBar.nativeElement);
    if (!elementRefInPath && this.rightMenu) {
      this.rightMenu = false;
    }
  }

  ngOnInit() {
    const userData = localStorage.getItem('user_data') && JSON.parse(localStorage.getItem('user_data')) || null;
    if (userData) {
      this.userData = userData;
      this.changepasswordform.controls['UserID'].setValue(this.userData.FirstName + this.userData.LastName);
      this.changepasswordform.controls['UserID'].disable();
    }

    this.sharedService.globalMasterItemData().subscribe((res: any) => {
      // this.sharedService.masterSettingsData = res.Data.Table;
      //   this.sharedService.setMasterData(res.Data.Table);
      this.sharedDataService.setMasterData(res);
    });

    this.sharedDataService.getMenuListData().subscribe((res: any) => {
      this.sharedDataService.setMenuList(res.Data);
      this.setMenus(res.Data);
    });

    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      this.sharedService.setGlobalSettings(res);
    });
    this.sharedService.getAlertMessageByCode().subscribe((res: any) => {
      this.sharedService.setAlertMessages(res);
    });
  }

  toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  onlogOut() {
    this.sharedService.deleteCookie('token');
    this.sharedService.deleteCookie('OceanSystem');
    localStorage.clear();
    this.router.navigate(['login']);
    this.loginService.updateOnAuthChange(false);
  }

  toggleRightMenu(event: any): void {
    this.rightMenu = !this.rightMenu;
    event.stopPropagation();
  }

  toggleMenu(menuName: string) {
    if (this.openedMenu === '' || this.openedMenu !== menuName) {
      this.openedMenu = menuName;
    } else {
      this.openedMenu = '';
    }
  }

  toggleSubMenu(menuName: string) {
    if (this.openedSubMenu === '' || this.openedSubMenu !== menuName) {
      this.openedSubMenu = menuName;
    } else {
      this.openedSubMenu = '';
    }
  }


  private setMenus(menuList: any[]): void {
    const topbarDropDownMenus = menuList.filter(item => item.MenuType === MenuItemType.TOPDROPDOWN);
    if (topbarDropDownMenus.length > 0) {
      this.topbarDropDownMenus = topbarDropDownMenus[0].Menus;
    }
    console.log('topbarDropDownMenus', this.topbarDropDownMenus);
    const reportMenus = menuList.filter(item => item.MenuType === MenuItemType.REPORT);
    if (reportMenus.length > 0) {
      this.reportMenus = reportMenus[0].Menus;
    }
    console.log('reportMenus', this.reportMenus);

    const commonMasterMenus = menuList.filter(item => item.MenuType === MenuItemType.COMMONMASTER);
    if (commonMasterMenus.length > 0) {
      this.commonMasterMenus = commonMasterMenus[0].Menus;
    }
    console.log('commonMasterMenus', this.commonMasterMenus);

    const configurationMenus = menuList.filter(item => item.MenuType === MenuItemType.CONFIGURATION);
    if (configurationMenus.length > 0) {
      this.configurationMenus = configurationMenus[0].Menus;
    }
    console.log('configurationMenus', this.configurationMenus);

    const settingMenus = menuList.filter(item => item.MenuType === MenuItemType.SETTING);
    if (settingMenus.length > 0) {
      this.settingMenus = settingMenus[0].Menus;
    }
    console.log('settingMenus', this.settingMenus);

    const applicationGroupMenus = menuList.filter(item => item.MenuType === MenuItemType.APPLICATIONGROUP);
    if (applicationGroupMenus.length > 0) {
      this.applicationGroupMenus = applicationGroupMenus[0].Menus;
    }
    console.log('applicationGroupMenus', this.applicationGroupMenus);
  }

  staticMenuItems() {
    // this.sideBarMenus = [
    //   {
    //     menuName: 'dashboard',
    //     title: 'Dashboard',
    //     menuIcon: 'fa fa-pie-chart',
    //     subMenu: [
    //       {
    //         menuName: 'yearly',
    //         title: 'Yearly',
    //         link: '/dashboard/yearly'
    //       }, {
    //         menuName: 'monthly',
    //         title: 'Monthly',
    //         link: '/dashboard/monthly'
    //       }
    //     ]
    //   }, {
    //     menuName: 'contractor',
    //     title: 'Contractor',
    //     menuIcon: 'icon-Employee2',
    //     subMenu: [
    //       {
    //         menuName: 'contractors',
    //         title: 'Contractor',
    //         link: '/contractor/contractors'
    //       }, {
    //         menuName: 'workOrders',
    //         title: 'Work Orders',
    //         link: '/contractor/work-orders'
    //       }, {
    //         menuName: 'ecPolicies',
    //         title: 'E.C. Policies',
    //         link: '/contractor/ec-policies'
    //       }, {
    //         menuName: 'licences',
    //         title: 'Licenses',
    //         link: '/contractor/licences'
    //       }
    //     ]
    //   }, {
    //     menuName: 'labour',
    //     title: 'Labour',
    //     menuIcon: 'icon-Labour5',
    //     subMenu: [
    //       {
    //         menuName: 'labours',
    //         title: 'Contract Man',
    //         link: '/labour/labours'
    //       }, {
    //         menuName: 'gatePassRequest',
    //         title: 'Gate Pass Requests',
    //         link: '/labour/gate-pass-requests'
    //       }, {
    //         menuName: 'bulkCanellation',
    //         title: 'Bulk Cancellation',
    //         link: '/labour/bulk-cancellation'
    //       }
    //     ]
    //   },
    //   // {
    //   //   menuName: 'hrClearance',
    //   //   title: 'HR Clearance',
    //   //   menuIcon: 'fa icon-target-of-audience',
    //   //   link: '/hrc/list'
    //   // },
    //   // {
    //   //   menuName: 'grievance',
    //   //   title: 'Grievance',
    //   //   menuIcon: 'fa icon-Work-Order',
    //   //   // link: '/grievance',
    //   //   subMenu: [
    //   //     {
    //   //       menuName: 'grievances',
    //   //       title: 'Grievances',
    //   //       link: '/grievance/grievances'
    //   //     }]
    //   // },
    //   // {
    //   //   menuName: 'communication',
    //   //   title: 'Communication',
    //   //   menuIcon: 'icon-Megaphone1',
    //   //   link: '/communication'
    //   // },
    //   {
    //     menuName: 'calender',
    //     title: 'Calender',
    //     menuIcon: 'icon-calendar-and-clock',
    //     subMenu: [
    //       {
    //         menuName: 'calendars',
    //         title: 'Calender',
    //         link: '/calendar/calendars'
    //       }, {
    //         menuName: 'complianceTasks',
    //         title: 'Compliance Task Calander',
    //         link: '/calendar/compliance-tasks'
    //       }
    //     ]
    //   },
    //   {
    //     menuName: 'attendance',
    //     title: 'Attendance',
    //     menuIcon: 'icon-calendar',
    //     subMenu: [
    //       {
    //         menuName: 'MPM',
    //         title: 'Manual Punch Management',
    //         subMenu: [
    //           {
    //             menuName: 'employeeWise',
    //             title: 'Employee Wise',
    //             link: '/attendance/employee-attendance'
    //           }, {
    //             menuName: 'contractorWise',
    //             title: 'Contractor Wise',
    //             link: '/attendance/contractor-attendance'
    //           }
    //         ]
    //       },
    //       {
    //         menuName: 'attendanceApproval',
    //         title: 'Approval',
    //         link: '/attendance/approve-attendance'
    //       },
    //       {
    //         menuName: 'weekOffChange',
    //         title: 'Week Off Changes',
    //         link: '/attendance/weekoff-changes'
    //       },
    //       {
    //         menuName: 'labourweekOffChange',
    //         title: 'Labour Week Off Changes',
    //         link: '/attendance/labour-weekoff-changes'
    //       }
    //     ]
    //   },
    //   {
    //     menuName: 'user-management',
    //     title: 'User Management',
    //     menuIcon: 'icon-businessman',
    //     subMenu: [
    //       {
    //         menuName: 'Module-Role',
    //         title: 'Module Role Management',
    //         link: '/user-management/module-role'
    //       }, {
    //         menuName: 'module-page',
    //         title: 'Module Wise Page',
    //         link: '/user-management/module-page'
    //       }, {
    //         menuName: 'Page-action',
    //         title: 'Page Wise Action',
    //         link: '/user-management/page-action'
    //       }
    //     ]
    //   },
    //   {
    //     menuName: 'yearly-budget',
    //     title: 'Yearly Budget',
    //     menuIcon: 'icon-businessman',
    //     link: '/budget/yearly-budget'
    //   },
    //   {
    //     menuName: 'man-power',
    //     title: 'Man Power',
    //     menuIcon: 'fa fa-pie-chart',
    //     subMenu: [
    //       {
    //         menuName: 'man-power',
    //         title: 'man-power',
    //         link: '/man-power/man-power',
    //       },
    //       {
    //         menuName: 'daily-man-power-location-assign',
    //         title: 'Daily Man Power Location',
    //         link: '/man-power/man-power-location-assign',
    //       }
    //     ]
    //   },

    //   {
    //     menuName: 'company-commons-master',
    //     title: 'Company Commons Master',
    //     menuIcon: 'icon-calendar',
    //     subMenu: [
    //       {
    //         menuName: 'shift-master',
    //         title: 'Shift-Master',
    //         link: '/company-commons-master/shift-master',
    //       },
    //       {
    //         menuName: 'shift-group',
    //         title: 'Shift-Group',
    //         link: '/company-commons-master/shift-group',
    //       },
    //       {
    //         menuName: 'plant-master',
    //         title: 'Plant',
    //         link: '/company-commons-master/plant-master',
    //       },
    //       {
    //         menuName: 'holiday-master',
    //         title: 'Holiday',
    //         link: '/company-commons-master/holiday-master',
    //       },
    //       {
    //         menuName: 'punch-from-reader',
    //         title: 'PunchFromReader',
    //         link: '/clpms-reports/punch-from-reader',
    //       }
    //     ]
    //   },

    //   {
    //     menuName: 'man-power-yearly',
    //     title: 'Man Power Yearly',
    //     menuIcon: 'icon-businessman',
    //     link: '/man-power-yearly/man-power-yearly'
    //   },
    //   {
    //     menuName: 'report-builder',
    //     title: 'Report builder',
    //     menuIcon: 'icon-dashboard-pie',
    //     link: '/report/report-builder'
    //   },
    //   {
    //     menuName: 'Shift',
    //     title: 'Shift',
    //     menuIcon: 'icon-Labour5',
    //     subMenu: [
    //       {
    //         menuName: 'ShiftGroup',
    //         title: 'Shift Group',
    //         link: '/shift/shift-group'
    //       }
    //     ]
    //   },
    //   {
    //     menuName: 'Reports',
    //     title: 'Reports',
    //     menuIcon: 'icon-dashboard-pie',
    //     subMenu: [
    //       {
    //         menuName: 'EntryForTheDay',
    //         title: 'Entry For The Day',
    //         link: '/reports/entryforday'
    //       },
    //       {
    //         menuName: 'DailyAttendanceStatusReport',
    //         title: 'Daily Attendance Status Report',
    //         link: '/reports/dailyattendancestatusreport'
    //       },
    //       {
    //         menuName: 'DailyAbsentPresentReport',
    //         title: 'Daily Absent Present Report',
    //         link: '/reports/dailyabsentpresentreport'
    //       },
    //       {
    //         menuName: 'DailyContractManPresentReport',
    //         title: 'Daily ContractMan Present Report',
    //         link: '/reports/dailycontractmanpresentreport'
    //       },
    //       {
    //         menuName: 'DailyContractManAbsentReport',
    //         title: 'Daily ContractMan Absent Report',
    //         link: '/reports/dailycontractmanpabsentreport'
    //       },
    //       //   .{
    //       //     menuName: 'ContinuesPresentReport',
    //       //     title: 'Continues Present Report',
    //       //     link: '/reports/dailycontinuesmorethanwhreport'
    //       //   },
    //       {
    //         menuName: 'PunchFromReaderReport',
    //         title: 'Punch From Reader Report',
    //         link: '/reports/punchfromreaderreport'
    //       },
    //       {
    //         menuName: 'DailyShiftwisePresentReport',
    //         title: 'Daily Shiftwise Present Report',
    //         link: '/reports/dailyshiftwisepresentreport'
    //       }
    //     ]
    //   }
    // ];
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.unsubscribe();
  }

  public openChangePasswordPopup(): void {
    // tslint:disable-next-line: max-line-length
    this.modalRef = this.modalService.open(this.templateRef, { size: 'lg', backdrop: 'static', keyboard: false });
    this.changepasswordform.controls['oldpassword'].setValue(null);
    this.changepasswordform.controls['newpassword'].setValue(null);
    this.changepasswordform.controls['confirmpassword'].setValue(null);
  }

  public onCloseModel(): void {
    this.modalRef.close('click');
  }

  checkvalidation() {
    if ((this.changepasswordform.controls['oldpassword'].value === this.changepasswordform.controls['newpassword'].value)
      && (this.changepasswordform.controls['oldpassword'].value !== '' && this.changepasswordform.controls['newpassword'].value !== '')) {
      this.logHelperService.logError({
        message: 'Old password and new password should not be the same!!'
      });
      this.changepasswordform.controls['oldpassword'].setValue(null);
      this.changepasswordform.controls['newpassword'].setValue(null);
      this.changepasswordform.controls['confirmpassword'].setValue(null);
    } else if ((this.changepasswordform.controls['newpassword'].value !== this.changepasswordform.controls['confirmpassword'].value) &&
      (this.changepasswordform.controls['newpassword'].value !== '' && this.changepasswordform.controls['confirmpassword'].value !== '')) {
      this.logHelperService.logError({
        message: 'New password and confirm password must be same!!'
      });
      this.changepasswordform.controls['oldpassword'].setValue(null);
      this.changepasswordform.controls['newpassword'].setValue(null);
      this.changepasswordform.controls['confirmpassword'].setValue(null);
    }
  }

  changepassword() {
    this.checkvalidation();
    this.UserID = parseInt(this.userData.UserId, 10);
    this.userManagementService.changepassword(this.UserID, this.changepasswordform.controls['oldpassword'].value,
      this.changepasswordform.controls['newpassword'].value).subscribe((response: any) => {

        if (response === 0) {
          this.logHelperService.logError({
            message: 'Sorry your old password was incorrect!'
          });
        } else if (response === 1) {
          this.logHelperService.logSuccess({
            message: 'Your password is successfully changed'
          });
        }
      });
  }
}
