import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { NgForm } from '@angular/forms';
declare var $;
export class WizardStep {
  id: number | string;
  name: string;
  isValid: () => boolean;
}

@Component({
  selector: 'app-wizard-wrapper',
  templateUrl: './wizard-wrapper.component.html',
  styleUrls: ['./wizard-wrapper.component.css']
})
export class WizardWrapperComponent implements OnInit, AfterViewInit, AfterViewChecked {

  @Input() steps: WizardStep[] = [];
  @Input() form: NgForm;
  @Input() editMode = false;
  currentStep = 0;
  visibleSteps = [];
  isNextPrevVisible = false;
  constructor(private cdr: ChangeDetectorRef) { }
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() saveAndNew: EventEmitter<any> = new EventEmitter();
  @Output() updateAndNew: EventEmitter<any> = new EventEmitter();
  @Output() clear: EventEmitter<any> = new EventEmitter();
  @Output() stepChange: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    $('#smartwizard').smartWizard(
      {
        selected: this.currentStep,  // Initial selected step, 0 = first step
        keyNavigation: false, // Enable/Disable keyboard navigation(left and right keys are used if enabled)
        autoAdjustHeight: true, // Automatically adjust content height
        cycleSteps: false, // Allows to cycle the navigation of steps
        backButtonSupport: true, // Enable the back button support
        useURLhash: false, // Enable selection of the step based on url hash
        lang: {  // Language variables
          next: 'Next',
          previous: 'Previous'
        },
        toolbarSettings: {
          toolbarPosition: 'bottom', // none, top, bottom, both
          toolbarButtonPosition: 'left', // left, right
          showNextButton: false, // show/hide a Next button
          showPreviousButton: false, // show/hide a Previous button
          toolbarExtraButtons: [
            // $('<button></button>').text('Finish')
            //   .addClass('btn btn-info')
            //   .on('click', () => {
            //     alert('Finsih button click');
            //   }),
            // $('<button></button>').text('Cancel')
            //   .addClass('btn btn-danger')
            //   .on('click', () => {
            //     alert('Cancel button click');
            //   })
          ]
        },
        anchorSettings: {
          anchorClickable: true, // Enable/Disable anchor navigation
          enableAllAnchors: false, // Activates all anchors clickable all times
          markDoneStep: true, // add done css
          enableAnchorOnDoneStep: true // Enable/Disable the done steps navigation
        },
        contentURL: null, // content url, Enables Ajax content loading. can set as data data-content-url on anchor
        disabledSteps: [],    // Array Steps disabled
        errorSteps: [],    // Highlight step with errors
        theme: 'dots',
        transitionEffect: 'fade', // Effect on navigation, none/slide/fade
        transitionSpeed: '400',
        showStepURLhash: false
      }
    );

    // Initialize the leaveStep event
    $('#smartwizard').on('leaveStep', (e, anchorObject, stepNumber: number, stepDirection) => {
      if (stepDirection === 'forward') {
        this.currentStep = stepNumber + 1;
        this.stepChange.emit({
          currentStep: stepNumber,
          nextStep: this.currentStep
        });
      } else {
        this.currentStep = stepNumber - 1;
        this.stepChange.emit({
          currentStep: stepNumber,
          nextStep: this.currentStep
        });
      }
    });
    this.onResize();
  }

  calculateVisibility() {
    this.visibleSteps = [];
    const visibleSteps = Math.floor($('#view-container').width() / 192);
    this.visibleSteps.push(0);
    this.visibleSteps.push(visibleSteps - 1);
    if (this.steps.length > this.visibleSteps[1]) {
      this.isNextPrevVisible = true;
    } else {
      this.isNextPrevVisible = true;
    }
  }

  onResize() {
    this.calculateVisibility();
    this.manageVisibilityOfSteps();
  }

  nextStep() {
    if (this.visibleSteps[1] === this.steps.length - 1) {
      return;
    }
    this.visibleSteps[0] += 1;
    this.visibleSteps[1] += 1;
    this.manageVisibilityOfSteps();
  }

  previousStep() {
    if (this.visibleSteps[0] === 0) {
      return;
    }
    this.visibleSteps[0] -= 1;
    this.visibleSteps[1] -= 1;
    this.manageVisibilityOfSteps();
  }

  manageVisibilityOfSteps() {
    const children = $('#view-container').children();
    // tslint:disable-next-line:no-increment-decrement
    for (let index = 0; index < this.steps.length; index++) {
      if (index < this.visibleSteps[0] || index > this.visibleSteps[1]) {
        $(children[index]).css('display', 'none');
      } else {
        $(children[index]).css('display', 'block');
      }
    }
  }

  forceNext() {
    if (this.currentStep >= this.visibleSteps[1]) {
      this.nextStep();
    }
  }

  forcePrevious() {
    if (this.currentStep <= this.visibleSteps[0]) {
      this.previousStep();
    }
  }

  onCancel() {
    this.cancel.emit(true);
  }

  clickSaveAndNew() {
    this.saveAndNew.emit(true);
  }

  clickUpdateAndNew() {
    this.updateAndNew.emit(true);
  }

  onClear() {
    this.clear.emit(true);
  }

}
