import { Component, OnInit, ViewChild , AfterViewInit, OnDestroy } from '@angular/core';
import { ContractorsService } from '..';
import { GLOBAL } from 'src/app/app.globals';
import { formatMoment } from 'ngx-bootstrap/chronos/format';
import { NgForm } from '@angular/forms';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/core/services';
import { stringify } from 'querystring';
import { stripSummaryForJitNameSuffix } from '@angular/compiler/src/aot/util';
@Component({
  selector: 'app-create-contractor',
  templateUrl: './create-contractor.component.html',
  styleUrls: ['./create-contractor.component.css']
})

export class CreateContractorComponent implements OnInit {
  @ViewChild('contractorForm') contractorForm: NgForm;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  btnsave: boolean;
  btnsavenexit: boolean;
  company: any;
  shortCode: any;
  email: any;
  pANNo: any;
  mobileNo: number;
  contractorCode:number;
  constructor(private contractorsService: ContractorsService,
    private logHelperService: LogHelperService,   public sharedService: SharedService,
    private router: Router) { }
  ngOnInit() {
  }

  async onSubmit(formData, redirect = true) {

    const contractor: any = {
      ContractorVendor_Code: 0,
      Company: formData.Company,
      ShortCode:formData.ShortCode,
      EmailID: formData.EmailID,
      PhoneNo: formData.PhoneNo,
      FullName:formData.FullName,
      PANNo:formData.PANNo,
      AltEmailID  :formData.AltEmailID,
      MobileNo:formData.MobileNo,
      AltMobileNo:formData.AltMobileNo,
      Designation:formData.Designation,
      // CreateBy: GLOBAL.USER.LOGGED_IN_USER,
      // RoleCode: GLOBAL.USER.ROLE_CODE,
      CreateBy: localStorage.getItem('UserID'),
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      EntityComplianceInfos: [],
    };
    if (formData.gstdetail === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'CCLRA1003';
      entityComplianceInfo.RegistrationNumber = formData.epfcode;
      entityComplianceInfo.Other1 = formData.gstregistrationno;
      entityComplianceInfo.Other2 = formData.companyregistrationno;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    const uploadParams = {
      contractorvendor_code: ''
    };
    await this.contractorsService.addNewContractor(contractor).subscribe((result: any) => {

      uploadParams.contractorvendor_code = result.Data;

      this.logHelperService.logSuccess({
        message: 'Contractor addded successfully'
      });
    });
  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit(): void {
    this.contractorForm.reset();
  }
  clearForm() {
    this.contractorForm.reset();
  }
  async onCancel() {
    this.router.navigate(['contractor/contractors']);
  }
  saveAndNew() {

    // tslint:disable-next-line: triple-equals
    if (this.contractorForm.controls.Company.valid
      && this.contractorForm.controls.ShortCode.valid
      && this.contractorForm.controls.FullName.valid
      && this.contractorForm.controls.EmailID.valid
      && this.contractorForm.controls.MobileNo.valid
      && this.contractorForm.controls.PANNo.valid) {
      this.onSubmit(this.contractorForm.value, false);
      this.router.navigate(['contractor/create-contractor']);
      this.clearForm();
    }
    // tslint:disable-next-line: brace-style
    else {
      this.logHelperService.logError({
        message: 'Please Fill Form Properly'
      });
    }
  }

  save() {

    // tslint:disable-next-line: triple-equals
    if (this.contractorForm.controls.Company.valid
      && this.contractorForm.controls.ShortCode.valid
      && this.contractorForm.controls.FullName.valid
      && this.contractorForm.controls.EmailID.valid
      && this.contractorForm.controls.MobileNo.valid
      && this.contractorForm.controls.PANNo.valid) {
      this.onSubmit(this.contractorForm.value, false);
      this.router.navigate(['contractor/contractors']);
      this.clearForm();
    }
    // tslint:disable-next-line: brace-style
    else {
      this.logHelperService.logError({
        message: 'Please Fill Form Properly'
      });
    }
  }

  // tslint:disable-next-line: function-name
  CheckCompany() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkCompany(this.contractorForm.controls.Company.value, this.contractorCode).subscribe((res:any) => {
        this.company = res.Data;
        if (this.company > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            message: 'This field requires a unique entry. This Firm / Company name has already been used. Please enter different input.'
          });
          this.contractorForm.controls['Company'].reset();
        }
      });
    return true;
  }
  // tslint:disable-next-line: function-name
  CheckShort() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkShortCode(this.contractorForm.controls.ShortCode.value, this.contractorCode).subscribe((res:any) => {
        this.shortCode = res.Data;
        if (this.shortCode > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'This field requires a unique entry. This Vendor code has already been used with other Firm / Company. Please enter different vendor code.'
          });
          this.contractorForm.controls['ShortCode'].reset();
        }
      });
    return true;
  }
  // tslint:disable-next-line: function-name
  CheckEmail() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkEmail(this.contractorForm.controls.EmailID.value, this.contractorCode).subscribe((res:any) => {
        this.email = res.Data;
        if (this.email > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'Email address already taken for other company. Please use different email id for this company.'
          });
          this.contractorForm.controls['EmailID'].reset();
        }
      });
    return true;
  }
  // tslint:disable-next-line: function-name
  CheckPanCardNo() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkPanCard(this.contractorForm.controls.PANNo.value, this.contractorCode).subscribe((res:any) => {
        this.pANNo = res.Data;
        if (this.pANNo > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'This field requires a unique entry. This PAN number has been used for other Firm / Company name. Please enter different input.'
          });
          this.contractorForm.controls['PANNo'].reset();
        }
      });
    return true;
  }
  checkAlternateEmailID() {
    if (this.contractorForm.controls.EmailID.value === this.contractorForm.controls.AltEmailID.value) {
      {
        this.logHelperService.logError({
          // tslint:disable-next-line: max-line-length
          message: 'Email ID and Alternate email id should not be same input.'
        });
        this.contractorForm.controls['AltEmailID'].reset();
      }
    }
    return true;
  }
  checkAlternateMobileNo() {
    if (this.contractorForm.controls.MobileNo.value === this.contractorForm.controls.AltMobileNo.value) {
      {
        this.logHelperService.logError({
          // tslint:disable-next-line: max-line-length
          message: 'Mobile No and Alternate mobile no should not be same input.'
        });
        this.contractorForm.controls['AltMobileNo'].reset();
      }
    }
    return true;
  }
  checkMobileNo() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkMobileNO(this.contractorForm.controls.MobileNo.value, this.contractorCode).subscribe((res:any) => {
        this.mobileNo = res.Data;
        if (this.mobileNo > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'This field requires a unique entry. This MobileNo has been used for other Firm / Company name. Please enter different input.'
          });
          this.contractorForm.controls['MobileNo'].reset();
        }
      });
    return true;
  }
}
