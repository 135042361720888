import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManPowerComponent } from '../man-power/man-power.component';
import { DailyManPowerLocationAssignComponent } from './daily-man-power-location-assign/daily-man-power-location-assign.component';
import { ManPowerListComponent } from './man-power-list/man-power-list.component';
import { ManPowerLocationComponent } from './man-power-location/man-power-location/man-power-location.component';
import { ManPowerUnassignComponent } from './man-power-unassign/man-power-unassign.component';
import { FilterManPowerAssignComponent } from './filter-man-power-assign/filter-man-power-assign.component';
import { ManpowerOTApprovalComponent } from './ot-approval-list/ot-approval-list.component';


const routes: Routes = [
  {
    path: 'man-power',
    component: ManPowerListComponent
  },
  {
    path: 'man-power1',
    component: ManPowerComponent
  },
  {
    path: 'manpower-location-assign',
    component: DailyManPowerLocationAssignComponent
  },

  {
    path: 'man-power-location',
    component: ManPowerLocationComponent
  },
  {
    path: 'man-power-unassign',
    component: ManPowerUnassignComponent
  },
  {
    path: 'man-power-location-assign',
    component: FilterManPowerAssignComponent
  },
  {
    path: 'ot-approval',
    component: ManpowerOTApprovalComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManPowerRoutingModule { }
