import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SharedService } from 'src/app/core/services';
import { FileDoc, WizardStep, Checklist } from 'src/app/core/components';
import { takeUntil } from 'rxjs/operators';
import { OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { LicencesService } from '../licences.service';
import { isNullOrUndefined } from 'util';
import { MasterItemCode, NatureOfLicenceCode } from '../../contractor.enum';
import { GLOBAL } from 'src/app/app.globals';
import * as moment from 'moment';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-licenses-form',
  templateUrl: './licenses-form.component.html',
  styleUrls: ['./licenses-form.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class LicencesFormComponent implements OnInit, OnDestroy {

  @ViewChild('licenseForm') public licenseForm: NgForm;
  // @ViewChild('select') select: NgSelectComponent;

  public checkList: Checklist[] = [];
  public wizardSteps: WizardStep[] = [];
  public stateList: any[] = [];
  public natureOfLicenseList: any = [];
  public companyList: any[] = [];
  public plantList: any[] = [];
  public contractorWorkOrderList: any[] = [];
  public contractorList: any[] = [];
  public subContractorList: any[] = [];
  public subContractorWorkOrderList: any[] = [];
  public statuses: any[] = [];
  public natureOfWorkList: any[] = [];
  public verifyByList: any[] = [];
  // datepicker control config
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  // selector component config
  public contractorConfig: any;
  public subContractorConfig: any;
  public workOrderConfig: any;
  public subContractorWOConfig: any;
  public editMode = false;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  public alertOption: SweetAlertOptions = {};
  public isSubContractor = false;
  public documentTypes = [];
  public uploadedFiles: FileDoc[] = [];
  public isValidStartDate = false;

  public minDate = new Date();
  public minEndDate: Date;
  public maxEndDate: Date;

  private onDestroy: Subject<any>;
  private license: any = {};
  logHelperService: any;
  licenceno: any;
  public tempdate = false;
  public status = false;
  public isverified = false;
  public verifiedby = false;

  constructor(
    private sharedService: SharedService,
    private licenseService: LicencesService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.onDestroy = new Subject();
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
  }

  ngOnInit() {
    if (this.checkContractorType()) {
      this.bindWizardSteps();
      setTimeout(() => {
        this.getLicense();
        this.getDocumentTypes();
        this.bindCheckList();
      }, 10);
    }

    // tslint:disable-next-line: variable-name
    // tslint:disable-next-line: prefer-const
    let roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    const userId = localStorage.getItem('UserID');
    // tslint:disable-next-line: triple-equals
    if (roleCode == 118 || roleCode == 103 || roleCode == 104 || roleCode == 101) {
      this.tempdate = true;
    }
    // tslint:disable-next-line: brace-style
    else {
      this.tempdate = false;
    }
    if (roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }
  getContractorsSelectedByDefault(userId: any, roleCode: any) {

    this.licenseService.getContractorsbydefault(userId, roleCode).subscribe((response: any[]) => {
      console.log('getContractors response', response);
      this.contractorList = response;
      this.licenseForm.controls['Company'].setValue(this.contractorList[0].ContractorVendor_Code);
     // this.licenseForm.controls['ParentContractorVendor_Code'].setValue(this.license.ParentContractorVendor_Code);
      if (this.license.ParentContractorVendor_Code) {
        this.getContractorWorkOrder(this.license.ParentContractorVendor_Code);
      }
    });
  }

  public onCompanyChange(): void {
    this.plantList = [];
    this.licenseForm.controls['Plants'].setValue(null);
    const stateCode = this.licenseForm.controls['State_Code'].value;
    const companyCode = this.licenseForm.controls['Company'].value;
    if (stateCode && companyCode) {
      this.getPlants(companyCode);
    }
  }

  public ngOnDestroy() {
    this.onDestroy.next(true);
    // window.removeEventListener('scroll', this.onScroll, true);
  }

  public onStateChange(): void {
    // this.companyList = [];
    // this.contractorWorkOrderList = [];
    // this.subContractorList = [];
    // this.subContractorWorkOrderList = [];
    // this.licenseForm.controls['ParentContractorVendor_Code'].setValue(null);
    // this.licenseForm.controls['ParentWorkOrderCode'].setValue(null);
    // if (this.isSubContractor) {
    //   this.licenseForm.controls['ContractorVendor_Code'].setValue(null);
    //   this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
    // }
    this.licenseForm.controls['Company'].setValue(null);
    this.licenseForm.controls['Plants'].setValue(null);
    this.license.Company = null;
    this.license.Plants = null;

    const stateCode = this.licenseForm.controls['State_Code'].value;
    if (stateCode) {
      this.licenseForm.controls['Company'].enable();
      this.licenseForm.controls['Plants'].enable();
    } else {
      this.licenseForm.controls['Company'].disable();
      this.licenseForm.controls['Plants'].disable();
    }
  }

  public onContractorChange(data: any): void {
    console.log('onContractorChange', data);
    // if (!data.contractor) return;
    // this.isContractorTouched = true;
    // this.license.ParentContractorVendor_Code = data.contractor.ShortCode;
    this.contractorWorkOrderList = [];
    this.subContractorList = [];
    this.subContractorWorkOrderList = [];

    this.licenseForm.controls['ParentWorkOrderCode'].setValue(null);
    if (this.isSubContractor) {
      this.licenseForm.controls['ContractorVendor_Code'].setValue(null);
      this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
    }
    const contractorCode = this.licenseForm.controls['ParentContractorVendor_Code'].value;
    if (contractorCode) {
      this.getContractorWorkOrder(contractorCode);
    }
  }

  public onContractorWOChange(data: any): void {
    // console.log('onContractorWOChange', data);
    // if (!data.workOrder) return;
    // this.license.ParentWorkOrderCode = data.workOrder.WorkOrder_Code;
    if (this.isSubContractor) {
      this.licenseForm.controls['ContractorVendor_Code'].setValue(null);
      this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
    }
    this.subContractorList = [];
    this.subContractorWorkOrderList = [];
    const parentWorkOrderCode = this.licenseForm.controls['ParentWorkOrderCode'].value;
    if (parentWorkOrderCode && this.isSubContractor) {
      this.getSubContractorsByWorkOrder(parentWorkOrderCode);
    }
  }

  public onSubContractorChange(data: any): void {

    console.log('onContractorChange', data);
    // if (!data.contractor) return;
    // this.isContractorTouched = true;
    // this.license.ContractorVendor_Code = data.contractor.ShortCode;
    this.subContractorWorkOrderList = [];
    this.licenseForm.controls['ChildWorkOrderCode'].setValue(null);
    const subContractorCode = this.licenseForm.controls['ContractorVendor_Code'].value;
    if (subContractorCode) {
      this.getSubContractorWorkOrder(subContractorCode);
    }
  }

  public onSubContractorWOChange(data: any): void {
    console.log('onSubContractorWOChange', data);
    // if (!data.workOrder) return;
    // this.license.ChildWorkOrderCode = data.workOrder.WorkOrder_Code;
  }

  public onStartDateChange(): void {
    if (this.licenseForm.controls['StartDate'].valid) {
      const startDate = this.licenseForm.controls['StartDate'].value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        // this.licenseForm.controls['StartDate'].setValue('');
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>Please enter current date or greater than current date.</span>`,
          type: 'warning'
        };
        this.openAlert();
        // return;
      }
      const licenseTypeMasterListCode = this.licenseForm.controls['LicenseTypeMasterList_Code'].value;
      this.maxEndDate = this.setEndDateByLicenceType(licenseTypeMasterListCode, startDate);
      const endDate = this.licenseForm.controls['EndDate'].value;
      // this.isValidStartDate = true;
      this.licenseForm.controls['EndDate'].enable();
      this.minEndDate = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.licenseForm.controls['EndDate'].valid) {
          this.licenseForm.controls['EndDate'].setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      this.licenseForm.controls['EndDate'].disable();
      this.licenseForm.controls['EndDate'].setValue('');
    }
  }

  public onNatureOfLicenceChange() {
    const licenseTypeMasterListCode = this.licenseForm.controls['LicenseTypeMasterList_Code'].value;
    if (licenseTypeMasterListCode) {
      this.licenseForm.controls['StartDate'].enable();
      this.licenseForm.controls['StartDate'].setValue('');
      this.licenseForm.controls['EndDate'].setValue('');
    } else {
      this.licenseForm.controls['StartDate'].disable();
      this.licenseForm.controls['EndDate'].disable();

      this.licenseForm.controls['StartDate'].setValue('');
      this.licenseForm.controls['EndDate'].setValue('');

    }
  }

  public onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }

  public saveAndNew(): void {
    this.onSubmit(this.licenseForm.value, true);
  }
  // save() {
  //
  //   this.onSubmit(this.licenseForm.value, true);
  //
  //   this.router.navigate(['contractor/licences']);
  //   this.clearForm();
  // }
  public updateAndNew(): void {
    this.onSubmit(this.licenseForm.value, true);
  }

  public clearForm(): void {
    this.licenseForm.reset();
  }

  public onCancel() {
    this.router.navigate(['/contractor/licences']);
  }

  public onSubmit(formValue: any, saveAndNew: boolean): void {

    console.log('form value Licesce', formValue);
    if (this.uploadedFiles.length === 0) {
      this.showAlert('error', 'Please add license documents.', 'Error!!!');
      return;
    }
    let licenseObj: any;
    licenseObj = { ...this.license, ...formValue };
    licenseObj.roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    licenseObj.CreateBy = localStorage.getItem('UserID');
    const licenceNo = licenseObj.LicenseNo;
    const licenceCode = licenseObj.License_Code || '0';
    // this.licenseService.checkLicenseNoAvailability(licenceNo, licenceCode).pipe(takeUntil(this.onDestroy)).subscribe(
    //   (response: any) => {
        // if (response === 0) {
    if (!this.isSubContractor) {
      licenseObj.ContractorVendor_Code = licenseObj.ParentContractorVendor_Code;
      licenseObj.ParentContractorVendor_Code = null;
    }

    licenseObj.DocumentImageMasterViews = [];
    if (licenseObj.License_Code) {
      this.licenseService.updateLicense(licenseObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                if (response.Data) {

                  this.saveDocuments(response.Data, saveAndNew, false);
                } else {
                  this.alertOption = {
                    title: 'Error!!!',
                    html: `<span class='customClass'>Some error has occured! Please try again.</span>`,
                    type: 'error'
                  };
                  this.openAlert();
                }
              }
            );
    } else {
      this.licenseService.addLicense(licenseObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                if (response.Data) {

                  this.saveDocuments(response.Data, saveAndNew, true);
                } else {
                  this.alertOption = {
                    title: 'Error!!!',
                    html: `<span class='customClass'>Some Error has occured! Please try again.</span>`,
                    type: 'error'
                  };
                  this.openAlert();
                }
              }
            );
    }
        // } else {
        //   this.alertOption = {
        //     title: 'Error!!!',
        //     html: `<span class='customClass'>License no. is already exist..</span>`,
        //     type: 'error'
        //   };
        //   this.openAlert();
        // }
      // });
  }

  private saveDocuments(licenseCode: any, saveAndNew: boolean, isAdd: boolean) {
    console.log('saveDocuments response', licenseCode);

    const uploadParams = {
      License_Code: licenseCode
    };
    const filesToUpload = this.getNotUploadedItems(this.uploadedFiles);
    if (uploadParams.License_Code && filesToUpload.length > 0) {
      this.licenseService.uploadDocuments(filesToUpload, uploadParams)
        .pipe(takeUntil(this.onDestroy)).subscribe((res) => {
          if (saveAndNew) {
            this.showLicenseInfo(isAdd, false);
            this.resetFormAfterSave();
          } else {
            this.showLicenseInfo(isAdd, true);
          }
        });
    } else if (saveAndNew) {
      this.showLicenseInfo(isAdd, false);
      this.resetFormAfterSave();
    } else {
      this.showLicenseInfo(isAdd, true);
    }
  }

  private showLicenseInfo(isAdd: boolean, redirect: boolean) {

    const message = isAdd ? 'License detail is successfully added.' : 'License detail is successfully updated.';
    this.alertOption = {
      title: 'Success!!!',
      html: `<span class='customClass'>${message}</span>`,
      type: 'success',
      timer: 2000
    };
    this.openAlert();
    if (redirect) {
      setTimeout(() => {
        this.router.navigate(['contractor/licences']);
      }, 3000);
    }
    this.clearForm();
  }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }

  private setEndDateByLicenceType(licenseTypeMasterListCode: number, startDate: any): Date {
    let maxDate = null;
    if (licenseTypeMasterListCode === NatureOfLicenceCode.Acknowledgment) {
      maxDate = new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 1));
    } else {
      maxDate = new Date(new Date(startDate).setFullYear(new Date(startDate).getFullYear() + 1));
    }
    return maxDate;
  }

  private checkContractorType(): boolean {
    let isValidType = false;
    const id: string = this.activatedRoute.snapshot.params.id;
    if (id) {
      isValidType = true;
      this.editMode = true;
    } else {
      const type: string = this.activatedRoute.snapshot.queryParamMap.get('type');
      if (type === '1' || type === '0') {
        this.isSubContractor = type === '0';
        isValidType = true;
      }
    }
    return isValidType;
  }

  private bindWizardSteps() {
    this.wizardSteps = [
      {
        id: 'licenseDetails',
        name: 'License Info',
        isValid: () => {
          // return true;
          return this.wizard1Validation();
        },
      },
      {
        id: 'documentDetails',
        name: 'Documents',
        isValid: () => { return true; }
      }
    ];
  }
  // tslint:disable-next-line: function-name
  CheckLicenceNo() {

      // tslint:disable-next-line: max-line-length
    const id = this.activatedRoute.snapshot.params.id;
    this.licenseService.checkLicenseNoAvailability(this.licenseForm.controls.LicenseNo.value, id).subscribe((res:any) => {

      this.licenceno = res.Data;
      if (this.licenceno > 0)
        // tslint:disable-next-line: brace-style
      {
        this.alertOption = {
          title: 'Error!!!',
          html: `<span class='customClass'>That License no already exists.  Enter correct value or different.</span>`,
          type: 'error'
        };
        this.openAlert();
        this.licenseForm.controls['LicenseNo'].reset();
      }
    });
    return true;
  }
  private wizard1Validation(): boolean {
    let flag = false;
    if (this.licenseForm.controls.State_Code) {
      flag = this.licenseForm.controls.State_Code.valid
        && this.licenseForm.controls.LicenseTypeMasterList_Code.valid
        && this.licenseForm.controls.Company.valid
        && this.licenseForm.controls.Plants.valid
        && this.licenseForm.controls.ParentContractorVendor_Code.valid
        && this.licenseForm.controls.ParentWorkOrderCode.valid
        && this.licenseForm.controls.LicenseNo.valid
        && this.licenseForm.controls.Person.valid
        && this.licenseForm.controls.StartDate.valid
        && this.licenseForm.controls.EndDate.valid
        && this.licenseForm.controls.NatureOfWorkList.valid
        && this.licenseForm.controls.LinNumber.valid;

      if (flag) {
        flag = this.checkValidEndDate();
      }

      if (flag && this.isSubContractor) {
        flag = this.licenseForm.controls.ContractorVendor_Code.valid
          && this.licenseForm.controls.ChildWorkOrderCode.valid;
      }
    }
    return flag;
  }

  private checkValidEndDate() {
    if (this.licenseForm.controls.StartDate.value !== '' && this.licenseForm.controls.EndDate.value !== '') {
      const startDate: Date = new Date(this.licenseForm.controls.StartDate.value);
      const endDate: Date = new Date(this.licenseForm.controls.EndDate.value);
      if (startDate > endDate) {
        return false;
      }
    }
    return true;
  }

  private getLicense() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.licenseService.getLicenseByLicenseCode(id).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
        if (response.length > 0) {
          this.license = response[0];
          if (this.license.ParentContractorVendor_Code) {
            this.isSubContractor = true;
          } else {
            this.license.ParentContractorVendor_Code = this.license.ContractorVendor_Code;
            this.license.ContractorVendor_Code = null;
          }
          this.getAllData();
          this.setControlValue(true);
        }
      });
    } else {
      this.setDefaultECPolicyObj();
      this.setControlValue(false);
      this.getAllData();
    }
  }

  private setDefaultECPolicyObj() {
    this.license = {
      ParentContractorVendor_Code: null,
      ContractorVendor_Code: null,
      ParentWorkOrderCode: null,
      ChildWorkOrderCode: null,
      Plants: null,
      NatureOfWorkList: null,
      SatusMasterList_Code: null,
      VerifyBy: null,
      DocumentImageMasterViews: []
    };
  }

  private setControlValue(isEdit: boolean): void {
    if (!isEdit) {

      this.licenseForm.controls['Company'].disable();
      this.licenseForm.controls['Plants'].disable();
      this.licenseForm.controls['StartDate'].disable();
      this.licenseForm.controls['EndDate'].disable();
    }
    this.licenseForm.controls['ParentContractorVendor_Code'].setValue(this.license.ParentContractorVendor_Code);
    this.getContractorWorkOrder(this.license.ParentContractorVendor_Code);
    this.licenseForm.controls['ParentWorkOrderCode'].setValue(this.license.ParentWorkOrderCode);
    if (this.licenseForm.controls['ContractorVendor_Code']) {
      this.licenseForm.controls['ContractorVendor_Code'].setValue(this.license.ContractorVendor_Code);
    }
    if (this.licenseForm.controls['ChildWorkOrderCode']) {
      this.licenseForm.controls['ChildWorkOrderCode'].setValue(this.license.ChildWorkOrderCode);
    }
    this.licenseForm.controls['LicenseNo'].setValue(this.license.LicenseNo);
    this.licenseForm.controls['Person'].setValue(this.license.Person);
    this.licenseForm.controls['StartDate'].setValue(this.license.StartDate);
    this.licenseForm.controls['EndDate'].setValue(this.license.EndDate);
    this.licenseForm.controls['LinNumber'].setValue(this.license.LinNumber);
    this.licenseForm.controls['ExtensionDate'].setValue(this.license.ExtensionDate);
    this.licenseForm.controls['VerifyDate'].setValue(this.license.VerifyDate);
    // this.licenseForm.controls['VerifyBy'].setValue(this.license.VerifyBy);
    this.licenseForm.controls['Comment'].setValue(this.license.Comment);
    if (isEdit) {
      this.isValidStartDate = true;
      this.licenseForm.controls['EndDate'].enable();
      this.minEndDate = new Date((new Date(this.license.StartDate)).getTime() + (60 * 60 * 24 * 1000));
     // this.maxEndDate = this.setEndDateByLicenceType(this.license.LicenseTypeMasterList_Code, this.license.StartDate);
    }

    this.uploadedFiles = [];
    if (this.license.DocumentImageMasterViews) {
      this.license.DocumentImageMasterViews.forEach((file) => {
        this.uploadedFiles.push({
          ID: file.ID,
          file: {
            lastModified: file.ModifiedDate,
            name: file.UploadFileName
          },
          documentTypeCode: file.DocumentTypeMasterList_Code,
          isUploaded: true,
          url: GLOBAL.BASE_URL_DOC + file.ParentRootFolder
        });
      });
    }
  }

  private getAllData() {
    this.getStates();
    this.getCompanies();
    this.getNatureOfLicense();
    this.getStatus();
    this.getNatureOfWorks();
    this.getVerifyByList();
  }

  private getStates(): void {
    this.licenseService.getState().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getState response', response);
      this.stateList = response;
      this.licenseForm.controls['State_Code'].setValue(this.license.State_Code);
    });
  }

  private getCompanies(): void {
    this.licenseService.getCompanies().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.companyList = response;
      if (!isNullOrUndefined(this.license.Company_Code)) {
        this.licenseForm.controls['Company'].setValue(this.license.Company_Code);
        this.getPlants(this.license.Company_Code);
      } else {
        this.licenseForm.controls['Company'].setValue(null);
        if (this.companyList.length === 1) {
          this.licenseForm.controls['Company'].setValue(this.companyList[0].Company_Code);
          this.licenseForm.controls['Company'].disable();
          this.getPlants(this.companyList[0].Company_Code);
        }
      }
      console.log('getCompanies response', response);
    });
  }

  getContractors() {

    this.licenseService.getContractors().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getContractors response', response);
      this.contractorList = response;
      this.licenseForm.controls['ParentContractorVendor_Code'].setValue(this.license.ParentContractorVendor_Code);
      if (this.license.ParentContractorVendor_Code) {
        this.getContractorWorkOrder(this.license.ParentContractorVendor_Code);
      }
    });
  }

  public getNatureOfLicense(): void {
    this.licenseService.getMasterItemListByCode(MasterItemCode.NATURE_OF_LICENSE).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any[]) => {
        console.log('getNatureOfLicense response', response);
        this.natureOfLicenseList = response;
        this.licenseForm.controls['LicenseTypeMasterList_Code'].setValue(this.license.LicenseTypeMasterList_Code);
      });
  }

  private getStatus(): void {
    this.licenseService.getMasterItemListByCode(MasterItemCode.STATUS_CODE).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any[]) => {
        this.statuses = response;
        this.licenseForm.controls['SatusMasterList_Code'].setValue(this.license.SatusMasterList_Code);
      });
  }

  private getNatureOfWorks(): void {
    this.licenseService.getNatureOfWorks().pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('getNatureOfWorks response', response);
      this.natureOfWorkList = response;
      this.licenseForm.controls['NatureOfWorkList'].setValue(this.license.NatureOfWorkList);
    });
  }

  private getSubContractorsByWorkOrder(excludeCode: number): void {

    this.licenseService.getContractors(excludeCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {

      this.subContractorList = response;
      this.licenseForm.controls['ContractorVendor_Code'].setValue(this.license.ContractorVendor_Code);
      // if (this.license.ContractorVendor_Code) {
      //
      //   this.getSubContractorWorkOrder(this.license.ContractorVendor_Code);
      // }
    });
  }

  private getPlants(companyCode: any): void {
    this.licenseService.getPlants(companyCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('getPlants response', response);
      this.plantList = response;
      if (this.license.Plants) {
        this.licenseForm.controls['Plants'].setValue(this.license.Plants);
      }
    });
  }

  private getContractorWorkOrder(contractorCode: number): void {

    this.licenseService.getWorkOrderFilterData(contractorCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorWorkOrderList = response;
      this.licenseForm.controls['ParentWorkOrderCode'].setValue(this.license.ParentWorkOrderCode);
      if (this.license.ParentWorkOrderCode && this.isSubContractor) {
        this.getSubContractorsByWorkOrder(this.license.ParentWorkOrderCode);
      }
    });
  }

  // private getSubContractorWorkOrder(contractorCode: number, subContractorCode: number): void {
  //   // tslint:disable-next-line: max-line-length
  // tslint:disable-next-line: max-line-length
  //   this.licenseService.getWorkOrderFilterDataForSubWorkorder(contractorCode, subContractorCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
  //     this.subContractorWorkOrderList = response;
  //     this.licenseForm.controls['ChildWorkOrderCode'].setValue(this.license.ChildWorkOrderCode);
  //   });
  // }
  private getSubContractorWorkOrder(subContractorCode: number): void {
    // tslint:disable-next-line: max-line-length
    this.licenseService.getWorkOrderFilterDataForSubWorkorder(subContractorCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.subContractorWorkOrderList = response;
      this.licenseForm.controls['ChildWorkOrderCode'].setValue(this.license.ChildWorkOrderCode);
    });
  }
  private getVerifyByList(): void {
    this.licenseService.getVerifyByList().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getState response', response);
      this.verifyByList = response;
      this.licenseForm.controls['VerifyBy'].setValue(this.license.VerifyBy ? this.license.VerifyBy.toString() : null);
    });
  }

  private bindCheckList() {
    this.checkList.push(
      {
        id: 1,
        text: 'Licenses Info',
        open: true,
        childrens: [
          {
            text: 'State',
            title: 'State',
            desc: 'State is required.',
            isValid: () => {
              return (this.licenseForm.controls.State_Code.valid);
            },
            hasError: () => {
              return ((this.licenseForm.controls.State_Code.dirty || this.licenseForm.controls.State_Code.touched)
                && this.licenseForm.controls.State_Code.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Company & Plant',
            title: 'Company & Plant',
            desc: 'Company and Plant is required.',
            isValid: () => {
              return (this.licenseForm.controls.Company.valid
                && this.licenseForm.controls.Plants.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.Company.dirty
                || this.licenseForm.controls.Company.touched)
                && this.licenseForm.controls.Company.invalid)
                || ((this.licenseForm.controls.Plants.dirty
                  || this.licenseForm.controls.Plants.touched)
                  && this.licenseForm.controls.Plants.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Contractor Details',
            title: 'Contractor Details',
            desc: 'Contractor and Work Order is required.',
            isValid: () => {
              return (this.licenseForm.controls.ParentContractorVendor_Code.valid
                && this.licenseForm.controls.ParentWorkOrderCode.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.ParentContractorVendor_Code.dirty
                || this.licenseForm.controls.ParentContractorVendor_Code.touched)
                && this.licenseForm.controls.ParentContractorVendor_Code.invalid)
                || ((this.licenseForm.controls.ParentWorkOrderCode.dirty
                  || this.licenseForm.controls.ParentWorkOrderCode.touched)
                  && this.licenseForm.controls.ParentWorkOrderCode.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'License Details',
            title: 'License Details',
            desc: 'License No, Nature of license is required.',
            isValid: () => {
              return (this.licenseForm.controls.LicenseNo.valid
                && this.licenseForm.controls.LicenseTypeMasterList_Code.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.LicenseNo.dirty || this.licenseForm.controls.LicenseNo.touched)
                && this.licenseForm.controls.LicenseNo.invalid)
                || ((this.licenseForm.controls.LicenseTypeMasterList_Code.dirty
                  || this.licenseForm.controls.LicenseTypeMasterList_Code.touched)
                  && this.licenseForm.controls.LicenseTypeMasterList_Code.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'License Validity',
            title: 'License Validity',
            desc: 'Issue Date and Validity Date is required.',
            isValid: () => {
              return (this.licenseForm.controls.StartDate.valid
                && this.licenseForm.controls.EndDate.valid);
            },
            hasError: () => {
              return (((this.licenseForm.controls.StartDate.dirty || this.licenseForm.controls.StartDate.touched)
                && this.licenseForm.controls.StartDate.invalid)
                || ((this.licenseForm.controls.EndDate.dirty || this.licenseForm.controls.EndDate.touched)
                  && this.licenseForm.controls.EndDate.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Maximum Associates',
            title: 'Maximum Associates',
            desc: 'Maximum Associates is required.',
            isValid: () => {
              return (this.licenseForm.controls.Person.valid);
            },
            hasError: () => {
              return ((this.licenseForm.controls.Person.dirty
                || this.licenseForm.controls.Person.touched)
                && this.licenseForm.controls.Person.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },

        ]
      },
      {
        id: 2,
        text: 'Documents',
        open: true,
        childrens: [
          {
            text: 'License Documents',
            title: 'License Documents',
            desc: 'License Document is required.',
            isValid: () => {
              return (this.uploadedFiles.length > 0);
            },
            hasError: () => {
              return (this.uploadedFiles.length === 0);
            },
            isMandatory: true,
            isVisible: true,
            step: '2',
          }
        ]
      }
    );
    if (this.isSubContractor) {
      const subContractorhekListObj = {
        text: 'Sub Contractor Details',
        title: 'Sub Contractor Details',
        desc: 'Sub Contractor and Sub-Work Order is required.',
        isValid: () => {
          return (this.licenseForm.controls.ContractorVendor_Code.valid
            && this.licenseForm.controls.ChildWorkOrderCode.valid);
        },
        hasError: () => {
          return (((this.licenseForm.controls.ContractorVendor_Code.dirty
            || this.licenseForm.controls.ContractorVendor_Code.touched)
            && this.licenseForm.controls.ContractorVendor_Code.invalid)
            || ((this.licenseForm.controls.ChildWorkOrderCode.dirty
              || this.licenseForm.controls.ChildWorkOrderCode.touched)
              && this.licenseForm.controls.ChildWorkOrderCode.invalid));
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      };
      this.checkList[0].childrens.splice(3, 0, subContractorhekListObj);
    }
  }

  private getDocumentTypes() {

    this.licenseService.getDocumentTypes().pipe(takeUntil(this.onDestroy)).subscribe((docTypes: any[]) => {
      this.documentTypes = docTypes;
    });
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    this.openAlert();
  }

  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }

  private resetFormAfterSave() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      const isDirect = this.license.IsDirect;
      setTimeout(() => {
        if (isDirect) {
          this.router.navigate(['/contractor/add-licences/'], { queryParams: { type: '1' } });
        } else {
          this.router.navigate(['/contractor/add-licences/'], { queryParams: { type: '0' } });
        }
      }, 500);
    }

    this.licenseForm.reset();
    this.setDefaultECPolicyObj();
    this.setControlValue(false);
  }
}
