import * as moment from "moment";

export function findTotalMinutes(value: string) {
  if (!value) {
    return 0;
  }
  const [hours, minutes] = value.toString().split(".");
  const minutesValue = minutes || '0';
  return +hours * 60 + (+(minutesValue.length > 1 ? minutesValue : `${minutesValue}0`));
}

export function convertToHourAndMinutes(totalMinutes: number): string {
  if (!totalMinutes) {
    return '0';
  }
  const num = totalMinutes;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  return `${rhours}.${rminutes}`;
}


export function getMinDateFromCode(data: any[], code1: string, code2: string): string {
  if(!data){
    return '';
  }
  const enableDisableObj = data.find(item => item.Code === code1);
  const daysValueObj = data.find(item => item.Code === code2);
  if (enableDisableObj && daysValueObj && +enableDisableObj.Value > 0) {
    const value = +daysValueObj.Value;
    if (value === 0) {
      return moment().format('YYYY-MM-DD');
    } else {
      return moment().add(value, 'days').format('YYYY-MM-DD');
    }
  }
  return '';
}
