import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Input } from '@angular/core';
import { ContractorsService } from './contractors.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import {
  AgNumberCountComponent,
  AgActionCellRendererComponent,
} from '../../core/components';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';
import * as moment from 'moment';
import { values } from 'lodash';
import { click } from '@syncfusion/ej2-angular-pivotview';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
//import { Clipboard } from "@angular/cdk/clipboard"

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

declare var $;
const dateFormat = 'DD-MM-YYYY';
// const date1: Date = new Date();
const date1 = new Date();
date1.setDate(date1.getDate() - 1);
console.log(date1.toString());
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }, { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})

export class ContractorsComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  private modalRef: NgbModalRef;
  forContractorLogin = true;
  leaveContractor = false;
  hidesubgrid = false;
  leavetype = [];
  datePickerPickerMode: string;
  datePickerPlaceHolder: string;
  public contractorcode: number;
  leaveform: FormGroup;
  //  = new FormGroup({
  // LeaveType_Code: new FormControl(''),
  // LeaveType: new FormControl(''),
  // ApplicableDate: new FormControl(''),
  // ValidityDate: new FormControl(''),
  // ContractorVendor_Code: new FormControl(''),
  // AllowLeave: new FormControl('')
  // leaveTypeFormArray: new FormControl([])
  // });
  public get leaveTypeFormArray(): any {
    return this.leaveform.get('leaveTypeFormArray');
  }

  leavetypes = [];
  isChecked = [];
  optionsChecked = [];
  statusChecked = [];
  public contractor: [];
  public leave: [];
  identityDetails = [];
  final = [];
  public disablesavebutton = false;
  hidevaliditydate = false;
  // public disablecheckbox = false;
  public leavedatatable: any;
  public checkapplicability: any[] = [];
  public flag: boolean;
  public closeModal: EventEmitter<any>;
  //public globalSettings: any;
  @Input() public globalSettings: any[];
  public isHidePOInput: boolean = true;

  // tslint:disable-next-line: member-ordering
  contractorType = 1;
  columnDefs = [
    {
      headerName: '#', field: '', cellStyle: {textAlign: 'center'}, width: 10,
      sortable: false, suppressMenu: true, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'CCN', field: 'ContractorVendor_Code', cellStyle: {textAlign: 'center'}, width: 20, 
      //sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true }
    },
    {
      headerName: 'Company', field: 'Company', width: 100, cellRenderer: function(params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>'}, 
      tooltip: (params) => params.data.Company,
      sortable: true, filter: true, 
      suppressMenu: true,
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
        navigator['clipboard'].writeText(cellValue)
        .then(() => {
          console.log('Text copied to clipboard:', cellValue);
        })
        }
      },
    },
    {
      headerName: 'Vendor Code', field: 'ShortCode', width: 60, cellRenderer: function(params) { return '<span class="hover-shadow">' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>'}, 
      tooltip : (params) => "Copy to Vendor Code",
      sortable: true, filter: true, 
      suppressMenu: true,
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
        navigator['clipboard'].writeText(cellValue)
        .then(() => {
          console.log('Text copied to clipboard:', cellValue);
        })
        }
      },
    },
    {
      headerName: 'PAN No', field: 'PANNo', width: 60, cellRenderer: function(params) { return '<span class="hover-shadow"> ' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>'},
      tooltip : (params) => "Copy to PAN No",
      sortable: true, filter: true,
      suppressMenu: true,
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
        navigator['clipboard'].writeText(cellValue)
        .then(() => {
          console.log('Text copied to clipboard:', cellValue);
        })
        }
      },
    },
    {
      headerName: 'EmailID', field: 'EmailID', width: 100, cellRenderer: function(params) { return '<span class="hover-shadow"> ' + params.value + '&nbsp;&nbsp; <i class="fa fa-clipboard" [cdkCopyToClipboard]=' + params.value + '></i> </span>'},
      tooltipValueGetter: params => 'Copy to EmailID',
      sortable: true, filter: true,
      suppressMenu: true,
      filterParams: { applyButton: true, clearButton: true },
      onCellClicked: (event: CellClickedEvent) => {
        const cellValue = event.value;
        if (navigator && navigator['clipboard']) {
        navigator['clipboard'].writeText(cellValue)
        .then(() => {
          console.log('Text copied to clipboard:', cellValue);
        })
        }
      },
    },
    {
      headerName: 'Status', field: 'Status', cellStyle: {textAlign: 'center'}, width: 100, hide : this.isHidePOInput ,
      //sortable: true, filter: true,
      filterParams: { applyButton: true, clearButton: true },
      cellRenderer: ({ data }) => {
        if (!data.Status) {
          return null;
        }
        if (data.Status === "Deactivate") {
          return `<span class="badge badge-danger">${data.Status}</span>`;
        }
        else if (data.Status === "Active")
        return `<span class="badge badge-success">${data.Status}</span>`;
      }
    },
    {
      headerName: 'Action', field: '', cellStyle: {textAlign: 'center'}, width: 25,
      sortable: false, suppressMenu: true, cellRendererFramework: AgActionCellRendererComponent
    }
  ];

//   copyToClipboard(text) {
//     this['clipboard'].copy(text);
// }

  rowData = [];
  public isFormSubmitted: boolean;

  constructor(
    private router: Router,
    private contractorsService: ContractorsService,
    private modalService: NgbModal,
    private logHelperService: LogHelperService,
    public sharedService: SharedService,
    //private clipboard: Clipboard,
    private fb: FormBuilder,
  ) {
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
    this.closeModal = new EventEmitter();

    this.leaveform = this.fb.group({
      ContractorVendor_Code: [null],
      leaveTypeFormArray: this.fb.array([])
    });
    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      this.sharedService.setGlobalSettings(res);
      if (res) {
        this.globalSettings = res.Data.Table;
        const ContractorSetting = this.globalSettings.find(item => item.Code === 'MOD52PG195REQCONSTATUS');
        if (ContractorSetting && ContractorSetting.Value === '1') {
          this.isHidePOInput = false;
        }
        else if (ContractorSetting && ContractorSetting.Value === '0') {
          this.isHidePOInput = true;
        }
      }
    });
    
  }

  ngOnInit() {
    const rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (rolecode === 119)
    // tslint:disable-next-line: brace-style
    {
      this.forContractorLogin = false;
      this.leaveContractor = false;
    }
    // tslint:disable-next-line: brace-style
    else
    // tslint:disable-next-line: brace-style
    {
      this.forContractorLogin = true;
      this.leaveContractor = true;
    }
    this.getContractorList(this.contractorType);
    this.getLeaveType();
    // this.getLeaveTypes();
    this.getContractor();
  }
  // getContractorList(contractorType: number) {
  //   this.contractorsService.getAllContractors(contractorType).subscribe((response: any) => {
  //     if (response.length > 0) {
  //       this.rowData = response;
  //     }
  //   });
  // }

  getContractorList(contractorType: number) {
    this.contractorsService.getAllContractors(contractorType).subscribe((response: any) => {
      if (response) {
        this.rowData = response;
      }
    });
  }

  onTabChange(event) {
    this.getContractorList(event.nextId);
  }

  editContractor(rowData) {
    this.router.navigate([`/edit-sub-contractor/${rowData.ContractorVendor_Code}`]);
    console.log('edit', rowData);
  }

  deleteContractor(rowData) {
    console.log(rowData);
  }

  public openContractorLeaveConfiguration(): void {
    console.log('form', this.leaveform);

    // if (this.leavetypes && this.leavetypes.length > 0) {

    const modalRef = this.modalService.open(this.templateRef, { size: 'lg', backdrop: 'static', keyboard: false });
    this.leaveform.reset();
    // this.setControlValues(this.leavetypes);

    this.hidesubgrid = false;
    this.disablesavebutton = false;
    this.optionsChecked = [];
    this.statusChecked = [];
    // }
  }

  private buildForm(dataObj: any, isDisabled?: boolean): FormGroup {
    return this.fb.group({
      isChecked: [false],
      LeaveType_Code: [dataObj.LeaveType_Code],
      ApplicableDate: [''],
      AllowLeave: [{ value: null, disabled: isDisabled }, [Validators.maxLength(4)]],
    });
  }

  // public validation(leavedatatable): void {
  //   this.leavedatatable.forEach((val) => {
  //     const existData = this.leavetypes.find(data => data.LeaveType === val.LeaveType);
  //     if (existData) {
  //       existData.ApplicableDate = val.ApplicableDate;
  //       existData.leavetype = val.leavetype;
  //     }
  //     // this.leavetypes.forEach((data) => {
  //     //   if (data.LeaveType === val.LeaveType) {
  //     //     if (val.ApplicableDate !== null) {
  //     //       this.leaveform.controls['ApplicableDate'].setValue(val.Applicability);
  //     //     }
  //     //   }
  //     // });
  //   });
  // }

  public onCloseAction(): void {
    this.closeModal.next('click');
    this.modalService.dismissAll(this.templateRef);
    this.optionsChecked = [];
    this.statusChecked = [];
  }

  public getLeaveType(): void {
    this.contractorsService.getLeaveTypeData().subscribe((response: any) => {
      this.leavetypes = response;
      this.setControlValues(this.leavetypes);
    });
  }

  private setControlValues(leaveTypes: any[]): void {
    const control = this.leaveform.get('leaveTypeFormArray') as FormArray;
    control.controls = [];
    for (const leaveType of leaveTypes) {
      const formGroup: any = this.buildForm(leaveType);
      control.push(formGroup);
    }
  }

  public getLeaveTypes(): void {
    this.contractorsService.getLeaveTypes(this.contractorcode).subscribe((response: any) => {
      this.leave = response;
      if (response.length > 0) {
        this.hidesubgrid = true;
      } else {
        this.hidesubgrid = false;
      }
      // this.leavedatatable = this.leave;
      // this.leavedatatable.forEach((data) => {
      //   // if (data.ApplicableDate !== null && data.ValidityDate !== null) {
      //     this.disablecheckbox = false;
      //   // }
      // });
    });
  }

  public getContractor(): void {
    this.contractorsService.getContractor().subscribe((response: any) => {
      this.contractor = response;
    });
  }

  // tslint:disable-next-line: function-name
  onCheckedLeave(leavetype: any, index: number, event) {
    const control = this.leaveTypeFormArray.controls[index];
    if (control.controls.isChecked.value) {
      control.controls.ApplicableDate.setValidators([Validators.required]);
      control.controls.ApplicableDate.updateValueAndValidity();
      control.controls.AllowLeave.setValidators([Validators.required, Validators.maxLength(4)]);
      control.controls.AllowLeave.updateValueAndValidity();
    } else {
      control.controls.ApplicableDate.setValue('');
      control.controls.AllowLeave.setValue('');

      control.controls.ApplicableDate.clearValidators();
      control.controls.ApplicableDate.updateValueAndValidity();
      control.controls.AllowLeave.clearValidators();
      control.controls.AllowLeave.updateValueAndValidity();
    }

    // this.leavetypes[leavetype] = event.target.checked;
    // if (this.leavetypes[leavetype] === true) {
    //   if (this.optionsChecked.indexOf(leavetype.LeaveType_Code) === -1) {
    //     this.optionsChecked.push(leavetype.LeaveType_Code);
    //     this.disablesavebutton = true;
    //     // tslint:disable-next-line: no-parameter-reassignment
    //     leavetype.optionsChecked = this.optionsChecked;
    //   } else {
    //     this.optionsChecked.splice(this.optionsChecked.indexOf(leavetype.LeaveType_Code), 1);
    //     // tslint:disable-next-line: no-parameter-reassignment
    //     leavetype.optionsChecked = this.optionsChecked;
    //   }
    // } else {
    //   this.optionsChecked.splice(this.optionsChecked.indexOf(leavetype.LeaveType_Code), 1);
    //   this.disablesavebutton = false;
    //   // tslint:disable-next-line: no-parameter-reassignment
    //   leavetype.optionsChecked = this.optionsChecked;
    // }
  }

  // tslint:disable-next-line: function-name
  IsCheckLeaveStatus(leavetype, event) {
    this.leavetypes[leavetype] = event.target.checked;
    if (this.leavetypes[leavetype] === true) {
      if (this.statusChecked.indexOf(leavetype.LeaveType_Code) === -1) {
        this.statusChecked.push(leavetype.LeaveType_Code);
        this.hidevaliditydate = true;
        // tslint:disable-next-line: no-parameter-reassignment
        leavetype.statusChecked = this.statusChecked;
      } else {
        this.statusChecked.splice(this.statusChecked.indexOf(leavetype.LeaveType_Code), 1);
        // tslint:disable-next-line: no-parameter-reassignment
        leavetype.statusChecked = this.statusChecked;
      }
    } else {
      this.statusChecked.splice(this.statusChecked.indexOf(leavetype.LeaveType_Code), 1);
      this.hidevaliditydate = false;
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.statusChecked = this.statusChecked;
    }
  }

  // tslint:disable-next-line: function-name
  IsContrcatorSelected(event) {
    this.leaveform.get('leaveTypeFormArray').reset();
    if (event === undefined) {
      this.disablesavebutton = false;
    } else {
      if (event.ContractorVendor_Code !== null) {
        this.disablesavebutton = true;
        this.contractorcode = event.ContractorVendor_Code;
      } else {
        this.disablesavebutton = false;
        this.contractorcode = event.ContractorVendor_Code;
      }
    }
    this.getLeaveTypes();
  }

  // tslint:disable-next-line: function-name
  Applicability(event, leavetype) {
    if (typeof event === 'string') {
    } else if (event) {
      // const value = this.leaveform.getRawValue();
      // //  const applicableDate = moment(value.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
      // const adate = new Date(moment(event).format(this.sharedService.datePickerSettings.requestFormat));

      // console.log("applicableDate", adate);
      // console.log("value", value);
      // const applicabilitydate = adate;
      // console.log("applicabilitydate", applicabilitydate);
      // tslint:disable-next-line: no-parameter-reassignment
      // leavetype.ApplicableDate = applicabilitydate;
    }
  }

  // tslint:disable-next-line: function-name
  Validity(event, leavetype) {
    if (typeof event === 'string') {
    } else if (event) {
      const vdate = new Date(moment(event).format(this.sharedService.datePickerSettings.requestFormat));
      const validitydate = vdate;
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.ValidityDate = validitydate;
    }
  }

  // tslint:disable-next-line: function-name
  AllowedLeave(event, leavetype) {
    if (typeof event === 'string') {
    } else if (event) {
      const totalallowleave = parseInt(event.target.value, 10);
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.AllowLeave = totalallowleave;
    }
  }

  // public addLeaveType() {
  //   this.isFormSubmitted = !this.leaveform.valid;
  //   const leaveform = this.leaveform.getRawValue();
  //   // this.leavetypes.forEach((data) => {
  //   //   if (data.optionsChecked !== null) {
  //   //     if (data.ApplicableDate !== undefined) {
  //   //       this.flag = true;
  //   //     }
  //   //     else {
  //   //       this.flag = false;
  //   //     }
  //   //   }
  //   // });
  //   if (this.optionsChecked.length > 0 && leaveform.ContractorVendor_Code !== null && leaveform.ApplicableDate !== null) {
  //     this.disablesavebutton = true;
  //     const userId = localStorage.getItem('UserID');

  //     const leavedata: any = {
  //       // ContractorVendor_Code: leaveform.ContractorVendor_Code,
  //       // LeaveType_Code: this.optionsChecked,
  //       // Statusof_LeaveTypeCode: this.statusChecked,
  //       ApplicableDate: leaveform.ApplicableDate,
  //       ValidityDate: leaveform.ValidityDate,
  //       leavetypes: this.leavetypes,
  //       UserId: localStorage.getItem('UserID'),
  //       optionsChecked: this.optionsChecked,
  //       statusChecked: this.statusChecked,
  //       ContractorVendor_Code: leaveform.ContractorVendor_Code
  //     };
  //     this.contractorsService.addLeave(leavedata).subscribe((response: any) => {
  //       if (response) {
  //         this.logHelperService.logSuccess({
  //           message: 'LeaveType add successfully'
  //         });
  //       } else if (leaveform.ContractorVendor_Code === null) {
  //         this.disablesavebutton = true;
  //         this.logHelperService.logError({
  //           message: 'Please Select Contractor!'
  //         });
  //       } else if (leaveform.ApplicableDate === null) {
  //         this.disablesavebutton = true;
  //         this.logHelperService.logError({
  //           message: 'Please Select Applicable Date!'
  //         });
  //       }
  //     });
  //   }
  // }

  public addLeaveType() {
    this.isFormSubmitted = !this.leaveform.valid;
    const leaveform = this.leaveform.getRawValue();
    leaveform.leaveTypeFormArray.forEach((data) => {
      data.ApplicableDate = moment(data.ApplicableDate).format(this.sharedService.datePickerSettings.requestFormat);
    });
    const arrLeave = leaveform.leaveTypeFormArray;
    console.log('Date ApplicableDate', arrLeave);

    if (this.leaveform.valid) {
      // this.leavetypes.forEach((data) => {
      //   if (data.optionsChecked !== null) {
      //     if (data.ApplicableDate !== undefined) {
      //       this.flag = true;
      //     }
      //     else {
      //       this.flag = false;
      //     }
      //   }
      // });
      const selectedLeaves = arrLeave
        .map((value, index) => {
          return { ...value, LeaveType_Code: this.leavetypes[index].LeaveType_Code };
        })
        .filter(value => value.isChecked);
      // const selectedLeaves = leaveform.leaveTypeFormArray.filter(item => item.isChecked);
      if (selectedLeaves.length > 0) {
        this.disablesavebutton = true;
        const userId = localStorage.getItem('UserID');

        const leavedata: any = {
          // ContractorVendor_Code: leaveform.ContractorVendor_Code,
          // LeaveType_Code: this.optionsChecked,
          // Statusof_LeaveTypeCode: this.statusChecked,
          // ApplicableDate: leaveform.ApplicableDate,
          // ValidityDate: leaveform.ValidityDate,
          leavetypes: selectedLeaves,
          UserId: localStorage.getItem('UserID'),
          // optionsChecked: this.optionsChecked,
          // statusChecked: this.statusChecked,
          ContractorVendor_Code: leaveform.ContractorVendor_Code
        };
        this.contractorsService.addLeave(leavedata).subscribe((response: any) => {
          if (response) {
            this.logHelperService.logSuccess({
              message: 'LeaveType add successfully'
            });
            this.closeModal.next('click');
            this.modalService.dismissAll(this.templateRef);
            this.leaveform.reset();
            this.disablesavebutton = false;
            this.router.navigate(['contractors']);
          } else {
            this.logHelperService.logError({
              message: 'LeaveType not added'
            });
            this.closeModal.next('click');
            this.modalService.dismissAll(this.templateRef);
            this.leaveform.reset();
            this.disablesavebutton = false;
            this.router.navigate(['contractors']);
          }
        });
      } else {
        if (selectedLeaves.length === 0) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Please Select atleast one LeaveType!'
          });
        } else if (this.optionsChecked.length > 0 && this.statusChecked.length > 0) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Leave can be activated or de-activated only!'
          });
        } else if (leaveform.ContractorVendor_Code === null) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Please Select Contractor!'
          });
        } else if (leaveform.ApplicableDate === null) {
          this.disablesavebutton = true;
          this.logHelperService.logError({
            message: 'Please Select Applicable Date!'
          });
        }
      }
    }
  }

}
