import { Component, OnInit } from '@angular/core';
import { TableService } from '../service/table.service';

@Component({
  selector: 'app-table2',
  templateUrl: './table2.component.html',
  styleUrls: ['./table2.component.css']
})
export class Table2Component implements OnInit {

  public tableData : any;
  public tableHeader : any;

  constructor(private tableService : TableService) { }

  ngOnInit() {
    this.tableService.getTableData(2).subscribe((res : any) => {
      console.log('response',res)
      this.tableData = res;
      this.tableHeader = this.tableData.Table1.map((header) => {
        if(header.Value == false){
          header.child = header.RPTGroup;
        }
        return header;
      })

      this.tableHeader.forEach(element => {
        if(element.Value == false) {
          element.children = [];
          this.tableHeader.find(child => {
            if(child.RPTGroup == element.RPTGroup && child.Value){
              if(!element.children[child.RPTGroupChildL1 - 1]){
                element.children[child.RPTGroupChildL1 - 1] = [child];
              } else {
                element.children[child.RPTGroupChildL1 - 1].push(child);
              }
            }
          })
        }
      });
      console.log(this.tableData)
    });
  }

  public getTotal(item){
    let sum = 0;
    if(item.headerName === 'Net Amount Paid'){
      for(let value of this.tableData.Table2){
        let val = parseInt(value[item.headerName])
        sum = sum + val;
      }
      return sum;
    } else if(item.headerName === 'Designation'){
      return 'Total';
    }
    return null;
  }

  public getChildTotal(item){
    let sum = 0;
    for(let value of this.tableData.Table2){
      for(let i of item){
        if(!!value[i.headerName] && typeof value[i.headerName] === 'number'){
          sum = sum + value[i.headerName]
        }else if(!!value[i.headerName] && typeof value[i.headerName] === 'string'){
          let val = parseInt(value[i.headerName])
          sum = sum + val;
        }
      }
    }
    return sum;
  }

}
