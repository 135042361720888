import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListofWeekoffPresendDataComponent } from './listof-weekoff-presend-data/listof-weekoff-presend-data.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // {
  //   path: 'lateinpolicy',
  //   component: LatecomingPolicyComponent
  // },
  {
    path: 'list-data',
    component: ListofWeekoffPresendDataComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoffApprovalProcessRoutingModule { }
