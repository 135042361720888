import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GridApi, GridOptions } from "ag-grid-community";
import * as moment from "moment";
import { AgNumberCountComponent, AgSelectActionCellRendererComponent } from "src/app/core/components";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { GatepassRequestProcess } from "../gatepass-request-process.model";

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Component({
  selector: 'app-wc-policy-selection',
  templateUrl: './wc-policy-selection.component.html',
  styleUrls: ['./wc-policy-selection.component.css']
})
export class WCPolicySelectionComponent implements OnInit {
  @Input() public alertMessages: any[] = [];
  @Input() public wcPolicyList: any[] = [];
  @Input() public stepData: GatepassRequestProcess;
  @Input() public globalSettings: any[];

  @Output() public handleSave: EventEmitter<any>;
  @Output() public handleClose: EventEmitter<void>;
  @Output() public handlePrevAction: EventEmitter<void>;

  public currentStep: number = 1;
  public allStepDataObj: any;
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public gridAPI: GridApi;
  public showSkipButton: boolean = true;

  private parentWCPolicyList: any[] = [];

  private parentWCPolicyCode: number;
  private isCheckValidation: any;
  private isStatusColumnHide: any;
  constructor(
    private logHelperService: LogHelperService
  ) {
    this.handleSave = new EventEmitter<any>();
    this.handleClose = new EventEmitter<void>();
    this.handlePrevAction = new EventEmitter<void>();

    
  }

  public ngOnInit(): void {
    const workOrderSetting = this.globalSettings.find(item => item.Code === 'MOD52PG195REQECCAP');
    if (workOrderSetting) {
      this.isCheckValidation = workOrderSetting.Value;
    }
    const statusColumnStatus = this.globalSettings.find(item => item.Code === 'MOD52PG195REQECSTATUS');
    if (statusColumnStatus) {
      this.isStatusColumnHide = statusColumnStatus.Value;
    }
    this.createColumnDef();
    this.createGridOptions();
  }

  public onNextAction(): void {
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  public onSkipAction(): void {
    this.handleSave.emit({ ECPolicy_Code: null, NewECPolicy_Code: null, isSkipWCPolicy: true });
  }

  public onPrevAction(): void {
    if (this.parentWCPolicyCode) {
      this.wcPolicyList = this.parentWCPolicyList;
      this.parentWCPolicyCode = null;
      return;
    }
    this.handlePrevAction.emit();
  }

  public onRowClicked({ data: selectedData }: any): void {
    if (this.stepData.requestType === 103 && this.isCheckValidation != '0' && selectedData.ActivePerson <= 0) {
      let msg = this.alertMessages.find(item => item.Short_Code === 'MSG195_14')
      if (this.isCheckValidation === '1' && selectedData.ActivePerson <= 0) {
        this.logHelperService.logError({
          message: msg ? msg.MSG : ''
        })

        return;
      } else if (this.isCheckValidation === '2' && selectedData.ActivePerson <= 0) {
        this.logHelperService.logError({
          title: 'Warning!',
          message: msg ? msg.MSG : '',
        }, 'warning', 'Go to Next', true).then((res) => {
          if (res && res.value) {
            if (this.parentWCPolicyCode) {
              this.handleSave.emit({ ECPolicy_Code: this.parentWCPolicyCode, NewECPolicy_Code: selectedData.ECPolicy_Code });
              return;
            }
            if (selectedData.Status !== 'Expired') {
              this.handleSave.emit({ ECPolicy_Code: selectedData.ECPolicy_Code, NewECPolicy_Code: null });
              return;
            }

            this.logHelperService.logError({
              title: 'OOPS!!!',
              message: 'WC/EC policy is already expired! Please extend validity date for selected EC /WC Policy. Or select other active EC / WC Policy.'
            });
          }
        })
      }
    }

    if (this.parentWCPolicyCode) {
      this.handleSave.emit({ ECPolicy_Code: this.parentWCPolicyCode, NewECPolicy_Code: selectedData.ECPolicy_Code });
      return;
    }
    if (selectedData.Status !== 'Expired') {
      this.handleSave.emit({ ECPolicy_Code: selectedData.ECPolicy_Code, NewECPolicy_Code: null });
      return;
    }

    this.logHelperService.logError({
      title: 'OOPS!!!',
      message: 'WC/EC policy is already expired! Please extend validity date for selected EC /WC Policy. Or select other active EC / WC Policy.'
    });

    if (this.stepData.requestType === 104) {
      this.parentWCPolicyCode = selectedData.ECPolicy_Code;
      this.parentWCPolicyList = this.wcPolicyList;
      this.wcPolicyList = this.wcPolicyList.filter(item => item.ECPolicy_Code !== selectedData.ECPolicy_Code);
    }
  }

  checkCondition: any;
  private createColumnDef() {
    // this.checkCondition = this.globalSettings.find(item => item.Code === 'MOD52PG195REQECCAP').Value;
    this.columnDefs = [
      {
        headerName: '#', field: '',
        width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: 'WC / EC Policy No', field: 'ECPolicyNo',
        width: 100, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
      },
      {
        headerName: 'No of Insured', field: 'Person', width: 70, filterParams:
          { applyButton: true, clearButton: true }, filter: true
      },
      {
        headerName: 'Active Person', field: 'ActivePerson', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        hide: this.isCheckValidation === "1" || this.isCheckValidation === "2" ? true : false
      },
      {
        headerName: 'Remaining', field: 'RemainingPerson', width: 50, filterParams:
          { applyButton: true, clearButton: true }, filter: true,
        hide: this.isCheckValidation === "1" || this.isCheckValidation === "2" ? false : true
      },
      {
        headerName: 'Issue Dt.', field: 'IssueDate', width: 70, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          return moment(data.IssueDate).format('DD/MM/YYYY');
        }
      },
      {
        headerName: 'Validity Dt.', field: 'ValidtyDate', width: 80, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          return moment(data.ValidityDate).format('DD/MM/YYYY');
        }
      },
      {
        headerName: 'Status', field: 'Status', width: 30, filterParams: { applyButton: true, clearButton: true },
        hide: this.isStatusColumnHide === "1" ? false : true,
        sortable: false, suppressMenu: false, filter: false, cellStyle: { textAlign: 'center' },
        cellRenderer: ({ data }) => {
          if (data.Status === 'Expired') {
            return `<span class="badge badge-danger">${data.Status}</span>`;
          }

          return `<span class="badge badge-success">${data.Status}</span>`;
        }
      },
      {
        headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
      }
    ];
  }

  private createGridOptions(): void {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        return 37;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort" />',
        sortAscending: '<i class="fa fa-sort-alpha-asc" />',
        sortDescending: '<i class="fa fa-sort-alpha-desc" />'
      },
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 40,
    };
  }

}