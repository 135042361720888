import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YearlyBudgetComponent } from '../yearly-budget/yearly-budget.component';
import { YearlyBudgetFormComponent } from './yearly-budget-form/yearly-budget-form.component';
import { YearlyBudgetListComponent } from './yearly-budget-list/yearly-budget-list.component';

const routes: Routes = [
  {
    path: 'yearly-budget',
    // component: YearlyBudgetComponent
    component: YearlyBudgetListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class YearlyBudgetRoutingModule { }
