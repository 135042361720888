import { Component, OnInit, EventEmitter, Input, ViewChild } from '@angular/core';
import { GridApi, GridOptions } from 'ag-grid-community';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment';
import {
  AgApproveStatusCellRendererComponent
} from '../ag-helper/ag-approve-status-cell-renderer/ag-approve-status-cell-renderer.component';
import { AgDecimalEditCellRendererComponent } from '../ag-helper/ag-decimal-edit-cell-renderer/ag-decimal-edit-cell-renderer.component';
import { AgInputEditCellRendererComponent } from '../ag-helper/ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component';
import { AttendanceViewListGroupRowComponent } from './attendance-view-list-group-row/attendance-view-list-group-row.component';
import { ReportViewListGroupRowComponent }
  from '../../reports/daily-attendance/report-view-list-group-row/report-view-list-group-row.component';
import { SharedService } from 'src/app/core/services';
import { AttendanceService } from '../attendance.service';
import { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { GLOBAL } from 'src/app/app.globals';
// import { AllCommunityModules } from '@ag-grid-community/all-modules';
// import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
// import '@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css';

@Component({
  selector: 'app-attendance-approve',
  templateUrl: './attendance-approve.component.html',
  styleUrls: ['./attendance-approve.component.css']
})
export class AttendanceApproveComponent implements OnInit {

  public isShowCloseButton: boolean;
  public closeModal: EventEmitter<any>;
  private gridApi;
  gridAPI: GridApi;
  gridColumnApi;
  getRowNodeId;
  gridOptions: GridOptions;
  reportValueData = [];
  rowData: any[] = [];
  groupingMeta = ['ContractorName'];
  columnDefs;
  selectedCount = 0;
  private defaultColDef;
  private editType;
  selectedRows: any[] = [];
  rowClassRules;
  requiredFlag = false;
  private isRowSelectable;
  private isFilterData: boolean;
  public alertOption: SweetAlertOptions = {};
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  // public modules: any[] = AllCommunityModules;

  /*** Grid Configuration */
  dateValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('MM/DD/YYYY');
    }
    return '';
  }

  timeValueFormatter = (params) => {
    if (params.value) {
      return moment(params.value).format('HH:mm');
    }

    return '';
  }

  decimalValueFormatter = (params) => {
    if (params.value) {
      return this.decimalPipe.transform(params.value, '2.2-2');
    }
    return '';
  }

  rowIndexCellRenderer = (params) => {
    if (!params.data.parent) {
      return `<div style="padding: 5px"></div>`;
    }
    return '<div></div>';
  }



  constructor(
    public decimalPipe: DecimalPipe,
    private sharedService: SharedService,
    private attendanceService: AttendanceService,
  ) {
    this.closeModal = new EventEmitter();
    this.setColumnDef();
    this.defaultColDef = {
      flex: 1,
    };
    this.isRowSelectable = function (rowNode) {

      rowNode.data.CanEdit = false;
      return !rowNode.data.CanEdit;
    };
    this.editType = 'fullRow';
    this.gridOptions = {
      context: {
        componentParent: this
      },
      groupRowRenderer: (params) => {
        return params.node.key;
      },
      getRowHeight: (params) => {
        const isFullWidth = params.node.data.fullWidth;
        if (isFullWidth) {
          return 165;
        }
        return 45;
      },
      isExternalFilterPresent: () => true,
      isRowSelectable: (rowNode) => {
        return !rowNode.data.parent;
      },
      doesExternalFilterPass: node => node.data.isVisible,
      onGridReady: (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridAPI = params.api;
      },
      onGridSizeChanged: (params) => {
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      rowMultiSelectWithClick: true,
      pagination: false,
      headerHeight: 40,
    };
    this.getRowNodeId = function (data) {
      return data.group ? data.group : data.Code;
    };
  }

  ngOnInit() {
    this.getApproveAttendanceList();
  }
  public showCloseButton() {
    this.isShowCloseButton = true;
  }

  getApproveAttendanceList() {
    if (!this.isFilterData) {
      const model = {
        UserID: GLOBAL.USER.LOGGED_IN_USER,
        rdoDateType: null,
        Sdate: null,
        Edate: null,
        groupBy: null,
        Company_Code: 101,
        Unit_Code: null,
        Contractors: null,
        WorkOrder_Code: null,
        License_Code: null,
        ESIC: null,
        WCPolicy: null,
        SubContractors: null,
        SubWorkOrder_Code: null,
        id: null,
        SubLicense_Code: null,
        SubESIC: null,
        SubWCPolicy: null,
        WorkSiteCategory: null,
        IsApprove: true
      };
      this.attendanceService.getApproveAttendanceList(model).subscribe((res: any[]) => {
        this.reportData = res;
      });
    }
  }

  @Input()
  public set reportData(value: any[]) {
    if (value) {
      this.isFilterData = true;
      this.reportValueData = value;
      const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, value);
      this.rowData = tempRowData;
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }
  public onCloseAction(): void {
    this.closeModal.next();
  }

  onSelectionChanged(event) {
    let data = event.api.getSelectedRows();
    data = data.filter(e => !e.groupKey);
    this.selectedCount = data.length;
    // this.selectedCount = 1;
    console.log('selectedCount', this.selectedCount);
  }

  onApprove() {
    this.selectedRows = this.gridApi.getSelectedRows();
    this.selectedRows = this.selectedRows.filter(e => !e.groupKey);
    if (this.selectedRows.length === 0) {
      alert('Please select atleast one row');
      // return;
    } else if (this.requiredFlag) {
      alert('Please fill required fields!!');
      // return;
    } else {
      this.selectedRows.map((e) => {
        if (e.WorkingHours && !e.ActualWorkingHours) {
          e.ActualWorkingHours = e.WorkingHours;
        }
        if (e.OverTime && !e.ActualOverTime) {
          e.ActualOverTime = e.OverTime;
        }
        if (!e.Comment) {
          if (e.WorkingHours || e.OverTime) {
            if ((e.ActualWorkingHours && (e.WorkingHours !== e.ActualWorkingHours)) ||
              (e.ActualOverTime && (e.OverTime !== e.ActualOverTime))) {
              alert('Please fill required details!!');
              return;
            }
          }
        }
        e.IsSubmited = true;
      });
      this.attendanceService.approveAttendance(this.selectedRows).subscribe((dt: any[]) => {
        console.log('after approve:', dt);
        this.alertOption = {
          title: 'Saved!!!',
          html: `<span class='customClass'>Data Successfully Save.</span>`,
          type: 'success'
        };
        setTimeout(() => {
          this.openAlert();
        }, 10);
      });
    }
  }

  private openAlert(): void {
    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {
    });
  }

  onRowSelected(event) {
    if (event.data.parent) {
      console.log('onRowSelected Group', event);
      this.gridOptions.api.forEachNode((node) => {
        if (node.data.ContractorName === event.data.group) {
          // Master open detail.  You could also call node.setSelected( true ); for alternate design.
          node.setSelected(event.node.selected);
        }
      });
    } else {
      if (event.node.selected) {
        this.setWorkingToActualWorking(event);
        event.node.columnApi.getColumn('Comment').getColDef().editable = true;
        event.node.columnApi.getColumn('ActualWorkingHours').getColDef().editable = true;
        event.node.columnApi.getColumn('ActualWorkingHours').getColDef().cellRendererFramework = AgDecimalEditCellRendererComponent;
        event.node.columnApi.getColumn('ActualOverTime').getColDef().editable = true;
        event.node.columnApi.getColumn('ActualOverTime').getColDef().cellRendererFramework = AgDecimalEditCellRendererComponent;
        // event.node.columnApi.getColumn('Comment').getColDef().cellStyle = { 'border': '1px solid green' };
        this.onBtStartEditing(event);
      } else {
        event.node.columnApi.getColumn('Comment').getColDef().editable = false;
        event.node.columnApi.getColumn('ActualWorkingHours').getColDef().editable = false;
        event.node.columnApi.getColumn('ActualWorkingHours').getColDef().cellRendererFramework = null;
        event.node.columnApi.getColumn('ActualOverTime').getColDef().editable = false;
        event.node.columnApi.getColumn('ActualOverTime').getColDef().cellRendererFramework = null;
        // event.node.columnApi.getColumn('Comment').getColDef().cellStyle = { 'border': 'none' };
        // this.gridOptions.columnApi.getColumn('Comment').getColDef().cellStyle = {'border': 'none'}

        this.onBtStopEditing();
      }
    }
  }

  setColumnDef() {
    this.rowClassRules = {
      'group-row': (params) => {
        return params.data.groupLevel === 1;
      },
    };
    this.columnDefs = [
      // {
      //   headerName: '', field: '', width: 30,
      //   pinned: 'left',
      //   sortable: false, suppressMenu: true,
      //   cellRenderer: this.rowIndexCellRenderer,
      //   filter: false,
      //   // checkboxSelection: (params) => {
      //   //   return params.data.group ? false : true;
      //   // },
      //   checkboxSelection: false,
      //   headerCheckboxSelection: true,
      // },
      {
        headerName: '#',
        width: 50,
        // pinned: 'left',
        suppressMenu: true,
        valueGetter(params) {
          return params.data.group ? '' : params.node.rowIndex;
        },
      },
      {
        headerName: 'Contractor Name',
        field: 'ContractorNameCount',
        hide: true, visible: false,
      },
      {
        headerName: 'Date', field: 'Date', width: 80,
        filter: true,
      },
      {
        // headerName: 'Contractor Detail',
        // cellStyle: { textAlign: 'center' }, headerClass: 'align-center',
        // children: [
        //   {
        headerName: 'Code', field: 'Code', width: 70,
        sortable: true, filter: true,

        colSpan: (params) => {
          return params.data.parent ? 16 : 1;
        },
        // cellRenderer: (params) =>{
        //   return params.data.parent ? params.data.ContractorNameCount : params.data.Code;
        // },
        cellRendererFramework: AttendanceViewListGroupRowComponent,
        // cellRendererFramework: ReportViewListGroupRowComponent,
        // cellRendererParams: {
        //   groupingData: this.groupingMeta
        // },
      },
      {
        headerName: 'Name', field: 'FullName', width: 150,
        sortable: false, filter: true,
      },

      {
        headerName: 'Shift', field: 'Shift', width: 80,
        sortable: false, filter: true,
      },
      {
        headerName: 'Shift Time', field: 'ShiftTime',
        sortable: false, width: 140,
      },
      {
        headerName: 'In Time', field: 'UnitFIn', width: 100,
        sortable: false, valueFormatter: this.timeValueFormatter,
      },
      {
        headerName: 'Out Time', field: 'UnitFOut', width: 100,
        sortable: false, valueFormatter: this.timeValueFormatter
      },
      {
        headerName: 'Late In', field: 'LateInTime', width: 100,
        sortable: false,
      },
      {
        headerName: 'Early Out', field: 'EarlyOutTime', width: 100,
        sortable: false,
      },
      {
        headerName: 'WHR', field: 'WorkingHours', width: 100,
        sortable: false, valueFormatter: this.decimalValueFormatter
      },
      // {
      // 	headerName: 'Actual WHR', field: 'ActualWorkingHours',
      // 	sortable: false, valueFormatter: this.decimalValueFormatter,
      // 	editable: true, width: 100,
      // 	// cellRendererFramework: AgDecimalEditCellRendererComponent,
      // },
      {
        headerName: 'OTH', field: 'OverTime', width: 100,
        sortable: false, valueFormatter: this.decimalValueFormatter,
      },
      // {
      // 	headerName: 'Actual OTH', field: 'ActualOverTime',
      // 	sortable: false, valueFormatter: this.decimalValueFormatter,
      // 	editable: true, width: 100,
      // 	// cellRendererFramework: AgDecimalEditCellRendererComponent,
      // 	// cellRendererParams: {
      // 	//   isDisable: (params) => {
      // 	//     return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
      // 	//   },
      // 	// }
      // },
      // {
      //   headerName: 'Comment', field: 'Comment',
      //   sortable: false,
      //   // cellRendererFramework: AgInputEditCellRendererComponent,
      //   editable: true,
      //   cellRendererParams: {
      //     isDisable: (params) => {
      //       return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
      //     },
      //   },
      //   cellStyle: (event) => {
      //     this.commentCellStyle(event);
      //   }
      // },
      // {
      //   headerName: 'Status', field: 'ApproveStatus', width: 120,
      //   sortable: false,
      //   // cellRendererFramework: AgApproveStatusCellRendererComponent,
      //   // editable: true,
      //   // cellRendererParams: {
      //   //   isDisable: (params) => {
      //   //     return params.data.FShift_Code === -2 || params.data.FShift_Code === -1;
      //   //   },
      //   // }
      // },
      {
        headerName: 'Last approved By', field: 'LastUpdatedByName', width: 200,
        sortable: false,
      },
      //   ],
      // }
    ];
  }

  private onRowEditingStarted(event) {
    if (!this.gridApi.getSelectedRows().find(e => e.rowIndex === event.rowIndex)) {
      this.gridApi.stopEditing();
    }
  }

  commentCellStyle = (event) => {
    if (!event.data.Comment) {
      if (event.data.WorkingHours || event.data.OverTime) {
        if ((event.data.ActualWorkingHours && (event.data.WorkingHours !== event.data.ActualWorkingHours)) ||
          (event.data.ActualOverTime && (event.data.OverTime !== event.data.ActualOverTime))) {
          return { border: '1px solid red' };
        }
      }
    }
    return { border: 'none' };
  }

  private cellEditingStopped(event) {
    if (!event.data.Comment) {
      if (event.data.WorkingHours || event.data.OverTime) {
        if ((event.data.ActualWorkingHours && (event.data.WorkingHours !== event.data.ActualWorkingHours)) ||
          (event.data.ActualOverTime && (event.data.OverTime !== event.data.ActualOverTime))) {
          this.requiredFlag = true;
          return { border: '1px solid red' };
        }
      }
    }
    this.requiredFlag = false;
    return { border: 'none' };
  }

  onBtStopEditing() {
    this.gridApi.stopEditing();
  }

  onBtStartEditing(event) {
    this.gridApi.setFocusedCell(event.rowIndex, 'ActualWorkingHours');
    this.gridApi.startEditingCell({
      rowIndex: event.rowIndex,
      colKey: 'ActualWorkingHours',
    });
  }

  setWorkingToActualWorking(event) {
    const rowNode = this.gridApi.getRowNode(event.data.Code);
    if (event.data.WorkingHours && !event.data.ActualWorkingHours) {
      rowNode.setDataValue('ActualWorkingHours', event.data.WorkingHours);
    }
    if (event.data.OverTime && !event.data.ActualOverTime) {
      rowNode.setDataValue('ActualOverTime', event.data.OverTime);
    }
  }
}
