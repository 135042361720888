import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { GLOBAL } from '../../app.globals';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ContractorAdapter } from '../contractor-adapter/contractor.adpater';
declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EcPoliciesService {

  constructor(private http: HttpClient,
    private ecPolicyAdapter: ContractorAdapter) { }

  /**
  * @description used to get all the work order.
  * @author Sonal Prajapati
  * @param contractorType a number type of the contractor.
  */
  getAllEcPolicies(contractorType: number) {

    const queryString = $.param({
      userid: localStorage.getItem('UserID'),
      drpfilterwork: contractorType
    });
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_EC_POLICYS}?${queryString}`,
      httpOptions
    );
  }

  // public getDocumentTypes() {
  //
  // //	const url = `${GLOBAL.APIS.ECPOLICY.GET_DOC_TYPES}/documents`;
  //   const url = `${GLOBAL.APIS.ECPOLICY.GET_DOC_TYPES}`;
  //   return this.http.get<any[]>(url);
  // }
  public getDocumentTypes() {
    const url = `${GLOBAL.APIS.ECPOLICY.GET_DOC_TYPES}`;
    return this.http.get<any[]>(url).pipe(map((response: any) => response.Data));
  }

  public checkECPolicyNoAvailability(ecPolicyNo: string, ecPolicyCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.ECPOLICY.CheckECPolicyNoAvailability}?ECPolicyNo=${ecPolicyNo}&ECPolicyCode=${ecPolicyCode}`;
    return this.http.get<any[]>(url);
  }

  public addECPolicy(value: any) {
    const url = `${GLOBAL.APIS.ECPOLICY.ADD_ECPOLICY} `;
    return this.http.post(url, value).pipe(map((response: any) => response.Data));
  }
  getWCPolicy(contractorType: number) {

    const userId = localStorage.getItem('UserID');
    const queryString = $.param({
      userid: localStorage.getItem('UserID'),
      // tslint:disable-next-line: object-shorthand-properties-first
      contractorType
    });
    const url = `${GLOBAL.APIS.ECPOLICY.GET_WC_POLICY}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=
    &Status_Code=&Plant_Code=&IsActive=&NatureOfWork_Code=&TotalAccupied=
    &Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=${contractorType}&UserId=${userId}`;
    return this.http.request(
      GLOBAL.HTTP_GET,
      url,
      httpOptions
    ).pipe(map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  }

  public updateECPolicy(value: any) {
    const url = `${GLOBAL.APIS.ECPOLICY.UPDATE_ECPOLICY}`;
    return this.http.post(url, value).pipe(map((response: any) => response.Data));
  }

  public uploadDocuments(files: any[], params) {
    const url = `${GLOBAL.APIS.ECPOLICY.ADD_DOCUMENT}`;
    const userId = localStorage.getItem('UserID');
    const fd = new FormData();
    let docType = '';
    const filesToUpload = this.getNotUploadedItems(files);
    filesToUpload.forEach((fileDoc, index) => {
      fd.append(`file${String(index)}`, fileDoc['file'], fileDoc['file']['name']);
      docType += filesToUpload.length - 1 === index ? `${fileDoc.documentTypeCode}` : `${fileDoc.documentTypeCode},`;
    });
    fd.append('Image_Type', docType);
    fd.append('UserID', userId);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const param = params[key];
        fd.append(key, String(param));
      }
    }

    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    });
    // headers = headers.delete('Content-Type');
    const requestOptions = {
      headers,
      body: fd
    };

    return this.http.request(GLOBAL.HTTP_POST, url, requestOptions);
  }

  public getEcPolicyByPolicyNo(ecpolicyCode: string): Observable<any> {

    const url = `${GLOBAL.APIS.ECPOLICY.GET_ECPOLICY}?ecpolicyCode=${ecpolicyCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertToDataResponse<any>(response)));
  }

  public getNatureOfWorks(): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_NATUREOFWORKS}?Search=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  }

  public getMasterItemListByCode(shortCode: string): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_MASTERITEMLIST}?Search=&ShortCode=${shortCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  }

  public getPlants(companyCode: any = ''): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_PLANTS}?Search=&Company=${companyCode}&State=&City=&Country=&SDate=&EDate=
    &UnitType=&Ownership=&LocatedAt=&ISESICApplicable=`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  }

  public getContractors(excludeContractorCode: any = ''): Observable<any> {
    const url = `${GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_CONTRACTORS}?search=&sDate=&eDate=&selectedField=&isFilter=&isMain=
    &ExcludeContractor_Code=${excludeContractorCode}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  }
  public getWorkOrderFilterDataForSubWorkorder(subContractorCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
      + '?sDate='
      + '&eDate='
      + '&search='
      + '&selectedField='
      + '&filterType='
      + '&condition='
      + '&isActive='
      + '&workOrderType='
      + '&workOrderOwner_Code='
      + '&workOrderHOD_Code='
      + '&department_Code='
      + '&isContractorType='
      + '&Plant_Code='
      + '&NatureOfWork_Code='
      + '&NatureOfWork_Code='
      + '&Contractor_Code='
      + '&SubContractor_Code=' + subContractorCode
      + '&WorkOrder_Code='
      + '&SubWorkOrder_Code='
      + '&WorkSiteArea_Code='
      + '&IsHRCApplicable='
      + '&Status_Code='
      + '&UserId=';
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  }


  public getWorkOrderFilterData(contractorCode: any): Observable<any> {
    // tslint:disable-next-line: prefer-template
    const url = GLOBAL.APIS.CONTRACTOR_MANAGEMENT.GET_WORKORDER_FILTER
      + '?sDate='
      + '&eDate='
      + '&search='
      + '&selectedField='
      + '&filterType='
      + '&condition='
      + '&isActive='
      + '&workOrderType='
      + '&workOrderOwner_Code='
      + '&workOrderHOD_Code='
      + '&department_Code='
      + '&isContractorType='
      + '&Plant_Code='
      + '&NatureOfWork_Code='
      + '&NatureOfWork_Code='
      + '&Contractor_Code=' + contractorCode
      + '&SubContractor_Code='
      + '&WorkOrder_Code='
      + '&SubWorkOrder_Code='
      + '&WorkSiteArea_Code='
      + '&IsHRCApplicable='
      + '&Status_Code='
      + '&UserId=';
    return this.http.get<any[]>(url).pipe(
      map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  }

  // getPOGlobalSetting(code: string): Observable<any> {
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => this.ecPolicyAdapter.convertResponse<any>(response)));
  // }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }
}
