import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleWithProviders } from '@angular/compiler/src/core';
import { CoreModule } from '../core/core.module';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { VisitorManagementRoutingModule } from './visitor-management-routing.module';
import { VisitorManagementService } from './visitor-management.service';
import { VisitorComponent } from './visitor/visitor.component';
import { VisitorDashboardComponent } from './visitor-dashboard/visitor-dashboard.component';
import { FaceTrainImageFileComponent } from '../face-recognition/face-file-upload/face-train-file.component';
import { FaceTrainCameraComponent } from '../face-recognition/face-train-cam/face-train-cam.component';
import { FaceRecognizatonComponent } from '../face-recognition/face-recognization/face-recognization.component';
import { FaceRecognitionUIComponent } from '../face-recognition/faceRecognitionUI.component';

@NgModule({
  exports: [
    VisitorManagementRoutingModule
  ],
  declarations: [
    VisitorComponent,
    VisitorDashboardComponent,
    // FaceRecognitionUIComponent,
    // FaceRecognizatonComponent,
    // FaceTrainCameraComponent,
    // FaceTrainImageFileComponent
  ],
  imports: [
    CommonModule,
    VisitorManagementRoutingModule,
    CoreModule.forRoot(),
    SweetAlert2Module,
  ],
  providers: [
    VisitorManagementService
  ],

})
export class VisitorManagementModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: VisitorManagementModule,
      providers: [
        VisitorManagementService,
      ]
    };
  }
}
