import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { WizardStep, Checklist, FileDoc, FileUploadService } from 'src/app/core/components';
import { NgForm } from '@angular/forms';
import { Subscription, Subject, forkJoin } from 'rxjs';
// import { TreeviewItem } from 'ngx-treeview';
import { GLOBAL } from 'src/app/app.globals';
import { ContractorsService } from '../contractors.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/core/services';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { EnumRoleType, Master } from '../../const/enum';
declare let _;
@Component({
  selector: 'app-create-sub-contractor',
  templateUrl: './create-sub-contractor.component.html',
  styleUrls: ['./create-sub-contractor.component.css']
})


export class CreateSubContractorComponent implements OnInit, AfterViewInit, OnDestroy {
  isContractore : any= localStorage.getItem('RoleCode');
  enumRoleType:any=EnumRoleType;
  formChangesSubscription: Subscription;
  @ViewChild('contractorForm') contractorForm: NgForm;
  checkList: Checklist[] = [];
  wizardSteps: WizardStep[] = [];
  contractorTypes: any[] = [];
  entityTypes:any = [];
  contractorCode: number;
  ecPolicies :any= [];
  masterItemList :any = [];
  statusOfIndustries = [];
  addressTypes :any= [];
  contactPerTypes:any = [];
  bankNames:any = [];
  accountTypes :any= [];
  countries:any = [];
  lcountries :any= [];
  states :any= [];
  districts :any= [];
  cities :any= [];
  postOfficies:any = [];
  statesLocal :any= [];
  districtsLocal :any= [];
  citiesLocal :any= [];
  postOfficiesLocal :any= [];

  complienceInfoCode: number;
  // tslint:disable-next-line:variable-name
  ContractorContactPersonViews :any= [];
  hideAddContactPerson = false;
  disableAddContactPerson = true;
  hideUpdateContactPerson = true;
  editContractorContactPersonIndex: number;

  drivingLicenceTypes :any= [];
  contractorContactStates:any = [];
  hideAddContactState:any = false;
  disableAddContactState :any= true;
  hideUpdateContactState :any= true;
  editContractorContactStateIndex: number;
  documentTypes :any= [];
  editMode:any = false;
  uploadedFiles: FileDoc[] = [];
  editContractorCode: string;
  editContractorData: any;
  gender: string[];
  masterItemDataSubscription: Subscription;
  alertOption: { title: string; type: any; html: string; };
  dialogerror: any;
  datePickerPickerMode: string;
  datePickerPlaceHolder: string;
  private onDestroy: Subject<any>;
  showMainContent1 = true;
  showMainContent = false;
  company: any;
  shortCode: any;
  email: any;
  pANNo: any;
  mobileNo: any;
 // sharedDataService: any;
  constructor(
    private contractorsService: ContractorsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fileUploadService: FileUploadService,
    public sharedService: SharedService,
    public sharedDataService: SharedDataService,
    private logHelperService: LogHelperService,
  )
  // {
  //   this.gender = [
  //     'male',
  //     'female',
  //     'transGender'
  //   ];
  // };
  // tslint:disable-next-line: brace-style
  {
    this.onDestroy = new Subject();
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;
  }

  async ngOnInit() {
    this.formChangesSubscription = this.contractorForm.form.valueChanges.subscribe((form) => {
      if (form['DocumentType_Code'] === '') {
        form['DocumentType_Code'] = null;
      }
      if (form['PersonTypeMasterList_Code']
        && form['FullName']
        && form['Designation']
        && form['personemail']
        && form['contactPermobileno']) {
        this.disableAddContactPerson = false;
      } else {
        this.disableAddContactPerson = true;
      }

      if (form['epfcode1']
        && form['statecode']) {
        this.disableAddContactState = false;
      } else {
        this.disableAddContactState = true;
      }
    });

    this.checkList = [
      {
        id: 1,
        text: 'Contractor Vendor',
        open: true,
        childrens: [
          {
            text: 'Company Name and Code is required',
            title: 'NameandCode',
            desc: 'Company Name and Code is required.',
            isValid: () => {
             // return true;
               // tslint:disable-next-line: ter-indent
               return (this.contractorForm.controls.Company.valid && this.contractorForm.controls.ShortCode.valid);
            },
            hasError: () => {
              // tslint:disable-next-line: comment-format
              //return false;
              return ((this.contractorForm.controls.Company.dirty || this.contractorForm.controls.Company.touched)
                && this.contractorForm.controls.Company.invalid)
                || ((this.contractorForm.controls.ShortCode.dirty || this.contractorForm.controls.ShortCode.touched)
                  && this.contractorForm.controls.ShortCode.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Email Id',
            title: 'Company Email Id',
            desc: 'Please provide valid Email Id.',
            isValid: () => { return this.contractorForm.controls.EmailID.valid; },
            hasError: () => {
              return ((this.contractorForm.controls.EmailID.dirty || this.contractorForm.controls.EmailID.touched)
                && this.contractorForm.controls.EmailID.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Company PAN',
            title: 'Company Pan',
            desc: 'Please provide valid PAN No.',
            isValid: () => { return this.contractorForm.controls.PANNo.valid; },
            hasError: () => {
              return ((this.contractorForm.controls.PANNo.dirty || this.contractorForm.controls.PANNo.touched)
                && this.contractorForm.controls.PANNo.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Mobile Number',
            title: 'Mobile',
            desc: 'Please provide 10 digit Mobile number.',
            isValid: () => { return this.contractorForm.controls.PhoneNo.valid; },
            hasError: () => {
              return ((this.contractorForm.controls.PhoneNo.dirty || this.contractorForm.controls.PhoneNo.touched)
                && this.contractorForm.controls.PhoneNo.invalid);
            },
            isMandatory: false,
            isVisible: true,
            step: '1',
          }
        ],
      },
      {
        id: 2,
        text: 'Complience Registration Checklist',
        open: true,
        childrens: [
          {
            text: 'PF Detail',
            title: 'Address Proof',
            desc: 'Provide EPF Code, EPC Regional Office And Date.',
            step: '4',
            isVisible: true,
            isValid: () => {
              return this.contractorForm.controls.theemployees.value === true  
                && this.contractorForm.controls.epfcode.valid
                && this.contractorForm.controls.epcregional.valid
                && this.contractorForm.controls.renewaldate.valid;
            },
            hasError: () => {
              return this.contractorForm.controls.theemployees.value === true
                && ((this.contractorForm.controls.epfcode.invalid)
                  || (this.contractorForm.controls.epcregional.invalid)
                  || (this.contractorForm.controls.renewaldate.invalid));
            },
          },
          {
            text: 'GST Detail',
            title: 'GST Detail',
            desc: 'Provide GST Registraton Number, Company Registration Number.',
            step: '4',
            isVisible: true,
            isValid: () => {
              return this.contractorForm.controls.gstdetail.value === true
                && this.contractorForm.controls.gstregistrationno.valid
                && this.contractorForm.controls.companyregistrationno.valid;
            },
            hasError: () => {
              return this.contractorForm.controls.gstdetail.value === true
                && ((this.contractorForm.controls.gstregistrationno.invalid)
                || (this.contractorForm.controls.companyregistrationno.invalid));
            },
          },
          {
            text: 'ESI Detail',
            title: 'Address Proof',
            desc: 'Provide ESIC Code, ESIC Local Office And Date.',
            step: '4',
            isVisible: true,
            isValid: () => {
              return this.contractorContactStates.length > 0;
            },
            hasError: () => {
              return this.contractorForm.controls.employeesstates.value === true
                && this.contractorContactStates.length === 0;
            },
          }
        ],
      }
    ];

    this.wizardSteps = [
      {
        id: 'contractor',
        name: 'Contractor',
        isValid: () => {
        // tslint:disable-next-line: ter-indent
       // return true;
          return this.contractorForm.controls.Company.valid
            && this.contractorForm.controls.ShortCode.valid
            && this.contractorForm.controls.EmailID.valid
            && this.contractorForm.controls.PANNo.valid
            && this.contractorForm.controls.PhoneNo.valid;
        },
      },
      {
        id: 'contactPerson',
        name: 'Contact Person',
        isValid: () => {
          return true;
          return this.ContractorContactPersonViews.length >= GLOBAL.NO_OF_REQUIRED_CP;
        },
      },
      {
        id: 'identityDetail',
        name: 'Identity Detail',
        isValid: () => { return true; },

      },
      {
        id: 'complienceDetail',
        name: 'Complience Detail',
        isValid: () => {
          let valid = true;
          if (this.contractorForm.controls.theemployees.value === true) {
            if (this.contractorForm.controls.epfcode.valid
              && this.contractorForm.controls.epcregional.valid
              && this.contractorForm.controls.renewaldate.valid) {
              valid = true;
            } else {
              valid = false;
            }
          }

          if (this.contractorForm.controls.employeesstates.value === true) {
            if (this.contractorContactStates.length === 0) {
              valid = false;
            }
          }
          return valid;
        }
      },
      {
        id: 'documentDetail',
        name: 'Document Detail',
        isValid: () => { return true; }
      }
    ];
    this.showMainContent = true;
    this.showMainContent1 = false;
    // await this.contractorsService.getContractorCode().subscribe((contractorCode: any) => {
    //   this.contractorCode = contractorCode;
    // });
    // await this.contractorsService.getECPolicies().subscribe((ecPolicies: any) => this.ecPolicies = ecPolicies);
    this.sharedDataService.masterItemData$.subscribe((masterList: any) => {
      if( masterList && masterList.Data && masterList.Data.Table) {
        this.masterItemList = masterList.Data.Table;
      this.entityTypes = this.masterItemList.filter(list => list.Parent_Code === Master.EntityTypes);
      this.statusOfIndustries = this.masterItemList.filter(list => list.Parent_Code === 104);
      this.addressTypes = this.masterItemList.filter(list => list.Parent_Code === 138);
      this.contactPerTypes = this.masterItemList.filter(list => list.Parent_Code === 212);
      this.bankNames = this.masterItemList.filter(list => list.Parent_Code === 145);
      this.accountTypes = this.masterItemList.filter(list => list.Parent_Code === 213);
      this.drivingLicenceTypes = this.masterItemList.filter(list => list.Parent_Code === 194);
      this.contractorTypes = this.masterItemList.filter(list => list.Parent_Code === 215);
      
      }
    });

    await this.contractorsService.getCountries().subscribe((res:any) => {
      this.countries = res;
      this.lcountries = res;
    });

    // await this.contractorsService.getComplienceInfoCode().subscribe((complienceInfoCode: number) => {
    //   this.complienceInfoCode = complienceInfoCode;
    // });

    await this.contractorsService.getDocumentTypes().subscribe((res:any) => {
      this.documentTypes = res.Data;
    });

   // await this.getStatesByCountryId();
    this.editContractorCode = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.editContractorCode) {

      this.editMode = true;
      this.showMainContent1 = true;
      this.showMainContent = false;
      // tslint:disable-next-line: max-line-length
      await this.contractorsService.getContractorByID(this.editContractorCode, JSON.parse(localStorage.getItem('Role'))[0].Role_Code).subscribe((response:any) => {

        const contractor = response.Data;
        if (!contractor) {
          return;
        }
        this.editContractorData = contractor;

        // Step 1
        // Contractor Vendor
        this.contractorForm.controls['Company'].setValue(contractor.Company);
        this.contractorForm.controls['ShortCode'].setValue(contractor.ShortCode);
        this.contractorForm.controls['EmailID'].setValue(contractor.EmailID);
        this.contractorForm.controls['PANNo'].setValue(contractor.PANNo);
        this.contractorForm.controls['PANRegDate'].setValue(contractor.PANRegDate);
        this.contractorForm.controls['PhoneNo'].setValue(contractor.PhoneNo);
        this.contractorForm.controls['EffectiveDate'].setValue(contractor.EffectiveDate);
        this.contractorForm.controls['ContractorTypeMasterList_Code'].setValue(contractor.ContractorTypeMasterList_Code);
        this.contractorForm.controls['EntityTypeMasterList_Code'].setValue(contractor.EntityTypeMasterList_Code);
        this.contractorForm.controls['StatusofIndustryMasterList_Code'].setValue(contractor.StatusofIndustryMasterList_Code);
        this.contractorForm.controls['CommencementDate'].setValue(contractor.CommencementDate);
        this.contractorForm.controls['WebSiteURL'].setValue(contractor.WebSiteURL);
        // Register Office
        this.contractorForm.controls['PermentAddress1'].setValue(contractor.PermentAddress1);
        this.contractorForm.controls['PermentAddress2'].setValue(contractor.PermentAddress2);
        this.contractorForm.controls['PermentAddress3'].setValue(contractor.PermentAddress3);
        this.contractorForm.controls['PermentZipCode'].setValue(contractor.PermentZipCode);
        this.contractorForm.controls['PermentCountry_Code'].setValue(contractor.PermentCountry_Code);
        this.getStatesByCountryId() ;
        this.contractorForm.controls['PermentState_Code'].setValue(contractor.PermentState_Code);
        this.getDistrictsByState();
        this.contractorForm.controls['PermentDistrict_Code'].setValue(contractor.PermentDistrict_Code);
        this.getCitiesByDistrict();
        this.contractorForm.controls['PermentCity_Code'].setValue(contractor.PermentCity_Code);
        this.getPOsByCity();
        this.contractorForm.controls['PermentPostoffice_Code'].setValue(contractor.PermentPostoffice_Code);
        // Other Office
        this.contractorForm.controls['AddressTypeMasterList_Code'].setValue(contractor.AddressTypeMasterList_Code);
        this.contractorForm.controls['LocalAddress1'].setValue(contractor.LocalAddress1);
        this.contractorForm.controls['LocalAddress2'].setValue(contractor.LocalAddress2);
        this.contractorForm.controls['LocalAddress3'].setValue(contractor.LocalAddress3);
        this.contractorForm.controls['LocalZipCode'].setValue(contractor.LocalZipCode);
        this.contractorForm.controls['LocalCountry_Code'].setValue(contractor.LocalCountry_Code);
        // 
        // if(contractor.LocalCountry_Code){
        this.getStatesByCountryIdLocal();
        // }
        this.contractorForm.controls['LocalState_Code'].setValue(contractor.LocalState_Code);
        // if(contractor.LocalState_Code){
        this.getDistrictsByStateLocal();
        // }
        this.contractorForm.controls['LocalDistrict_Code'].setValue(contractor.LocalDistrict_Code);
        this.getCitiesByDistrictLocal();
        this.contractorForm.controls['LocalCity_Code'].setValue(contractor.LocalCity_Code);
        this.getPOsByCityLocal();
        this.contractorForm.controls['LocalPostoffice_Code'].setValue(contractor.LocalPostoffice_Code);
        // Step 3
        // Identity details
        this.contractorForm.controls['adhaarnumber'].setValue(contractor.AadhaarNo);
        this.contractorForm.controls['adhaarname'].setValue(contractor.AadhaarName);
        this.contractorForm.controls['pannumber'].setValue(contractor.PANNumber);
        this.contractorForm.controls['panname'].setValue(contractor.PANName);
        this.contractorForm.controls['driliceType'].setValue(contractor.DLCTypeMasterList_Code);
        this.contractorForm.controls['drilicenumber'].setValue(contractor.DrivingLicenseNo);
        this.contractorForm.controls['liceanceRegDate'].setValue(contractor.DrivingLicenseRegDate);
        this.contractorForm.controls['drilicevaliditydate'].setValue(contractor.DrivingLicenseValidityDate);
        this.contractorForm.controls['drilicensename'].setValue(contractor.DrivingLicenseName);
        this.contractorForm.controls['licbatchnumber'].setValue(contractor.DrivingLicenseBatchNo);
        this.contractorForm.controls['passportno'].setValue(contractor.PassportNo);
        this.contractorForm.controls['passportname'].setValue(contractor.PassportName);
        this.contractorForm.controls['passportexpiredate'].setValue(contractor.PassportValidityDate);
        // Bank details
        this.contractorForm.controls['bankname'].setValue(contractor.BankNameMasterList_Code);
        this.contractorForm.controls['accounttype'].setValue(contractor.AccountTypeMasterList_Code);
        this.contractorForm.controls['branchcode'].setValue(contractor.BranchCode);
        this.contractorForm.controls['nameinbank'].setValue(contractor.NameInBank);
        this.contractorForm.controls['accountnumber'].setValue(contractor.BankAccountNumber);
        this.contractorForm.controls['branchname'].setValue(contractor.BranchName);
        this.contractorForm.controls['ifsccode'].setValue(contractor.IFSCCode);
        this.contractorForm.controls['micrcode'].setValue(contractor.MICRCode);

        // Step 4
        if (contractor.IsContactor === 0)
        // tslint:disable-next-line: brace-style
        {
          if (contractor.EntityComplianceInfos) {
            if (contractor.EntityComplianceInfos[0]
            && contractor.EntityComplianceInfos[0].RegistrationNumber
            && contractor.EntityComplianceInfos[0].Other3
            && contractor.EntityComplianceInfos[0].DueOn) {
              this.contractorForm.controls['theemployees'].setValue(true);
              this.contractorForm.controls['epfcode'].setValue(contractor.EntityComplianceInfos[0].RegistrationNumber);
              this.contractorForm.controls['epcregional'].setValue(contractor.EntityComplianceInfos[0].Other3);
              this.contractorForm.controls['renewaldate'].setValue(contractor.EntityComplianceInfos[0].DueOn);
            }
            if (contractor.EntityComplianceInfos[1]
            && contractor.EntityComplianceInfos[1].Other1
            && contractor.EntityComplianceInfos[1].Other2) {
              this.contractorForm.controls['gstdetail'].setValue(true);
              this.contractorForm.controls['gstregistrationno'].setValue(contractor.EntityComplianceInfos[1].Other1);
              this.contractorForm.controls['companyregistrationno'].setValue(contractor.EntityComplianceInfos[1].Other2);
            }
            contractor.EntityComplianceInfos.forEach((element, index) => {
              if (index > 0) {
                const contractorContactState = {
                  RegistrationNumber: element.RegistrationNumber,
                  StateName: ''
                };
                this.states.forEach((state) => {
                  if (state.State_Code === element.State_Code) {
                    contractorContactState['StateName'] = state;
                  }
                });
                this.contractorContactStates.push(contractorContactState);
              }
            });
            if (this.contractorContactStates.length) {
              this.contractorForm.controls['employeesstates'].setValue(true);
            }
          }
        }
        // tslint:disable-next-line: brace-style
        else {
          if (contractor.EntityComplianceInfos[0]
          && contractor.EntityComplianceInfos[0].Other1
          && contractor.EntityComplianceInfos[0].Other2) {
            this.contractorForm.controls['gstdetail'].setValue(true);
            this.contractorForm.controls['gstregistrationno'].setValue(contractor.EntityComplianceInfos[0].Other1);
            this.contractorForm.controls['companyregistrationno'].setValue(contractor.EntityComplianceInfos[0].Other2);
          }
        }
        if (contractor.ContractorContactPersonViews) {
          contractor.ContractorContactPersonViews.forEach((element, index) => {
            if (index === 0) {
              const contractorContactPerson = {
                FullName: element.FullName,
                MobileNo: element.MobileNo,
                EmailID: element.EmailID,
                Designation: element.Designation,
                PhoneNo: element.PhoneNo,
                AltEmailID: element.AltEmailID,
                Name: ''
              };
              this.contractorTypes.forEach((contractorType) => {
                if (contractorType.MasterItem_Code === element.PersonTypeMasterList_Code) {
                  contractorContactPerson['Name'] = contractorType;
                }
              });
              this.ContractorContactPersonViews.push(contractorContactPerson);
            }
          });
        }
        // Step 5
        if (contractor.DocumentImageMasterViews) {
          this.uploadedFiles = [];
          contractor.DocumentImageMasterViews.forEach((file) => {
            this.uploadedFiles.push({
              ID: file.ID,
              file: {
                lastModified: file.ModifiedDate,
                name: file.UploadFileName
              },
              documentTypeCode: file.DocumentTypeMasterList_Code,
              isUploaded: true,
              url: GLOBAL.BASE_URL_DOC + file.ParentRootFolder
            });
          });

        }
       // this.editMode = false;
      });
      // await this.contractorsService.getContractorContactDataByID(this.editContractorCode).subscribe((res: []) => {
      //
      //   this.ContractorContactPersonViews = res;
      // });
     // this.editMode = false;
    } else {
      this.editMode = false;
    }
  }

  ngAfterViewInit(): void {
    this.contractorForm.reset();
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.

  }

  checkAvailabilityForEmail() {
  }
  getStatesByCountryId() {
    return this.contractorsService.getStatesByCountryId(this.contractorForm.control.value['PermentCountry_Code'] || 100)
      .subscribe((response:any) => {
        this.states = response;
      });
  }

  getDistrictsByState() {
    this.contractorsService.getDistrictsByState(this.contractorForm.control.value['PermentState_Code']).subscribe((response:any) => {
      this.districts = response;
    });
  }

  getCitiesByDistrict() {
    this.contractorsService.getCitiesByDistrict(this.contractorForm.control.value['PermentDistrict_Code']).subscribe((response:any) => {
      this.cities = response;
    });
  }

  getPOsByCity() {
    this.contractorsService.getPOsByCity(this.contractorForm.control.value['PermentCity_Code']).subscribe((response:any) => {
      this.postOfficies = response;
    });
  }

  getStatesByCountryIdLocal() {
    this.contractorsService.getStatesByCountryId(this.contractorForm.value.PermentCountry_Code).subscribe((response:any) => {
      
      this.statesLocal = response;
    });
  }

  getDistrictsByStateLocal() {
    this.contractorsService.getDistrictsByState(this.contractorForm.value['LocalState_Code']).subscribe((response:any) => {
      this.districtsLocal = response;
    });
  }

  getCitiesByDistrictLocal() {
    this.contractorsService.getCitiesByDistrict(this.contractorForm.control.value['LocalDistrict_Code']).subscribe((response:any) => {
      this.citiesLocal = response;
    });
  }

  getPOsByCityLocal() {
    this.contractorsService.getPOsByCity(this.contractorForm.control.value['LocalCity_Code']).subscribe((response:any) => {
      this.postOfficiesLocal = response;
    });
  }

  addContractorContactPerson(index?: number) {
    const contractorContactPerson = {
      AltEmailID: this.contractorForm.control.value['altPersonemail11'],
      AltMobileNo: this.contractorForm.control.value['ALtcontactPermobileno'],
      ContractorVendor_Code: this.complienceInfoCode,
      Designation: this.contractorForm.control.value['Designation'],
      EmailID: this.contractorForm.control.value['personemail'],
      FullName: this.contractorForm.control.value['FullName'],
      MobileNo: this.contractorForm.control.value['contactPermobileno'],
      PersonTypeMasterList_Code: this.contractorForm.control.value['PersonTypeMasterList_Code'],
      PhoneNo: this.contractorForm.control.value['contactPerphoneno']
    };

    this.contractorForm.controls['altPersonemail11'].reset();
    this.contractorForm.controls['ALtcontactPermobileno'].reset();
    this.contractorForm.controls['Designation'].reset();
    this.contractorForm.controls['personemail'].reset();
    this.contractorForm.controls['FullName'].reset();
    this.contractorForm.controls['contactPermobileno'].reset();
    this.contractorForm.controls['PersonTypeMasterList_Code'].reset();
    this.contractorForm.controls['contactPerphoneno'].reset();
    if (typeof index !== 'undefined') {
      this.ContractorContactPersonViews[index] = contractorContactPerson;
    } else {
      this.ContractorContactPersonViews.push(contractorContactPerson);
    }
  }

  updateContractorContactPerson() {
    this.addContractorContactPerson(this.editContractorContactPersonIndex);
    this.hideUpdateContactPerson = true;
  }

  editContractorContactPerson(person, index) {
    this.editContractorContactPersonIndex = index;
    this.contractorForm.controls['altPersonemail11'].setValue(person.AltEmailID);
    this.contractorForm.controls['ALtcontactPermobileno'].setValue(person.AltMobileNo);
    this.contractorForm.controls['Designation'].setValue(person.Designation);
    this.contractorForm.controls['personemail'].setValue(person.EmailID);
    this.contractorForm.controls['FullName'].setValue(person.FullName);
    this.contractorForm.controls['contactPermobileno'].setValue(person.MobileNo);
    this.contractorForm.controls['PersonTypeMasterList_Code'].setValue(person.PersonTypeMasterList_Code);
    this.contractorForm.controls['contactPerphoneno'].setValue(person.PhoneNo);
    this.hideUpdateContactPerson = false;
  }

  deleteContractorContactPerson(index: number) {
    this.ContractorContactPersonViews.splice(index, 1);
  }

  addContractorContactState(index?: number) {
    const contractorContactState = {
      State_Code: this.contractorForm.control.value['statecode'],
      RegistrationNumber: this.contractorForm.control.value['epfcode1'],
      StateName: ''
    };
    this.states.forEach((state) => {
      if (state.State_Code === this.contractorForm.control.value['statecode']) {
        contractorContactState['StateName'] = state.StateName;
      }
    });
    this.contractorForm.controls['statecode'].reset();
    this.contractorForm.controls['epfcode1'].reset();
    if (typeof index !== 'undefined') {
      this.contractorContactStates[index] = contractorContactState;
    } else {
      this.contractorContactStates.push(contractorContactState);
    }
  }

  updateContractorContactState() {
    this.addContractorContactState(this.editContractorContactStateIndex);
    this.hideUpdateContactState = true;
  }

  editContractorContactState(state, index) {
    this.editContractorContactStateIndex = index;
    this.contractorForm.controls['statecode'].setValue(state.State_Code);
    this.contractorForm.controls['epfcode1'].setValue(state.RegistrationNumber);
    this.hideUpdateContactState = false;
  }

  deleteContractorContactState(index: number) {
    this.contractorContactStates.splice(index, 1);
  }

  onSubmit(formData, redirect = true) {

    const contractor: any = {
      ContractorVendor_Code: this.editContractorCode,
      EntityTypeMasterList_Code: formData.EntityTypeMasterList_Code,
      StatusofIndustryMasterList_Code: formData.StatusofIndustryMasterList_Code,
      Company: formData.Company,
      CommencementDate: formData.CommencementDate,
      ShortCode: formData.ShortCode,
      EmailID: formData.EmailID,
      PhoneNo: formData.PhoneNo,
      PANNo: formData.PANNo,
      PANRegDate: formData.PANRegDate,
      EffectiveDate: formData.EffectiveDate,
      WebSiteURL: formData.WebSiteURL,
      AddressTypeMasterList_Code: formData.AddressTypeMasterList_Code,
      PermentAddress1: formData.PermentAddress1,
      PermentAddress2: formData.PermentAddress2,
      PermentAddress3: formData.PermentAddress3,
      PermentZipCode: formData.PermentZipCode,
      PermentCountry_Code: formData.PermentCountry_Code,
      PermentState_Code: formData.PermentState_Code,
      PermentCity_Code: formData.PermentCity_Code,
      PermentDistrict_Code: formData.PermentDistrict_Code,
      PermentPostoffice_Code: formData.PermentPostoffice_Code,
      LocalAddress1: formData.LocalAddress1,
      LocalAddress2: formData.LocalAddress2,
      LocalAddress3: formData.LocalAddress3,
      LocalZipCode: formData.LocalZipCode,
      LocalCountry_Code: formData.LocalCountry_Code,
      LocalState_Code: formData.LocalState_Code,
      LocalDistrict_Code: formData.LocalDistrict_Code,
      LocalCity_Code: formData.LocalCity_Code,
      LocalPostoffice_Code: formData.LocalPostoffice_Code,
      BankNameMasterList_Code: formData.bankname,
      NameInBank: formData.nameinbank,
      BranchName: formData.branchname,
      BankAccountNumber: formData.accountnumber,
      BranchCode: formData.branchcode,
      AccountTypeMasterList_Code: formData.accounttype,
      IFSCCode: formData.ifsccode,
      MICRCode: formData.micrcode,
      PANNumber: formData.pannumber,
      PANName: formData.panname,
      AadhaarNo: formData.adhaarnumber,
      AadhaarName: formData.adhaarname,
      DLCTypeMasterList_Code: formData.driliceType,
      DrivingLicenseNo: formData.drilicenumber,
      DrivingLicenseValidityDate: formData.drilicevaliditydate,
      DrivingLicenseName: formData.drilicensename,
      DrivingLicenseBatchNo: formData.licbatchnumber,
      DrivingLicenseRegDate: formData.liceanceRegDate,
      PassportNo: formData.passportno,
      PassportName: formData.passportname,
      PassportValidityDate: formData.passportexpiredate,
      CreateBy: localStorage.getItem('UserID'),
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      ContractorTypeMasterList_Code: formData.ContractorTypeMasterList_Code,
      EntityComplianceInfos: [],
      ContractorContactPersonViews: [],
      DocumentImageMasterViews: []
    };

    if (formData.theemployees === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'CCLRA1003';
      entityComplianceInfo.ContractorVendor_Code = this.editContractorCode;
      entityComplianceInfo.RegistrationNumber = formData.epfcode;
      entityComplianceInfo.Other3 = formData.epcregional;
      entityComplianceInfo.DueOn = formData.renewaldate;
      entityComplianceInfo.Legislation_Code = 103;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.gstdetail === true) {
      const entityComplianceInfo: any = {};
      entityComplianceInfo.ShortCode = 'CCLRA1004';
      entityComplianceInfo.Other1 = formData.gstregistrationno;
      entityComplianceInfo.Other2 = formData.companyregistrationno;
      entityComplianceInfo.Legislation_Code = 100;
      contractor.EntityComplianceInfos.push(entityComplianceInfo);
    }
    if (formData.employeesstates === true) {
      this.contractorContactStates.forEach((entityrel, index) => {
        const entityComplianceInfo: any = {};
        entityComplianceInfo.ShortCode = 'CCLRA1007';
        entityComplianceInfo.State_Code = entityrel.State_Code;
        entityComplianceInfo.RegistrationNumber = entityrel.RegistrationNumber;
        entityComplianceInfo.Legislation_Code = 107;
        contractor.EntityComplianceInfos.push(entityComplianceInfo);
      });
    }
    if (this.ContractorContactPersonViews.length) {
      contractor.ContractorContactPersonViews = this.ContractorContactPersonViews;
    }
    if (this.uploadedFiles.length) {
      this.uploadedFiles.forEach((file) => {
        if (file.isUploaded) {
          contractor.DocumentImageMasterViews.push({
            ID: file.ID,
            DocumentTypeMasterList_Code: file.documentTypeCode
          });
        } else {
          contractor.DocumentImageMasterViews.push({
            DocumentTypeMasterList_Code: file.documentTypeCode
          });
        }
      });
    }

    const uploadParams = {
      contractorvendor_code: ''
    };
    if (this.editMode) {
      this.contractorsService.updateContractor(contractor).subscribe((result: any) => {
        uploadParams.contractorvendor_code = result.Data;
        if (uploadParams.contractorvendor_code) {
          this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams, GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_DOC)
            .subscribe((res) => {
              if (redirect) {
                this.router.navigate(['contractors']);
              } else {
                this.contractorForm.reset();
                this.clearForm();
              }
            });
        }
        this.logHelperService.logSuccess({
          message: 'Contractor Updated successfully'
        });
        this.router.navigate(['contractors']);
      });
    } else {
      this.contractorsService.addContractor(contractor).subscribe((result: any) => {
        uploadParams.contractorvendor_code = result;
        if (uploadParams.contractorvendor_code) {
          this.fileUploadService.uploadFiles(this.uploadedFiles, uploadParams, GLOBAL.APIS.CONTRACTOR_MANAGEMENT.UPLOAD_DOC)
            .subscribe((res) => {
              if (redirect) {
                this.router.navigate(['contractors']);
              } else {
                this.contractorForm.reset();
                this.clearForm();
              }
            });
        }
        this.logHelperService.logSuccess({
          message: 'Sub-Contractor addded successfully'
        });
        this.contractorForm.reset();
        this.clearForm();
      });
    }
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    // this.formChangesSubscription.unsubscribe();
    // this.masterItemDataSubscription.unsubscribe();
  }

  onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }

  onCancel() {
    this.router.navigate(['contractors']);
  }

  saveAndNew() {

    this.onSubmit(this.contractorForm.value, false);

    this.router.navigate(['contractor/create-sub-contractor']);
    this.clearForm();
  }
  updateAndNew() {
    this.onSubmit(this.contractorForm.value, false);
  }
  // tslint:disable-next-line: function-name
  save() {

    this.onSubmit(this.contractorForm.value, false);

    this.router.navigate(['contractor/contractors']);
    this.clearForm();
  }

  clearForm() {
    this.ContractorContactPersonViews = [];
    this.contractorContactStates = [];
  }
  // tslint:disable-next-line: function-name
  CheckCompany() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkCompany(this.contractorForm.controls.Company.value, this.editContractorCode).subscribe((res:any) => {
        this.company = res.Data;
        if (this.company > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            message: 'This field requires a unique entry. This Firm / Company name has already been used. Please enter different input.'
          });
          this.contractorForm.controls['Company'].reset();
        }
      });
    return true;
  }
  // tslint:disable-next-line: function-name
  CheckShort() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkShortCode(this.contractorForm.controls.ShortCode.value, this.editContractorCode).subscribe((res:any) => {
        this.shortCode = res.Data;
        if (this.shortCode > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'This field requires a unique entry. This Vendor code has already been used with other Firm / Company. Please enter different vendor code.'
          });
          this.contractorForm.controls['ShortCode'].reset();
        }
      });
    return true;
  }
  // tslint:disable-next-line: function-name
  CheckEmail() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkEmail(this.contractorForm.controls.EmailID.value, this.editContractorCode).subscribe((res:any) => {
        this.email = res.Data;
        if (this.email > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'Email address already taken for other company. Please use different email id for this company.'
          });
          this.contractorForm.controls['EmailID'].reset();
        }
      });
    return true;
  }
  // tslint:disable-next-line: function-name
  CheckPanCardNo() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkPanCard(this.contractorForm.controls.PANNo.value, this.editContractorCode).subscribe((res:any) => {
        this.pANNo = res.Data;
        if (this.pANNo > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'This field requires a unique entry. This PAN number has been used for other Firm / Company name. Please enter different input.'
          });
          this.contractorForm.controls['PANNo'].reset();
        }
      });
    return true;
  }
  checkAlternateEmailID() {
    if (this.contractorForm.controls.personemail.value === this.contractorForm.controls.altPersonemail11.value) {
      {
        this.logHelperService.logError({
          // tslint:disable-next-line: max-line-length
          message: 'Email ID and Alternate email id should not be same input.'
        });
        this.contractorForm.controls['altPersonemail11'].reset();
      }
    }
    return true;
  }
  checkAlternateMobileNo() {
    if (this.contractorForm.controls.contactPermobileno.value === this.contractorForm.controls.ALtcontactPermobileno.value) {
      {
        this.logHelperService.logError({
          // tslint:disable-next-line: max-line-length
          message: 'Mobile No and Alternate mobile no should not be same input.'
        });
        this.contractorForm.controls['ALtcontactPermobileno'].reset();
      }
    }
    return true;
  }
  checkMobileNo() {

      // tslint:disable-next-line: ter-indent
      this.contractorsService.checkMobileNO(this.contractorForm.controls.MobileNo.value, this.editContractorCode).subscribe((res:any) => {
        this.mobileNo = res.Data;
        if (this.mobileNo > 0)
        // tslint:disable-next-line: brace-style
        {
          this.logHelperService.logError({
            // tslint:disable-next-line: max-line-length
            message: 'This field requires a unique entry. This MobileNo has been used for other Firm / Company name. Please enter different input.'
          });
          this.contractorForm.controls['MobileNo'].reset();
        }
      });
    return true;
  }
 
}
