import {
  Component, OnInit, ViewChild, AfterViewInit, Output, EventEmitter,
  ChangeDetectorRef, OnDestroy
} from '@angular/core';
import { WizardStep, Checklist, FileDoc, AgActionDeleteCellRendererComponent } from 'src/app/core/components';
import { NgForm } from '@angular/forms';
import { SharedService } from 'src/app/core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AgFilterCellRenderComponent } from
  'src/app/core/components/select-steps-filter-wrapper/ag-filter-cell-render/ag-filter-cell-render.component';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { isNullOrUndefined } from 'util';
import { NgSelectComponent } from '@ng-select/ng-select';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeComponent } from 'ng-pick-datetime';
import { WorkOrdersService } from '../../work-orders.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GLOBAL } from 'src/app/app.globals';
import * as moment from 'moment';
import { MasterItemCode } from 'src/app/contractor/contractor.enum';
import { EnumRoleType } from 'src/app/contractor/const/enum';

const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};


@Component({
  selector: 'app-work-order-form-wizard',
  templateUrl: './work-order-form-wizard.component.html',
  styleUrls: ['./work-order-form-wizard.component.css'],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class WorkOrderFormWizardComponent implements OnInit, AfterViewInit, OnDestroy {
  isContractore : any= localStorage.getItem('RoleCode');
  enumRoleType:any=EnumRoleType;
  @Output() public closeModal: EventEmitter<string>;
  @ViewChild('workOrderForm') public workOrderForm: NgForm;
  @ViewChild('dialogerror') public dialogerror: SwalComponent;
  @ViewChild('select') select: NgSelectComponent;
  @ViewChild('areaCoordinatorSelect') areaCoordinatorSelect: NgSelectComponent;
  @ViewChild('OrderComplitedDate1') orderComplitedDate: OwlDateTimeComponent<any>;

  // @ViewChild('EICUser') eicUserElement: ElementRef;
  public eicUserModel = {
    EICUserID: null
  };

  public workOrder: any = {};
  public editMode = false;
  public isSubContractor = false;

  // datepicker control config
  public datePickerPlaceHolder: string;
  public datePickerPickerMode: string;
  public dateTimeRange: Date[];
  public checkList: Checklist[] = [];
  public wizardSteps: WizardStep[] = [];
  public companyList: any[] = [];
  public contractorList: any[] = [];
  public contractorWorkOrderList: any[] = [];
  public subContractorList: any[] = [];
  public plantList: any[] = [];
  public orderTypeList: any[] = [];
  public orderCategoryList: any[] = [];
  public natureOfWorkList: any[] = [];
  public workSiteAreaList: any[] = [];
  public workTypes: any[] = [];
  public eICUserList: any[] = [];
  public executingDepartmentList: any[] = [];
  public departmentHeadList: any[] = [];
  public areaCoordinatorList: any[] = [];
  public poAgainstLOIList: any[] = [];
  public eicUserList: any[] = [];

  public documentTypes = [];
  public uploadedFiles: FileDoc[] = [];
  public woOwnerRowDataColumnDefs: any;

  // to disabled control
  public isCompanyDisabled: boolean;
  public isContractorDisabled: boolean;

  public workOrderNo: any;

  // grid config
  public woOwnerRowData: any[] = [];
  public woOwnerGridOptions: any;
  public companyPolicyRowData: any[] = [];
  public companyPolicyGridOptions: any;
  public woOwnerGridApi: any;
  public companyPolicyGridApi: any;

  public contractorConfig: any;
  public subContractorConfig: any;
  public workOrderConfig: any;
  public poAgainstLOIConfig: any;
  public departmentHeadConfig: any;
  public eicUserConfig: any;
  public areaCoordinatorConfig: any;
  public ecPolicyConfig: any;

  public companyPolicyValue: any;
  public eicUserValue: any;
  public hrcApplicableList: any[] = [
    {
      label: 'Partial',
      value: '1'
    },
    {
      label: 'Yes',
      value: '2'
    },
    {
      label: 'No',
      value: '3'
    }
  ];

  public isValidStartDate = false;
  public minDate: Date = new Date();
  public minEndDate: Date;
  public isValidEndDate = false;
  public minComplitedDate: Date;

  public alertOption: SweetAlertOptions = {};
  private isAreaCoordinatorTouched = false;
  private isSubContractorTouched = false;
  private isPOAgainstLOITouched = false;
  private isContractorTouched = false;
  private isDepartmentHeadTouched = false;
  private isEicUserTouched = false;
  private isCompanyPolicyTouched = false;
  private onDestroy: Subject<any>;
  private mainContractorWO: any = {};
  public globalSettings: any;
  public isHidePOInput: boolean = true;

  constructor(private sharedService: SharedService,
    private workOrderService: WorkOrdersService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.sharedService.globalMasterSettingData().subscribe((res: any) => {
      this.sharedService.setGlobalSettings(res);

      if (res) {
        this.globalSettings = res.Data.Table;
        const workOrderSetting = this.globalSettings.find(item => item.Code === 'MOD119PAG191CONWOSER');
        const workOrderSettings = this.globalSettings.find(item => item.Code === 'MOD119PAG191CONWOEC');
        if (workOrderSetting && workOrderSetting.Value === '1') {
          this.isHidePOInput = false;
        }
        else if (workOrderSettings && workOrderSettings.Value === '0') {
          this.isHidePOInput = true;
        }
      }
    });
  
    
    this.onDestroy = new Subject();
    this.closeModal = new EventEmitter();
    this.datePickerPlaceHolder = this.sharedService.datePickerSettings.placeHolder;
    this.datePickerPickerMode = this.sharedService.datePickerSettings.pickerMode;

    this.createWOOwnerGridConfig();
    this.createCompanyPolicyGridConfig();

    this.createConfigForContractor();
    this.createConfigForSubContractor();
    this.createConfigForWorkOrder();
    this.createConfigForPOAgainstLOI();
    this.createConfigForDepartmentHead();
    this.createConfigForAreaCoordinator();
    this.createConfigForEICUser();
    this.createConfigForCompanyPolicy();
  }

  public ngOnInit() {
    if (this.checkContractorType()) {
      this.bindWizardSteps();
      setTimeout(() => {
        this.getWorkOrderDetails();
        this.getDocumentTypes();
        this.bindCheckList();
        // this.setDefaultControlValue();
        // this.getAllData();
        window.addEventListener('scroll', this.onScroll, true);

        if (this.isSubContractor) {
          // this.workOrderForm.controls['WorkOrderNo'].disable();
        }

      }, 10);
    }
  }

  public ngAfterViewInit(): void {
    this.workOrderForm.reset();
  }

  public ngOnDestroy() {
    this.onDestroy.next(true);
    window.removeEventListener('scroll', this.onScroll, true);
  }

  public generateWONo(contractorVendorCode: any): void {
    // if (!this.isSubContractor) {
    this.workOrderService.generateWONo(contractorVendorCode).pipe(takeUntil(this.onDestroy)).
      pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
        this.workOrderForm.controls['WorkOrderNo'].setValue(response);
      });
    // }
  }

  private showAlert(type: any, message: string, title: string) {
    this.alertOption = {
      title,
      type,
      html: `<span class='customClass'>${message}</span>`
    };
    this.openAlert();
  }

  public onSubmit(formValue: any, saveAndNew: boolean): void {

    console.log('form value', formValue);
    // if (!this.workOrder.ParentContractorVendor_Code) {
    //   this.showAlert('error', 'Please select contractor.', 'Error!!!');
    //   return;
    // }
    // if (!this.workOrder.HODUser_Code) {
    //   this.showAlert('error', 'Please select department head.', 'Error!!!');
    //   return;
    // }
    // if (this.isSubContractor && !this.workOrder.ChildContractorVendor_Code) {
    //   this.showAlert('error', 'Please select sub contractor.', 'Error!!!');
    //   return;
    // }
    // if (this.isSubContractor && !this.workOrder.ParentWorkOrder_Code) {
    //   this.showAlert('error', 'Please select contractor work order.', 'Error!!!');
    //   return;
    // }
    if (this.woOwnerRowData.length === 0) {
      this.showAlert('error', 'Please add EIC user.', 'Error!!!');
      return;
    }

    let workOrderObj: any;
    workOrderObj = { ...this.workOrder, ...formValue };
    const workOrderNo = workOrderObj.WorkOrderNo;
    const workOrderCode = workOrderObj.WorkOrder_Code || '';
    workOrderObj.UserId = localStorage.getItem('UserID');
    this.workOrderService.checkWONoAvailability(workOrderCode, workOrderNo).pipe(takeUntil(this.onDestroy))
      .subscribe((response: any) => {
        if (response.Data === 0) {
          workOrderObj.OrderOwnerUserAndPrioritys = this.woOwnerRowData;
          workOrderObj.IsDirect = true;
          if (this.isSubContractor) {
            workOrderObj.IsDirect = false;
          }

          workOrderObj.WorkOrderCompanyPolicyViewModals = this.companyPolicyRowData;
          workOrderObj.DocumentImageMasterViewModals = [];

          console.log('workOrderObj', workOrderObj);
          if (workOrderObj.WorkOrder_Code) {
            this.workOrderService.updateWorkOrder(workOrderObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                this.saveDocuments(response, saveAndNew, false);
              }
            );
          } else {
            this.workOrderService.addWorkOrder(workOrderObj).pipe(takeUntil(this.onDestroy)).subscribe(
              (response: any) => {
                this.saveDocuments(response, saveAndNew, true);
              }
            );
          }
        } else {
          this.alertOption = {
            title: 'Error!!!',
            html: `<span class='customClass'>Work Order number already exist. Please try different work order detail</span>`,
            type: 'error'
          };
          this.openAlert();
        }
      });
  }

  private saveDocuments(response: any, saveAndNew: boolean, isAdd: boolean) {
    console.log('save response', response);
    const uploadParams = {
      WorkOrder_Code: response
    };
    const filesToUpload = this.getNotUploadedItems(this.uploadedFiles);
    if (uploadParams.WorkOrder_Code && filesToUpload.length > 0) {
      this.workOrderService.uploadDocuments(filesToUpload, uploadParams)
        .pipe(takeUntil(this.onDestroy)).subscribe((res) => {
          // this.alertOption = {
          //   title: 'Success!!!',
          //   html: `<span class='customClass'>Work order is saved successfully.</span>`,
          //   type: 'success'
          // };
          // this.openAlert();

          if (saveAndNew) {
            this.showAddWorkOrderInfo(isAdd, false);
            this.resetFormAfterSave();
          } else {
            this.showAddWorkOrderInfo(isAdd, true);
          }
        });
    } else if (saveAndNew) {
      this.showAddWorkOrderInfo(isAdd, false);
      this.resetFormAfterSave();
    } else {
      this.showAddWorkOrderInfo(isAdd, true);
    }
  }

  private showAddWorkOrderInfo(isAdd: boolean, redirect: boolean) {
    const message = isAdd ? 'Work order is successfully added.' : 'Work order is successfully updated.';
    this.alertOption = {
      title: 'Success!!!',
      html: `<span class='customClass'>${message}</span>`,
      type: 'success',
      timer: 2000
    };
    this.openAlert();
    if (redirect) {
      setTimeout(() => {
        this.router.navigate(['/contractor/work-orders']);
      }, 3000);
    }
  }

  private getNotUploadedItems(files: any[]) {
    return files.filter((item: any) => !item.isUploaded);
  }

  public onCloseModal(reason: string) {
    this.closeModal.emit(reason);
  }

  public saveAndNew(data): void {
    this.onSubmit(this.workOrderForm.value, true);
  }

  public updateAndNew(): void {
    this.onSubmit(this.workOrderForm.value, true);
  }

  public clearForm(): void {
    this.workOrderForm.reset();
  }

  public onCancel() {
    this.router.navigate(['/contractor/work-orders']);
  }

  public onCompanyChange(): void {
    const companyCode = this.workOrderForm.controls['Company_Code'].value;
    if (companyCode) {
      this.getPlants(companyCode);
    } else {
      this.plantList = [];
    }
  }

  public onOrderTypeChange(data: any): void {

    const orderType = this.workOrderForm.controls['OrderTypeMaster_Code'].value;
    if (orderType === 228) {
      this.workOrderForm.controls['LOIRef_WorkOrder_Code'].disable();
    }
  }

  public onContractorChange(data: any): void {
    // console.log('data', data);
    // if (!data.contractor) return;
    this.isContractorTouched = true;
    // this.workOrder.ParentContractorVendor_Code = data.contractor.ShortCode;
    this.contractorWorkOrderList = [];
    this.subContractorList = [];

    const contractorCode = this.workOrderForm.controls['ParentContractorVendor_Code'].value;
    this.workOrder.ParentWorkOrder_Code = null;
    if (this.isSubContractor) {
      this.workOrderForm.controls['ParentWorkOrder_Code'].setValue(null);
      this.workOrderForm.controls['ChildContractorVendor_Code'].setValue(null);
      this.workOrder.ChildContractorVendor_Code = null;
    }
    if (contractorCode && this.isSubContractor) {
      this.generateWONo(contractorCode);
      this.getContractorWorkOrder(contractorCode);
      this.getSubContractorsByExcludeCode(contractorCode);
    }
  }

  public onSubContractorChange(data: any): void {
    // console.log('onSubContractorChange', data);
    // if (!data.contractor) return;
    this.isSubContractorTouched = true;
    // this.workOrder.ChildContractorVendor_Code = data.contractor.ShortCode;
  }

  public onPOAgainstLOIChange(data: any): void {
    // console.log('po against loi', data);
    // if (!data.workOrder) return;
    this.isPOAgainstLOITouched = true;
    // this.workOrder.LOIRef_WorkOrder_Code = data.workOrder.WorkOrder_Code;
  }

  public onAreaCoordinatorChange(data: any): void {
    // console.log('area coordinator', data);
    // if (!data.userList) return;
    // this.isAreaCoordinatorTouched = true;
    // this.workOrder.AreaCoordinatorUser_Code = data.userList.UserCode;
  }

  public onDepartmentHeadChange(data: any): void {
    console.log('onDepartmentHeadChange', data);
    if (!data.userList) return;
    this.isDepartmentHeadTouched = true;
    this.workOrder.HODUser_Code = data.userList.UserCode;
  }

  public onCompanyPolicyChange(data: any): void {

    console.log('onCompanyPolicyChange', data);
    if (!data.fieldData.ecPolicy) return;
    this.isCompanyPolicyTouched = true;
    this.companyPolicyValue = data.fieldData.ecPolicy;
  }

  public onContractorWOChange(data: any) {
    // console.log('work order data', data);
    // if (!data.workOrder) return;
    // this.workOrder.ParentWorkOrder_Code = data.workOrder.WorkOrder_Code;
    // const worOrderCode = data.workOrder.WorkOrder_Code;
    const workOrderCode = this.workOrderForm.controls['ParentWorkOrder_Code'].value;
    this.workOrderService.getWorkOrderDetails(workOrderCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      let workOrder: any;
      if (response.length > 0) {
        workOrder = response[0];
        this.mainContractorWO = workOrder;
        // this.workOrderForm.controls['WorkOrderNo'].setValue(workOrder.WorkOrderNo);
        this.workOrderForm.controls['OrderTypeMaster_Code'].setValue(workOrder.OrderTypeMaster_Code);
        this.workOrderForm.controls['OrderCategoryMaster_Code'].setValue(workOrder.OrderCategoryMaster_Code);
        // if (workOrder.OrderStartDate > new Date()) {
        this.workOrderForm.controls['OrderStartDate'].setValue(workOrder.OrderStartDate);
        // } else {
        //   this.workOrderForm.controls['OrderStartDate'].setValue(new Date());
        // }
        this.workOrderForm.controls['OrderEndDate'].setValue(workOrder.OrderEndDate);
        this.isValidStartDate = true;
        this.minEndDate = new Date((new Date(workOrder.OrderStartDate)).getTime() + (60 * 60 * 24 * 1000));
        this.orderComplitedDate.dtInput.min = workOrder.OrderStartDate;
        this.orderComplitedDate.dtInput.max = workOrder.OrderEndDate;
        this.workOrderForm.controls['OrderComplitedDate'].setValue(workOrder.OrderComplitedDate);
        this.workOrderForm.controls['Person'].setValue(workOrder.Person);
        this.workOrderForm.controls['NatureOfWorkMasterListCodes'].setValue(workOrder.NatureOfWorkMasterListCodes);
        this.workOrderForm.controls['AreaOfWorkMasterListCodes'].setValue(workOrder.AreaOfWorkMasterListCodes);
        this.workOrderForm.controls['Department_Code'].setValue(workOrder.Department_Code);
        // this.workOrderForm.controls['IsHrcApplicable'].setValue(workOrder.IsHrcApplicable);
        this.workOrderForm.controls['WorkTypeMaster_Code'].setValue(workOrder.WorkTypeMaster_Code);
        this.workOrderForm.controls['HODUser_Code'].setValue(workOrder.HODUser_Code);
        // this.workOrderForm.controls['LOIRef_WorkOrder_Code'].setValue(parseInt(this.workOrder.LOIRef_WorkOrder_Code, 16));

        const wrk = parseInt(this.workOrder.LOIRef_WorkOrder_Code, 16);
        this.workOrder.HODUser_Code = workOrder.HODUser_Code;
        this.workOrder.LOIRef_WorkOrder_Code = wrk;
        this.workOrder.AreaCoordinatorUser_Code = workOrder.AreaCoordinatorUser_Code;
      }
    });
  }

  public onGatePassChange(): void {
    if (this.isSubContractor && this.mainContractorWO && this.mainContractorWO.Person) {
      const noOfGatePass = this.workOrderForm.controls['Person'].value;
      if (parseInt(noOfGatePass, 16) > this.mainContractorWO.Person) {
        this.workOrderForm.controls['Person'].setValue('');
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>No. of gate pass should be less than main contractor work order's no. of gate pass.</span>`,
          type: 'error'
        };
        this.openAlert();
      }
    }
  }

  public onEICUserChange(data: any): void {

    console.log('onEICUserChange', data);
    if (!data) return;
    this.isEicUserTouched = true;
    this.eicUserValue = data;
  }

  public addEICUserDetails(priorityRef: any): void {
    console.log('hod user', this.eicUserModel);

    // if (!this.eicUserValue) {
    if (!this.eicUserModel.EICUserID) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please select any EIC user.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else if (!priorityRef.value) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please enter priority.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else {
      const priority = parseInt(priorityRef.value, 10);
      if (priority <= 5 && priority > 0) {
        const checkExistUser = this.woOwnerRowData.find(rc => rc.OrderOwnerUser_Code === this.eicUserModel.EICUserID);
        const checkExistUserPriority = this.woOwnerRowData.find(rc => rc.PriorityLevel === priority);
        if (!checkExistUser && !checkExistUserPriority) {
          const eicUserObj = this.eICUserList.find(user => user.UserID === this.eicUserModel.EICUserID);
          if (eicUserObj) {
            this.woOwnerRowData.push(
              {
                OrderOwnerUser_Code: this.eicUserModel.EICUserID,
                PriorityLevel: priority,
                UserName: eicUserObj.UserName,
                EmailID: eicUserObj.AlternetEmailID
              });
            this.woOwnerRowData = [...this.woOwnerRowData];
            // this.woOwnerGridApi.setRowData(this.woOwnerRowData); // Refresh grid
            console.log('woOwnerRowData', this.woOwnerRowData);
            // poOwnerRef.value = '';
            // this.eicUserValue = null;
            // hodUser.value = '';
            priorityRef.value = '';
            this.eicUserModel.EICUserID = null;
          }
        } else if (checkExistUser) {
          this.alertOption = {
            title: 'OOPS!!!',
            html: `<span class='customClass'>This user is already exist.</span>`,
            type: 'error'
          };
          this.openAlert();
        } else if (checkExistUserPriority) {
          this.alertOption = {
            title: 'OOPS!!!',
            html: `<span class='customClass'>This priority is already exist.</span>`,
            type: 'error'
          };
          this.openAlert();
        }
      } else {
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>Priority should be between 1 to 5.</span>`,
          type: 'error'
        };
        this.openAlert();
      }
    }
  }

  public deleteWOOwnerData(data: any): void {
    const index = this.woOwnerRowData.indexOf(data);
    if (index >= 0) {
      this.woOwnerRowData = this.woOwnerRowData.filter(item => item !== data);
    }
    console.log('data', this.woOwnerRowData);
  }

  public addCompanyPolicy(maxPersonAllowedRef: any): void {

    if (!this.companyPolicyValue) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please select any company policy.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else if (!maxPersonAllowedRef.value) {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Please enter maximum allowed person.</span>`,
        type: 'error'
      };
      this.openAlert();
    } else {
      const checkexistPolicy = this.companyPolicyRowData.
        find(rc => rc.CompanyECPolicy_Code === this.companyPolicyValue.CompanyECPolicy_Code);
      if (!checkexistPolicy) {
        const companyPolicyObj: any = {
          CompanyECPolicy_Code: this.companyPolicyValue.CompanyECPolicy_Code,
          CompanyName: this.companyPolicyValue.CompanyName,
          ECPolicyNo: this.companyPolicyValue.ECPolicyNo,
          InsurePerson: maxPersonAllowedRef.value,
          IssueDate: this.companyPolicyValue.IssueDate,
          NatureOfWork: this.companyPolicyValue.NatureOfWork,
        };
        this.companyPolicyRowData.push(companyPolicyObj);
        console.log('row data', this.companyPolicyRowData);
        // this.companyPolicyGridApi.setRowData(this.companyPolicyRowData); // Refresh grid
        this.companyPolicyRowData = [...this.companyPolicyRowData];
        // companyPolicyRef.value = '';
        this.companyPolicyValue = null;
        maxPersonAllowedRef.value = '';
      } else {
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>This company policy is already exist.</span>`,
          type: 'error'
        };
        this.openAlert();
      }
    }
  }

  public deleteCompanyPolicyDetails(data: any): void {
    const index = this.companyPolicyRowData.indexOf(data);
    if (index >= 0) {
      // this.companyPolicyRowData = [...this.companyPolicyRowData.splice(index)];
      this.companyPolicyRowData = this.companyPolicyRowData.filter(item => item !== data);
    }
    console.log('data', this.companyPolicyRowData);
  }

  public onStartDateChange(): void {
    if (this.workOrderForm.controls['OrderStartDate'].valid) {
      const startDate = this.workOrderForm.controls['OrderStartDate'].value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {

      } else if (startDate < new Date()) {
        // this.workOrderForm.controls['OrderStartDate'].setValue('');
        this.alertOption = {
          title: 'OOPS!!!',
          html: `<span class='customClass'>Please enter current date or greater than current date.</span>`,
          type: 'warning'
        };
        this.openAlert();
        // return;
      }
      const endDate = this.workOrderForm.controls['OrderEndDate'].value;
      this.isValidStartDate = true;
      this.minEndDate = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.workOrderForm.controls['OrderEndDate'].valid) {
          this.workOrderForm.controls['OrderEndDate'].setValue('');
          // this.isValidStartDate = false;
        }
      }
    } else {
      this.isValidStartDate = false;
      this.workOrderForm.controls['OrderEndDate'].setValue('');
    }
  }

  public onEndDateChange(): void {
    if (this.workOrderForm.controls['OrderEndDate'].valid) {
      const endDate = this.workOrderForm.controls['OrderEndDate'].value;
      const complitedDate = this.workOrderForm.controls['OrderComplitedDate'].value;
      this.isValidEndDate = true;
      this.minComplitedDate = new Date((new Date(endDate)).getTime() + (60 * 60 * 24 * 1000));
      if (endDate > complitedDate) {
        if (this.workOrderForm.controls['OrderComplitedDate'].valid) {
          this.workOrderForm.controls['OrderComplitedDate'].setValue('');
          // this.isValidStartDate = false;
        }
      }
    } else {
      this.isValidEndDate = false;
      this.workOrderForm.controls['OrderComplitedDate'].setValue('');
    }
  }

  public onFilesChanged(newFiles) {
    this.uploadedFiles = newFiles;
  }

  private hasErrorCompany(): boolean {
    if ((this.workOrderForm.controls.Company_Code.dirty || this.workOrderForm.controls.Company_Code.touched)
      && this.workOrderForm.controls.Company_Code.invalid) {
      return true;
    }
    return false;
  }

  private hasErrorPlant(): boolean {
    if ((this.workOrderForm.controls.PlantCodes.dirty || this.workOrderForm.controls.PlantCodes.touched)
      && this.workOrderForm.controls.PlantCodes.invalid) {
      return true;
    }
    return false;
  }

  private hasErrorDepartment(): boolean {
    if ((this.workOrderForm.controls.Department_Code.dirty || this.workOrderForm.controls.Department_Code.touched)
      && this.workOrderForm.controls.Department_Code.invalid) {
      return true;
    }
    return false;
  }

  private bindCheckList() {

    const checkListId = 1;
    this.checkList.push(
      {
        id: checkListId,
        text: 'Work Order Entry Checklist',
        open: true,
        childrens: [
          {
            text: 'Company Details',
            title: 'Company Details',
            desc: ' Plant, Executing Department and Department Head is required.',
            isValid: () => {
              return (this.workOrderForm.controls.PlantCodes.valid
                && this.workOrderForm.controls.Department_Code.valid
              );
              // && this.checkValidDepartmentHead());
            },
            hasError: () => {
              // return (this.hasErrorCompany() || this.hasErrorPlant() || this.hasErrorDepartment() || this.hasDepartmentHeadError());
              return (
                  ((this.workOrderForm.controls.PlantCodes.dirty
                  || this.workOrderForm.controls.PlantCodes.touched)
                  && this.workOrderForm.controls.PlantCodes.invalid)
                || ((this.workOrderForm.controls.Department_Code.dirty
                  || this.workOrderForm.controls.Department_Code.touched)
                  && this.workOrderForm.controls.Department_Code.invalid)
              );
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: 'Contractor',
            title: 'Contractor',
            desc: 'Contractor is required.',
            isValid: () => {
              // return this.checkValidContractor();
              return this.workOrderForm.controls.ParentContractorVendor_Code.valid;
            },
            hasError: () => {
              // return this.hasContractorError();
              return ((this.workOrderForm.controls.ParentContractorVendor_Code.dirty
                || this.workOrderForm.controls.ParentContractorVendor_Code.touched)
                && this.workOrderForm.controls.ParentContractorVendor_Code.invalid);
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          {
            text: this.isSubContractor ? 'Sub-Contrator Work Order Details' : 'Work Order Details',
            title: this.isSubContractor ? 'Sub-Contrator Work Order Details' : 'Work Order Details',
            desc: 'Order type,Order Category, Start Date, End Date, Completed Date, No of gate pass is required.',
            isValid: () => {
              return (this.workOrderForm.controls.OrderTypeMaster_Code.valid
                && this.workOrderForm.controls.OrderCategoryMaster_Code.valid
                && this.workOrderForm.controls.OrderStartDate.valid
                && this.workOrderForm.controls.OrderEndDate.valid
                && this.workOrderForm.controls.Person.valid);
            },
            hasError: () => {
              return (((this.workOrderForm.controls.OrderTypeMaster_Code.dirty
                || this.workOrderForm.controls.OrderTypeMaster_Code.touched)
                && this.workOrderForm.controls.OrderTypeMaster_Code.invalid)
                || ((this.workOrderForm.controls.OrderCategoryMaster_Code.dirty
                  || this.workOrderForm.controls.OrderCategoryMaster_Code.touched)
                  && this.workOrderForm.controls.OrderCategoryMaster_Code.invalid)
                || ((this.workOrderForm.controls.OrderStartDate.dirty
                  || this.workOrderForm.controls.OrderStartDate.touched)
                  && this.workOrderForm.controls.OrderStartDate.invalid)
                || ((this.workOrderForm.controls.OrderEndDate.dirty
                  || this.workOrderForm.controls.OrderEndDate.touched)
                  && this.workOrderForm.controls.OrderEndDate.invalid)
                || ((this.workOrderForm.controls.Person.dirty
                  || this.workOrderForm.controls.Person.touched)
                  && this.workOrderForm.controls.Person.invalid));
            },
            isMandatory: true,
            isVisible: true,
            step: '1',
          },
          // {
          //   text: 'Is HRC Applicable',
          //   title: 'Is HRC Applicable',
          //   desc: 'HRC Status is required.',
          //   isValid: () => {
          //     return (this.workOrderForm.controls.IsHrcApplicable.valid);
          //   },
          //   hasError: () => {
          //     return ((this.workOrderForm.controls.IsHrcApplicable.dirty || this.workOrderForm.controls.IsHrcApplicable.touched)
          //       && this.workOrderForm.controls.IsHrcApplicable.invalid);
          //   },
          //   isMandatory: true,
          //   isVisible: true,
          //   step: '2',
          // },
          {
            text: 'Order Owner',
            title: 'Order Owner',
            desc: 'Order Owner is required.',
            isValid: () => {
              if (this.woOwnerRowData.length > 0) {
                return true;
              }
              return false;
            },
            hasError: () => {
              if (this.woOwnerRowData.length === 0 && this.eicUserModel.EICUserID) {
                return true;
              }
              return false;
            },
            isMandatory: true,
            isVisible: true,
            step: '2',
          }
        ]
      }
    );

    if (this.isSubContractor) {
      const workOrderCheckList = {
        text: 'Work Order',
        title: 'Work Order',
        desc: 'Work Order is required.',
        isValid: () => {
          return this.workOrderForm.controls.ParentWorkOrder_Code.valid;
        },
        hasError: () => {
          return ((this.workOrderForm.controls.ParentWorkOrder_Code.dirty
            || this.workOrderForm.controls.ParentWorkOrder_Code.touched)
            && this.workOrderForm.controls.ParentWorkOrder_Code.invalid);
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      };
      this.checkList[0].childrens.splice(2, 0, workOrderCheckList);

      const subContractorCheckList = {
        text: 'Sub-Contractor',
        title: 'Sub-Contractor',
        desc: 'Sub-Contractor is required.',
        isValid: () => {
          return this.workOrderForm.controls.ChildContractorVendor_Code.valid;
        },
        hasError: () => {
          return ((this.workOrderForm.controls.ChildContractorVendor_Code.dirty
            || this.workOrderForm.controls.ChildContractorVendor_Code.touched)
            && this.workOrderForm.controls.ChildContractorVendor_Code.invalid);
        },
        isMandatory: true,
        isVisible: true,
        step: '1',
      };
      this.checkList[0].childrens.splice(3, 0, subContractorCheckList);
    }
  }

  private checkValidContractor(): boolean {
    if (!isNullOrUndefined(this.workOrder.ParentContractorVendor_Code) && (this.editMode || this.isContractorTouched)) {
      return true;
    }
    return false;
  }

  private hasContractorError(): boolean {
    if (isNullOrUndefined(this.workOrder.ParentContractorVendor_Code) && this.isContractorTouched) {
      return true;
    }
    return false;
  }

  private checkValidOrderDate(): boolean {
    const startDate = this.workOrderForm.controls.OrderStartDate.value;
    const endDate = this.workOrderForm.controls.OrderEndDate.value;

    if (!isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)) {
      if (new Date(startDate) < new Date(endDate)) {
        return true;
      }
    }
    return false;
  }

  private hasOrderEndDateError(): boolean {
    const startDate = this.workOrderForm.controls.OrderStartDate.value;
    const endDate = this.workOrderForm.controls.OrderEndDate.value;

    if (!isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)) {
      if (new Date(startDate) < new Date(endDate)) {
        return false;
      }
    }
    return true;
  }

  private checkValidCompletedDate(): boolean {
    const startDate = this.workOrderForm.controls.OrderStartDate.value;
    const orderCompletedDate = this.workOrderForm.controls.OrderComplitedDate.value;

    if (!isNullOrUndefined(startDate) && !isNullOrUndefined(orderCompletedDate)) {
      if (new Date(startDate) < new Date(orderCompletedDate)) {
        return true;
      }
    }
    return false;
  }

  private hasCompletedDateError(): boolean {
    const startDate = this.workOrderForm.controls.OrderStartDate.value;
    const orderCompletedDate = this.workOrderForm.controls.OrderComplitedDate.value;

    if (!isNullOrUndefined(startDate) && !isNullOrUndefined(orderCompletedDate)) {
      if (new Date(startDate) < new Date(orderCompletedDate)) {
        return false;
      }
    }
    return true;
  }

  private checkValidDepartmentHead(): boolean {
    if (!isNullOrUndefined(this.workOrder.HODUser_Code) && (this.editMode || this.isDepartmentHeadTouched)) {
      return true;
    }
    return false;
  }

  private hasDepartmentHeadError(): boolean {
    if (isNullOrUndefined(this.workOrder.HODUser_Code) && this.isDepartmentHeadTouched) {
      return true;
    }
    return false;
  }

  private checkValidAreaCoordinator(): boolean {
    if (isNullOrUndefined(this.workOrder.AreaCoordinatorUser_Code)) {
      return false;
    }
    return true;
  }

  private hasAreaCoordinatorError(): boolean {
    if (isNullOrUndefined(this.workOrder.AreaCoordinatorUser_Code)) {
      return true;
    }
    return false;
  }

  private checkValidLOIRef_WorkOrder_Code(): boolean {
    if (!isNullOrUndefined(this.workOrder.LOIRef_WorkOrder_Code) && this.isPOAgainstLOITouched) {
      return true;
    }
    return false;
  }

  private hasValidExecutingDepartmentError(): boolean {
    if (isNullOrUndefined(this.workOrder.Department_Code)) {
      return true;
    }
    return false;
  }

  private checkValidSubContractor(): boolean {
    if (isNullOrUndefined(this.workOrder.ChildContractorVendor_Code)) {
      return false;
    }
    return true;
  }

  private hasSubContractorError(): boolean {
    if (isNullOrUndefined(this.workOrder.ChildContractorVendor_Code)) {
      return true;
    }
    return false;
  }

  private checkValidContractorWO(): boolean {
    if (isNullOrUndefined(this.workOrder.ParentWorkOrder_Code)) {
      return false;
    }
    return true;
  }

  private hasContractorWOError(): boolean {
    if (isNullOrUndefined(this.workOrder.ParentWorkOrder_Code)) {
      return true;
    }
    return false;
  }

  private bindWizardSteps() {
    this.wizardSteps = [
      {
        id: 'workOrderDetails',
        name: 'Work Order Details',
        isValid: () => {
          // return true;
          return this.wizard1Validation();
        },
      },
      {
        id: 'woOwnerEICUserDetails',
        name: 'WO Owner / EIC User',
        isValid: () => {
          // return true;
          return this.woOwnerRowData.length > 0;
        },
      }
    ];

    if (!this.isSubContractor  && this.isHidePOInput) {
      this.wizardSteps.push(
        {
          id: 'companyPolicyDetails',
          name: 'Company Details',
          isValid: () => {
            return this.wizard1Validation();
          },
        },
      );
      // this.wizardSteps.push(
      //   {
      //     id: 'otherDetails',
      //     name: 'Other Details',
      //     isValid: () => {
      //       return true;
      //     },
      //   });
    }
    this.wizardSteps.push(
      {
        id: 'documentDetails',
        name: 'Documents',
        isValid: () => { return true; }
      }
    );
  }

  private checkContractorType(): boolean {
    let isValidType = false;
    const id: string = this.activatedRoute.snapshot.params.id;
    if (id) {
      isValidType = true;
      this.editMode = true;
    } else {
      this.workOrder = {};
      const type: string = this.activatedRoute.snapshot.queryParamMap.get('type');
      if (type === '1' || type === '0') {
        this.isSubContractor = type === '0';
        isValidType = true;
      }
    }
    return isValidType;
  }

  private getWorkOrderDetails() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      this.workOrderService.getWorkOrderByWorkOrderCode(id).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
        if (response.length > 0) {
          this.workOrder = response[0];
          if (!this.workOrder.IsDirect) {
            this.isSubContractor = true;
          }
          // this.setDefaultControlValue();
          this.getAllData();
          this.workOrderForm.controls['OrderTypeMaster_Code'].setValue(this.workOrder.OrderTypeMaster_Code);
          this.workOrderForm.controls['OrderCategoryMaster_Code'].setValue(this.workOrder.OrderCategoryMaster_Code);
          this.workOrderForm.controls['NatureOfWorkMasterListCodes'].setValue(this.workOrder.OrderCategoryMaster_Code);
          this.workOrderForm.controls['AreaOfWorkMasterListCodes'].setValue(this.workOrder.AreaOfWorkMasterListCodes);
          this.workOrderForm.controls['Department_Code'].setValue(this.workOrder.Department_Code);
          this.workOrderForm.controls['WorkTypeMaster_Code'].setValue(this.workOrder.WorkTypeMaster_Code);
          this.workOrderForm.controls['OrderStartDate'].setValue(this.workOrder.OrderStartDate);
          this.workOrderForm.controls['OrderEndDate'].setValue(this.workOrder.OrderEndDate);
          this.isValidStartDate = true;
          this.minEndDate = new Date((new Date(this.workOrder.OrderStartDate)).getTime() + (60 * 60 * 24 * 1000));
          this.isValidEndDate = true;
          this.minComplitedDate = new Date((new Date(this.workOrder.OrderEndDate)).getTime() + (60 * 60 * 24 * 1000));
          this.workOrderForm.controls['OrderComplitedDate'].setValue(this.workOrder.OrderComplitedDate);
          this.workOrderForm.controls['Person'].setValue(this.workOrder.Person);
          this.workOrderForm.controls['poNumber'].setValue(this.workOrder.WorkOrderNo);
          this.workOrderForm.controls['WorkOrderNo'].setValue(this.workOrder.WorkOrderNo);
          this.workOrderForm.controls['HODUser_Code'].setValue(this.workOrder.HODUser_Code);
          // this.workOrderForm.controls['IsHrcApplicable'].setValue(this.workOrder.IsHrcApplicable.toString());

          this.woOwnerRowData = this.workOrder.OrderOwnerUserAndPrioritys ? [...this.workOrder.OrderOwnerUserAndPrioritys] : [];

          this.companyPolicyRowData = this.workOrder.WorkOrderCompanyPolicyViewModals ?
            [...this.workOrder.WorkOrderCompanyPolicyViewModals] : [];
          // this.uploadedFiles = this.workOrder.DocumentImageMasterViewModals ? [...this.workOrder.DocumentImageMasterViewModals] : [];

          this.uploadedFiles = [];
          if (this.workOrder.DocumentImageMasterViewModals) {
            this.workOrder.DocumentImageMasterViewModals.forEach((file) => {
              this.uploadedFiles.push({
                ID: file.ID,
                file: {
                  lastModified: file.ModifiedDate,
                  name: file.UploadFileName
                },
                documentTypeCode: file.DocumentTypeMasterList_Code,
                isUploaded: true,
                url: GLOBAL.BASE_URL_DOC + file.ParentRootFolder
              });
            });
          }
        }
      });
    } else {
      this.setDefaultControlValue();
      this.getAllData();
    }
  }

  // step-1 code: contractor details

  private setDefaultControlValue() {
    console.log('work order', this.workOrder);
    this.workOrderForm.controls['Company_Code'].setValue(null);
    this.workOrderForm.controls['ParentContractorVendor_Code'].setValue(this.workOrder.ParentContractorVendor_Code);
    if (this.workOrderForm.controls['ParentWorkOrder_Code']) {
      this.workOrderForm.controls['ParentWorkOrder_Code'].setValue(null);
    }
    if (this.workOrderForm.controls['ChildContractorVendor_Code']) {
      this.workOrderForm.controls['ChildContractorVendor_Code'].setValue(null);
    }
    this.workOrderForm.controls['PlantCodes'].setValue(null);
    this.workOrderForm.controls['OrderTypeMaster_Code'].setValue(null);
    this.workOrderForm.controls['OrderCategoryMaster_Code'].setValue(null);
    this.workOrderForm.controls['NatureOfWorkMasterListCodes'].setValue(null);
    if (this.workOrderForm.controls['AreaOfWorkMasterListCodes']) {
      this.workOrderForm.controls['AreaOfWorkMasterListCodes'].setValue(null);
    }
    this.workOrderForm.controls['Department_Code'].setValue(null);
    this.workOrderForm.controls['HODUser_Code'].setValue(null);
    this.workOrderForm.controls['WorkTypeMaster_Code'].setValue(null);
    // this.workOrderForm.controls['IsHrcApplicable'].setValue(null);
    this.workOrder.OrderOwnerUserAndPrioritys = [];
    this.workOrder.DocumentImageMasterViewModals = [];

    this.woOwnerRowData = [];
    this.companyPolicyRowData = [];
    this.uploadedFiles = [];
  }

  private wizard1Validation() {
    let flag = false;
    if (this.workOrderForm.controls.Company_Code) {

      flag =  this.workOrderForm.controls.PlantCodes.valid
        // // && this.checkValidContractor()
        && this.workOrderForm.controls.ParentContractorVendor_Code.valid
        && this.workOrderForm.controls.OrderTypeMaster_Code.valid
        && this.workOrderForm.controls.OrderCategoryMaster_Code.valid
        && this.workOrderForm.controls.OrderStartDate.valid
        && this.workOrderForm.controls.OrderEndDate.valid
        && this.workOrderForm.controls.Person.valid;
        // && this.workOrderForm.controls.LOIRef_WorkOrder_Code.valid;

      // && this.checkValidLOIRef_WorkOrder_Code()
      // && this.checkValidAreaCoordinator()
      // && this.checkValidDepartmentHead();
      if (flag) {
        flag = this.checkValidEndDate();
      }
      if (flag) {
        // flag = this.checkValidOrderCompletedDate();
      }
      if (flag && this.isSubContractor) {
        // if (!this.checkValidSubContractor() || !this.checkValidContractorWO()) {
        //   flag = false;
        // }
        flag = this.workOrderForm.controls.ParentWorkOrder_Code.valid
          && this.workOrderForm.controls.ChildContractorVendor_Code.valid;
      }
    }

    return flag;
  }

  private checkValidEndDate() {
    if (this.workOrderForm.controls.OrderStartDate.value !== '' && this.workOrderForm.controls.OrderEndDate.value !== '') {
      const startDate: Date = new Date(this.workOrderForm.controls.OrderStartDate.value);
      const endDate: Date = new Date(this.workOrderForm.controls.OrderEndDate.value);
      if (startDate > endDate) {
        return false;
      }
    }
    return true;
  }

  private checkValidOrderCompletedDate() {
    if (this.workOrderForm.controls.OrderStartDate.value !== '' && this.workOrderForm.controls.OrderComplitedDate.value !== '') {
      const startDate: Date = new Date(this.workOrderForm.controls.OrderStartDate.value);
      const endDate: Date = new Date(this.workOrderForm.controls.OrderComplitedDate.value);
      if (startDate > endDate) {
        return false;
      }
    }
    return true;
  }

  // step-2 code: WO Owner
  private createWOOwnerGridConfig() {
    const woOwnerRowDataColumnDefs = [
      {
        headerName: 'EIC Name', field: 'UserName', width: 25,
        sortable: true
      },
      {
        headerName: 'Email ID', field: 'EmailID', width: 25,
        sortable: true

      },
      {
        headerName: 'Priority', field: 'PriorityLevel', width: 25,
        sortable: true
      },
      {
        headerName: 'Action', field: '', width: 25,
        sortable: false, suppressMenu: true, cellRendererFramework: AgActionDeleteCellRendererComponent
      }
    ];
    this.woOwnerGridOptions = {
      columnDefs: woOwnerRowDataColumnDefs,
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 39,
      onGridReady: (params) => {
        this.onWOOwnerGridReady(params);
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      }
    };
  }

  // step-2 code: WO Owner
  private createCompanyPolicyGridConfig() {
    const columnDefs = [
      {
        headerName: 'Policy No', field: 'ECPolicyNo', width: 20,
        sortable: false
      },
      {
        headerName: 'Company Name', field: 'CompanyName', width: 20,
        sortable: false
      },
      {
        headerName: 'Issue Date', field: 'IssueDate', width: 20,
        sortable: false
      },
      {
        headerName: 'Allowed Max Person', field: 'InsurePerson', width: 20,
        sortable: false
      },
      {
        headerName: 'Action', field: '', width: 20,
        sortable: false, suppressMenu: true, cellRendererFramework: AgActionDeleteCellRendererComponent
      }
    ];

    this.companyPolicyGridOptions = {
      columnDefs,
      // rowData: this.companyPolicyRowData,
      // getRowHeight: (params) => {
      //   return 39;
      // },
      onGridReady: (params) => {
        this.onCopmanyPolicyGridReady(params);
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort"/>',
        sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
        sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
      },
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 39
    };
  }

  public onWOOwnerGridReady(params) {
    this.woOwnerGridApi = params.api; // To access the grids API
  }

  public onCopmanyPolicyGridReady(params) {
    this.companyPolicyGridApi = params.api; // To access the grids API
  }

  // it will all api data and assign it to respective list
  private getAllData() {
    this.workOrderService.getCompanies().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.companyList = response;
      if (!isNullOrUndefined(this.workOrder.Company_Code)) {
        this.workOrderForm.controls['Company_Code'].setValue(this.workOrder.Company_Code);
        this.getPlants(this.workOrder.Company_Code);
      } else {
        this.workOrderForm.controls['Company_Code'].setValue(null);
        if (this.companyList.length === 1) {
          this.workOrderForm.controls['Company_Code'].setValue(this.companyList[0].Company_Code);
          this.workOrderForm.controls['Company_Code'].disable();
          this.getPlants(this.companyList[0].Company_Code);
        }
      }
      console.log('getCompanies response', response);
    });

    this.getContractors();
    if (!this.isSubContractor) {
      this.getPOAgainstLOIList();
    }
    // this.getUserList();

    this.workOrderService.getOrderTypes(MasterItemCode.ORDER_TYPE).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getOrderTypes response', response);
      this.orderTypeList = response;
      this.workOrderForm.controls['OrderTypeMaster_Code'].setValue(this.workOrder.OrderTypeMaster_Code);
    });

    this.workOrderService.getOrderCategories(MasterItemCode.ORDER_CATEGORY).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getOrderCategories response', response);
      this.orderCategoryList = response;
      this.workOrderForm.controls['OrderCategoryMaster_Code'].setValue(this.workOrder.OrderCategoryMaster_Code);
    });

    this.workOrderService.getNatureOfWorks().pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('getNatureOfWorks response', response);
      this.natureOfWorkList = response;
      this.workOrderForm.controls['NatureOfWorkMasterListCodes'].setValue(this.workOrder.NatureOfWorkMasterListCodes);

    });

    this.workOrderService.getWorkSiteAreas(MasterItemCode.WORK_SITE_AREA).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      console.log('getWorkSiteAreas response', response);
      this.workSiteAreaList = response;
      this.workOrderForm.controls['AreaOfWorkMasterListCodes'].setValue(this.workOrder.AreaOfWorkMasterListCodes);
    });


    this.workOrderService.getExecutingDepartments().pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('getExecutingDepartments response', response);
      this.executingDepartmentList = response.Data;
      this.workOrderForm.controls['Department_Code'].setValue(this.workOrder.Department_Code);
      this.onDepartmentChange();
    });

    // GLOBAL.USER.EMAIL_ID
    this.workOrderService.getEICUsers(GLOBAL.USER.EMAIL_ID).subscribe((response: any) => {

      // console.log('getExecutingDepartments response', response);
      this.eICUserList = response.Data;
      // this.workOrderForm.controls['Department_Code'].setValue(this.workOrder.Department_Code);
    });

    this.workOrderService.getWorkTypes(MasterItemCode.WORK_TYPE).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('getWorkTypes response', response);
      this.workTypes = response;
      this.workOrderForm.controls['OrderTypeMaster_Code'].setValue(this.workOrder.OrderTypeMaster_Code);
    });

    this.workOrderService.getUserList().subscribe((response: any) => {
      this.areaCoordinatorList = response;
      this.workOrderForm.controls['AreaCoordinatorUser_Code'].setValue(this.workOrder.AreaCoordinatorUser_Code);
    });
    // let getCompany = this.workOrderService.getCompanies();
    // let getOrderTypes = this.workOrderService.getOrderTypes(MasterItemCode.ORDER_TYPE);
    // let getOrderCategory = this.workOrderService.getOrderCategories(MasterItemCode.ORDER_CATEGORY);
    // let getNatureOfWorks = this.workOrderService.getNatureOfWorks();
    // let getWorkSiteAreas = this.workOrderService.getWorkSiteAreas(MasterItemCode.WORK_SITE_AREA);
    // let getExecutingDepartments = this.workOrderService.getExecutingDepartments();
    // let getWorkTypes = this.workOrderService.getWorkTypes(MasterItemCode.WORK_TYPE);

    // forkJoin(getCompany, getOrderTypes, getOrderCategory, getNatureOfWorks,
    //   getWorkSiteAreas, getExecutingDepartments, getWorkTypes)
    //   .pipe(takeUntil(this.onDestroy)).subscribe(([companyList, orderTypeList, orderCategoryList, natureOfWorkList, workSiteAreaList,
    //     executingDepartmentList, , workTypes]) => {

    //     this.companyList = companyList;
    //     if (this.companyList.length === 1) {
    //       this.workOrderForm.controls['Company'].setValue(this.companyList[0].Company_Code);
    //       this.workOrderForm.controls['Company'].disable();
    //       this.getPlants(this.companyList[0].Company_Code);
    //     }
    //     this.orderTypeList = orderTypeList;
    //     this.orderCategoryList = orderCategoryList;
    //     this.natureOfWorkList = natureOfWorkList;
    //     this.workSiteAreaList = workSiteAreaList;
    //     this.executingDepartmentList = executingDepartmentList;
    //   });

    // this.getWorkOrderDetails();
  }

  private getContractors() {
    this.workOrderService.getContractors().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {

      this.contractorList = response;
      this.workOrderForm.controls['ParentContractorVendor_Code'].setValue(this.workOrder.ParentContractorVendor_Code);
      if (this.workOrder.ParentContractorVendor_Code && this.isSubContractor) {
        this.getContractorWorkOrder(this.workOrder.ParentContractorVendor_Code);
        this.getSubContractorsByExcludeCode(this.workOrder.ParentContractorVendor_Code);
      }
    });
  }

  private getSubContractorsByExcludeCode(excludeCode: string): void {

    this.workOrderService.getContractors(excludeCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {

      this.subContractorList = response;
      this.workOrderForm.controls['ChildContractorVendor_Code'].setValue(this.workOrder.ChildContractorVendor_Code);
    });
  }

  private getContractorWorkOrder(contractorCode: number): void {
    this.workOrderService.getWorkOrderFilterData(contractorCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.contractorWorkOrderList = response;
      this.workOrderForm.controls['ParentWorkOrder_Code'].setValue(this.workOrder.ParentWorkOrder_Code);
    });
  }

  private getPOAgainstLOIList(): void {
    this.workOrderService.getWorkOrderFilterData().pipe(takeUntil(this.onDestroy)).subscribe((response: any[]) => {
      this.poAgainstLOIList = response;

      // tslint:disable-next-line: radix
      if (this.workOrder.LOIRef_WorkOrder_Code !== undefined) {
        // tslint:disable-next-line: radix
        const wrk = parseInt(this.workOrder.LOIRef_WorkOrder_Code);
        this.workOrderForm.controls['LOIRef_WorkOrder_Code'].setValue(wrk);
      }else {
        this.workOrderForm.controls['LOIRef_WorkOrder_Code'].setValue(null);
      }
    });
  }


  private getDocumentTypes() {

    this.workOrderService.getDocumentTypes().pipe(takeUntil(this.onDestroy)).subscribe((docTypes: any) => {
      this.documentTypes = docTypes.Data;
    });
  }

  private getPlants(companyCode: any): void {
    this.workOrderService.getPlants(companyCode).pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
      console.log('getPlants response', response);
      this.plantList = response;
      this.workOrderForm.controls['PlantCodes'].setValue(this.workOrder.PlantCodes);
      // if (this.workOrder.PlantCodes.length === 1) {
      //   this.workOrderForm.controls['PlantCodes'].setValue(this.workOrder.PlantCodes);
      //   this.workOrderForm.controls['PlantCodes'].disable();
      // }
    });
  }

  // public onDepartmentChange(): void {
  //
  //   // this.workOrderService.getUserList().subscribe((response: any) => {
  //   //   this.departmentHeadList = response;
  //   //   this.areaCoordinatorList = response;
  //   //   this.eicUserList = response;
  //   //   this.workOrderForm.controls['HODUser_Code'].setValue(this.workOrder.HODUser_Code ?
  // this.workOrder.HODUser_Code.toString() : null);
  //   //   this.workOrderForm.controls['AreaCoordinatorUser_Code'].
  //   //     setValue(this.workOrder.AreaCoordinatorUser_Code ? this.workOrder.AreaCoordinatorUser_Code.toString() : null);
  //   // });
  //   this.workOrderService.getDepartmentHeadList(this.workOrderForm.control.value['Department_Code']).subscribe((response: any) => {
  //     this.departmentHeadList = response;
  //     this.areaCoordinatorList = response;
  //     this.eicUserList = response;
  //     this.workOrderForm.controls['HODUser_Code'].setValue(this.workOrder.HODUser_Code ? this.workOrder.HODUser_Code.toString() : null);
  //     this.workOrderForm.controls['AreaCoordinatorUser_Code'].
  //       setValue(this.workOrder.AreaCoordinatorUser_Code ? this.workOrder.AreaCoordinatorUser_Code.toString() : null);
  //   });
  // }

  onDepartmentChange() {
    return this.workOrderService.getDepartmentHeadList(this.workOrderForm.control.value['Department_Code'])
      .subscribe((res:any) => {

        this.departmentHeadList = res.Data;
        this.workOrderForm.controls['HODUser_Code'].setValue(this.workOrder.HODUser_Code);
      });
  }


  private getWorkOrderByContractorCode(parentContractorVendorCode: any): void {
    this.workOrderService.getWorkOrderByContractorCode(parentContractorVendorCode)
      .pipe(takeUntil(this.onDestroy)).subscribe((response: any) => {
        console.log('getPlants response', response);
        this.contractorWorkOrderList = response;
      });
  }

  // config for select filter component
  private createConfigForContractor(): void {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor Code', field: 'ShortCode',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Company', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Email Id', field: 'EmailID', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.contractorConfig = {
      contractor: { contractorColumnDefs, labelText: 'Contractor', placeholderText: 'Select Contractor' }
    };
  }

  // config for select filter component
  private createConfigForSubContractor(): void {
    const contractorColumnDefs = [
      {
        headerName: 'Vendor', field: 'ShortCode',
        width: 150, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Contractor', field: 'Company', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sort: 'asc', sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'Email Id', field: 'EmailID', width: 450,
        filterParams: { applyButton: true, clearButton: true }, sortable: true,
        filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.subContractorConfig = {
      contractor: { contractorColumnDefs, labelText: 'Sub-Contractor', placeholderText: 'Select Sub-Contractor' }
    };
  }

  private createConfigForWorkOrder(): void {
    const workOrderColumnDefs = [
      {
        headerName: 'Work Order Number', field: 'WorkOrderNO',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Company Name', field: 'CompanyName', width: 150, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Allow Person', field: 'Person', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Nature Of Work', field: 'NatureOfWork', width: 200, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
      {
        headerName: 'HOD', field: 'HOD', width: 100, filterParams: { applyButton: true, clearButton: true },
        sortable: false, suppressMenu: false, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
    ];
    this.workOrderConfig = {
      workOrder: { workOrderColumnDefs, labelText: 'Contractor WO', placeholderText: 'Select Contractor WO' }
    };
  }

  private createConfigForPOAgainstLOI(): void {
    const workOrderColumnDefs = [
      {
        headerName: 'Work Order Number', field: 'WorkOrderNo',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Company Name', field: 'CompanyName', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Allow Person', field: 'Person', width: 80, filterParams:
          { applyButton: true, clearButton: true }, filter: true, cellStyle: {
            'align-items': 'center', display: 'flex'
          },
      },
      {
        headerName: 'Owner', field: 'Owner', width: 70, filterParams: { applyButton: true, clearButton: true },
        suppressMenu: false, sortable: true, filter: true, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
      },
    ];
    this.poAgainstLOIConfig = {
      workOrder: { workOrderColumnDefs, labelText: 'PO Against LOI/FOA', placeholderText: 'Select PO Against LOI/FOA' }
    };
  }

  private createConfigForAreaCoordinator(): void {
    const userListColumnDefs = [
      {
        headerName: 'User Name', field: 'UserName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Email Id', field: 'AlternetEmailID',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.areaCoordinatorConfig = {
      userList: { userListColumnDefs, labelText: 'Area Coordinator', placeholderText: 'Select Area Coordinator' }
    };
  }

  private createConfigForDepartmentHead(): void {
    const userListColumnDefs = [
      {
        headerName: 'Full Name', field: 'FullName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Email Id', field: 'AlternetEmailID',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.departmentHeadConfig = {
      userList: { userListColumnDefs, labelText: 'Department Head', placeholderText: 'Select Department Head' }
    };
  }

  private createConfigForEICUser(): void {
    const userListColumnDefs = [
      {
        headerName: 'Full Name', field: 'FullName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Email Id', field: 'AlternetEmailID',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      }
    ];
    this.eicUserConfig = {
      userList: { userListColumnDefs, labelText: 'PO Owner/EIC User', placeholderText: 'Select PO Owner/EIC User' }
    };
  }

  private createConfigForCompanyPolicy(): void {
    const ecPolicyColumnDefs = [
      {
        headerName: 'Company', field: 'CompanyName',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        },
        cellRendererFramework: AgFilterCellRenderComponent
      },
      {
        headerName: 'Policy no', field: 'ECPolicyNo',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
      {
        headerName: 'Issue Date', field: 'IssueDate',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
      {
        headerName: 'Nature Of Work', field: 'NatureOfWork',
        width: 100, filterParams: { applyButton: true, clearButton: true }, cellStyle: {
          'align-items': 'center', display: 'flex'
        }
      },
    ];
    this.ecPolicyConfig = {
      ecPolicy: { ecPolicyColumnDefs, labelText: 'Company Policy', placeholderText: 'Select Company Policy' }
    };
  }

  private openAlert(): void {
    setTimeout(() => {
      const alertRef = this.dialogerror.show();
      alertRef.then((e) => {
      });
    }, 10);
  }


  private onScroll = (event: any) => {
    if (this.select && this.select.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.select.close();
    }
    if (this.areaCoordinatorSelect && this.areaCoordinatorSelect.isOpen) {
      const isScrollingInScrollHost = (event.target.className as string).indexOf('ng-dropdown-panel-items') > -1;
      if (isScrollingInScrollHost) { return; }
      this.areaCoordinatorSelect.close();
    }
  }

  private resetFormAfterSave() {
    const id = this.activatedRoute.snapshot.params.id;
    if (id) {
      const isDirect = this.workOrder.IsDirect;
      setTimeout(() => {
        if (isDirect) {
          this.router.navigate(['/contractor/work-order/'], { queryParams: { type: '1' } });
        } else {
          this.router.navigate(['/contractor/work-order/'], { queryParams: { type: '0' } });
        }
      }, 500);
    }

    this.workOrderForm.reset();
    this.workOrder = {};
    this.isContractorTouched = false;
    this.isSubContractorTouched = false;
    this.isAreaCoordinatorTouched = false;
    this.isDepartmentHeadTouched = false;
    this.isPOAgainstLOITouched = false;
    this.isDepartmentHeadTouched = false;
    this.isEicUserTouched = false;
    this.isCompanyPolicyTouched = false;

    this.setDefaultControlValue();

  }
}
