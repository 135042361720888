import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManPowerComponent } from '../man-power-yearly/man-power-yearly.component';

const routes: Routes = [
  {
    path: 'man-power-yearly',
    component: ManPowerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManPowerRoutingModule { }
