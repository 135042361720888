import { Component, OnInit, ViewContainerRef, ComponentRef, TemplateRef, ViewChild, Input, ComponentFactoryResolver } from '@angular/core';
import { FilterTypeEnum, ReportFilterData } from 'src/app/entryforday/report-filter-test/report-filter.model';
import { isNullOrUndefined } from 'util';
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { OwlDateTimeComponent, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';
import { GLOBAL } from 'src/app/app.globals';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { Contractor } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.model';
import { EntryForThePrsenter } from 'src/app/reports/daily-attendance/entryfortheday/entryfortheday-presenter/entryfortheday.presenter';
import { AttendanceManualPunchService } from '../../attendance-manual-punch/attendance-manual-punch.service';
import { AttendanceService } from '../../attendance.service';
import { AttendanceManualPunchChangeComponent } from '../attendance-manual-punch-change.component';
import { EntryfordayService } from 'src/app/reports/daily-attendance/entryfortheday/entryforday.service';
declare var $;
const dateFormat = 'DD-MM-YYYY';
const date1: Date = new Date();
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};

@Component({
  selector: 'app-attendance-manual-punch-change-filter',
  templateUrl: './attendance-manual-punch-change-filter.component.html',
  styleUrls: ['./attendance-manual-punch-change-filter.component.css'],
  viewProviders: [EntryForThePrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }]
})
export class AttendanceManualPunchChangeFilterComponent implements OnInit {

  public activeFilter: string;
  public formGroup: FormGroup;
  public filterFormGroup: FormGroup;
  public filterTabList: any[] = [];
  @ViewChild('endDate') public endDate: OwlDateTimeComponent<any>;
  // public variables
  public showContractorTab = true;
  public isContractorSave: boolean;
  public isSubContractorSave: boolean;
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];

  public savedFilters: any[] = [];
  public groupByList: any[];
  public companyList: any[];
  public plantList: any[];
  public unitList: any[];
  public contractors: any[] = [];
  public subContractors: any[] = [];

  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;

  @ViewChild('templateRef') templateRef: TemplateRef<any>;

  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;
  public pagecode: number;
  public roleCode: any;
  public userId: any;
  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;

  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;

  private filterType: FilterTypeEnum;

  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;

  private contractorCode: number;
  private workOrderNo: number;
  reportValueData: any[];
  rowData: any;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    // private entryfordayService: EntryfordayService,
    private attendanceService: AttendanceService,
    private logHelperService: LogHelperService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private entryForThePrsenter: EntryForThePrsenter,
    public sharedService: SharedService) {
    this.formGroup = this.entryForThePrsenter.buildForm();
  }

  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getAllData();
    this.pagecode = 334;
    this.getALLSavedFilterDescripationUserWise(this.pagecode);
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;
    this.formGroup.get('JSONText').get('Date').setValue(date1);
    this.manageVisibilityOfSteps();
    this.roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    this.userId = localStorage.getItem('UserID');

  }

  public onPlantChange(): void {
    this.unitList = [];
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (plantCode) {
      this.getUnits(plantCode);
    }
  }

  private getAllData() {
    this.setGroupByList();
    this.getCompany();
    this.getContractors();
  }

  getCompany() {
    this.attendanceService.getCompanies().subscribe((response: any[]) => {

      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }

  getContractors() {
    this.attendanceService.getContractors(this.userId, this.roleCode).subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }



  getPlants(companyCode: any): void {
    this.attendanceService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
      if (this.selectedFilterData.JSONText.Plant_Code) {
        this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
      }
    });
  }

  getUnits(plantCode: any): void {
    this.attendanceService.getUnits(plantCode).subscribe((response: any[]) => {
      this.unitList = response;
      this.formGroup.get('JSONText').get('Unit_Code').setValue(this.selectedFilterData.JSONText.Unit_Code);
    });
  }

  setGroupByList() {
    this.groupByList = [
      {
        value: '1',
        label: 'Contractor Wise'
      },
      // {
      //   value: '1',
      //   label: 'Contractor PO Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor and Department Wise'
      // },
      // {
      //   value: '1',
      //   label: 'Contractor, PO and Department Wise'
      // }
    ];
  }

  onStartDateChangeEmitter(startDate: string) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.startDate = new Date(startDate);
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    this.formGroup.get('JSONText').get('Trade_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;
    this.selectedFilterData.JSONText.Trade_Code = null;

    // this.formGroup.get('JSONText').get('subContractorCode').setValue(null);
    // this.formGroup.get('JSONText').get('subContractorWorkOrderNo').setValue(null);
    // // this.workOrders = [];
    // this.subContractors = [];
    // this.subContractorWorkOrders = [];
    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  onContractorChange() {

    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (contractorCode && contractorCode.length === 1) {
      if (this.formGroup.get('JSONText').get('WorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('License_Code').disabled) {
        this.formGroup.get('JSONText').get('License_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('WCPolicy').disabled) {
        this.formGroup.get('JSONText').get('WCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('SubContractors').disabled) {
        this.formGroup.get('JSONText').get('SubContractors').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubLicense_Code').disabled) {
        this.formGroup.get('JSONText').get('SubLicense_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWCPolicy').disabled) {
        this.formGroup.get('JSONText').get('SubWCPolicy').enable();
      }

    } else {
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('License_Code').disable();
      this.formGroup.get('JSONText').get('WCPolicy').disable();
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubContractors').disable();
      this.formGroup.get('JSONText').get('SubWorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubLicense_Code').disable();
      this.formGroup.get('JSONText').get('SubWCPolicy').disable();
    }
  }


  public onStartDateChange(): void {
    if (this.formGroup.get('JSONText').get('SAge').valid) {
      const startDate = this.formGroup.get('JSONText').get('SAge').value;
      const date = moment(startDate).format('DD/MM/YYYY');
      const currentDate = moment(new Date()).format('DD/MM/YYYY');
      if (date === currentDate) {
      } else if (startDate < new Date()) {
        return;
      }
      const endDate = this.formGroup.get('JSONText').get('EAge').value;
      this.endDate.dtInput.min = new Date((new Date(startDate)).getTime() + (60 * 60 * 24 * 1000));
      if (startDate > endDate) {
        if (this.formGroup.get('JSONText').get('EAge').valid) {
          this.formGroup.get('JSONText').get('EAge').setValue('');
          // this.isValidStartDate = true;
        }
      }
    } else {
      // this.isValidStartDate = false;
      this.formGroup.controls['EAge'].setValue('');
    }
  }

  public onContractorSaveEmitter(data: any) {
    console.log('saved data', data);

    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubContractorChange() {
    const subContractorCode = this.formGroup.get('JSONText').get('SubContractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
  }

  public onSubContractorSaveEmitter(data: any) {
    console.log('saved data', data);
    this.reportFilterData = data;
    this.openModal();
    this.isContractorSave = false;
  }

  public onSubWorkOrderChangeEmitter(value: any) {
    this.reportFilterData.subContractorWorkOrderNo = value.workOrderNo;
  }

  getSubWorkOrderListEmitter(contractorCode: number, plantcode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
  }

  public onSaveFilter(): void {
    console.log('form data', this.formGroup.getRawValue());
    this.formGroup.get('JSONText').get('id').setValue(this.selectedTabId);
    console.log('saved data', this.reportFilterData);
    this.formGroup.get('FilterName').setValidators(Validators.required);
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.openModal();
  }

  // public onSubmit(): void {
  //
  //   const formValue = this.formGroup.getRawValue();
  //   console.log('response', formValue);
  //
  //   this.entryfordayService.getDailyPresentDetails(formValue).subscribe((response: any[]) => {
  //     console.log(response);
  //     this.openReportViewModal(response);
  //    });
  //  }

  public onSubmit(): void {

    const formValue = this.formGroup.getRawValue();
    formValue.JSONText.Date = moment(formValue.JSONText.Date).format(this.sharedService.datePickerSettings.requestFormat);

    let contCode = '';
    let subcontCode = '';
    let ptCode = '';
    let utCode = '';

    if (formValue.JSONText.Contractors) {
      contCode = formValue.JSONText.Contractors.join(',');
    } else {
      contCode = null;
    }

    if (formValue.JSONText.Plant_Code) {
      ptCode = formValue.JSONText.Plant_Code.join(',');
    } else {
      ptCode = null;
    }
    if (formValue.JSONText.Unit_Code) {
      utCode = formValue.JSONText.Unit_Code.join(',');
    } else {
      utCode = null;
    }


    if (formValue.JSONText.SubContractors) {
      subcontCode = formValue.JSONText.SubContractors;
    } else {
      subcontCode = null;
    }

    this.attendanceService.getListWrongPunch({
      UserId: localStorage.getItem('UserID'),
      date: formValue.JSONText.Date,
      plant_Code: ptCode,
      unit_Code: utCode,
      contractors: contCode,
      subContractors:subcontCode
    }).subscribe((response: any[]) => {

      if (response && response.length > 0) {
        this.openReportViewModal(response);
      } else {
        // message for data not found
      }
      // this.reportValueData = response;
      // const tempRowData = this.sharedService.agGridGroupRowOnProperties(this.groupingMeta, this.reportValueData);
      // this.rowData = tempRowData;
      // console.log('Output', tempRowData);
    });

  }

  openReportViewModal(response: any[]): void {
    const formValue = this.formGroup.getRawValue();
    // tslint:disable-next-line: indent
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
      formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
    }

    const modalRef = this.modalService.open(AttendanceManualPunchChangeComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.selectedReportData = formValue.JSONText;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }

  // public onFilterTab(filterObj: any): void {
  //   console.log('filterObj', filterObj);
  //   this.activeFilter = filterObj.name;
  //   this.selectedTabId = filterObj.id;
  //   this.manageVisibilityOfSteps();
  // }

  public onFilterTab(filterObj: any): void {

    if (filterObj.id === 4 && (!this.formGroup.get('JSONText').get('Contractors').value ||
      this.formGroup.get('JSONText').get('Contractors').value.length === 0 || this.formGroup.get('JSONText')
        .get('Contractors').value.length > 1)) {
      this.logHelperService.logError({
        message: 'Please first fullfill data in contractor.'
      });
      return;
    }
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  public onAddfilter(): void {
    console.log('form data open modal', this.formGroup.getRawValue());
    const value = this.formGroup.getRawValue();
    const selectedFilterDataObj = { ...this.selectedFilterData.JSONText, ...value.JSONText };
    const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName
      && item.Report_Code !== this.selectedFilterData.Report_Code);
    if (!filterTypeObj) {
      selectedFilterDataObj.id = this.selectedTabId;
      const data = {
        Report_Code: this.selectedFilterData.Report_Code || '',
        Page_Code: 334,
        FilterName: value.FilterName,
        JSONTextDetails: JSON.stringify(selectedFilterDataObj)
      };
      if (this.selectedFilterData.Report_Code) {
        this.attendanceService.updateFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      } else {
        this.attendanceService.addFilter(data).subscribe((response: any) => {
          if (response.Status) {
            this.getALLSavedFilterDescripationUserWise(this.pagecode);
            this.modalRef.close();
            this.clearData();
          }
        });
      }
      // const index = this.savedFilters.indexOf(this.selectedFilterData);
      // if (this.selectedFilterData && index >= 0) {
      //   this.savedFilters[index] = value;
      //   this.modalRef.close();
      //   this.clearData();
      // } else {
      //   const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName);
      //   if (!filterTypeObj) {
      //     // this.savedFilters.push(value);
      //     const data = {
      //       User_Code: GLOBAL.USER.LOGGED_IN_USER,
      //       Page_Code: 5,
      //       FilterName: value.FilterName,
      //       JSONTextDetails: JSON.stringify(value)
      //     };
      //     this.entryfordayService.addFilter(data).subscribe((response: any) => {
      //       if (response.Status) {
      //         this.getALLSavedFilterDescripationUserWise();
      //       }
      //     });
      //     console.log('saved filters', this.savedFilters);

      //     this.modalRef.close();
      //     this.clearData();
      //   } else {
      //     alert('This filter is already exist.');
      //   }
      // }
    } else {
      alert('This filter is already exist.');
    }
  }

  public deleteFilterItem(filterObj: any): void {
    this.attendanceService.deleteFilter(filterObj).subscribe((response: any[]) => {
      this.getALLSavedFilterDescripationUserWise(this.pagecode);
    });
  }

  private getALLSavedFilterDescripationUserWise(pagecode: number) {
    this.attendanceService.getALLSavedFilterDescripationUserWise(pagecode).subscribe((response: any[]) => {
      this.savedFilters = response;
    });
  }


  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;
    this.selectedFilterData = {
      JSONText: {}
    };
    this.manageVisibilityOfSteps();
    // this.reportFilterData = null;
    // this.selectedFilterData = null;

    // this.contractorCode = null;
    // this.workOrderNo = null;
    // this.subContractors = [];
    // this.workOrders = [];
    // // this.contractors=[];
    // setTimeout(() => {
    //   this.contractors = [...this.contractors];
    // }, 100);

    // this.filterNameFormGroup.get('filterTypeName').setValue('');
    // this.resetForm = true;
    // if (this.contractorComponentRef) {
    //   this.contractorComponentRef.destroy();
    // } else if (this.subContractorComponentRef) {
    //   this.subContractorComponentRef.destroy();
    // }
    // this.contentRef.clear();
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onFilterNameClick(filterObj: any) {

    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.entryForThePrsenter.bindForm(this.formGroup, filterObj);
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }




  private setWorkOrderList(response: any[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      },
      {
        id: 2,
        name: 'Company',
        active: false
      },
      {
        id: 3,
        name: 'Contractor',
        active: false
      },
      {
        id: 4,
        name: 'Sub-Contractor',
        active: false
      },
      // {
      //   id: 5,
      //   name: 'Associate',
      //   active: false
      // }
    ];
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }

}
