import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { GatePassRequestsService } from '../../gate-pass-requests.service';
import { VerificationHistoryComponent } from '../verification-history/verification-history.component';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { SharedService } from 'src/app/core/services';

let userID: string;
userID = localStorage.getItem('UserID');
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {

  @Input() resolveObject;
  documentUrl = GLOBAL.BASE_URL_DOC;
  photoUrl = GLOBAL.PHOTO_URL;
  approvalData: any;
  labourData: any;
  approvalOn = {
    FirstName: false,
    MiddleName: false,
    LastName: false,
    DOB: true,
    VoterId: false,
    AadharCard: true,
    LicenseNo: false,
    PFUANNo: false,
    PanCard: false,
    BankAc: false
  };
  public checkdocverify = false;
  documents = [];
  public isverifydisable = false;
  public disableverifybutton = true;
  laboutVerifyParams: any = {};
  selectedULC = 0;
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private gatePassRequestsService: GatePassRequestsService,
    private logHelperService: LogHelperService,
    public sharedService: SharedService
  ) { }

  ngOnInit() {
    console.log(this.resolveObject);
    this.approvalData = this.resolveObject.approvalData;
    this.labourData = this.resolveObject.labourData;
    this.selectedULC = this.labourData.ULC;
    // this.getDocumentVerify();
    this.getDocuments();
    if (this.approvalData.ULC) {
      this.checkdocverify = true;
      this.disableverifybutton = false;
    } else {
      this.checkdocverify = false;
      this.disableverifybutton = true;
    }
    this.laboutVerifyParams = {
      AdharCardId: this.approvalData.AadharCard,
      DateofBirth: '',
      FirstName: this.resolveObject.labourData.FirstName,
      LabourCode: this.resolveObject.labourData.Labour_Code,
      LastName: this.resolveObject.labourData.LastName,
      MiddleName: this.resolveObject.labourData.MiddleName,
      PanNumber: '',
      VoterIdCard: ''
    };
    this.getLabourVerifyData(false, '');
  }

  getLabourVerifyData(event, control) {
    if (control === 'aid' && !event.target.checked) {
      this.laboutVerifyParams.AdharCardId = '';
    }
    else {
      if (control === 'aid')
        this.laboutVerifyParams.AdharCardId = this.approvalData.AadharCard;
    }
    if (control === 'dob' && !event.target.checked) {
      this.laboutVerifyParams.DateofBirth = '';
    } else {
      if (control === 'dob')
        this.laboutVerifyParams.DateofBirth = this.resolveObject.labourData.DOB;
    }
    if (control === 'fname' && !event.target.checked) {
      this.laboutVerifyParams.FirstName = '';
    } else {
      if (control === 'fname')
        this.laboutVerifyParams.FirstName = this.resolveObject.labourData.FirstName;
    }
    if (control === 'lname' && !event.target.checked) {
      this.laboutVerifyParams.LastName = '';
    } else {
      if (control === 'lname')
        this.laboutVerifyParams.LastName = this.resolveObject.labourData.LastName;
    }
    if (control === 'mname' && !event.target.checked) {
      this.laboutVerifyParams.MiddleName = '';
    } else {
      if (control === 'mname')
        this.laboutVerifyParams.MiddleName = this.resolveObject.labourData.MiddleName;
    }
    if (control === 'pno' && !event.target.checked) {
      this.laboutVerifyParams.PanNumber = '';
    } else {
      if (control === 'pno')
        this.laboutVerifyParams.PanNumber = this.approvalData.PanCard;
    }
    if (control === 'vid' && !event.target.checked) {
      this.laboutVerifyParams.VoterIdCard = '';
    } else {
      if (control === 'vid')
        this.laboutVerifyParams.VoterIdCard = this.resolveObject.labourData.Vatoreid;
    }
    this.gatePassRequestsService.getLabourVerifyData(this.laboutVerifyParams).subscribe((labourVerificationData: any) => {
      this.resolveObject['labourVerificationData'] = labourVerificationData.Data;
    });
  }

  getDocumentVerify($event) {
    console.log('$event', $event, $event.target.checked);
    this.approvalData.IsVerifyDoc = $event.target.checked;
    if (this.approvalData.IsVerifyDoc === true) {
      this.isverifydisable = true;
    } else {
      this.isverifydisable = false;
      this.logHelperService.logError({
        message: 'Please Verify Document First!!'
      });
    }
  }


  labourVerifications() {
    this.gatePassRequestsService.labourVerifications(this.labourData.Labour_Code, this.selectedULC || 0,
      this.approvalData.IsVerifyDoc, userID).subscribe((res: any) => {

        // this.getDocumentVerify();
        // if (this.verifyOn.IsVerifyDoc) {
        if (res) {
          this.logHelperService.logSuccess({ message: 'Associate Verified' });
          this.activeModal.close(res);
        } else {
          this.logHelperService.logError({ message: 'Associate can\'t Verified!' });
          this.activeModal.close(false);
        }
        // } else {
        //   alert('Please Verify Document First!!');
        // }
      });
  }

  getLabourHistory(labourCode) {
    this.gatePassRequestsService.getHistoryData(labourCode).subscribe((res: any) => {
      const modalRef = this.modalService.open(VerificationHistoryComponent,
        { size: 'lg', backdrop: 'static', windowClass: 'history-window' });
      modalRef.componentInstance.rowData = res.Data.Table;
    });
  }

  getThisVerifiedData(rowData) {
    this.resolveObject.labourVerificationData.forEach((rowData) => {
      rowData.isSelected = false;
    });
    if (rowData.ULC) {
      rowData.isSelected = true;
      this.selectedULC = rowData.ULC;
    } else {
      rowData.isSelected = false;
    }
  }

  getDocuments() {
    this.gatePassRequestsService.getDocumentForLabourVerify(this.resolveObject.labourData.Labour_Code)
      .subscribe((verificationDoc: any) => {
        if (verificationDoc.Status) {
          this.documents = verificationDoc.Data;
        }
      });
  }

}
