import { Injectable } from '@angular/core';
import { ChartUtilService } from './chart-util.service';

declare var d3: any;
declare var dc: any;

interface Config {
  crossfilter: any;
  filterGroup: string;
  chartId: string;
  groupProperty: string;
  width: number;
  height: number;

  // Optional
  crossfilter2?: any;
  isQuarter?: boolean;
  xLabel?: string;
  yLabel?: string;
  legendX?: number;
  legendY?: number;
  legendWidth?: number;
  labelValueFormatted?: boolean;
  margins?: {
    top: number,
    right: number,
    bottom: number,
    left: number,
  };
}

@Injectable({
  providedIn: 'root'
})
export class GroupBarChartService {
  quarterMonth = {
    JAN: 'Q1',
    FEB: 'Q1',
    MAR: 'Q1',
    APR: 'Q2',
    MAY: 'Q2',
    JUN: 'Q2',
    JUL: 'Q3',
    AUG: 'Q3',
    SEP: 'Q3',
    OCT: 'Q4',
    NOV: 'Q4',
    DEC: 'Q4',
  };

  constructor(
    private chartUtilService: ChartUtilService,
  ) {

  }

  getGroupBarChart1(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['Budget', 'Actual'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
    };

    const dimension = config.crossfilter.dimension((d: any) => {
      if (config.isQuarter) {
        const q = this.quarterMonth[d[config.groupProperty]];
        return q ? q : d[config.groupProperty];
      }

      return d[config.groupProperty];
    });
    const amountSumGroup = this.getGroupByDimensionBudgetAndActual(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.Budget !== 0 && d.value.Actual !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(amountSumGroup);

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .brushOn(false)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(config.xLabel)
      .yAxisLabel(config.yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      .legend(
        dc.legend()
          .x(config.legendX ? config.legendX : 100)
          .y(config.legendY ? config.legendY : 2)
          .itemWidth(config.legendWidth ? config.legendWidth : 100)
          .horizontal(true)
          .legendText((d) => {
            return barLabelMap[d.name];
          }))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    if (config.margins) {
      chart.margins(config.margins);
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupBarChart(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['TotalReq', 'ActualDeployed', 'TotalShortage'];
    const barLabelMap = {
      TotalReq: 'Planned',
      ActualDeployed: 'Actual',
      TotalShortage: 'Shortage'
    };

    const dimension = config.crossfilter.dimension((d: any) => {
      if (d[config.groupProperty] !== null) {
        return d[config.groupProperty];
      }
    });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        if (v.IsShortageClear === 0) {
          p.TotalReq += +v.ConractorAssigne;
          p.TotalShortage += +v.TotalShortage;
        } else {
          p.TotalShortage -= +v.ActualDeployed;
        }
        p.ActualDeployed += +v.ActualDeployed;
        // p.TotalReq += +v.TotalReq;
        // p.ActualDeployed += +v.ActualDeployed;
        // p.TotalShortage += +v.TotalShortage;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }

        return p;
      },
      (p: any, v: any) => {
        if (v.IsShortageClear === 0) {
          p.TotalReq -= +v.ConractorAssigne;
          p.TotalShortage -= +v.TotalShortage;
        } else {
          p.TotalShortage += +v.ActualDeployed;
        }

        p.ActualDeployed -= +v.ActualDeployed;

        const total = p.TotalReq + p.ActualDeployed + p.TotalShortage;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }
        return p;
      },
      () => {
        return {
          TotalReq: 0,
          ActualDeployed: 0,
          TotalShortage: 0,
          TotalReqPec: 0,
          ActualDeployedPec: 0,
          TotalShortagePec: 0
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.TotalReq !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(amountSumGroup);

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(config.xLabel)
      .yAxisLabel(config.yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      .legend(
        dc.legend()
          .x(config.legendX ? config.legendX : 100)
          .y(config.legendY ? config.legendY : 2)
          .itemWidth(config.legendWidth ? config.legendWidth : 100)
          .horizontal(true)
          .legendText((d) => {
            return barLabelMap[d.name];
          }))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    if (config.margins) {
      chart.margins(config.margins);
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupBarChartP(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);

    const barKeyList = ['TotalReq', 'ActualDeployed'];
    const barLabelMap = {
      TotalReq: 'Requirements',
      ActualDeployed: 'Actual'
    };

    const dimension = config.crossfilter.dimension((d: any) => {
      if (d[config.groupProperty] !== null) {
        return d[config.groupProperty];
      }
    });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.TotalReq += +v.TotalReq;
        p.ActualDeployed += +v.ActualDeployed;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
        }

        return p;
      },
      (p: any, v: any) => {
        p.TotalReq -= +v.TotalReq;
        p.ActualDeployed -= +v.ActualDeployed;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
        }
        return p;
      },
      () => {
        return {
          TotalReq: 0,
          ActualDeployed: 0,
          TotalReqPec: 0,
          ActualDeployedPec: 0,
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.TotalReq !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(amountSumGroup);

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(config.xLabel)
      .yAxisLabel(config.yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      .legend(
        dc.legend()
          .x(config.legendX ? config.legendX : 100)
          .y(config.legendY ? config.legendY : 2)
          .itemWidth(config.legendWidth ? config.legendWidth : 100)
          .horizontal(true)
          .legendText((d) => {
            return barLabelMap[d.name];
          }))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart, config);
      })
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    if (config.margins) {
      chart.margins(config.margins);
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupBarChartOne(config: Config) {
    const chart = dc.barChart(config.chartId, config.filterGroup);
    const shiftPresentDimension = config.crossfilter.dimension((d: any) => {
      return d.State;
    });
    const shiftPresentULCGroup = shiftPresentDimension.group().reduceCount((d: any) => {
      let cnt = 0;
      if (d.AttendanceStatus === 'P') {
        cnt++;
      }
      return cnt;
    });

    const barKeyList = ['TotalReq', 'ActualDeployed', 'TotalShortage'];
    const barLabelMap = {
      TotalReq: 'Requirements',
      ActualDeployed: 'Actual',
      TotalShortage: 'Shortage'
    };

    const dimension = config.crossfilter.dimension((d: any) => { return d[config.groupProperty]; });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.TotalReq += +v.TotalReq;
        // p.ActualDeployed +=  Sum(P.AttendanceStatus);
        p.ActualDeployed += +v.AttendanceStatus;
        p.TotalShortage += +v.TotalShortage;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }

        return p;
      },
      (p: any, v: any) => {
        p.TotalReq -= +v.TotalReq;
        p.ActualDeployed -= +v.ActualDeployed;
        p.TotalShortage -= +v.TotalShortage;

        const total = p.TotalReq + p.ActualDeployed + p.TotalShortage;

        if (p.TotalReq > 0) {
          p.TotalReqPec = 100;
          p.ActualDeployedPec = Math.round((p.ActualDeployed * 100) / p.TotalReq);
          p.TotalShortagePec = Math.round((p.TotalShortage * 100) / p.TotalReq);
        }
        return p;
      },
      () => {
        return {
          TotalReq: 0,
          ActualDeployed: 0,
          TotalShortage: 0,
          TotalReqPec: 0,
          ActualDeployedPec: 0,
          TotalShortagePec: 0
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .brushOn(false)
      .clipPadding(10)
      .width(config.width)
      .height(config.height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${this.chartUtilService.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(config.xLabel)
      .yAxisLabel(config.yLabel)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .on('postRender', this.rotateXaxis)
      .group(amountSumGroup, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(amountSumGroup, barKeyList[i], sel_stack(barKeyList[i]));
    }

    if (config.margins) {
      chart.margins(config.margins);
    }

    chart.render();
    return chart;
  }
  getGroupBarChart3(ndx1, chartId, groupProperty, xLabel, yLabel, width, height) {
    const chart = dc.barChart(chartId);

    const barKeyList = ['Budget', 'Actual', 'Function'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
      Function: 'Function'
    };

    const dimension = ndx1.dimension((d: any) => { return d[groupProperty]; });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000);
        p.Actual += Math.round(v.Actual / 100000);
        p.Function += +v.Function;


        return p;
      },
      (p: any, v: any) => {
        // p.TotalReq -= +v.TotalReq;
        p.Budget -= +Math.round(v.Budget / 100000);
        p.Actual -= +Math.round(v.Actual / 100000);
        p.Function += +v.Function;
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .margins({ left: 50, top: 0, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        // return `${this.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .on('postRender', this.rotateXaxis)
      .group(amountSumGroup, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(amountSumGroup, barKeyList[i], sel_stack(barKeyList[i]));
    }
    chart.render();
    return chart;
  }
  getGroupBarChart4(ndx1, chartId, groupProperty, xLabel, yLabel, width, height) {
    const chart = dc.barChart(chartId);

    const barKeyList = ['Budget', 'Actual', 'Month'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
      Month: 'Month'
    };

    const dimension = ndx1.dimension((d: any) => { return d[groupProperty]; });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000) / 4;
        p.Actual += Math.round(v.Actual / 100000) / 4;
        p.Month += +v.Month / 4;


        return p;
      },
      (p: any, v: any) => {
        // p.TotalReq -= +v.TotalReq;
        p.Budget -= +Math.round(v.Budget / 100000) / 4;
        p.Actual -= +Math.round(v.Actual / 100000) / 4;
        p.Month += +v.Month / 4;
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .margins({ left: 50, top: 0, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        // return `${this.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .on('postRender', this.rotateXaxis)
      .group(amountSumGroup, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(amountSumGroup, barKeyList[i], sel_stack(barKeyList[i]));
    }
    chart.render();
    return chart;
  }
  getGroupBarChart5(ndx1, chartId, groupProperty, xLabel, yLabel, width, height) {
    const chart = dc.barChart(chartId);

    const barKeyList = ['Budget', 'Actual', 'Plant'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
      Plant: 'Plant'
    };

    const dimension = ndx1.dimension((d: any) => { return d[groupProperty]; });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000);
        p.Actual += Math.round(v.Actual / 100000);
        p.Function += +v.Function;


        return p;
      },
      (p: any, v: any) => {
        // p.TotalReq -= +v.TotalReq;
        p.Budget -= +Math.round(v.Budget / 100000);
        p.Actual -= +Math.round(v.Actual / 100000);
        p.Function += +v.Function;
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .margins({ left: 50, top: 0, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        // return `${this.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .on('postRender', this.rotateXaxis)
      .group(amountSumGroup, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(amountSumGroup, barKeyList[i], sel_stack(barKeyList[i]));
    }
    chart.render();
    return chart;
  }
  getGroupBarChart6(ndx1, chartId, groupProperty, xLabel, yLabel, width, height) {
    const chart = dc.barChart(chartId);

    const barKeyList = ['Budget', 'Actual', 'Unit'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
      Unit: 'Unit'
    };

    const dimension = ndx1.dimension((d: any) => { return d[groupProperty]; });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000);
        p.Actual += Math.round(v.Actual / 100000);
        p.Function += +v.Function;


        return p;
      },
      (p: any, v: any) => {
        // p.TotalReq -= +v.TotalReq;
        p.Budget -= +Math.round(v.Budget / 100000);
        p.Actual -= +Math.round(v.Actual / 100000);
        p.Function += +v.Function;
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .margins({ left: 50, top: 0, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        // return `${this.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .on('postRender', this.rotateXaxis)
      .group(amountSumGroup, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(amountSumGroup, barKeyList[i], sel_stack(barKeyList[i]));
    }
    chart.render();
    return chart;
  }
  // tslint:disable-next-line: indent
  getGroupBarChart7(ndx1, chartId, groupProperty, xLabel, yLabel, width, height) {
    const chart = dc.barChart(chartId);

    const barKeyList = ['Budget', 'Actual', 'NatureofWork'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
      NatureofWork: 'NatureofWork'
    };

    const dimension = ndx1.dimension((d: any) => { return d[groupProperty]; });
    const amountSumGroup = dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000);
        p.Actual += Math.round(v.Actual / 100000);
        p.NatureofWork += +v.NatureofWork;


        return p;
      },
      (p: any, v: any) => {
        // p.TotalReq -= +v.TotalReq;
        p.Budget -= +Math.round(v.Budget / 100000);
        p.Actual -= +Math.round(v.Actual / 100000);
        p.NatureofWork += +v.NatureofWork;
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0
        };
      });

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .margins({ left: 50, top: 0, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        // return `${this.getLabelValue(d.y, config.labelValueFormatted)} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .on('postRender', this.rotateXaxis)
      .group(amountSumGroup, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(amountSumGroup, barKeyList[i], sel_stack(barKeyList[i]));
    }
    chart.render();
    return chart;
  }


  getGroupBarBudgetChart(ndx1, chartId, groupProperty, xLabel, yLabel, width, height, isQuarter?) {
    const chart = dc.barChart(chartId, 'group2');

    const barKeyList = ['Budget', 'Actual'];
    const barLabelMap = {
      Budget: 'Budget',
      Actual: 'Actual',
    };

    const dimension = ndx1.dimension((d: any) => {
      if (isQuarter) {
        const q = this.quarterMonth[d[groupProperty]];
        return q ? q : d[groupProperty];
      }

      return d[groupProperty];
    });
    const amountSumGroup = this.getGroupByDimensionBudgetAndActual(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.Budget !== 0 && d.value.Actual !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(amountSumGroup);
    // console.log('Budget chart', nonEmptyData.all());
    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .margins({ left: 50, top: 50, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${d.y} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart);
      })
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupBarManPBudgetChart(ndx1, chartId, groupProperty, xLabel, yLabel, width, height, isQuarter?) {
    const chart = dc.barChart(chartId, 'group2');

    const barKeyList = ['Requirement', 'Present'];
    const barLabelMap = {
      Requirement: 'Requirement',
      Present: 'Present',
    };

    const dimension = ndx1.dimension((d: any) => {
      if (isQuarter) {
        const q = this.quarterMonth[d[groupProperty]];
        return q ? q : d[groupProperty];
      }

      return d[groupProperty];
    });
    const amountSumGroup = this.getGroupByDimensionManPBudgetAndActual(dimension);

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    function removeEmptyData(sourceGroup) {
      return {
        all: () => {
          return sourceGroup.all().filter((d) => {
            return d.value.Requirement !== 0 && d.value.y !== 0;
          });
        }
      };
    }

    const nonEmptyData = removeEmptyData(amountSumGroup);

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E'])
      .margins({ left: 50, top: 50, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        if (d.value[this.layer]) {
          // tslint:disable-next-line
          return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        }
        return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${d.y} (${d.data.value[d.layer + 'Pec']}%)`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true).legendText((d) => {
        return barLabelMap[d.name];
      }))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart);
      })
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupBarShiftWiseWeeklyChart(ndx1, chartId, groupProperty, xLabel, yLabel, width, height) {
    const barKeyList = ['A', 'B', 'C', 'GEN'];
    // const barLabelMap = {
    //   A: 'A',
    //   B: 'B',
    //   C: 'C',
    //   GEN: 'GEN',
    // };

    const chart = dc.barChart(chartId, 'group1');
    const dimension = ndx1.dimension((d: any) => {
      return d[groupProperty];
    });
    const amountSumGroup = dimension.group().reduce((p, v) => {
      p[v.Shift] = (p[v.Shift] || 0) + v.Value;
      return p;
    }, (p, v) => {
      p[v.Shift] = (p[v.Shift] || 0) - v.Value;
      return p;
    }, () => ({}));

    function sel_stack(i) {
      return function (d) {
        return d.value[i];
      };
    }

    const nonEmptyData = amountSumGroup;

    chart
      .x(d3.scale.ordinal().domain(dimension)) // old version d3.scale.ordinal() and new verion d3.scaleOrdinal()
      .xUnits(dc.units.ordinal)
      .ordinalColors(['#1F77B4', '#2CA02C', '#FF7F0E', '#FFEE0E'])
      .margins({ left: 50, top: 50, right: 50, bottom: 50 })
      .brushOn(false)
      .clipPadding(10)
      .width(width)
      .height(height)
      .title(function (d, index) {
        return d.key + '[' + this.layer + ']: ' + d.value[this.layer];
        // if (d.value[this.layer]) {
        //   // tslint:disable-next-line
        //   return `${d.key} [${barLabelMap[this.layer]}]: ${d.value[this.layer]} (${d.value[this.layer + 'Pec']}%)`;
        // }
        // return d.key;
      })
      .label((d) => {
        // tslint:disable-next-line
        return `${d.y}`;
      })
      .xAxisLabel(xLabel)
      .yAxisLabel(yLabel)
      .elasticX(true)
      .elasticY(true)
      .groupBars(true)
      .groupGap(5)
      .centerBar(true)
      .dimension(dimension)
      // .legend(dc.legend())
      .legend(dc.legend().x(100).y(2).itemWidth(100).horizontal(true).legendText((d) => {
        return d.name;
      }))
      .on('pretransition', () => {
        this.hideLabel(chart);
      })
      .on('renderlet', (chart) => {
        this.rotateBarLabel(chart);
      })
      .group(nonEmptyData, barKeyList[0], sel_stack(barKeyList[0]));

    for (let i = 1; i < barKeyList.length; ++i) {
      chart.stack(nonEmptyData, barKeyList[i], sel_stack(barKeyList[i]));
    }

    chart.yAxis().tickFormat(this.chartUtilService.tickValueFormat);
    chart.render();
    return chart;
  }

  getGroupByDimensionBudgetAndActual(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        p.Budget += Math.round(v.Budget / 100000);
        p.Actual += Math.round(v.Actual / 100000);

        if (p.Budget > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }

        return p;
      },
      (p: any, v: any) => {
        p.Budget -= +Math.round(v.Budget / 100000);
        p.Actual -= +Math.round(v.Actual / 100000);

        if (p.Budget > 0) {
          p.BudgetPec = 100;
          p.ActualPec = Math.round((p.Actual * 100) / p.Budget);
        }
        return p;
      },
      () => {
        return {
          Budget: 0,
          Actual: 0,
          BudgetPec: 0,
          ActualPec: 0
        };
      });
  }

  getGroupByDimensionManPBudgetAndActual(dimension) {
    return dimension.group().reduce(
      (p: any, v: any) => {
        p.Requirement += Math.round(v.Requirement / 100);
        p.Present += Math.round(v.Present / 100);

        if (p.Requirement > 0) {
          p.RequirementPec = 100;
          p.PresentPec = Math.round((p.Present * 100) / p.Requirement);
        }

        return p;
      },
      (p: any, v: any) => {
        p.Requirement -= +Math.round(v.Requirement / 100);
        p.Present -= +Math.round(v.Present / 100);

        if (p.Requirement > 0) {
          p.RequirementPec = 100;
          p.PresentPec = Math.round((p.Present * 100) / p.Requirement);
        }
        return p;
      },
      () => {
        return {
          Requirement: 0,
          Present: 0,
          RequirementPec: 0,
          PresentPec: 0
        };
      });
  }

  hideLabel = (chart) => {
    chart.selectAll('text.barLabel')
      .attr('class', 'barLabel d-none');
  }

  rotateBarLabel = (chart, config?: Config) => {
    if (!chart.select('.bar')[0][0]) {
      return;
    }

    const y = chart.y();
    const x = chart.x();
    // tslint:disable-next-line
    const barWidth = parseInt(chart.select('.bar')[0][0].getAttribute('width'));
    const gap = chart.gap();
    const groupGap = chart.groupGap();

    // console.log('gap', gap);
    // console.log('groupGap', groupGap);
    // console.log('barWidth', barWidth);

    chart.selectAll('text.barLabel')
      .attr('class', 'barLabel d-none')
      .attr('transform', 'rotate(-90)')
      .attr('y', (d) => {
        let groupBars = 0;
        if (d.layer === 'Actual') {
          groupBars = barWidth + groupGap;
        } else if (d.layer === 'ActualDeployed') {
          groupBars = barWidth + gap;
        } else if (d.layer === 'TotalShortage') {
          groupBars = (barWidth * 2) + gap + groupGap;
        }

        if (d.layer === 'B') {
          groupBars = barWidth + groupGap;
        } else if (d.layer === 'C') {
          groupBars = (barWidth * 2) + gap + groupGap;
        } else if (d.layer === 'GEN') {
          groupBars = (barWidth * 3) + gap + groupGap;
        }
        // console.log('y', x(d.x), groupBars);

        return x(d.x) + (barWidth / 2) + gap + groupGap + groupBars;
      })
      .attr('x', (d) => {
        // console.log('x', -y(0));
        return -y(0) + 40;
      })
      .attr('class', 'barLabel');
  }

}
