import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './calendar.component';
import { ComplianceTasksComponent } from './compliance-tasks/compliance-tasks.component';

const routes: Routes = [
  {
    path: 'calendars',
    component: CalendarComponent
  },
  {
    path: 'compliance-tasks',
    component: ComplianceTasksComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarRoutingModule { }
