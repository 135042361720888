import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GatePassAccociateInformation } from "../gatepass-request-process.model";

@Component({
  selector: 'app-gatepass-job-type',
  templateUrl: './gatepass-job-type.component.html',
  styleUrls: ['./gatepass-job-type.component.css']
})
export class GatepassJobTypeSelectionComponent implements OnInit {

  @Input() public stepData: any;

  @Output() public handleSave: EventEmitter<GatePassAccociateInformation>;
  @Output() public handleClose: EventEmitter<void>;
  @Output() public handlePrevAction: EventEmitter<void>;

  public currentStep: number = 1;

  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.handleSave = new EventEmitter<GatePassAccociateInformation>();
    this.handleClose = new EventEmitter<void>();
    this.handlePrevAction = new EventEmitter<void>();
    this.formGroup = this.buildFormGroup();
  }

  public ngOnInit(): void {
    if (this.stepData) {
      this.formGroup.patchValue({ ...this.stepData });
    }
  }

  public onNextAction(): void {
    this.handleSave.emit(this.formGroup.getRawValue());
  }

  public onPrevAction(): void {
    this.handlePrevAction.emit();
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  private buildFormGroup(): FormGroup {
    return this.fb.group({
      jobType: ['', [Validators.required]]
    })
  }

}