import { Component, OnInit, Input } from '@angular/core';
declare let $;
export class ChecklistItem {
  text: string;
  title: string;
  desc: string;
  isValid: () => boolean;
  hasError: () => boolean;
  isMandatory?: boolean;
  isVisible ?= true;
  step: string;
}
export class Checklist {
  id: number;
  text: string;
  open: boolean;
  childrens: ChecklistItem[];
}

@Component({
  selector: 'app-validation-checklist',
  templateUrl: './validation-checklist.component.html',
  styleUrls: ['./validation-checklist.component.css']
})
export class ValidationChecklistComponent implements OnInit {

  @Input() checklist: Checklist[];
  constructor() { }

  ngOnInit() {
  }

  /**
   * @description toogles checklist accordion
   * @author Amit Mahida
   * @param {*} id
   * @memberof ValidationChecklistComponent
   */
  toggleCheckList(id) {
    $(`#${id}`).slideToggle();
  }

}
