import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from '../app.globals';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { element } from '@angular/core/src/render3';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const httpOptionsform = {
  headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private selectedrowdata: BehaviorSubject<any> = new BehaviorSubject([]);
  public selectedrowdata$ = this.selectedrowdata.asObservable();

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) { }

  getModuleRoleList() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_MODULE_ROLE}`,
      httpOptions
    );
  }

  getModulePageList() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_MODULE_PAGE}`,
      httpOptions
    );
  }

  getPageActionList() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_PAGE_ACTION}`,
      httpOptions
    );
  }

  setModuleWisePageReqParameter(orignalData, columnDefs, data) {
    const finalData = [];
    columnDefs.filter((res) => {
      if (res.field === 'Module') {
        res.children.filter((modules) => {
          const selectedPages = [];
          data.filter((page) => {
            if (page.selectedModuleId.indexOf(modules.colId) >= 0 && page.selectedModuleId.length > 0) {
              selectedPages.push(page.pageId);
            }
          });
          // tslint:disable-next-line:prefer-const
          let masterIndex = _.findIndex(orignalData, ['moduleId', modules.colId]);
          // tslint:disable-next-line:prefer-const
          let moduleData = orignalData[masterIndex];
          // tslint:disable-next-line:prefer-const
          let selectedIds = this.convertStringToInteger(moduleData.selectedPageIds);
          // tslint:disable-next-line:prefer-const
          let newAddedAction = _.differenceWith(moduleData, selectedIds, _.isEqual);
          // tslint:disable-next-line:prefer-const
          let removed = _.differenceWith(selectedIds, selectedPages, _.isEqual);
          const mod = { moduleId: modules.colId, moduleName: modules.headerName };
          mod['newlyAdded'] = newAddedAction;
          mod['deleted'] = removed;
          finalData.push(mod);
        });
      }
      console.log(finalData);
    });
  }

  setModuleRoleManagementParam(orignalData, columnDefs, data) {
    const finalData = [];
    columnDefs.filter((res) => {
      if (res.field === 'Module') {
        res.children.filter((modules) => {
          const selectedRole = [];
          data.filter((role) => {
            if (role.selectedModuleId.indexOf(modules.colId) >= 0 && role.selectedModuleId.length > 0) {
              selectedRole.push(role.pageId);
            }
          });
          // tslint:disable-next-line:prefer-const
          let masterIndex = _.findIndex(orignalData, ['moduleId', modules.colId]);
          // tslint:disable-next-line:prefer-const
          let moduleData = orignalData[masterIndex];
          // tslint:disable-next-line:prefer-const
          let selectedIds = this.convertStringToInteger(moduleData.selectedRoleIds);
          // tslint:disable-next-line:prefer-const
          let newAddedAction = _.differenceWith(selectedRole, selectedIds, _.isEqual);
          // tslint:disable-next-line:prefer-const
          let removed = _.differenceWith(selectedIds, selectedRole, _.isEqual);
          const mod = { moduleId: modules.colId, moduleName: modules.headerName };
          mod['newlyAdded'] = newAddedAction;
          mod['deleted'] = removed;
          finalData.push(mod);
        });
      }
      console.log(finalData);
    });
  }

  setPagewiseActionReqParameter(orignalData, columnDefs, data) {
    const finalData = [];
    columnDefs.filter((res) => {
      if (res.field !== 'Action List') {
        res.children.filter((pages) => {
          const selectedAction = [];
          data.filter((actions) => {
            if (actions.selectedPageId.indexOf(pages.colId) >= 0 && actions.selectedPageId.length > 0) {
              selectedAction.push(actions.actionId);
            }
          });
          // tslint:disable-next-line:prefer-const
          let masterIndex = _.findIndex(orignalData, ['pageId', pages.colId]);
          // tslint:disable-next-line:prefer-const
          let pageData = orignalData[masterIndex];
          // tslint:disable-next-line:prefer-const
          let selectedIds = this.convertStringToInteger(pageData.selectedActionIds);
          // tslint:disable-next-line:prefer-const
          let newAddedAction = _.differenceWith(selectedAction, selectedIds, _.isEqual);
          // tslint:disable-next-line:prefer-const
          let removed = _.differenceWith(selectedIds, selectedAction, _.isEqual);
          const mod = { pageId: pages.colId, pageName: pages.headerName };
          mod['newlyAdded'] = newAddedAction;
          mod['deleted'] = removed;
          finalData.push(mod);
        });
      }
      console.log(finalData);
    });
  }

  getUserRoleList(): Observable<any> {
    // OLD KH 31-05-2020
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_ROLE_LIST}`,
    //   httpOptions
    // );
    // new KH 31-05-2020
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_COM_GetAllRole`;
    return this.httpClient.post(url, '');
    // return this.httpClient.get<any[]>(url).pipe(
    //   map((response: any) => response.Data.Table));
  }

  getOrganizationList() {
    // OLD KH 31-05-2020
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_ORGANIZATION_LIST}`,
    //   httpOptions
    // );
    // new KH 31-05-2020
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_ORGANIZATION_LIST}?Search=`;
    // return this.httpClient.get<any[]>(url).pipe(
    //   map((response: any) => response.Data.Table));
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_COM_GetAllOrganization`;
    return this.httpClient.post(url, '');
  }

  getDesignationList() {
    // OLD KH 31-05-2020
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.MASTER_DATA.GET_DESIGNATION}`,
    //   httpOptions
    // );
    // new KH 31-05-2020
    const url = `${GLOBAL.APIS.LABOUR_MANAGEMENT.GET_ALL_TRADE}`;
    return this.httpClient.get < any[]>(url).pipe(
      map((response: any) => response));


  }

  getUserCode() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_CODE}`,
      httpOptions
    );
  }

  getLanguageData() {
    // return this.httpClient.request(
    //   GLOBAL.HTTP_GET,
    //   `${GLOBAL.APIS.USER_MANAGEMENT.GET_LANGUAGE_DATA}`,
    //   httpOptions
    // );
    // const url = `${GLOBAL.APIS.MASTER_DATA.GET_LANGUAGE_DATA}?Search=`;
    // return this.httpClient.get<any[]>(url).pipe(
    //   map((response: any) => response.Data.Table));
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DATA_BY_SP}SP_COM_GetAllLanguage`;
    return this.httpClient.post(url, '');
  }

  getTimeZoneData() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_TIMEZONE_DATA}`,
      httpOptions
    );
  }

  /**
   * @description add new User
   * @author Milan Goswami
   * @param {*} userParams
   * @returns {Observable<Object>}
   * @memberof UserManagementService
   * @param {*} iscontractor
   */
  addUser(userParams): Observable<Object> {
    const body = JSON.stringify(userParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.USER_MANAGEMENT.CREATE_USER}`,
      httpOptions
    );
  }

  updateUser(userParams): Observable<Object> {
    const body = JSON.stringify(userParams);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.USER_MANAGEMENT.UPDATE_USER}`,
      httpOptions
    );
  }

  actionPermission() {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_ACTION_PERMISSION}`,
      httpOptions
    );
  }

  getUserList(iscontractor: number) {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      // tslint:disable-next-line:max-line-length
      `${GLOBAL.APIS.USER_MANAGEMENT.USER_LIST}?search=&sDate=&eDate=&selectedField=&filterType=&condition=&State_Code=&Status_Code=&Plant_Code=&IsActive=&NatureOfWork_Code=&TotalAccupied=&Contractor_Code=&SubContractor_Code=&WorkOrder_Code=&SubWorkOrder_Code=&isContractorType=&UserId=&isContractor=${iscontractor}`,
      httpOptions
    );
  }

  isLockUser(userId, isLocked) {
    const queryString = $.param({
      userId,
      isLocked
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_ISLOCKED}?${queryString}`,
      httpOptions
    );
  }

  isActiveUser(userId, isActive) {
    const queryString = $.param({
      userId,
      isActive
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_ISACTIVE}?${queryString}`,
      httpOptions
    );
  }

  resetPassword(userId, password) {
    const queryString = $.param({
      userId,
      password
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.RESET_USER_PASSWORD}?${queryString}`,
      httpOptions
    );
  }

  sendMail(userId) {
    const queryString = $.param({
      userId
    });
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.USER_MANAGEMENT.SEND_MAIL_FOR_RESETPASSWORD}?${queryString}`,
      httpOptions
    );
  }

  getUserInformationById(userID) {
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.USER_INFORMATION}?userID=${userID}`,
      httpOptions
    );
  }

  changepassword(userID, oldPassword, newPassword) {
    const queryString = $.param({
      userID,
      oldPassword,
      newPassword
    });
    return this.httpClient.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.USER_MANAGEMENT.CHANGE_USER_PASSWORD}?${queryString}`,
      httpOptions
    );
  }

  convertStringToInteger(str) {
    const output = [];
    if (str !== '') {
      let temp: any;
      temp = str.split(', ');
      temp.filter((element) => {
        output.push(Number(element));
      });
    }
    return output;
  }

  updateAccessInformation(data): Observable<Object> {
    const userId = new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserID');
    const body = new HttpParams().set('Module_Code', data.moduleId ? data.moduleId : '')
      .set('Role_Code', data.roleId ? data.roleId : '')
      .set('Page_Code', data.pageId ? data.pageId : '')
      .set('Action_Code', data.actionId ? data.actionId : '')
      .set('ActionName', data.relation ? data.relation : '')
      .set('IsActive', data.value ? data.value : false)
      .set('UserId', userId);
    return this.httpClient.post(`${GLOBAL.APIS.USER_MANAGEMENT.UPDATE_USER_ACCESS_DATA}`, body, httpOptionsform);
  }

  getDepartmentSectionList(): Observable<any> {
    const model = {
      UserId: localStorage.getItem('UserID'),
      PlantCode: null,
      UnitCode: null,
      DepartmentCode: null
    };
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DEPARTMENT_PLANT_UNIT_SECTION_TABLE}`;
    return this.httpClient.post(url, model);
  }

  setFormData(selecteddata) {
    this.selectedrowdata.next(selecteddata);
  }

  getFormData() {
    return this.selectedrowdata.asObservable();
  }

  addUserfordepartment(selectedRow): Observable<Object> {
    const body = JSON.stringify(selectedRow);
    httpOptions['body'] = body;
    return this.httpClient.request(
      GLOBAL.HTTP_POST,
      `${GLOBAL.APIS.USER_MANAGEMENT.INSERT_DEPARTMENT_PERMISSION}`,
      httpOptions
    );
  }

  public getUserRoles(loggedinUserID): Observable<any[]> {
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_LISING_USERROLES}`;
    return this.httpClient.post(url, { UserID: loggedinUserID }).
      pipe(
        map((response: any) => response));
  }

  public getUserDataAccess(userId: number): Observable<any> {
    const request = {
      Filter: {
        UserId: userId
      },
      Fields: [
        {
          KeyName: 'SectionId3',
          DisplayName: 'selectedRoles'
        }
      ]
    };
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_DATA_ACCESS_RELATION}`;
    return this.httpClient.post(url, request);
  }

  public updateUserAccessDataRelation(data): Observable<any> {
    const request = {
      SectionId3: data.sectionId3,
      Role_Code: data.roleCode,
      UserId: data.userId,
      IsActive: data.value
    };
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.UPDATE_USER_DATA_ACCESS_RELATION}`;
    return this.httpClient.post(url, request);
  }

  public getUserPageAction(userId: number): Observable<any> {
    const request = {
      Filter: {
        UserId: userId
      },
      Fields: [
        {
          KeyName: 'PageId',
          DisplayName: 'selectedActionIds'
        }
      ]
    };
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.GET_USER_PAGE_ACTION_RELATION}`;
    return this.httpClient.post(url, request);
  }

  public updateUserPageAction(data): Observable<any> {
    const request = {
      Module_Code: data.moduleId,
      Page_Code: data.pageId,
      Action_Code: data.actionId,
      UserId: data.userId,
      IsActive: data.value
    };
    const url = `${GLOBAL.APIS.USER_MANAGEMENT.UPDATE_USER_PAGE_ACTION_RELATION}`;
    return this.httpClient.post(url, request);
  }
}
