// import { Injectable } from '@angular/core';
// import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
// import { SessionTimeoutComponent } from './core/components/session-timeout/session-timeout.component';

import { Injectable } from '@angular/core';
// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from './core/components';
import { Router } from '@angular/router';
import { LogHelperService } from './core/services/log-helper.service';
import { SharedService } from './core/services';
import { GLOBAL } from './app.globals';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  totalReq = 0;
  constructor(public loadingService: Ng4LoadingSpinnerService, public router: Router,
    private logHelperService: LogHelperService, private sharedService: SharedService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /**
     * We'll check later for the set token issue in cookies
     */
    // const token: string = this.sharedService.getCookie('token');
    const token: string = localStorage.getItem('token');
    // console.log('token', this.sharedService.checkCookie('token'));

    const ignoreLoader = request.headers.has(GLOBAL.IGNORE_LOADER);

    if (token) {
      // tslint:disable-next-line: no-parameter-reassignment
      // request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }

    if (!request.headers.has('Content-Type')) {
      // tslint:disable-next-line: no-parameter-reassignment
      // request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    // tslint:disable-next-line: no-parameter-reassignment
    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    if (!ignoreLoader) {
      this.loadingService.show();
      // console.log(request);
      // tslint:disable-next-line:no-increment-decrement
      this.totalReq++;
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!ignoreLoader) {
            // tslint:disable-next-line:no-increment-decrement
            this.totalReq--;
            // console.log(this.totalReq);
            // console.log('event--->>>', event);
            if (this.totalReq <= 0) {
              this.loadingService.hide();
            }
          }
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        if (error.error.Message === 'Authorization has been denied for this request.') {
          this.sharedService.deleteCookie('token');
          this.sharedService.deleteCookie('OceanSystem');
          this.logHelperService.logError({ message: 'Token has been expired, Plese login first !' });
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 1000);
        }
        if (!ignoreLoader) {
          this.totalReq--;
          if (this.totalReq <= 0) {
            this.loadingService.hide();
          }
        }
        // this.loadingService.hide();
        // this.totalReq = 0;
        return throwError(error);
      }));
  }
}
