import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
// import { DashboardComponent } from './dashboard.component';
import { YearlyComponent } from './yearly/yearly.component';
import { MonthlyComponent } from './monthly/monthly.component';
import { CoreModule } from '../core/core.module';
import { DashboardService } from './dashboard.service';
import { DashboardComponent } from './dashboard.component';
import { DashboardStatisticsComponent } from './dashboard-statistics/dashboard-statistics.component';
import { ContractorDashboardComponent } from './contractor-dashboard/contractor-dashboard.component';
// tslint:disable-next-line: max-line-length
import { ContractorDashboardGroupRowComponent } from './contractor-dashboard/contractor-dashboard-group-row/contractor-dashboard-group-row.component';

@NgModule({
  declarations: [
    DashboardComponent,
    YearlyComponent,
    MonthlyComponent,
    DashboardStatisticsComponent,
    ContractorDashboardComponent,
    ContractorDashboardGroupRowComponent
  ],
  imports: [
    CommonModule,
    CoreModule.forRoot(),
    DashboardRoutingModule
  ],
  providers: [
    DashboardService,
    DatePipe,
  ],
  entryComponents:[ContractorDashboardGroupRowComponent],
  exports: [
    DashboardRoutingModule,
    DashboardComponent
  ]
})
export class DashboardModule { }
