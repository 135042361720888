import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';

import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from 'src/app/core/components';
import { GridOptions } from 'ag-grid-community';
import { AgInputEditCellRendererComponent } from './../ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component';
import { AgSelectEditCellRendererComponent } from './../ag-select-edit-cell-renderer/ag-select-edit-cell-renderer.component';
import { AgDatePickerEditCellRendererComponent } from './../ag-datepicker-edit-cell-renderer/ag-datepicker-edit-cell-renderer.component';
import { AgIconActionCellRendererComponent } from './../ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
import { CookieService } from 'ngx-cookie-service';
import { ImportExcelService } from '../import-excel.service';
import { UtilService } from 'src/app/core/services/util.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { GLOBAL } from 'src/app/app.globals';
import * as _ from 'lodash';

declare const $: any;

@Component({
  selector: 'app-labour-import-hr',
  templateUrl: './labour-import-hr.component.html',
  styleUrls: ['./labour-import-hr.component.css']
})
export class LabourImportHrComponent implements OnInit {
  @ViewChild('fileInput') fileInput;
  fileError = '';

  public closeModal: EventEmitter<any>;

  maindivclass = 'content Overlapclass';
  userEmail = ''; // GetCokkiesValue(3);
  loginUserId = ''; // GetCokkiesValue(3);
  loading = false;
  showLabourImport = false;
  maxGridHeight = window.innerHeight - 210;
  fullHeight = `${(window.innerHeight - 110)}px`;
  fileObj = null;
  labourData = [];

  // path = globalapipath.replace(globalapipath.substring(globalapipath.length - 5), '');
  // sampleexcelLink = path + '/Documents/LabourExcelFormate.xlsx';

  pincodeMap = {};
  pincodeNotFoundSet = new Set();
  workOrderVendorCodeList = [];
  workOrderVendorCodeLicenseNoList = [];
  workOrderVendorCodeECPolicyNoList = [];
  gatePassNoULC = [];
  gatePassNoULCIdentity = [];
  gatePassNoULCIForNEw = [];
  ulcMap = {};
  ulcNotFoundSet = new Set();
  gatePassMap = {};
  gatePassNotFoundSet = new Set();
  isAdhaarMandatory = true;
  isBankMandatory = false;
  dateOptions = {
    showWeeks: false
  };
  yesNoList = [
    {
      id: 'Yes',
      text: 'Yes'
    },
    {
      id: 'No',
      text: 'No'
    }];

  labourForList = [
    {
      id: 'General',
      text: 'General'
    },
    {
      id: 'Shout Down',
      text: 'Shout Down'
    }];

  commonmachine = [
    {
      id: 'Yes',
      text: 'Yes'
    },
    {
      id: 'No',
      text: 'No'
    }];

  nationalityList = [];

  wageCriteriaList = [
    {
      id: 'Wage / Salary   ≥   21000.00/-   ( EC Policy Applicable)',
      text: 'Wage / Salary   ≥   21000.00/-   ( EC Policy Applicable)'
    },
    {
      id: 'Wage / Salary   <   21000.00/- (ESIC Applicable)',
      text: 'Wage / Salary   <   21000.00/- (ESIC Applicable)'
    }];
  pincodeList = [];
  genderList = [];
  maritalStatusList = [];
  qualificationList = [];
  qulificationStreamList = [];
  technicalQulificationList = [];
  bloodGroupList = [];
  skillList = [];
  tradeList = [];
  relationList = [];
  bankNameList = [];
  workSiteList = [];
  workSiteCategoryList = [];
  castList = [];
  religionList = [];
  plantUnitDepartmentTree = [];
  validityPeriodList = [];
  workmanCategoryList = [];
  siteshiftgrouplist = [];
  show = false;
  downloadSampleList = [
    {
      Name: 'Goa',
      Url: 'assets/sample/import/labour-hr/HR_LabourMasterImportSheet_V3_Goa.xlsx',
    }
  ];
  globalSettingData = [];
  globalDataSampleDownload = [];
  userplantDetail = [];
  allPlantsUser = [];
  DownloadSampleExelShortCode = 'LBRIMPEXL001';

  onInputTextChange = (rowIndex, field) => {
    $('div[uib-tooltip-popup]').remove();
    if (field === 'Qualification') {
      const val = String(this.labourData[rowIndex]['Qualification']);
      let isQualificationIlletrate = false;
      for (let p = 0; p < this.qualificationList.length; p++) {
        if (this.qualificationList[p].id === val) {
          if (this.qualificationList[p].ShortCode === 'GM332') {
            isQualificationIlletrate = true;
          }
          break;
        }
      }
      if (isQualificationIlletrate) {
        for (let p = 0; p < this.qulificationStreamList.length; p++) {
          if (this.qulificationStreamList[p].ShortCode === 'GM815') {
            this.labourData[rowIndex]['QulificationStream'] = String(this.qulificationStreamList[p].id);
            break;
          }
        }

        for (let p = 0; p < this.technicalQulificationList.length; p++) {
          if (this.technicalQulificationList[p].ShortCode === 'GM814') {
            this.labourData[rowIndex]['TechnicalQulification'] = String(this.technicalQulificationList[p].id);
            break;
          }
        }
      }
    }
    if (field === 'Pincode') {
      this.labourData[rowIndex]['VillageCode'] = null;
      // this.labourData[rowIndex]['VillageCode'] = null;
      // const val = this.labourData[rowIndex]['Pincode'] || '';
      // if (val !== '') {
      //   for (let p = 0; p < this.pincodeList.length; p++) {
      //     if (this.pincodeList[p].text.toLowerCase() === val.toLowerCase()) {
      //       this.labourData[rowIndex]['VillageCode'] = this.pincodeList[p].id;
      //       break;
      //     }
      //   }
      // }
    }

    if (field === 'PermanentPincode') {
      this.labourData[rowIndex]['PermanentVillageCode'] = null;
      // const val = this.labourData[rowIndex]['PermanentPincode'] || '';
      // if (val !== '') {
      //   for (let p = 0; p < this.pincodeList.length; p++) {
      //     if (this.pincodeList[p].text.toLowerCase() === val.toLowerCase()) {
      //       this.labourData[rowIndex]['PermanentVillageCode'] = this.pincodeList[p].id;
      //       break;
      //     }
      //   }
      // }
    }

    if (field === 'RPincode') {
      this.labourData[rowIndex]['RVillageCode'] = null;
    }

    if (field === 'EPincode') {
      this.labourData[rowIndex]['EVillageCode'] = null;
    }

    if (field === 'Plant_Code') {
      const row = this.labourData[rowIndex];
      row.Unit_Code = null;
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === 'Unit_Code') {
      const row = this.labourData[rowIndex];
      row.Department_Code = null;
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === 'Department_Code') {
      const row = this.labourData[rowIndex];
      row.SectionPlantRelationId1 = null;
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === 'SectionPlantRelationId1') {
      const row = this.labourData[rowIndex];
      row.SectionPlantRelationId2 = null;
      row.SectionPlantRelationId3 = null;
    }

    if (field === 'SectionPlantRelationId2') {
      const row = this.labourData[rowIndex];
      row.SectionPlantRelationId3 = null;
    }

    if (field === 'SectionPlantRelationId3') {
    }

    const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    const rowNodes = [row]; // params needs an array
    // const params = {
    //   force: true,
    //   rowNodes: rowNodes,
    //   columns: [field]
    // };
    // this.gridOptions.api.refreshCells(params);
    // tslint:disable-next-line
    this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    // this.gridOptions.api.refreshCells({ rowNodes: rowNodes, columns: [field], force: true });
  }

  isColValid = (rowIndex, field) => {
    const returnObj = {
      isValid: true,
      requireValidation: false,
      msg: ''
    };
    let val = '';
    const requireValidationMessage = 'Validation required';

    const vendorCode = this.labourData[rowIndex]['VendorCode'];
    const workOrder = this.labourData[rowIndex]['WorkOrder'];
    const licenseNo = this.labourData[rowIndex]['LicenseNo'];
    const ecPolicyNo = this.labourData[rowIndex]['ECPolicyNo'];
    const plantCode = this.labourData[rowIndex]['Plant_Code'];
    const ulc = this.labourData[rowIndex]['ULC'];
    const adhaarCardNumber = this.labourData[rowIndex]['AdhaarCardNumber'];
    const gatepassNo = this.labourData[rowIndex]['GatepassNo'];
    const wageCriteria = this.labourData[rowIndex]['WageCriteria'];

    switch (field) {
      case 'VendorCode':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select ${field}.`;
        } else {
          const obj = this.workOrderVendorCodeList.find(tmp => tmp.VendorCode === vendorCode && tmp.WorkOrder === workOrder);
          if (!obj) {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          } else if (!obj.IsContractorValid) {
            returnObj.isValid = false;
            returnObj.msg = `Please enter valid Vendor Code.`;
          }
        }
        break;
      case 'WorkOrder':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select Work Order.`;
        } else {
          const obj = this.workOrderVendorCodeList.find(tmp => tmp.VendorCode === vendorCode && tmp.WorkOrder === workOrder);
          if (!obj) {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          } else if (!obj.IsWorkOrderValid) {
            returnObj.isValid = false;
            returnObj.msg = `Please enter valid Work Order.`;
          }
        }
        break;
      case 'LicenseNo':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = true;
          // returnObj.msg = `Please select ${field}.`;
        } else {
          const obj = this.workOrderVendorCodeLicenseNoList.find((tmp) => {
            return tmp.VendorCode === vendorCode && tmp.WorkOrder === workOrder && tmp.LicenseNo === licenseNo;
          });
          if (!obj) {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          } else if (!obj.IsLicenseValid) {
            returnObj.isValid = false;
            returnObj.msg = `Please enter valid License No.`;
          } else if (!obj.IsMapped) {
            returnObj.isValid = false;
            returnObj.msg = `License No not mapped with Vendore Code and Work Order.`;
          }
        }
        break;
      case 'ESICNo':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          if (wageCriteria === 'Wage / Salary   <   21000.00/- (ESIC Applicable)') {
            returnObj.isValid = false;
            returnObj.msg = `Please select ${field}.`;
          }
        }
        break;
      case 'ECPolicyNo':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          if (wageCriteria === 'Wage / Salary   ≥   21000.00/-   ( EC Policy Applicable)') {
            returnObj.isValid = false;
            returnObj.msg = `Please select ${field}.`;
          }
        } else {
          const obj = this.workOrderVendorCodeECPolicyNoList.find((tmp) => {
            return tmp.VendorCode === vendorCode && tmp.WorkOrder === workOrder
              && tmp.ECPolicyNo === ecPolicyNo && tmp.Plant_Code === plantCode;
          });
          if (!obj) {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          } else if (!obj.IsECPolicyValid) {
            returnObj.isValid = false;
            returnObj.msg = `Please enter valid EC Policy.`;
          } else if (!obj.IsMapped) {
            returnObj.isValid = false;
            returnObj.msg = `License No not mapped with Work Order and ECPolicyNo`;
          }
        }
        break;
      case 'ULC':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter ULC.';
          // } else if (this.labourData[rowIndex]['PermanentVillageCode'] === null) {
        }
        // else if (!this.ulcMap[val]) {
        //   returnObj.isValid = false;
        //   // if (this.ulcNotFoundSet.has(val)) {
        //   //   // if (this.gatePassNotFoundSet.has(gatepassNo)) {
        //   //   //   returnObj.isValid = true;
        //   //   //   returnObj.msg = '';
        //   //   // } else {
        //   //   //   returnObj.msg = ' ULC not found in master data. Please contract administrator or change ULC.';
        //   //   // }
        //   // } else {
        //   //   returnObj.isValid = false;
        //   //   returnObj.requireValidation = true;
        //   //   returnObj.msg = requireValidationMessage;
        //   // }
        // }
        break;
      case 'GatepassNo':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Gatepass No.';
          // } else if (this.labourData[rowIndex]['PermanentVillageCode'] === null) {
        } else {
          const obj = this.gatePassNoULCIForNEw.find((tmp) => {
            return tmp.ULC === ulc;
          });
          if (!obj) {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          } else if (!obj.IsValid) {
            returnObj.isValid = false;
            returnObj.msg = 'Code already exist but not map with UAC.';
          }
        }
        // tslint:disable-next-line: brace-style
        break;
      case 'Plant_Code':
      case 'Unit_Code':
      case 'Department_Code':
      case 'SectionPlantRelationId1':
      case 'SectionPlantRelationId2':
      case 'SectionPlantRelationId3':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = `Please select ${field}.`;
        }
        break;
      case 'WageCriteria':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Wage Criteria.';
        }
        break;
      // case 'VendorCode':
      //   val = this.labourData[rowIndex][field] || '';
      //   if (val === '') {
      //     returnObj.isValid = false;
      //     returnObj.msg = 'Please enter Vendor Code.';
      //   }
      //   break;
      case 'ContractorName':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Contractor Name.';
        }
        break;
      // case 'WONo':
      //   val = this.labourData[rowIndex][field] || '';
      //   if (val === '') {
      //     returnObj.isValid = false;
      //     returnObj.msg = 'Please enter WO No.';
      //   }
      //   break;
      case 'LabourDemandFor':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Labour Demand For.';
        }
        break;
      case 'Nationality':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Nationality.';
        }
        break;
      case 'WorkSite':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Work Site.';
        }
        break;
      case 'WorkCategory':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Work Category.';
        }
        break;
      case 'ValidityPeriod':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Validity Period.';
        }
        break;
      case 'WorkmanCategory':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Workman Category.';
        }
        break;
      case 'SiteShiftGroup':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter SiteShiftGroup.';
        }
        break;
      case 'PassportNumber':
        val = this.labourData[rowIndex][field] || '';
        if (this.labourData[rowIndex]['Nationality'] === '569' && val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Passport Number.';
        }
        break;
      case 'NameInPassport':
        val = this.labourData[rowIndex][field] || '';
        if (this.labourData[rowIndex]['Nationality'] === '569' && val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Name in Passport.';
        }
        break;
      case 'PassportRegistraionDate':
        val = this.labourData[rowIndex][field] || '';
        if (this.labourData[rowIndex]['Nationality'] === '569' && val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Passport Registraion Date.';
        }
        break;
      case 'PassportExpireDate':
        val = this.labourData[rowIndex][field] || '';
        if (this.labourData[rowIndex]['Nationality'] === '569' && val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Passport Expire Date.';
        }
        break;
      case 'FirstName':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter First Name.';
        }
        break;
      case 'FatherHusbandName':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Father/Husband Name.';
        }
        break;
      case 'Relation':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter relation.';
        }
        break;
      case 'LastName':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Last Name.';
        }
        break;
      case 'BirthDate':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Birth Date.';
        } else {
          const valArr = val.split('-');
          const forDate = `${valArr[2]}-${valArr[1]}-${valArr[0]}`;
          const years = moment().diff(forDate, 'years');
          if (years < 18 || years > 65) {
            returnObj.isValid = false;
            returnObj.msg = 'age should be between 18 and 65 years.';
          }
        }
        break;
      case 'JoiningDate':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Joining Date.';
        }
        break;
      case 'Gender':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter gender.';
        }
        break;
      case 'maritalStatus':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Marital Status.';
        }
        break;
      // case 'PoliceVerificationDate':
      //   val = this.labourData[rowIndex][field] || '';
      //   if (val === '') {
      //     const passportNumber = this.labourData[rowIndex]['PassportNumber'] || '';
      //     const passportRegistraionDate = this.labourData[rowIndex]['PassportRegistraionDate'] || '';
      //     const passportExpireDate = this.labourData[rowIndex]['PassportExpireDate'] || '';
      //     if (passportNumber === '' || passportRegistraionDate === '' && passportExpireDate === '') {
      //       returnObj.isValid = false;
      //       returnObj.msg = 'Please enter Police Verification Date.';
      //     }
      //   }
      //   break;
      case 'PermanentAddress':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Permanent Address.';
        }
        break;
      case 'PermanentLandMark':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Permanent Land Mark.';
        }
        break;
      case 'PermanentArea':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Permanent Area.';
        }
        break;
      case 'PermanentPincode':
        this.labourData[rowIndex]['PermanentState'] = '';
        this.labourData[rowIndex]['PermanentDistct'] = '';
        this.labourData[rowIndex]['PermanentCity'] = '';
        this.labourData[rowIndex]['PermanentStateCode'] = '';
        this.labourData[rowIndex]['PermanentCityCode'] = '';
        this.labourData[rowIndex]['PermanentDistctCode'] = '';
        this.labourData[rowIndex]['PermanentLocationList'] = [];
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Permanent Pincode.';
          // } else if (this.labourData[rowIndex]['PermanentVillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg = ' Pin code not found in master data. Please contract administrator or change pin code.';
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]['PermanentState'] = pincodeDetail.State_Name;
          this.labourData[rowIndex]['PermanentDistct'] = pincodeDetail.Distct_Name;
          this.labourData[rowIndex]['PermanentCity'] = pincodeDetail.City_Name;
          this.labourData[rowIndex]['PermanentStateCode'] = pincodeDetail.State_Code;
          this.labourData[rowIndex]['PermanentCityCode'] = pincodeDetail.City_Code;
          this.labourData[rowIndex]['PermanentDistctCode'] = pincodeDetail.Distct_Code;
          this.labourData[rowIndex]['PermanentLocationList'] = pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]['PermanentVillageCode'] = pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case 'LocalAddress':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Local Address.';
        }
        break;
      case 'LocalLandMark':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Local Land Mark.';
        }
        break;
      case 'LocalArea':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Local Area.';
        }
        break;
      case 'Pincode':
        this.labourData[rowIndex]['State'] = '';
        this.labourData[rowIndex]['Distct'] = '';
        this.labourData[rowIndex]['City'] = '';
        this.labourData[rowIndex]['StateCode'] = '';
        this.labourData[rowIndex]['CityCode'] = '';
        this.labourData[rowIndex]['DistctCode'] = '';
        this.labourData[rowIndex]['LocationList'] = [];
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Pincode.';
          // } else if (this.labourData[rowIndex]['VillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg = ' Pin code not found in master data. Please contract administrator or change pin code.';
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]['State'] = pincodeDetail.State_Name;
          this.labourData[rowIndex]['Distct'] = pincodeDetail.Distct_Name;
          this.labourData[rowIndex]['City'] = pincodeDetail.City_Name;
          this.labourData[rowIndex]['StateCode'] = pincodeDetail.State_Code;
          this.labourData[rowIndex]['CityCode'] = pincodeDetail.City_Code;
          this.labourData[rowIndex]['DistctCode'] = pincodeDetail.Distct_Code;
          this.labourData[rowIndex]['LocationList'] = pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]['VillageCode'] = pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case 'Qualification':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Qualification.';
        }
        break;
      case 'QulificationStream':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Qulification Stream.';
        }
        break;
      case 'TechnicalQulification':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Technical Qulification.';
        }
        break;
      case 'Cast':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Cast.';
        }
        break;
      case 'Religion':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Religion.';
        }
        break;
      case 'AdhaarCardNumber':
        if (this.isAdhaarMandatory) {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Aadhaar Card Number.';
          } else {
            const obj = this.gatePassNoULCIdentity.find((tmp) => {
              return tmp.AdhaarCardNumber === adhaarCardNumber;
            });
            if (!obj) {
              returnObj.isValid = false;
              returnObj.requireValidation = true;
              returnObj.msg = requireValidationMessage;
            } else if (!(/^\d+$/.test(val))) {
              returnObj.isValid = false;
              returnObj.msg = 'Aadhaar card number should not contain characters.';
            } else if (val.length !== 12) {
              returnObj.isValid = false;
              returnObj.msg = 'Aadhaar card number should be 12 digit number.';
            } else if (!obj.IsValid) {
              returnObj.isValid = false;
              returnObj.msg = ' UAC already exist but Aadhaar card does not match with existing UAC';
            }
          }
        }
        break;
      case 'NameInAdhaarCard':
        if (this.isAdhaarMandatory) {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Name In Adhaar Card.';
          }
        }
        break;
      case 'BankName':
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Bank Name.';
          }
        }
        break;
      case 'BankBranch':
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Bank Branch.';
          }
        }
        break;
      case 'AccountNumber':
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Account Number.';
          }
        }
        break;
      case 'IFSCCode':
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter IFSC Code.';
          }
        }
        break;
      case 'NameAsPerBankRecord':
        if (this.isBankMandatory) {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Name As Per Bank Record.';
          }
        }
        break;
      // case 'BloodGroup':
      //   val = this.labourData[rowIndex][field] || '';
      //   if (val === '') {
      //     returnObj.isValid = false;
      //     returnObj.msg = 'Please enter Blood Group.';
      //   }
      //   break;
      case 'SkillType':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Skill Type.';
        }
        break;
      case 'Trade':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Trade.';
        }
        break;
      case 'CommonMachine':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please select Common Machine.';
        }
        break;
      case 'DrivingLicenseNumber':
        val = this.labourData[rowIndex][field] || '';
        if ((this.labourData[rowIndex]['WorkCategory'] === 'Driver'
          || this.labourData[rowIndex]['WorkCategory'] === 'Crain Operator') && val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Driving License Number.';
        }
        break;
      case 'NameInDrivingLicense':
        val = this.labourData[rowIndex][field] || '';
        if ((this.labourData[rowIndex]['WorkCategory'] === 'Driver'
          || this.labourData[rowIndex]['WorkCategory'] === 'Crain Operator') && val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Name In Driving License.';
        }
        break;
      case 'DrivingLicenseExpireDate':
        val = this.labourData[rowIndex][field] || '';
        if ((this.labourData[rowIndex]['WorkCategory'] === 'Driver'
          || this.labourData[rowIndex]['WorkCategory'] === 'Crain Operator') && val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Driving License Expire Date.';
        }
        break;
      case 'RPincode':
        this.labourData[rowIndex]['RState'] = '';
        this.labourData[rowIndex]['RDistct'] = '';
        this.labourData[rowIndex]['RCity'] = '';
        this.labourData[rowIndex]['RStateCode'] = '';
        this.labourData[rowIndex]['RCityCode'] = '';
        this.labourData[rowIndex]['RDistctCode'] = '';
        this.labourData[rowIndex]['RLocationList'] = [];
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          // returnObj.isValid = false;
          // returnObj.msg = 'Please enter Pincode.';
          // } else if (this.labourData[rowIndex]['RVillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg = ' Pin code not found in master data. Please contract administrator or change pin code.';
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]['RState'] = pincodeDetail.State_Name;
          this.labourData[rowIndex]['RDistct'] = pincodeDetail.Distct_Name;
          this.labourData[rowIndex]['RCity'] = pincodeDetail.City_Name;
          this.labourData[rowIndex]['RStateCode'] = pincodeDetail.State_Code;
          this.labourData[rowIndex]['RCityCode'] = pincodeDetail.City_Code;
          this.labourData[rowIndex]['RDistctCode'] = pincodeDetail.Distct_Code;
          this.labourData[rowIndex]['RLocationList'] = pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]['RVillageCode'] = pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case 'EPincode':
        this.labourData[rowIndex]['EState'] = '';
        this.labourData[rowIndex]['EDistct'] = '';
        this.labourData[rowIndex]['ECity'] = '';
        this.labourData[rowIndex]['EStateCode'] = '';
        this.labourData[rowIndex]['ECityCode'] = '';
        this.labourData[rowIndex]['EDistctCode'] = '';
        this.labourData[rowIndex]['ELocationList'] = [];
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          // returnObj.isValid = false;
          // returnObj.msg = 'Please enter Pincode.';
          // } else if (this.labourData[rowIndex]['EVillageCode'] === null) {
        } else if (!this.pincodeMap[val]) {
          returnObj.isValid = false;
          if (this.pincodeNotFoundSet.has(val)) {
            returnObj.msg = ' Pin code not found in master data. Please contract administrator or change pin code.';
          } else {
            returnObj.isValid = false;
            returnObj.requireValidation = true;
            returnObj.msg = requireValidationMessage;
          }
          // } else {
          //   this.getPincodeDetails([val], rowIndex, field);
          //   returnObj.msg = ' Validation pincode, Please wait.';
          // }
        } else {
          const pincodeDetail = this.pincodeMap[val];
          this.labourData[rowIndex]['EState'] = pincodeDetail.State_Name;
          this.labourData[rowIndex]['EDistct'] = pincodeDetail.Distct_Name;
          this.labourData[rowIndex]['ECity'] = pincodeDetail.City_Name;
          this.labourData[rowIndex]['EStateCode'] = pincodeDetail.State_Code;
          this.labourData[rowIndex]['ECityCode'] = pincodeDetail.City_Code;
          this.labourData[rowIndex]['EDistctCode'] = pincodeDetail.Distct_Code;
          this.labourData[rowIndex]['ELocationList'] = pincodeDetail.Locations;
          if (pincodeDetail.Locations.length === 1) {
            this.labourData[rowIndex]['EVillageCode'] = pincodeDetail.Locations[0].Village_Code;
          }
        }
        break;
      case 'PermanentVillageCode':
      case 'VillageCode':
        val = this.labourData[rowIndex][field] || '';
        if (val === '') {
          returnObj.isValid = false;
          returnObj.msg = 'Please enter Village.';
        }
        break;
      case 'RVillageCode':
        val = this.labourData[rowIndex]['RPincode'] || '';
        if (val !== '') {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Village.';
          }
        }
        break;
      case 'EVillageCode':
        val = this.labourData[rowIndex]['EPincode'] || '';
        if (val !== '') {
          val = this.labourData[rowIndex][field] || '';
          if (val === '') {
            returnObj.isValid = false;
            returnObj.msg = 'Please enter Village.';
          }
        }
        break;
      default:
        break;
    }

    return returnObj;
  }

  isOneAdressProofAvailable = (rowIndex) => {
    const returnObj = {
      isValid: true,
      msg: ''
    };

    const panNo = this.labourData[rowIndex]['PANNo'] || '';
    const nameInPAN = this.labourData[rowIndex]['NameInPAN'] || '';
    if (panNo !== '' && nameInPAN !== '') {
      return returnObj;
    }

    const adhaarCardNumber = this.labourData[rowIndex]['AdhaarCardNumber'] || '';
    const nameInAdhaarCard = this.labourData[rowIndex]['NameInAdhaarCard'] || '';
    if (adhaarCardNumber !== '' && nameInAdhaarCard !== '') {
      return returnObj;
    }

    const voterIDNumber = this.labourData[rowIndex]['VoterIDNumber'] || '';
    const nameInVoterID = this.labourData[rowIndex]['NameInVoterID'] || '';
    if (voterIDNumber !== '' && nameInVoterID !== '') {
      return returnObj;
    }

    const rationCardNumber = this.labourData[rowIndex]['RationCardNumber'] || '';
    const nameInRationCard = this.labourData[rowIndex]['NameInRationCard'] || '';
    if (rationCardNumber !== '' && nameInRationCard !== '') {
      return returnObj;
    }

    const drivingLicenseNumber = this.labourData[rowIndex]['DrivingLicenseNumber'] || '';
    const nameInDrivingLicense = this.labourData[rowIndex]['NameInDrivingLicense'] || '';
    const drivingLicenseExpireDate = this.labourData[rowIndex]['DrivingLicenseExpireDate'] || '';
    if (drivingLicenseNumber !== '' && nameInDrivingLicense !== '' && drivingLicenseExpireDate !== '') {
      return returnObj;
    }

    const passportNumber = this.labourData[rowIndex]['PassportNumber'] || '';
    const passportRegistraionDate = this.labourData[rowIndex]['PassportRegistraionDate'] || '';
    const passportExpireDate = this.labourData[rowIndex]['PassportExpireDate'] || '';
    if (passportNumber !== '' && passportRegistraionDate !== '' && passportExpireDate !== '') {
      return returnObj;
    }

    returnObj.isValid = false;
    returnObj.msg = 'Atleast one address proof detail is required.';
    return returnObj;
  }

  deleteRow = (rowIndex) => {
    this.labourData.splice(rowIndex, 1);
    this.gridOptions.api.setRowData(this.labourData);
  }

  getPlantList = (rowIndex, field) => {
    return this.plantUnitDepartmentTree.map((item) => {
      return { id: item.Plantcode0, text: item.Plant0 };
    });
  }

  getUnitList = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .map((item) => {
        return { id: item.Unitcode0, text: item.Unit0 };
      });
  }

  getDepartmentList = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .map((item) => {
        return { id: item.UnitDeptID0, text: item.Department0 };
      });
  }

  getSection1List = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .find(item => item.UnitDeptID0 === row.Department_Code).Values
      .map((item) => {
        return { id: item.Sectioncode0, text: item.SectionName0 };
      });
  }

  getSection2List = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code || !row.SectionPlantRelationId1) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .find(item => item.UnitDeptID0 === row.Department_Code).Values
      .find(item => item.Sectioncode0 === row.SectionPlantRelationId1).Values
      .map((item) => {
        return { id: item.Sectioncode1, text: item.SectionName1 };
      });
  }

  getSection3List = (rowIndex, field) => {
    const row = this.labourData[rowIndex];

    if (!row.Plant_Code || !row.Unit_Code || !row.Department_Code || !row.SectionPlantRelationId1 || !row.SectionPlantRelationId2) {
      return [];
    }

    row.Plant_Code = parseInt(row.Plant_Code, 10);
    row.Unit_Code = parseInt(row.Unit_Code, 10);
    row.Department_Code = parseInt(row.Department_Code, 10);
    row.SectionPlantRelationId1 = parseInt(row.SectionPlantRelationId1, 10);
    row.SectionPlantRelationId2 = parseInt(row.SectionPlantRelationId2, 10);

    return this.plantUnitDepartmentTree
      .find(item => item.Plantcode0 === row.Plant_Code).Values
      .find(item => item.Unitcode0 === row.Unit_Code).Values
      .find(item => item.UnitDeptID0 === row.Department_Code).Values
      .find(item => item.Sectioncode0 === row.SectionPlantRelationId1).Values
      .find(item => item.Sectioncode1 === row.SectionPlantRelationId2).Values
      .map((item) => {
        return { id: item.LevelID2, text: item.SectionName2 };
      });
  }

  getLocationList = (rowIndex, field) => {
    const row = this.labourData[rowIndex];
    let list = [];
    switch (field) {
      case 'PermanentVillageCode':
        list = row['PermanentLocationList'];
        break;
      case 'VillageCode':
        list = row['LocationList'];
        break;
      case 'RVillageCode':
        list = row['RLocationList'];
        break;
      case 'EVillageCode':
        list = row['ELocationList'];
        break;
    }

    return list.map((item) => {
      return { id: item.Village_Code, text: item.Village_Name };
    });
  }

  // tslint:disable-next-line
  columnDefs = [
    {
      headerName: '', field: 'act', width: 30, minWidth: 30,
      cellRendererFramework: AgIconActionCellRendererComponent,
      cellRendererParams: {
        onClick: this.deleteRow,
        title: 'Delete',
        width: 30,
        icon: 'fa fa-times',
        cssClass: 'row-delete-icon',
        fontSize: 24,
      }, pinned: 'left'
    },
    {
      headerName: 'Company',
      children: [
        {
          headerName: 'Plant', field: 'Plant_Code', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getPlantList,
          }
        },
        {
          headerName: 'Unit', field: 'Unit_Code', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getUnitList,
          }
        },
        {
          headerName: 'Department', field: 'Department_Code', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getDepartmentList,
          }
        },
        {
          headerName: 'Section', field: 'SectionPlantRelationId1', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getSection1List,
          }
        },
        {
          headerName: 'Sub-Section 1', field: 'SectionPlantRelationId2', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getSection2List,
          }
        },
        {
          headerName: 'Sub-Section 2', field: 'SectionPlantRelationId3', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getSection3List,
          }
        },
      ]
    },
    {
      headerName: 'Contractor Details',
      children: [
        {
          headerName: 'Contrctor', field: 'Contrctor', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'VendorCode', field: 'VendorCode', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'WorkOrder', field: 'WorkOrder', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'License No', field: 'LicenseNo', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'EC Policy No', field: 'ECPolicyNo', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
      ]
    },
    {
      headerName: 'Labour Information',
      children: [
        {
          headerName: 'ULC', field: 'ULC', width: 100, minWidth: 100, pinned: 'left',
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Gatepass No', field: 'GatepassNo', width: 100, minWidth: 100, pinned: 'left',
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'First Name', field: 'FirstName', width: 100, minWidth: 100, pinned: 'left',
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Father/Husband Name', field: 'FatherHusbandName', headerClass: 'multi-header', width: 180, minWidth: 180,
          pinned: 'left', cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Relation', field: 'Relation', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.relationList,
          }
        },
        {
          headerName: 'Last Name', field: 'LastName', width: 100, minWidth: 100, pinned: 'left',
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Birth Date', field: 'BirthDate', width: 120, minWidth: 120,
          cellRendererFramework: AgDatePickerEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Joining Date', field: 'JoiningDate', width: 120, minWidth: 120,
          cellRendererFramework: AgDatePickerEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Gender', field: 'Gender', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.genderList,
          }
        },
        {
          headerName: 'Marital Status', field: 'MaritalStatus', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.maritalStatusList,
          }
        },
        {
          headerName: 'Mobile', field: 'Mobile', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Police Verification Date', field: 'PoliceVerificationDate', width: 120, minWidth: 120, headerClass: 'multi-header',
          cellRendererFramework: AgDatePickerEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'P-Address', field: 'PermanentAddress', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'P-Land Mark', field: 'PermanentLandMark', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'P-Area', field: 'PermanentArea', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'P-Pincode', field: 'PermanentPincode', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'P-Village', field: 'PermanentVillageCode', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getLocationList,
          }
        },
        {
          headerName: 'P-State', field: 'PermanentState', width: 100, minWidth: 100,
        },
        {
          headerName: 'P-Distct', field: 'PermanentDistct', width: 100, minWidth: 100,
        },
        {
          headerName: 'P-City', field: 'PermanentCity', width: 100, minWidth: 100,
        },
        {
          headerName: 'L-Address', field: 'LocalAddress', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'L-Land Mark', field: 'LocalLandMark', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'L-Area', field: 'LocalArea', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Pincode', field: 'Pincode', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Village', field: 'VillageCode', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getLocationList,
          }
        },
        {
          headerName: 'State', field: 'State', width: 100, minWidth: 100,
        },
        {
          headerName: 'Distct', field: 'Distct', width: 100, minWidth: 100,
        },
        {
          headerName: 'City', field: 'City', width: 100, minWidth: 100,
        },
        {
          headerName: 'Qualification', field: 'Qualification', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.qualificationList,
          }
        },
        {
          headerName: 'Qulification Stream', field: 'QulificationStream', width: 100, minWidth: 100, headerClass: 'multi-header',
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.qulificationStreamList,
          }
        },
        {
          headerName: 'Technical Qulification', field: 'TechnicalQulification', width: 100, minWidth: 100, headerClass: 'multi-header',
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.technicalQulificationList,
          }
        },
        {
          headerName: 'Cast', field: 'Cast', width: 100, minWidth: 100, headerClass: 'multi-header',
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.castList,
          }
        },
        {
          headerName: 'Religion', field: 'Religion', width: 100, minWidth: 100, headerClass: 'multi-header',
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.religionList,
          }
        }
      ]
    },
    {
      headerName: 'Compliance Details',
      children: [
        {
          headerName: 'Wage Criteria', field: 'WageCriteria', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.wageCriteriaList,
          }
        },
        {
          headerName: 'ESIC No', field: 'ESICNo', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'PF No', field: 'PFNo', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'UAN Number', field: 'UANNumber', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'DGMS No', field: 'DGMSNo', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Labour Demand For', field: 'LabourDemandFor', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.labourForList,
          }
        },
        {
          headerName: 'Nationality', field: 'Nationality', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.nationalityList,
          }
        },
        {
          headerName: 'Work Site', field: 'WorkSite', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.workSiteList,
          }
        },
        {
          headerName: 'Work Category', field: 'WorkCategory', headerClass: 'multi-header', width: 150, minWidth: 150,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.workSiteCategoryList,
          }
        },
        {
          headerName: 'Validity Period', field: 'ValidityPeriod', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.validityPeriodList,
          }
        },
        {
          headerName: 'Associate Category', field: 'WorkmanCategory', width: 150, minWidth: 150,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.workmanCategoryList,
          }
        },
        {
          headerName: 'Site Shift Group', field: 'SiteShiftGroup', width: 150, minWidth: 150,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.siteshiftgrouplist,
          }
        }
      ]
    },
    {
      headerName: 'Labour Identity Details',
      children: [
        {
          headerName: 'PAN No', field: 'PANNo', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'NameIn PAN', field: 'NameInPAN', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Adhaar Card Number', field: 'AdhaarCardNumber', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Name In Adhaar Card', field: 'NameInAdhaarCard', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Voter-IDNumber', field: 'VoterIDNumber', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Name In Voter-ID', field: 'NameInVoterID', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Ration Card Number', field: 'RationCardNumber', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Name In Ration Card', field: 'NameInRationCard', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'License Batch Number', field: 'LicenseBatchNumber', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Driving License Number', field: 'DrivingLicenseNumber', headerClass: 'multi-header', width: 130, minWidth: 130,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Name In Driving License', field: 'NameInDrivingLicense', headerClass: 'multi-header', width: 150, minWidth: 150,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Driving License Expire Date', field: 'DrivingLicenseExpireDate',
          headerClass: 'multi-header', width: 120, minWidth: 120,
          cellRendererFramework: AgDatePickerEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Passport Number', field: 'PassportNumber', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Name in Passport', field: 'NameInPassport', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Passport Registraion Date', field: 'PassportRegistraionDate', headerClass: 'multi-header', width: 120, minWidth: 120,
          cellRendererFramework: AgDatePickerEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Passport Expire Date', field: 'PassportExpireDate', width: 120, minWidth: 120,
          cellRendererFramework: AgDatePickerEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        }
      ]
    },
    {
      headerName: 'Labour Bank Details',
      children: [
        {
          headerName: 'Bank Name', field: 'BankName', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.bankNameList,
          }
        },
        {
          headerName: 'Bank Branch', field: 'BankBranch', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Account Number', field: 'AccountNumber', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'IFSC Code', field: 'IFSCCode', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Name As Per Bank Record', field: 'NameAsPerBankRecord', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        }
      ]
    },
    {
      headerName: '',
      children: [
        {
          headerName: 'Blood Group', field: 'BloodGroup', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.bloodGroupList,
          }
        },
        {
          headerName: 'Skill Type', field: 'SkillType', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.skillList,
          }
        },
        {
          headerName: 'Trade', field: 'Trade', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.tradeList,
          }
        },
        {
          headerName: 'Common Machine', field: 'CommonMachine', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataList: () => this.commonmachine,
          }
        },
      ]
    },
    {
      headerName: 'Referance Contact Details',
      children: [
        {
          headerName: 'Full Name', field: 'RFullName', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Relation', field: 'RRelation', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Address', field: 'RAddress', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Land Mark', field: 'RLandMark', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Area', field: 'RArea', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Pincode', field: 'RPincode', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Village', field: 'RVillageCode', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getLocationList,
          }
        },
        {
          headerName: 'State', field: 'RState', width: 100, minWidth: 100,
        },
        {
          headerName: 'Distct', field: 'RDistct', width: 100, minWidth: 100,
        },
        {
          headerName: 'City', field: 'RCity', width: 100, minWidth: 100,
        }
      ]
    },
    {
      headerName: 'Emergency Contact Details',
      children: [
        {
          headerName: 'Full Name', field: 'EFullName', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Relation', field: 'ERelation', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Address', field: 'EAddress', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Land Mark', field: 'ELandMark', headerClass: 'multi-header', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Area', field: 'EArea', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Pincode', field: 'EPincode', width: 100, minWidth: 100,
          cellRendererFramework: AgInputEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
          }
        },
        {
          headerName: 'Village', field: 'EVillageCode', width: 100, minWidth: 100,
          cellRendererFramework: AgSelectEditCellRendererComponent,
          cellRendererParams: {
            onInputTextChange: this.onInputTextChange,
            isColValid: this.isColValid,
            dataListByIndex: this.getLocationList,
          }
        },
        {
          headerName: 'State', field: 'EState', width: 100, minWidth: 100,
        },
        {
          headerName: 'Distct', field: 'EDistct', width: 100, minWidth: 100,
        },
        {
          headerName: 'City', field: 'ECity', width: 100, minWidth: 100,
        }
      ]
    }

    // { headerName: 'Vendor Code', field: 'VendorCode', width: 100, minWidth: 100,
    // cellRenderer: this.textCellRenderer, pinned: 'left' },
    // { headerName: 'Contractor Name', field: 'ContractorName', width: 100, minWidth: 100,
    // cellRenderer: this.textCellRenderer, headerClass: 'multi-header', pinned: 'left' },
    // { headerName: 'WO No', field: 'WONo', width: 100, minWidth: 100,
    // cellRenderer: this.textCellRenderer, pinned: 'left' },
    // { headerName: 'LIC No', field: 'LICNo', width: 100, minWidth: 100,
    //   cellRendererFramework: AgInputEditCellRendererComponent,
    // cellRendererParams: {
    //   onInputTextChange: this.onInputTextChange,
    //   isColValid: this.isColValid,
    // }
    // },
    // { headerName: 'ESIC Applicable', field: 'ESICApplicable', width: 100, minWidth: 100, headerClass: 'multi-header',
    // cellRenderer: this.selectCellRenderer, cellRendererParams: { dataList: this.yesNoList } },
    // { headerName: 'ESIC No', field: 'ESICNo', width: 100, minWidth: 100,
    // cellRendererFramework: AgInputEditCellRendererComponent,
    //   cellRendererParams: {
    //   onInputTextChange: this.onInputTextChange,
    //     isColValid: this.isColValid,
    //       }
    //     },
    // { headerName: 'WC No', field: 'WCNo', width: 100, minWidth: 100,
    // cellRendererFramework: AgInputEditCellRendererComponent,
    //   cellRendererParams: {
    //   onInputTextChange: this.onInputTextChange,
    //     isColValid: this.isColValid,
    //       }
    //     },

  ];

  // tslint:disable-next-line
  gridOptions: GridOptions = {
    context: {
      componentParent: this
    },
    columnDefs: this.columnDefs,
    rowData: this.labourData,
    headerHeight: 39,
    rowHeight: 39,
    onGridReady: (params) => {
      // this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    // onGridReady: (params) => {
    //   params.api.sizeColumnsToFit();
    //   //params.api.setRowData(this.data.ContractorVendor);
    // },
    // onGridSizeChanged: (params) => {
    //   params.api.sizeColumnsToFit();
    // },
    onCellFocused: (params) => {
      const rowIndex = params.rowIndex;
      if (params.column) {
        const field = params.column.getColId();
        this.setFocusInput(rowIndex, field);
      }
    },
    getRowStyle: (params) => {
      let isValid = true;
      const keys = Object.keys(params.data);
      for (let j = 0; j < keys.length; j++) {
        const validObj = this.isColValid(params.node.rowIndex, keys[j]);
        if (!validObj.isValid) {
          isValid = false;
          break;
        }
      }
      const validObj = this.isOneAdressProofAvailable(params.node.rowIndex);
      if (!validObj.isValid) {
        isValid = false;
      }
      if (isValid) {
        this.labourData[params.node.rowIndex]['rowValid'] = true;
        return { background: '#33CC00 !important' };
      }

      this.labourData[params.node.rowIndex]['rowValid'] = false;
      return { background: '#FFFF95 !important' };
    },
    pagination: false,
    paginationPageSize: 10,
    suppressMovableColumns: true,
    // angularCompileRows: true
  };

  setFocusInput = (rowIndex, field) => {
    // const colInput = $(`#importGrid .ag-body-container [row-index='${rowIndex}'] [col-id=''${field}'] input`);
    // const colSelect = $(`#importGrid .ag-body-container [row-index='${rowIndex}'] [col-id=''${field}'] select`);
    // const colLeftPinInput = $(`#importGrid .ag-pinned-left-cols-container [row-index='${rowIndex}'] [col-id=''${field}'] input`);
    // const colLeftPinSelect = $(`#importGrid .ag-pinned-left-cols-container [row-index='${rowIndex}'] [col-id='${field}'] select`);
    // if (colInput.length > 0) {
    //   colInput.focus();
    // } else if (colSelect.length > 0) {
    //   colSelect.focus();
    // } else if (colLeftPinInput.length > 0) {
    //   colLeftPinInput.focus();
    // } else if (colLeftPinSelect.length > 0) {
    //   colLeftPinSelect.focus();
    // }
  }

  constructor(
    private loadingService: Ng4LoadingSpinnerService,
    private cookieService: CookieService,
    private utilService: UtilService,
    private logHelperService: LogHelperService,
    private importExcelService: ImportExcelService
  ) {
    this.closeModal = new EventEmitter();
    // this.userEmail = String(new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserEmail'));
    this.userEmail = String(localStorage.getItem('UserEmailId'));
    this.loginUserId = localStorage.getItem('UserID');
    
  }

  closeLabourImport() {
  }
  openLabourImport() {
    this.showLabourImport = true;
    this.fullHeight = `${(window.innerHeight - 110)}px`;
  }

  ngOnInit() {
    this.getGlobalSettings();
    this.loadGlobalSettings();
    this.loadSkillList();
    this.loadTradeList();
    this.loadSiteShiftGroup();
    // this.getPincodeList();
    // this.GetWorkSiteCategoryList();
    this.getMasterList();
    this.getPlantUnitDepartmentTree();
    this.getUserplantDetail()
    setTimeout(() => {
      // this.bindExcelData(null);
      this.gridOptions.api.sizeColumnsToFit();
    }, 5000);
  }

  public getPlantUnitDepartmentTree() {
    // this.importExcelService.getDepartmentPlantUnitSectionTree()
    //   .subscribe((res) => {
    //     const companyData = res.find(item => item.Company_Code === 101);
    //     this.plantUnitDepartmentTree = companyData ? companyData.PlantTree : companyData;
    //   });

    this.importExcelService.getDepartmentPlantUnitSectionData()
      .subscribe((response) => {
        this.plantUnitDepartmentTree = response;
      });
  }

  public onCloseAction(): void {
    this.closeModal.next();
  }

  onFileChange($event) {
    this.validateFile($event.target);
  }

  onUploadFile() {
    // this.bindExcelData(null);
    if (this.validateFile(this.fileInput.nativeElement)) {
      const reader = new FileReader();
      reader.onload = (e: Event) => {
        if (e.target) {
          /* read workbook */
          const bstr = reader.result;
          const wb = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* save data */
          const fileContent = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false, defval: null, raw: false });

          if (!fileContent) {
            this.fileError = 'Please select a file to upload.';
            return;
          }
          const labourData = [];
          const colgrps = fileContent.shift();
          const keys: any = fileContent.shift();
          const firstTabId = '';
          const data = fileContent.map((e) => {
            const obj = {};
            for (let i = 0; i < keys.length; i++) {
              obj[keys[i]] = e[i];
            }
            return obj;
          });

          if (data.length === 0) {
            this.loadingService.hide();
            this.fileError = 'AtLeast one record should be availabe in file to upload a file.';
            return;
          }
          if (data.length > Number(this.globalSettingData[0].Value)) {
            this.loadingService.hide();
            this.fileError = `Maximum ${this.globalSettingData[0].Value} Records is allowed to import.`;
            return;
          }

          // this.loadingService.hide();
          localStorage.setItem('xlsx_data', JSON.stringify(data));
          this.bindExcelData(this.removeSortCharFromKeys(data));
        }
      };
      this.loadingService.show();
      setTimeout(() => {
        reader.readAsBinaryString(this.fileInput.nativeElement.files[0]);
      }, 1000);
    }
  }

  validateFile(target) {
    this.fileError = '';
    if (target.files.length === 0) {
      this.fileError = 'Please select Excel file';
      target.value = '';
      return false;
    }

    if (target.files.length > 1) {
      this.fileError = 'Cannot use multiple files';
      target.value = '';
      return false;
    }

    if (!(target.files[0].name.endsWith('.xlsx') || target.files[0].name.endsWith('.xls'))) {
      this.fileError = 'Invalid File format, please select Excel file only.';
      target.value = '';
      return false;
    }

    return true;
  }

  removeSortCharFromKeys(data) {
    const newData = [];
    data.forEach((obj) => {
      const newObj = Object.keys(obj).reduce((result, key) => {
        const newKey = key.replace('↓', '').trim();
        result[newKey] = obj[key];
        return result;
      }, Array.isArray(obj) ? [] : {});
      newData.push(newObj);
    });

    return newData;
  }

  bindExcelData(data) {

    const data1 = [{
      Plant: '1111',
    }];
    const data2 = [
      {
        Plant: 'Goa 1',
        Unit: 'Unit-1',
        Department: 'Accounts',
        Section: 'Packing',
        'Sub-Section 1': 'FFS',
        'Sub-Section 2': 'Line 2',

        Contrctor: 'Active Security Force',
        VendorCode: '100043211',
        WorkOrder: 'Dum45116',
        'License No': 'Ackow010',
        'EC Policy No': '654',
        ULC: '191009489',
        'Gatepass No': '11100012',

        // Plant: 'Goa 5',
        // Unit: 'CMN',
        // Department: 'Accounts',
        // 'Section 2': 'Accounts',
        // 'Sub-Section 1': 'Accounts',
        // 'Sub-Section 2': 'Accounts',

        'Wage Criteria': 'Wage / Salary   <   21000.00/- (ESIC Applicable)',
        'Labour Demand For': 'General',
        Nationality: 'Indian',
        'Work Site': 'External Perimeter',
        'Work Category': 'Casual Worker',
        'Validity Period': 'Casual worker < 3 months',
        'Associate Category': 'Contractor Casual Labour',
        'First Name': 'TestSP2',
        'Father/Husband Name': 'TestSP2',
        Relation: 'Father',
        'Last Name': 'xyz',
        'Birth Date': '1/1/92',
        Gender: 'Male',
        'Marital Status': 'MARRIED',
        'Police Verification Date': '1/1/20',
        'Permanent Address': 'Bina',
        'Permanent Land Mark': 'Bina',
        'Permanent Area': 'Bina',
        'Permanent Pincode': '470113',
        'Local Address': 'Bina',
        'Local Land Mark': 'Bina',
        'Local Area': 'Bina',
        Pincode: '470113',
        Qualification: 'Other',
        'Qulification Stream': 'Other',
        'Technical Qulification': 'Other',
        Cast: 'General',
        Religion: 'Hindu',
        'PAN No': 'PAN1234567',
        'NameIn PAN': 'Test Pan',
        'Adhaar Card Number': '423412341231',
        'Name In Adhaar Card': 'RAMKRISHAN MEENa',
        'Voter-IDNumber': 'VOTER123',
        'Name In Voter-ID': 'Test Voter',
        'Ration Card Number': 'RATION123',
        'Name In Ration Card': 'Test Ration',
        'License Batch Number': 'B2020',
        'Driving License Number': 'LIC123',
        'Name In Driving License': 'Test Lice',
        'Driving License Expire Date': '1/1/2025',
        'Passport Number': 'PASS123',
        'Name in Passport': 'Test',
        'Passport Registraion Date': '1/1/2017',
        'Passport Expire Date': '1/1/2025',
        'Bank Name': 'Axis Bank',
        'Bank Branch': 'Bank Ahmedabad',
        'Account Number': '1234567890',
        'IFSC Code': 'IFC1234',
        'Name As Per Bank Record': 'Test Bank',
        'Blood Group': 'B+',
        'Skill Type': 'Semi Skilled',
        Trade: 'Helper',
        'UAN Number': 'UAN123',
        'DGMS No': 'DG123',
        'PF No': 'PF123',
        Mobile: '9876543210',
        'R-Full Name': 'R name',
        'R-Relation': 'Brother',
        'R-Address': 'R address',
        'R-Land Mark': 'R landmark',
        'R-Area': 'R area',
        'R-Country': 'R country',
        'R-State': 'R state',
        'R-District': 'R district',
        'R-City': 'R city',
        'R-PinCode': '470113',
        'E-Full Name': 'E name',
        'E-Relation': 'Brother',
        'E-Address': 'E address',
        'E-Land Mark': 'E landmark',
        'E-Area': 'E area',
        'E-Country': 'E country',
        'E-State': 'E state',
        'E-District': 'E district',
        'E-City': 'E city',
        'E-PinCode': '470113'
      }
    ];

    const importData = [];
    for (let index = 0; index < data.length; index++) {

      const element = data[index];
      if (element && Object.keys(element).length > 0) {
        let dob = (element['Birth Date'] || '').toString().trim();
        dob = moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (dob === 'Invalid date') {
          dob = '';
        }
        let joiningdate = (element['Joining Date'] || '').toString().trim();
        joiningdate = moment(joiningdate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (joiningdate === 'Invalid date') {
          joiningdate = '';
        }
        let dled = (element['Driving License Expire Date'] || '').toString().trim();
        dled = moment(dled, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (dled === 'Invalid date') {
          dled = '';
        }

        let pvDate = (element['Police Verification Date'] || '').toString().trim();
        pvDate = moment(pvDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (pvDate === 'Invalid date') {
          pvDate = '';
        }

        let prDate = (element['Passport Registraion Date'] || '').toString().trim();
        prDate = moment(prDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (prDate === 'Invalid date') {
          prDate = '';
        }

        let peDate = (element['Passport Expire Date'] || '').toString().trim();
        peDate = moment(peDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (peDate === 'Invalid date') {
          peDate = '';
        }

        let gender = (element['Gender'] || '').toString().trim();
        let genderFound = false;
        if (gender !== '') {
          for (let p = 0; p < this.genderList.length; p++) {
            if (this.genderList[p].text === gender) {
              gender = String(this.genderList[p].id);
              genderFound = true;
              break;
            }
          }
        }
        if (!genderFound) {
          gender = '';
        }

        let maritalStatus = (element['Marital Status'] || '').toString().trim();
        let maritalStatusFound = false;
        if (maritalStatus !== '') {
          for (let p = 0; p < this.maritalStatusList.length; p++) {
            if (this.maritalStatusList[p].text.toLowerCase() === maritalStatus.toLowerCase()) {
              maritalStatus = String(this.maritalStatusList[p].id);
              maritalStatusFound = true;
              break;
            }
          }
        }
        if (!maritalStatusFound) {
          maritalStatus = '';
        }

        let relation = (element['Relation'] || '').toString().trim();
        let relationFound = false;
        if (relation !== '') {
          for (let p = 0; p < this.relationList.length; p++) {
            if (this.relationList[p].text.toLowerCase() === relation.toLowerCase()) {
              relation = String(this.relationList[p].id);
              relationFound = true;
              break;
            }
          }
        }
        if (!relationFound) {
          relation = '';
        }

        let qualification = (element['Qualification'] || '').toString().trim();
        let qualificationFound = false;
        let isQualificationIlletrate = false;
        if (qualification !== '') {
          for (let p = 0; p < this.qualificationList.length; p++) {
            if (this.qualificationList[p].text.toLowerCase() === qualification.toLowerCase()) {
              qualification = String(this.qualificationList[p].id);
              if (this.qualificationList[p].ShortCode === 'GM332') {
                isQualificationIlletrate = true;
              }
              qualificationFound = true;
              break;
            }
          }
        }
        if (!qualificationFound) {
          qualification = '';
        }

        let qulificationStream = (element['Qulification Stream'] || '').toString().trim();
        let qulificationStreamFound = false;
        if (isQualificationIlletrate) {
          for (let p = 0; p < this.qulificationStreamList.length; p++) {
            if (this.qulificationStreamList[p].ShortCode === 'GM815') {
              qulificationStream = String(this.qulificationStreamList[p].id);
              qulificationStreamFound = true;
              break;
            }
          }
        } else {
          if (qulificationStream !== '') {
            for (let p = 0; p < this.qulificationStreamList.length; p++) {
              if (this.qulificationStreamList[p].text.toLowerCase() === qulificationStream.toLowerCase()) {
                qulificationStream = String(this.qulificationStreamList[p].id);
                qulificationStreamFound = true;
                break;
              }
            }
          }
        }
        if (!qulificationStreamFound) {
          qulificationStream = '';
        }

        let technicalQulification = (element['Technical Qulification'] || '').toString().trim();
        let technicalQulificationFound = false;
        if (isQualificationIlletrate) {
          for (let p = 0; p < this.technicalQulificationList.length; p++) {
            if (this.technicalQulificationList[p].ShortCode === 'GM814') {
              technicalQulification = String(this.technicalQulificationList[p].id);
              technicalQulificationFound = true;
              break;
            }
          }
        } else {
          if (technicalQulification !== '') {
            for (let p = 0; p < this.technicalQulificationList.length; p++) {
              if (this.technicalQulificationList[p].text.toLowerCase() === technicalQulification.toLowerCase()) {
                technicalQulification = String(this.technicalQulificationList[p].id);
                technicalQulificationFound = true;
                break;
              }
            }
          }
        }
        if (!technicalQulificationFound) {
          technicalQulification = '';
        }

        let cast = (element['Cast'] || '').toString().trim();
        let castFound = false;
        if (cast !== '') {
          for (let p = 0; p < this.castList.length; p++) {
            if (this.castList[p].text === cast) {
              cast = String(this.castList[p].id);
              castFound = true;
              break;
            }
          }
        }
        if (!castFound) {
          cast = '';
        }

        let religion = (element['Religion'] || '').toString().trim();
        let religionFound = false;
        if (religion !== '') {
          for (let p = 0; p < this.religionList.length; p++) {
            if (this.religionList[p].text === religion) {
              religion = String(this.religionList[p].id);
              religionFound = true;
              break;
            }
          }
        }
        if (!religionFound) {
          religion = '';
        }

        let bankName = (element['Bank Name'] || '').toString().trim();
        let bankNameFound = false;
        if (bankName !== '') {
          for (let p = 0; p < this.bankNameList.length; p++) {
            if (this.bankNameList[p].text.toLowerCase() === bankName.toLowerCase()) {
              bankName = String(this.bankNameList[p].id);
              bankNameFound = true;
              break;
            }
          }
        }
        if (!bankNameFound) {
          bankName = '';
        }

        let bloodGroup = (element['Blood Group'] || '').toString().trim();
        let bloodGroupFound = false;
        if (bloodGroup !== '') {
          for (let p = 0; p < this.bloodGroupList.length; p++) {
            if (this.bloodGroupList[p].text.toLowerCase() === bloodGroup.toLowerCase()) {
              bloodGroup = String(this.bloodGroupList[p].id);
              bloodGroupFound = true;
              break;
            }
          }
        }
        if (!bloodGroupFound) {
          bloodGroup = '';
        }

        let skill = (element['Skill Type'] || '').toString().trim();
        let skillFound = false;
        if (skill !== '') {
          for (let p = 0; p < this.skillList.length; p++) {
            if (this.skillList[p].text.toLowerCase() === skill.toLowerCase()) {
              skill = String(this.skillList[p].id);
              skillFound = true;
              break;
            }
          }
        }
        if (!skillFound) {
          skill = '';
        }

        let trade = (element['Trade'] || '').toString().trim();
        let tradeFound = false;
        if (trade !== '') {
          for (let p = 0; p < this.tradeList.length; p++) {
            if (this.tradeList[p].text.toLowerCase() === trade.toLowerCase()) {
              trade = String(this.tradeList[p].id);
              tradeFound = true;
              break;
            }
          }
        }
        if (!tradeFound) {
          trade = '';
        }

        let workSite = (element['Work Site'] || '').toString().trim();
        let workSiteFound = false;
        if (workSite !== '') {
          for (let p = 0; p < this.workSiteList.length; p++) {
            if (this.workSiteList[p].text.toLowerCase() === workSite.toLowerCase()) {
              workSite = String(this.workSiteList[p].id);
              workSiteFound = true;
              break;
            }
          }
        }
        if (!workSiteFound) {
          workSite = '';
        }

        let workCategory = (element['Work Category'] || '').toString().trim();
        let workCategoryFound = false;
        if (workCategory !== '') {
          for (let p = 0; p < this.workSiteCategoryList.length; p++) {
            if (this.workSiteCategoryList[p].text.toLowerCase() === workCategory.toLowerCase()) {
              workCategory = String(this.workSiteCategoryList[p].id);
              workCategoryFound = true;
              break;
            }
          }
        }
        if (!workCategoryFound) {
          workCategory = '';
        }

        let nationality = (element['Nationality'] || '').toString().trim();
        let nationalityFound = false;
        if (nationality !== '') {
          for (let p = 0; p < this.nationalityList.length; p++) {
            if (this.nationalityList[p].text.toLowerCase() === nationality.toLowerCase()) {
              nationality = String(this.nationalityList[p].id);
              nationalityFound = true;
              break;
            }
          }
        }
        if (!nationalityFound) {
          nationality = '';
        }

        let validityPeriod = (element['Validity Period'] || '').toString().trim();
        if (validityPeriod !== '') {
          const validityPeriodFound = this.validityPeriodList.find(item => item.text.toLowerCase() === validityPeriod.toLowerCase());
          if (validityPeriodFound) {
            validityPeriod = validityPeriodFound.id;
          } else {
            validityPeriod = '';
          }
        }

        let workmanCategory = (element['Associate Category'] || '').toString().trim();
        if (workmanCategory !== '') {
          const workmanCategoryFound = this.workmanCategoryList.find(item => item.text.toLowerCase() === workmanCategory.toLowerCase());
          if (workmanCategoryFound) {
            workmanCategory = workmanCategoryFound.id;
          } else {
            workmanCategory = '';
          }
        }

        let siteshift = (element['Site Shift Group'] || '').toString().trim();
        let siteshiftFound = false;
        if (siteshift !== '') {
          for (let p = 0; p < this.siteshiftgrouplist.length; p++) {
            if (this.siteshiftgrouplist[p].text.toLowerCase() === siteshift.toLowerCase()) {
              siteshift = String(this.siteshiftgrouplist[p].id);
              siteshiftFound = true;
              break;
            }
          }
        }
        if (!siteshiftFound) {
          siteshift = '';
        }
        // const permanentPincode = (element['Permanent Pincode'] || '').toString().trim();
        // let permanentVillageCode = null;
        // if (permanentPincode !== '') {
        //   for (let p = 0; p < this.pincodeList.length; p++) {
        //     if (this.pincodeList[p].text.toLowerCase() === permanentPincode.toLowerCase()) {
        //       permanentVillageCode = this.pincodeList[p].id;
        //       break;
        //     }
        //   }
        // }

        // const pincode = (element['Pincode'] || '').toString().trim();
        // let villageCode = null;
        // if (pincode !== '') {
        //   for (let p = 0; p < this.pincodeList.length; p++) {
        //     if (this.pincodeList[p].text.toLowerCase() === pincode.toLowerCase()) {
        //       villageCode = this.pincodeList[p].id;
        //       break;
        //     }
        //   }
        // }

        const plant = (element['Plant'] || '').toString().trim();
        const unit = (element['Unit'] || '').toString().trim();
        const department = (element['Department'] || '').toString().trim();
        const section1 = (element['Section'] || '').toString().trim();
        const section2 = (element['Sub-Section 1'] || '').toString().trim();
        const section3 = (element['Sub-Section 2'] || '').toString().trim();

        let plantCode = null;
        let unitCode = null;
        let departmentCode = null;
        let sectionPlantRelationId1 = null;
        let sectionPlantRelationId2 = null;
        let sectionPlantRelationId3 = null;

        const plantFound = this.plantUnitDepartmentTree.find(item => item.Plant0.toLowerCase() === plant.toLowerCase());
        if (plantFound) {
          plantCode = plantFound.Plantcode0;

          const unitFound = plantFound.Values.find(item => item.Unit0.toLowerCase() === unit.toLowerCase());
          if (unitFound) {
            unitCode = unitFound.Unitcode0;

            const departmentFound = unitFound.Values.find(item => item.Department0.toLowerCase() === department.toLowerCase());
            if (departmentFound) {
              departmentCode = departmentFound.UnitDeptID0;

              const section1Found = departmentFound.Values.find(item => item.SectionName0.toLowerCase() === section1.toLowerCase());
              if (section1Found) {
                sectionPlantRelationId1 = section1Found.Sectioncode0;

                const section2Found = section1Found.Values.find(item => item.SectionName1.toLowerCase() === section2.toLowerCase());
                if (section2Found) {
                  sectionPlantRelationId2 = section2Found.Sectioncode1;

                  const section3Found = section2Found.Values.find(item => item.SectionName2.toLowerCase() === section3.toLowerCase());
                  if (section3Found) {
                    sectionPlantRelationId3 = section3Found.LevelID2;
                  }
                }
              }
            }
          }
        }
        importData.push({

          Login_UserId: this.loginUserId,
          Plant_Code: plantCode,
          Unit_Code: unitCode,
          Department_Code: departmentCode,
          SectionPlantRelationId1: sectionPlantRelationId1,
          SectionPlantRelationId2: sectionPlantRelationId2,
          SectionPlantRelationId3: sectionPlantRelationId3,

          Contrctor: (element['Contrctor'] || '').toString().trim(),
          VendorCode: (element['VendorCode'] || '').toString().trim(),
          WorkOrder: (element['WorkOrder'] || '').toString().trim(),
          LicenseNo: (element['License No'] || '').toString().trim(),
          ECPolicyNo: (element['EC Policy No'] || '').toString().trim(),
          ULC: (element['ULC'] || '').toString().trim(),
          GatepassNo: (element['Gatepass No'] || '').toString().trim(),

          WageCriteria: (element['Wage Criteria'] || '').toString().trim(),
          LabourDemandFor: (element['Labour Demand For'] || '').toString().trim(),
          Nationality: nationality,
          WorkSite: workSite,
          WorkCategory: workCategory,

          ValidityPeriod: validityPeriod,
          WorkmanCategory: workmanCategory,

          FirstName: (element['First Name'] || '').toString().trim(),
          FatherHusbandName: (element['Father/Husband Name'] || '').toString().trim(),
          // tslint:disable-next-line
          Relation: relation,
          LastName: (element['Last Name'] || '').toString().trim(),
          BirthDate: dob,
          JoiningDate: joiningdate,
          Gender: gender,
          MaritalStatus: maritalStatus,
          PoliceVerificationDate: pvDate,
          PermanentAddress: (element['Permanent Address'] || '').toString().trim(),
          PermanentLandMark: (element['Permanent Land Mark'] || '').toString().trim(),
          PermanentArea: (element['Permanent Area'] || '').toString().trim(),
          PermanentPincode: (element['Permanent Pincode'] || '').toString().trim(),
          PermanentVillageCode: null,

          LocalAddress: (element['Local Address'] || '').toString().trim(),
          LocalLandMark: (element['Local Land Mark'] || '').toString().trim(),
          LocalArea: (element['Local Area'] || '').toString().trim(),
          Pincode: (element['Pincode'] || '').toString().trim(),
          VillageCode: null,

          Qualification: qualification,
          QulificationStream: qulificationStream,
          TechnicalQulification: technicalQulification,
          Cast: cast,
          Religion: religion,

          PANNo: (element['PAN No'] || '').toString().trim(),
          NameInPAN: (element['NameIn PAN'] || '').toString().trim(),
          AdhaarCardNumber: (element['Adhaar Card Number'] || '').toString().trim(),
          NameInAdhaarCard: (element['Name In Adhaar Card'] || '').toString().trim(),
          VoterIDNumber: (element['Voter-IDNumber'] || '').toString().trim(),
          NameInVoterID: (element['Name In Voter-ID'] || '').toString().trim(),
          RationCardNumber: (element['Ration Card Number'] || '').toString().trim(),
          NameInRationCard: (element['Name In Ration Card'] || '').toString().trim(),

          LicenseBatchNumber: (element['License Batch Number'] || '').toString().trim(),
          DrivingLicenseNumber: (element['Driving License Number'] || '').toString().trim(),
          NameInDrivingLicense: (element['Name In Driving License'] || '').toString().trim(),
          DrivingLicenseExpireDate: dled,
          PassportNumber: (element['Passport Number'] || '').toString().trim(),
          NameInPassport: (element['Name in Passport'] || '').toString().trim(),
          PassportRegistraionDate: prDate,
          PassportExpireDate: peDate,

          BankName: bankName,
          BankBranch: (element['Bank Branch'] || '').toString().trim(),
          AccountNumber: (element['Account Number'] || '').toString().trim(),
          IFSCCode: (element['IFSC Code'] || '').toString().trim(),
          NameAsPerBankRecord: (element['Name As Per Bank Record'] || '').toString().trim(),

          BloodGroup: bloodGroup,
          SkillType: skill,
          Trade: trade,
          CommonMachine: (element['Common Machine'] || '').toString().trim(),
          SiteShiftGroup: siteshift,
          UANNumber: (element['UAN Number'] || '').toString().trim(),
          DGMSNo: (element['DGMS No'] || '').toString().trim(),
          ESICNo: (element['ESIC No'] || '').toString().trim(),
          PFNo: (element['PF No'] || '').toString().trim(),
          Mobile: (element['Mobile'] || '').toString().trim(),

          RFullName: (element['R-Full Name'] || '').toString().trim(),
          RRelation: (element['R-Relation'] || '').toString().trim(),
          RAddress: (element['R-Address'] || '').toString().trim(),
          RLandMark: (element['R-Land Mark'] || '').toString().trim(),
          RArea: (element['R-Area'] || '').toString().trim(),
          RCountry: (element['R-Country'] || '').toString().trim(),
          RState: (element['R-State'] || '').toString().trim(),
          RDistrict: (element['R-District'] || '').toString().trim(),
          RCity: (element['R-City'] || '').toString().trim(),
          RPincode: (element['R-PinCode'] || '').toString().trim(),

          EFullName: (element['E-Full Name'] || '').toString().trim(),
          ERelation: (element['E-Relation'] || '').toString().trim(),
          EAddress: (element['E-Address'] || '').toString().trim(),
          ELandMark: (element['E-Land Mark'] || '').toString().trim(),
          EArea: (element['E-Area'] || '').toString().trim(),
          ECountry: (element['E-Country'] || '').toString().trim(),
          EState: (element['E-State'] || '').toString().trim(),
          EDistrict: (element['E-District'] || '').toString().trim(),
          ECity: (element['E-City'] || '').toString().trim(),
          EPincode: (element['E-PinCode'] || '').toString().trim(),

          // VendorCode: (element['Vendor Code'] || '').toString().trim(),
          // ContractorName: (element['Contractor Name'] || '').toString().trim(),
          // WONo: (element['WO No'] || '').toString().trim(),
          // LICNo: (element['LIC No'] || '').toString().trim(),
          // ESICApplicable: (element['ESIC Applicable'] || '').toString().trim(),
          // ESICNo: (element['ESIC No'] || '').toString().trim(),
          // WCNo: (element['WC No'] || '').toString().trim(),
        });

        console.log('importData -------> ', importData);
      }
    }
    this.labourData = importData;
    this.gridOptions.api.setRowData(this.labourData);
    this.loadingService.hide();
    this.fileInput.nativeElement.value = null;
    this.validateDataByService();
  }

  validateDataByService() {

    const pincodeSet = new Set();
    const ulcSet = new Set();
    const gatePassSet = new Set();
    const vendorWorkOrderList = [];
    const vendorWorkOrderLicenceNoList = [];
    const vendorWorkOrderECPolicyNoList = [];
    const gatePassNoULC = [];
    const gatePassNoULCIdentity = [];
    const gatePassNoULCIForNEw = [];

    this.labourData.forEach((item) => {
      if (item.Pincode) {
        pincodeSet.add(item.Pincode);
      }

      if (item.PermanentPincode) {
        pincodeSet.add(item.PermanentPincode);
      }

      if (item.RPincode) {
        pincodeSet.add(item.RPincode);
      }

      if (item.EPincode) {
        pincodeSet.add(item.EPincode);
      }

      if (item.ULC) {
        ulcSet.add(item.ULC);
      }

      if (item.GatepassNo) {
        gatePassSet.add(item.GatepassNo);
      }
      if (item.VendorCode && item.WorkOrder && item.WorkOrder
        // tslint:disable-next-line
        && !vendorWorkOrderList.find(tmp => tmp.VendorCode === item.VendorCode && tmp.WorkOrder === item.WorkOrder)) {
        vendorWorkOrderList.push({
          VendorCode: item.VendorCode,
          WorkOrder: item.WorkOrder,
          IsContractorValid: false,
          IsWorkOrderValid: false,
          HasValidated: false,
        });
      }

      if (item.VendorCode && item.WorkOrder && item.LicenseNo
        // tslint:disable-next-line
        && !vendorWorkOrderLicenceNoList.find(tmp => tmp.VendorCode === item.VendorCode && tmp.WorkOrder === item.WorkOrder && tmp.LicenseNo === item.LicenseNo)) {
        vendorWorkOrderLicenceNoList.push({
          VendorCode: item.VendorCode,
          WorkOrder: item.WorkOrder,
          LicenseNo: item.LicenseNo,
          IsLicenseValid: false,
          IsMapped: false,
          HasValidated: false,
        });
      }

      if (item.VendorCode && item.WorkOrder && item.ECPolicyNo && item.Plant_Code
        // tslint:disable-next-line
        && !vendorWorkOrderECPolicyNoList.find(tmp => tmp.VendorCode === item.VendorCode && tmp.WorkOrder === item.WorkOrder && tmp.ECPolicyNo === item.ECPolicyNo && tmp.Plant_Code === item.Plant_Code)) {
        vendorWorkOrderECPolicyNoList.push({
          VendorCode: item.VendorCode,
          WorkOrder: item.WorkOrder,
          ECPolicyNo: item.ECPolicyNo,
          Plant_Code: item.Plant_Code,
          IsECPolicyValid: false,
          IsMapped: false,
          HasValidated: false,
        });
      }
      if (item.GatepassNo && item.ULC && item.AdhaarCardNumber
        // tslint:disable-next-line: max-line-length
        && !gatePassNoULCIdentity.find(tmp => tmp.GatepassNo === item.GatepassNo && tmp.ULC === item.ULC && tmp.AdhaarCardNumber === item.AdhaarCardNumber)) {
        gatePassNoULCIdentity.push({
          GatepassNo: item.GatepassNo,
          ULC: item.ULC,
          AdhaarCardNumber: item.AdhaarCardNumber,
          IsContractorValid: false,
          IsWorkOrderValid: false,
          HasValidated: false,
        });
      }

      if (item.GatepassNo && item.ULC
        // tslint:disable-next-line: max-line-length
        && !gatePassNoULCIForNEw.find(tmp => tmp.GatepassNo === item.GatepassNo && tmp.ULC === item.ULC)) {
        gatePassNoULCIForNEw.push({
          GatepassNo: item.GatepassNo,
          ULC: item.ULC,
          IsContractorValid: false,
          IsWorkOrderValid: false,
          HasValidated: false,
        });
      }
    });

    this.getPincodeDetails(Array.from(pincodeSet), null, null);
    this.validateWorkOrderContractor(vendorWorkOrderList, null, null);
    this.validateLicenseNoContractor(vendorWorkOrderLicenceNoList, null, null);
    this.validateECPolicyContractor(vendorWorkOrderECPolicyNoList, null, null);
    // this.validateGatePassNoULC(gatePassNoULC, null, null);
    this.validateGatePassNoULCAndIdentity(gatePassNoULCIdentity, null, null);
    this.validateGatePassNoULCForNew(gatePassNoULCIForNEw, null, null);
    /// this.validateULC(Array.from(ulcSet), null, null);
    /// this.validateGatePass(Array.from(gatePassSet), null, null);
  }


  validateData() {
    const returnObj = {
      isValid: true,
      rowIndex: -1,
      field: '',
      msg: ''
    };

    const isValid = true;
    for (let i = 0; i < this.labourData.length; i++) {
      const keys = Object.keys(this.labourData[i]);
      for (let j = 0; j < keys.length; j++) {
        const validObj = this.isColValid(i, keys[j]);
        if (!validObj.isValid) {
          returnObj.isValid = false;
          returnObj.rowIndex = i;
          returnObj.field = keys[j];
          returnObj.msg = '';
          break;
        }
      }
      if (!returnObj.isValid) {
        break;
      }
      const validObj = this.isOneAdressProofAvailable(i);
      if (!validObj.isValid) {
        returnObj.isValid = false;
        returnObj.rowIndex = i;
        returnObj.field = 'PANNo';
        returnObj.msg = validObj.msg;
        break;
      }
    }
    return returnObj;
  }

  getApiRequestData() {

    const data = [];
    this.labourData.forEach((row) => {
      const tmpData = {
        UserEmail: this.userEmail,

        // Contrctor: (element['Contrctor'] || '').toString().trim(),

        VendorCode: row.VendorCode,
        WorkOrderCode: row.WorkOrder,
        ECPolicyCode: row.ECPolicyNo,
        LicenseCode: row.LicenseNo,
        ULC: row.ULC,
        GatePassNo: row.GatepassNo,
        Plant_Code: row.Plant_Code ? parseInt(row.Plant_Code, 10) : null,
        // Labour_Code: row.test,
        WageCriteria: row.WageCriteria,
        LabourFor: row.LabourDemandFor,
        // LabourType: row.test,
        WorkmanCategory: row.WorkmanCategory ? parseInt(row.WorkmanCategory, 10) : null,
        WorkSite: row.WorkSite ? parseInt(row.WorkSite, 10) : null,
        ValidityPeriod: row.ValidityPeriod ? parseInt(row.ValidityPeriod, 10) : null,
        WorkCategory: row.WorkCategory ? parseInt(row.WorkCategory, 10) : null,
        Nationality: row.Nationality ? parseInt(row.Nationality, 10) : null,
        // Domicial: row.test,
        FirstName: row.FirstName,
        FatherHusbandName: row.FatherHusbandName,
        Relation: row.Relation ? parseInt(row.Relation, 10) : null,
        LastName: row.LastName,
        // BirthDate: this.formatToServerDate(row.BirthDate),
        BirthDate: row.BirthDate,
        JoiningDate: row.JoiningDate,
        Gender: row.Gender ? parseInt(row.Gender, 10) : null,
        Cast: row.Cast ? parseInt(row.Cast, 10) : null,
        Religion: row.Religion ? parseInt(row.Religion, 10) : null,
        MaritalStatus: row.MaritalStatus ? parseInt(row.MaritalStatus, 10) : null,
        PoliceVerificationDate: row.PoliceVerificationDate,
        PermanentAddress: row.PermanentAddress,
        PermanentLandMark: row.PermanentLandMark,
        PermanentArea: row.PermanentArea,
        PermanentPincode: row.PermanentPincode,
        PermanentVillageCode: row.PermanentVillageCode ? parseInt(row.PermanentVillageCode, 10) : null,
        LocalAddress: row.LocalAddress,
        LocalLandMark: row.LocalLandMark,
        LocalArea: row.LocalArea,
        Pincode: row.Pincode,
        VillageCode: row.VillageCode ? parseInt(row.VillageCode, 10) : null,
        Qualification: row.Qualification ? parseInt(row.Qualification, 10) : null,
        QulificationStream: row.QulificationStream ? parseInt(row.QulificationStream, 10) : null,
        TechnicalQulification: row.TechnicalQulification ? parseInt(row.TechnicalQulification, 10) : null,
        PANNo: row.PANNo,
        NameInPAN: row.NameInPAN,
        AdhaarCardNumber: row.AdhaarCardNumber,
        NameInAdhaarCard: row.NameInAdhaarCard,
        VoterIDNumber: row.VoterIDNumber,
        NameInVoterID: row.NameInVoterID,
        RationCardNumber: row.RationCardNumber,
        NameInRationCard: row.NameInRationCard,
        LicenseBatchNumber: row.LicenseBatchNumber,
        DrivingLicenseNumber: row.DrivingLicenseNumber,
        NameInDrivingLicense: row.NameInDrivingLicense,
        DrivingLicenseExpireDate: row.DrivingLicenseExpireDate,
        PassportNumber: row.PassportNumber,
        NameInPassport: row.NameInPassport,
        PassportRegistraionDate: row.PassportRegistraionDate,
        PassportExpireDate: row.PassportExpireDate,
        BankName: row.BankName ? parseInt(row.BankName, 10) : null,
        BankBranch: row.BankBranch,
        AccountNumber: row.AccountNumber,
        IFSCCode: row.IFSCCode,
        NameAsPerBankRecord: row.NameAsPerBankRecord,
        BloodGroup: row.BloodGroup,
        SkillType: row.SkillType ? parseInt(row.SkillType, 10) : null,
        Trade: row.Trade ? parseInt(row.Trade, 10) : null,
        CommonMachine: row.CommonMachine,
        SiteShiftGroup: row.SiteShiftGroup ? parseInt(row.SiteShiftGroup, 10) : null,
        UANNumber: row.UANNumber,
        DGMCMinesCode: row.DGMSNo,
        ESICNo: row.ESICNo,
        // DGMCMinesCode: row.test, // ?
        PFNo: row.PFNo,
        Mobile: row.Mobile ? parseInt(row.Mobile, 10) : null,
        UnitRelationId: row.Department_Code ? parseInt(row.Department_Code, 10) : null, // ?
        SectionPlant_ID: row.SectionPlantRelationId3 ? parseInt(row.SectionPlantRelationId3, 10) : null, // ?
        // WorkCategoryMIT_Code: row.test, // ?
        // CategoryMIL_Code: row.test, // ?
        ReferanceVillageCode: row.RVillageCode ? parseInt(row.RVillageCode, 10) : null,
        ReferanceFullName: row.RFullName,
        ReferanceRelationId: row.RRelation,
        ReferanceAddress: row.RAddress,
        ReferanceLandMark: row.RLandMark,
        ReferanceArea: row.RArea,
        EmergencyVillageCode: row.EVillageCode ? parseInt(row.EVillageCode, 10) : null,
        EmergencyFullName: row.EFullName,
        EmergencyRelationId: row.ERelation,
        EmergencyAddress: row.EAddress,
        EmergencyLandMark: row.ELandMark,
        EmergencyArea: row.EArea,
      };

      data.push(tmpData);
    });

    return data;
  }

  public formatToServerDate(date) {
    if (date) {
      let a = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (a === 'Invalid date') {
        a = null;
      }
      return a;
    }

    return null;
  }

  saveData() {
    if (this.labourData.length > 0) {
      const valid = this.validateData();
      if (valid.isValid) {
        this.loading = true;
        this.importExcelService.importDataHr(this.getApiRequestData()).subscribe((response) => {
          this.loading = false;
          if (response.Status) {
            this.labourData = [];
            this.gridOptions.api.setRowData(this.labourData);
            this.closeModal.next(this.labourData);
            this.logHelperService.logSuccess({
              message: 'Asscociate data imported successfully'
            });
          } else {
            this.logHelperService.logError({
              message: 'Error in importing data. Please try later.'
            });
          }
        }, (err) => {
          this.loading = false;
          alert('Error in importing data. Please try later.');
        });

      } else {
        if (valid.msg === '') {
          alert('Please correct data.');
        } else {
          alert(valid.msg);
        }
        setTimeout(() => {
          this.setFocusInput(valid.rowIndex, valid.field);
        }, 2000);
      }
    } else {
      this.logHelperService.logError({
        message: 'Please Select File First'
      });
    }
  }
  loadGlobalSettings() {
    // this.importExcelService.getGlobalSettings().then((response) => {
    //   console.log(response.data);
    //   this.isAdhaarMandatory = response.data.ADHAAR01 === 1 ? true : false;
    //   this.isBankMandatory = response.data.BANK01 === 1 ? true : false;
    // }, (err) => {
    //   alert(err);
    // });
  }

  loadSkillList() {
    this.importExcelService.getSkillType().subscribe((response: any) => {
      this.skillList = response.map((item) => {
        return {
          id: item.Skill_Code,
          text: item.SkillName,
        };
      });
    }, (err) => {
      alert(err);
    });
  }

  loadTradeList() {
    this.importExcelService.getAllTrade().subscribe((response: any) => {
      this.tradeList = response.map((item) => {
        return {
          id: item.Trade_Code,
          text: item.Name,
        };
      });
    }, (err) => {
      alert(err);
    });
  }

  loadSiteShiftGroup() {
    this.importExcelService.getAllSiteShiftGroup().subscribe((response: any) => {
      this.siteshiftgrouplist = response.map((item) => {
        return {
          id: item.SiteShiftGroup_Code,
          text: item.Name,
        };
      });
    }, (err) => {
      alert(err);
    });
  }
  // getPincodeList() {
  //   this.importExcelService.getPinCodeData().subscribe((response: any) => {
  //     this.pincodeList = response.Data.Table;
  //   }, (err) => {
  //     // alert(err);
  //   });
  // }

  // Get MasterList For DropDown
  getMasterList() {
    this.importExcelService.getMasterlistData().subscribe((masteritemlist: any) => {
      const masterItemList = masteritemlist.Data.Table;
      const gendertypes = masterItemList.filter(item => item.Parent_Code === 302);
      for (let i = 0; i < gendertypes.length; i++) {
        this.genderList.push({
          id: gendertypes[i].MasterItem_Code,
          text: gendertypes[i].Name
        });
      }
      const qualificationList = masterItemList.filter(item => item.Parent_Code === 331);
      for (let i = 0; i < qualificationList.length; i++) {
        this.qualificationList.push({
          id: qualificationList[i].MasterItem_Code,
          text: qualificationList[i].Name,
          ShortCode: qualificationList[i].ShortCode
        });
      }

      const qulificationStreamList = masterItemList.filter(item => item.Parent_Code === 352);
      for (let i = 0; i < qulificationStreamList.length; i++) {
        this.qulificationStreamList.push({
          id: qulificationStreamList[i].MasterItem_Code,
          text: qulificationStreamList[i].Name,
          ShortCode: qulificationStreamList[i].ShortCode
        });
      }

      const technicalQulificationList = masterItemList.filter(item => item.Parent_Code === 342);
      for (let i = 0; i < technicalQulificationList.length; i++) {
        this.technicalQulificationList.push({
          id: technicalQulificationList[i].MasterItem_Code,
          text: technicalQulificationList[i].Name,
          ShortCode: technicalQulificationList[i].ShortCode
        });
      }

      const bloodGroupList = masterItemList.filter(item => item.Parent_Code === 585);
      for (let i = 0; i < bloodGroupList.length; i++) {
        this.bloodGroupList.push({
          id: bloodGroupList[i].MasterItem_Code,
          text: bloodGroupList[i].Name,
          ShortCode: bloodGroupList[i].ShortCode
        });
      }

      const maritalStatusList = masterItemList.filter(item => item.Parent_Code === 294);
      for (let i = 0; i < maritalStatusList.length; i++) {
        this.maritalStatusList.push({
          id: maritalStatusList[i].MasterItem_Code,
          text: maritalStatusList[i].Name
        });
      }

      const bankNameList = masterItemList.filter(item => item.Parent_Code === 145);
      for (let i = 0; i < bankNameList.length; i++) {
        this.bankNameList.push({
          id: bankNameList[i].MasterItem_Code,
          text: bankNameList[i].Name
        });
      }

      const nationalityList = masterItemList.filter(item => item.Parent_Code === 479);
      for (let i = 0; i < nationalityList.length; i++) {
        this.nationalityList.push({
          id: nationalityList[i].MasterItem_Code,
          text: nationalityList[i].Name
        });
      }

      const workSiteList = masterItemList.filter(item => item.Parent_Code === 612);
      for (let i = 0; i < workSiteList.length; i++) {
        this.workSiteList.push({
          id: workSiteList[i].MasterItem_Code,
          text: workSiteList[i].Name
        });
      }

      const labRelationshipAll = masterItemList.filter(item => item.Parent_Code === 198);
      this.relationList = [];
      labRelationshipAll.forEach((item) => {
        // if (item.Name === 'Father' || item.Name === 'Husband') {
        this.relationList.push({
          id: item.MasterItem_Code,
          text: item.Name
        });
        // }
      });

      const castList = masterItemList.filter(item => item.Parent_Code === 557);
      for (let i = 0; i < castList.length; i++) {
        this.castList.push({
          id: castList[i].MasterItem_Code,
          text: castList[i].Name
        });
      }

      const religionList = masterItemList.filter(item => item.Parent_Code === 362);
      for (let i = 0; i < religionList.length; i++) {
        this.religionList.push({
          id: religionList[i].MasterItem_Code,
          text: religionList[i].Name
        });
      }

      const workSiteCategoryList = masterItemList.filter(item => item.Parent_Code === 618);
      for (let i = 0; i < workSiteCategoryList.length; i++) {
        this.workSiteCategoryList.push({
          id: workSiteCategoryList[i].MasterItem_Code,
          text: workSiteCategoryList[i].Name
        });
      }

      const validityPeriodList = masterItemList.filter(item => item.Parent_Code === 875);
      for (let i = 0; i < validityPeriodList.length; i++) {
        this.validityPeriodList.push({
          id: validityPeriodList[i].MasterItem_Code,
          text: validityPeriodList[i].Name
        });
      }

      const workmanCategoryList = masterItemList.filter(item => item.Parent_Code === 858);
      for (let i = 0; i < workmanCategoryList.length; i++) {
        this.workmanCategoryList.push({
          id: workmanCategoryList[i].MasterItem_Code,
          text: workmanCategoryList[i].Name
        });
      }

    }, () => {
      alert('Error in getting records');
    });
  }

  getPincodeDetails(pincodeList, rowIndex, fieldName) {

    let newPincodeList = pincodeList.filter(item => !this.pincodeMap[item]);
    newPincodeList = newPincodeList.filter(item => !this.pincodeNotFoundSet.has(item));
    if (newPincodeList.length === 0) {
      this.revalidateRow(rowIndex, fieldName);
      return;
    }

    this.importExcelService.getPincodeDetails(newPincodeList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          if (item.Locations.length > 0) {
            const pincodeDetails = JSON.parse(JSON.stringify(item.Locations[0]));
            pincodeDetails.Locations = item.Locations;
            this.pincodeMap[item.Zip_Code] = pincodeDetails;
          }
        });

        newPincodeList.forEach((item) => {
          if (!this.pincodeMap[item]) {
            this.pincodeNotFoundSet.add(item);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  revalidateRow(rowIndex, fieldName) {
    if (fieldName) {
      const row = this.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
      const rowNodes = [row]; // params needs an array
      // tslint:disable-next-line
      this.gridOptions.api.redrawRows({ rowNodes: rowNodes });
    } else {
      this.gridOptions.api.redrawRows();
    }
  }

  validateWorkOrderContractor(list, rowIndex, fieldName) {

    const newList = list.filter((item) => {
      return !this.workOrderVendorCodeList.find(tmp => tmp.VendorCode === item.VendorCode && tmp.WorkOrder === item.WorkOrder);
    })
      .map((item) => {
        return {
          ContractorVendor_Code: item.VendorCode,
          WorkOrderNo: item.WorkOrder,
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.importExcelService.validateWorkOrderContractor(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const listItem = list.find(tmp => tmp.VendorCode === item.ContractorVendor_Code && tmp.WorkOrder === item.WorkOrderNo);
          if (listItem) {
            listItem['IsContractorValid'] = item.IsContractorValid;
            listItem['IsWorkOrderValid'] = item.IsWorkOrderValid;
            listItem['HasValidated'] = true;
            this.workOrderVendorCodeList.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  validateLicenseNoContractor(list, rowIndex, fieldName) {
    const newList = list.filter((item) => {
      return !this.workOrderVendorCodeLicenseNoList.find((tmp) => {
        return tmp.VendorCode === item.VendorCode && tmp.WorkOrder === item.WorkOrder && tmp.LicenseNo === item.LicenseNo;
      });
    })
      .map((item) => {
        return {
          ContractorVendor_Code: item.VendorCode,
          WorkOrderNo: item.WorkOrder,
          LicenseNo: item.LicenseNo,
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.importExcelService.validateLicenceContractor(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const listItem = list.find((tmp) => {
            // tslint:disable-next-line: max-line-length
            return tmp.VendorCode === item.ContractorVendor_Code && tmp.WorkOrder === item.WorkOrderNo && tmp.LicenseNo === item.LicenseNo;
          });
          if (listItem) {
            listItem['IsLicenseValid'] = item.IsLicenseValid;
            listItem['IsMapped'] = item.IsMapped;
            listItem['HasValidated'] = true;
            this.workOrderVendorCodeLicenseNoList.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  validateECPolicyContractor(list, rowIndex, fieldName) {
    const newList = list.filter((item) => {
      return !this.workOrderVendorCodeECPolicyNoList.find((tmp) => {
        return tmp.VendorCode === item.VendorCode && tmp.WorkOrder === item.WorkOrder
          && tmp.ECPolicyNo === item.ECPolicyNo && tmp.Plant_Code === item.Plant_Code;
      });
    })
      .map((item) => {
        return {
          ContractorVendor_Code: item.VendorCode,
          WorkOrderNo: item.WorkOrder,
          ECPolicyNo: item.ECPolicyNo,
          Plant_Code: item.Plant_Code,
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.importExcelService.validateECPolicyContractor(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const listItem = list.find((tmp) => {
            return tmp.VendorCode === item.ContractorVendor_Code && tmp.WorkOrder === item.WorkOrderNo
              && tmp.ECPolicyNo === item.ECPolicyNo && tmp.Plant_Code === item.Plant_Code;
          });
          if (listItem) {
            listItem['IsECPolicyValid'] = item.IsECPolicyValid;
            listItem['IsMapped'] = item.IsMapped;
            listItem['HasValidated'] = true;
            this.workOrderVendorCodeECPolicyNoList.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  validateULC(ulcList, rowIndex, fieldName) {

    let newList = ulcList.filter(item => !this.ulcMap[item]);
    newList = newList.filter(item => !this.ulcNotFoundSet.has(item));
    if (newList.length === 0) {
      this.revalidateRow(rowIndex, fieldName);
      return;
    }

    this.importExcelService.validateULC(newList)
      .subscribe((res) => {
        if (res.Data) {
          res.Data.forEach((item) => {
            if (item.IsExist) {
              this.ulcMap[item.ULC] = true;
            } else {
              this.ulcNotFoundSet.add(item.ULC);
            }
          });
        }

        newList.forEach((item) => {
          if (!this.ulcMap[item]) {
            this.ulcNotFoundSet.add(item);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  validateGatePass(list, rowIndex, fieldName) {

    let newList = list.filter(item => !this.gatePassMap[item]);
    newList = newList.filter(item => !this.gatePassNotFoundSet.has(item));
    if (newList.length === 0) {
      this.revalidateRow(rowIndex, fieldName);
      return;
    }

    this.importExcelService.validateGatePass(newList)
      .subscribe((res) => {
        if (res.Data) {
          res.Data.forEach((item) => {
            if (item.IsExist) {
              this.gatePassMap[item.GatePassNo] = true;
            } else {
              this.gatePassNotFoundSet.add(item.GatePassNo);
            }
          });
        }

        newList.forEach((item) => {
          if (!this.gatePassMap[item]) {
            this.gatePassNotFoundSet.add(item);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  validateGatePassNoULC(list, rowIndex, fieldName) {

    const newList = list.filter(item => !this.gatePassNoULC.find(tmp => tmp.GatepassNo === item.GatepassNo && tmp.ULC === item.ULC))
      .map((item) => {
        return {
          GatePassNo: item.GatepassNo,
          ULC: item.ULC,
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.importExcelService.validateGatePassULC(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const listItem = list.find(tmp => tmp.GatepassNo === item.GatePassNo.toString() || tmp.ULC === item.ULC.toString());
          if (listItem) {
            listItem['Labour_Code'] = item.Labour_Code;
            listItem['IsValid'] = item.IsValid;
            listItem['HasValidated'] = true;
            this.gatePassNoULC.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }


  validateGatePassNoULCForNew(list, rowIndex, fieldName) {

    const newList = list.filter(item => !this.gatePassNoULCIForNEw.find(tmp => tmp.GatepassNo === item.GatepassNo && tmp.ULC === item.ULC))
      .map((item) => {
        return {
          GatePassNo: item.GatepassNo,
          ULC: item.ULC,
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.importExcelService.checkULcAgainstgatepassno(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          const listItem = list.find(tmp => tmp.GatepassNo === item.GatePassNo.toString() || tmp.ULC === item.ULC.toString());
          if (listItem) {
            listItem['IsValid'] = item.IsValid;
            listItem['HasValidated'] = true;
            this.gatePassNoULCIForNEw.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }


  validateGatePassNoULCAndIdentity(list, rowIndex, fieldName) {
    // tslint:disable-next-line: ter-indent

    // tslint:disable-next-line: max-line-length
    const newList = list.filter(item => !this.gatePassNoULCIdentity.find(tmp => tmp.GatepassNo === item.GatepassNo && tmp.ULC === item.ULC && tmp.AdhaarCardNumber === item.AdhaarCardNumber))
      .map((item) => {
        return {
          GatePassNo: item.GatepassNo,
          ULC: item.ULC,
          AdhaarCardNumber: item.AdhaarCardNumber
        };
      });

    if (newList.length === 0) {
      return;
    }

    this.importExcelService.validateGatePassNoULCAndIdentity(newList)
      .subscribe((res) => {
        res.Data.forEach((item) => {
          // tslint:disable-next-line: max-line-length
          const listItem = list.find(tmp => tmp.GatepassNo === item.GatePassNo.toString() || tmp.ULC === item.ULC.toString() || tmp.AdhaarCardNumber === item.AdhaarCardNumber.toString());
          if (listItem) {
            listItem['IsValid'] = item.IsValid;
            listItem['HasValidated'] = true;
            this.gatePassNoULCIdentity.push(listItem);
          }
        });

        this.revalidateRow(rowIndex, fieldName);
      }, (err) => {

      });
  }

  getUserplantDetail(){
    this.importExcelService.getCommonSpUserPlantAcces().subscribe((res) => {
      // console.log("user plant res ----->",res)
      this.allPlantsUser = res;
      this.userplantDetail = _.uniqBy(res, 'State');
      // console.log("userplantDetail ----->",this.userplantDetail)
    },(err) => {
      console.log("user plant err --->",err)
    })
  }

  getGlobalSettings(){
      this.importExcelService.globalSettingCode('').subscribe((res:any) => {
        // console.log("global setting res ---->",res)
        this.globalSettingData = res.Data.Table.filter((i) => i.Code == 'MOD119PG196EXCLIMPPRO_HR')
        this.globalDataSampleDownload = res.Data.Table.filter((i) => i.Code == 'MOD101IMPEXCL')
        // console.log("globalDataSampleDownload --->",this.globalDataSampleDownload)
      },(err) => {
        // console.log("global setting err --->",err)
      })
  }

  // downloadExelSampleList(){

  //   let paylaod = {
  //     "Plant_Code":null,
 	//     "SiteMIL_Code":null,
	//     "Short_Code": "LBRIMPEXL001",
	//     "ISActiveStatus":1,
  //     "RequireType": 1,
  //     "ActionType":0,
  //     "UserID": GLOBAL.USER_ID
  //     }

  //     if(this.globalDataSampleDownload[0].Value == 0){
  //         paylaod.Plant_Code = this.userplantDetail[0].Plantcode0
  //     }
  //     else if(this.globalDataSampleDownload[0].Value == 1){
  //         paylaod.SiteMIL_Code = this.userplantDetail[0].SiteMIL_Code
  //     }

  //     console.log("paylaod ------>",paylaod)

  //     this.importExcelService.DownloadCLPMSsampleExel(paylaod).subscribe((response) => {
  //       // console.log("exel sample res --->",response)
  //     },(err) => {
  //       // console.log("exel sample err ---->",err)
  //     })
  // }


  //  tslint:disable-next-line: ter-indent
}
