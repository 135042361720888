import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GatePassAccociateInformation } from "../gatepass-request-process.model";
import { LogHelperService } from "src/app/core/services/log-helper.service";
import { EnumRoleType } from "src/app/contractor/const/enum";

const browserWindow = window || {};
const browserWindowEnv = browserWindow['__env'] || {};

@Component({
  selector: 'app-associate-information-selection',
  templateUrl: './associate-information-selection.component.html',
  styleUrls: ['./associate-information-selection.component.css']
})
export class AssociateInformationSelectionComponent implements OnInit {

  @Input() public accociateInformation: GatePassAccociateInformation;
  @Input() public isContractor: boolean;
  @Input() public globalSettings: any[];

  @Output() public handleSave: EventEmitter<GatePassAccociateInformation>;
  @Output() public handleClose: EventEmitter<void>;

  public currentStep: number = 1;
  public allStepDataObj: any;
  public contractorString : string = '';
  public iuserapproved : boolean = true;
  public RoleCode;
  public enumRoleType:any=EnumRoleType;
  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private logHelperService: LogHelperService,
  ) {
    this.handleSave = new EventEmitter<GatePassAccociateInformation>();
    this.handleClose = new EventEmitter<void>();
    this.formGroup = this.buildFormGroup();
    this.RoleCode = localStorage.getItem('RoleCode');
  }

  public ngOnInit(): void {
    if (this.accociateInformation) {
      this.formGroup.patchValue({ ...this.accociateInformation });
    }
    const tempCont = this.globalSettings.find((setting) => setting.Code === browserWindowEnv.CONTRATOR_TYPE_STRING )
    // if(localStorage.getItem('RoleCode') === '119'){
    //   if(tempCont){
    //     this.contractorString = tempCont.Value;
    //   }
    // }
    

    const iuserapproveds = this.globalSettings.find((setting) => setting.Code === browserWindowEnv.USER_PROFILE_APPROVED )
    if(tempCont){
      this.iuserapproved = iuserapproveds.Value;
    }
  }

  public onNextAction(): void {
    if(localStorage.getItem('RoleCode') === '119'){
      if(this.iuserapproved ){
        this.handleSave.emit(this.formGroup.getRawValue());
      }
      else{
        this.logHelperService.logError({
          message: 'user is not approved'
        })
      }
    }
    // this.handleSave.emit(this.formGroup.getRawValue());
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  private buildFormGroup(): FormGroup {
    return this.fb.group({
      contractorType: ['', [Validators.required]]
    })
  }

}