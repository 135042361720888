import { RoleCode } from './../../man-power/man-power.modal';
import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, EventEmitter, TemplateRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { GridApi, GridOptions } from 'ag-grid-community';
import { AgNumberCountComponent, AgSelectActionCellRendererComponent, SelectStepsComponent, AdvanceFilters } from '../../core/components';
import {
  BackdropColor,
  ModalConfig,
  ModelDialogClass
} from '../../core/components/select-steps/select-steps-model/select-steps.model';
import { FullRowRendererComponent } from './ag-helper';
import { GatePassRequestsService } from './gate-pass-requests.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { GLOBAL } from 'src/app/app.globals';
import { NgxSidebarService, NgxSidebarOptions } from 'src/app/core/components';
import { SharedService } from 'src/app/core/services';
import { ApprovalFlowComponent } from './approval-flow/approval-flow.component';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { AgGridNg2 } from 'ag-grid-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { eventNames } from 'process';
import { FilterPopupComponent } from './filter-popup/filter-popup.component';
import { LaboursService } from '../labours/labours.service';
import { DebarredLabourComponent } from './approval-flow/debarred-labour/debarred-labour.component';
import { forkJoin, Observable } from 'rxjs';
import { EditGatepassRequestModalComponent } from './edit-gatepass-request/edit-gatepass-request.component';
import * as _ from 'lodash';
import * as moment from 'moment';
import { concatMap } from 'rxjs/operators';
import { GatePassRequestProcessComponent } from './gatepass-request-process/gatepass-request-process.component';
import { PopupWorkmanSalaryMasterComponent } from './approval-flow/date-details/popup-workman-salary-master/popup-workman-salary-master.component';

let roleActionCode: number;
if (GLOBAL.USER.ROLE_CODE === 119) {
  roleActionCode = 555;
} else {
  roleActionCode = null;
}

export class GatePassFilter {
  labourDemandType = -1;
  statusCode = null;
  gatePassStatus = false;
  roleCodeForApproval = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  roleActionCode = roleActionCode;
  contractorVendorCode = 0;
  workorderCode = null;
  licenseCode = null;
  mainEsic = null;
  ecpolicyCode = null;
  ecpolicyChecked = false;
  subContractorCode = null;
  subWorkorderCode = null;
  subLicenseCode = null;
  subEsic = false;
  subeEcPolicyCode = null;
  subEcpolicyChecked = false;
  departmentCode = null;
  worksiteCategoryCode = null;
  plantCode = null;
  skillTypeCode = null;
  search = '';
  filterStartDate = null;
  filterEndDate = null;
  ulc = null;
  // contractorvendorcode = 118;
}

export class StatusFilter {
  approved = false;
  onHold = false;
  pending = false;
  rejected = false;
  debarred = false;
}
@Component({
  selector: 'app-gate-pass-requests',
  templateUrl: './gate-pass-requests.component.html',
  styleUrls: ['./gate-pass-requests.component.css']
})
export class GatePassRequestsComponent implements OnInit, AfterViewInit {
  @ViewChild('stepsComponentNewGetPass') public newGetPass: SelectStepsComponent;
  @ViewChild('stepsComponentRenewGetPass') public renewGetPass: SelectStepsComponent;
  @ViewChild('stepsComponentCancelGetPass') public cancelGetPass: SelectStepsComponent;
  @ViewChild('selectedContractorRef') public contractorRef: TemplateRef<HTMLElement>;
  @ViewChild('labourCountRef') public labourCountRef: TemplateRef<HTMLElement>;
  @ViewChild('selectedContractorAndSelectedWorkerOrderNoRef') public contractorAndWorkerOrderNoRefRef: TemplateRef<HTMLElement>;
  @ViewChild('flexWrap') private flexWrap: ElementRef<HTMLElement>;
  @ViewChild('dialogerror') private dialogerror: SwalComponent;
  @ViewChild('templateRef') templateRef: TemplateRef<any>;
  @ViewChild('leavetemplate') leavetemplate: TemplateRef<any>;
  @ViewChild('btnPrint') public btnPrint: ElementRef<any>;
  private selectedFilterData: any = { JSONText: {} };
  gridApi: GridApi;
  gridOptions: GridOptions;
  rowData: any;
  params;
  public checkPrint: number;
  selectedRowData: any[] = [];
  selectedRowDataForPrint: any;
  selectedRowDataForPF: any;
  selectedRowDataForEsic: any;
  selectedRowDataForPFTwo: any;
  baseUrl = GLOBAL.PHOTO_URL;
  public lStates: any[] = [];
  statelist = [];
  addleave = new FormGroup({
    // LeaveType_Code: new FormControl(''),
    LeaveType: new FormControl(''),
    LeaveBalance: new FormControl(''),
    PayrollMonth_Code: new FormControl('')
  });
  leavetypes = [];
  public disablesavebutton = true;
  public months: any[];
  public balance: any[];
  currentmonth = [];
  optionsChecked = [];
  closeResult = '';
  // tslint:disable-next-line: ter-indent
  showWhilePrinting = false;
  showWhile = false;
  showWhilePFForm = false;
  hidesubgrid = false;
  public userId = localStorage.getItem('UserID');
  public rolecode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
  public gatepassno: number;
  components = {
    loadingRenderer: (params) => {
      if (params.value !== undefined) {
        return params.value;
      }
      return '<img src="assets//Image/6GIFLoading.gif">';
    }
  };
  reqTypeRoleList;
  reqTypePlantList;
  labourDataSource;
  public alertOption: SweetAlertOptions = {};
  public alertText: string;
  opened = false;
  selectedAdvanceFilters: AdvanceFilters[] = [];
  /** Here this variable hold the value of `selected contractor` */
  public selectedContractor: string;
  /** Here this variable hold the value of `selectedWorkerOrderNo` */
  public selectedWorkerOrderNo: string;
  /** Here this variable hold the value of `selectedLicense` */
  public selectedLicense: string;

  statusFilter: StatusFilter = new StatusFilter();

  /** Hold boolean value */
  public isSelectedContractor: boolean;
  /** Hold boolean value isSelectLicencePrevCallOnce */
  public isSelectLicencePrevCallOnce: boolean;
  /** Hold boolean value */
  public isSelectedContractorAndWorkOrder: boolean;
  /** Hold boolean value of isSelectedContractorWorkOrderAndLicense */
  public isSelectedContractorWorkOrderAndLicense: boolean;
  /** Hold boolean value of isSelectLabourCoveredPrevCallOnce */
  public isSelectLabourCoveredPrevCallOnce: boolean;
  /** Hold request type code  */
  public requestType: number;
  /** Hold row selected data */
  public rowSelectedData: any;
  /** Hold form group of new get pass */
  public newGetPassForm: FormGroup;
  /** Hold form group of new get pass */
  public contactorForm: FormGroup;
  /** Hold new get pass steps data  */
  public newGetPassSteps: any[];
  /** Hold renew get pass steps data  */
  public renewGetPassSteps: any[];
  /** Hold cancel get pass steps data  */
  public cancelGetPassSteps: any[];
  /** Hold isAlert variable */
  public isAlert: boolean;
  public generalData: any[] = [];
  /** Hold job type value */
  private jobType: string;
  private isGeneral: boolean;
  private isShutDown: boolean;
  // private generalData: any;
  private shutDownData: any;
  private selectionLabourCount: number;
  private licenceData: any;
  private isLicenceData: boolean;
  private role: number;
  private curruntRole: number;
  private labourCode: number[];
  /** Hold isESIC */
  private isEsic: boolean;
  /** Hold isWcPolicy */
  private isWcPolicy: boolean;
  private isLicenseData: boolean;
  /** Hold current steps value */
  private currentStep: string;
  /** Hold prev steps value */
  private prevStep: string;
  /** Hold wc policy data */
  private wcPolicyData: any;
  /** Hold company name */
  private selectedCompanyName: string;
  public actions = '';
  /** Hold work order number */
  private selectedWorkOrderNo: string;
  /** Hold selectedWorkOrderCode number */
  private selectedWorkOrderCode: string;
  /** Hold parentContractorCode number */
  private parentContractorCode: string;
  private wcpolicycode: number;
  /** Hold  labour value */
  private labourValue: string;
  /** Hold labourcovered value */
  private labourcovered: string;
  /** Hold prev call value */
  private plantCode: string;
  /** Hold licenseCode value */
  private licenseCode: string;
  /** Hold stateCode value */
  private stateCode: string;
  /** Hold body params */
  private bodyParams: object;
  private isContractorOrSubContractor: string;
  private parentContractorCodeofSub: number;
  private subContractorData: any;
  private subWorkOrderData: any;
  private subWorkOrderCode: any;
  private subLicense: any;
  private selectedWorkData: any;
  private plantData: any;
  private labourBody: any;
  private isJobTypeSteps: boolean;
  public modalConfig: ModalConfig = {
    backDropColor: BackdropColor.DarkGrey,
    modalWindowClass: ModelDialogClass.CustomClass,
    isCenter: false,
    // size: '',
    closeOnOutSideClick: false
  };
  checkboxLable = 'Select All';
  public closeModal: EventEmitter<any>;
  sidebarOpen = false;
  sidebarOptions: NgxSidebarOptions = {
    width: 45,
    title: 'Labour Advance Filter'
  };
  printFormat=2
  private getRowNodeId;
  filter: GatePassFilter = new GatePassFilter();
  gatepassReqGridApi: GridApi;
  gatepassReqGridOptions: GridOptions;
  columnDefs = [
    {
      headerName: '#', field: '',
      sortable: false, suppressMenu: true,
      cellRendererFramework: FullRowRendererComponent,
      cellRendererParams: {
        reqTypeRoleList: () => {
          return this.reqTypeRoleList;
        },
        reqTypePlantList: () => {
          return this.reqTypePlantList;
        },
        disableLabour: (params) => {
          // if ($scope.Contractorvendorcode == ""
          //   && $scope.Workordercode == ""
          //   && ($scope.mainesic == true || $scope.ecpolicychk == true)) {
          //   $scope.chkLabourDisable = true;
          // } else {
          //   $scope.chkLabourDisable = false;
          // }
        },
        isSelectAll: () => {
          return this.selectAll;
        }
      }
    },
  ];

  requestTypeCode = 103;
  // requestTypeCode = this.requestType;
  lastRow: any;
  totalPendingGatePass: number;
  selectAll = false;
  roles = GLOBAL.ROLES;
  contractorVendors = [];
  contractorInformation: any;
  userSiteCode: any;

  public gridRowData: any[] = [];
  public gridColumnDefs = [
    {
      headerName: '#', field: '',
      width: 50, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'CCN', field: 'ContractorVendor_Code',
      width: 100, filterParams: { applyButton: true, clearButton: true },
    },
    {
      headerName: 'Vendor Code', field: 'ShortCode',
      width: 150, filterParams: { applyButton: true, clearButton: true },
    },
    {
      headerName: 'Contractor / Company', field: 'Company', width: 350, filterParams:
        { applyButton: true, clearButton: true }, sort: 'asc', sortable: true, filter: true
    },
    {
      headerName: 'Email Id', field: 'EmailID', width: 350, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'PAN Nbr.', field: 'PANNo', width: 130, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Is Sub-Contractor', field: 'IsContactor', width: 250, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Status', field: 'StatusMasterlist_Code', width: 150, filterParams:
        { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 100, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: true, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public workOrderColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Work Order Nbr.', field: 'WorkOrderNO',
      width: 80, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Type', field: 'WorkOrderType',
      width: 80, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Plant Name', field: 'Plant_Name',
      width: 80, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Person', field: 'allowPerson', width: 100, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 70, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Validitiy date', field: 'ValidityDate', width: 70, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    // {
    //   headerName: 'Avaiilable Associates', field: 'RemainingPerson', width: 90, filterParams:
    //     { applyButton: true, clearButton: true }, filter: true
    // },
    {
      headerName: 'Order Owner', field: 'EICUser', width: 80, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Status', field: 'Status', width: 80, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public licenceColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Licence', field: 'LicenseNo',
      width: 70, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Plant Name', field: 'Plant_Name',
      width: 70, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Maximum Associates', field: 'allowPerson', width: 90, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Available Associates', field: 'RemainingPerson', width: 90, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'State', field: 'State', width: 40, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 80, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Validity', field: 'ValidityDate', width: 80, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Status', field: 'Status', width: 40, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public wcPolicyColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'EC Policy No', field: 'ECPolicyNo',
      width: 70, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'No of Insured', field: 'allowPerson', width: 50, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Avaiilable Associates', field: 'RemainingPerson', width: 70, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 80, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Validity', field: 'ValidityDate', width: 80, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Status', field: 'Status', width: 30, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public laboursColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    // {
    //   headerName: 'First Name', field: 'FirstName',
    //   width: 150, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    // },
    // {
    //   headerName: 'Middle Name', field: 'LastName', width: 100, filterParams:
    //     { applyButton: true, clearButton: true }, filter: true
    // },
    // {
    //   headerName: 'GatePassNo', field: 'GatePassNo', width: 100, filterParams:
    //     { applyButton: true, clearButton: true }, filter: true
    // },
    // tslint:disable-next-line:indent
    {
      headerName: 'Code', field: 'GatePassNo', width: 100, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Full Name', field: 'FullName', width: 200, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    // tslint:disable-next-line:indent
    //  {
    //     headerName: 'Issue Date', field: 'IssueDate', width: 80, filterParams: { applyButton: true, clearButton: true },
    //     sortable: false, suppressMenu: false, filter: true
    //   },
    {
      headerName: 'Validity Date', field: 'DisplayValidUptoDate', width: 80, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Resign Date', field: 'ResignDate', width: 80, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Action', field: '', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: true, checkboxSelection: true
    }
  ];

  public subContractorColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Vendor', field: 'ContractorVendor_Code',
      width: 100, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Contractor', field: 'Company', width: 100, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Email ID', field: 'EmailID', width: 90, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  private subWorkOrderColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Work Order Number', field: 'WorkOrderNO',
      width: 100, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Allow Person', field: 'allowPerson', width: 80, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Available Associates', field: 'RemainingPerson', width: 90, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'EIC User', field: 'EICUser', width: 100, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Validity', field: 'ValidityDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Status', field: 'Status', width: 70, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  private subLicenceColumnDefs = [
    {
      headerName: '#', field: '',
      width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
    },
    {
      headerName: 'Licence', field: 'LicenseNo',
      width: 100, filterParams: { applyButton: true, clearButton: true }, sortable: true, filter: true
    },
    {
      headerName: 'Maximum Associates', field: 'allowPerson', width: 100, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'Avaiilable Associates', field: 'RemainingPerson', width: 90, filterParams:
        { applyButton: true, clearButton: true }, filter: true
    },
    {
      headerName: 'State', field: 'State', width: 100, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Issue Date', field: 'IssueDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Validity', field: 'ValidityDate', width: 50, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, filter: true
    },
    {
      headerName: 'Status', field: 'Status', width: 70, filterParams: { applyButton: true, clearButton: true },
      suppressMenu: false, sortable: true, filter: true
    },
    {
      headerName: 'Action', field: '', width: 40, filterParams: { applyButton: true, clearButton: true },
      sortable: false, suppressMenu: false, cellRendererFramework: AgSelectActionCellRendererComponent,
    }
  ];

  public numberOfRowsPerPage = 10;
  public gridAPI: GridApi;
  public stepsGridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    rowModelType : 'normal',
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort" />',
      sortAscending: '<i class="fa fa-sort-alpha-asc" />',
      sortDescending: '<i class="fa fa-sort-alpha-desc" />'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };

  public stepsGridSelectLabourOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    rowSelection: 'multiple',
    rowMultiSelectWithClick: false,
    suppressRowClickSelection: true,
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    icons: {
      sortNone: '<i class="fa fa-sort" />',
      sortAscending: '<i class="fa fa-sort-alpha-asc" />',
      sortDescending: '<i class="fa fa-sort-alpha-desc" />'
    },
    pagination: true,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 40,
  };
  workOrders = [];
  licensesByWo = [];
  ecPoliciesByWo = [];
  subContractorsByWo = [];
  subWorkOrders = [];
  subLicences = [];
  subEcPoliciesByWo = [];
  departments = [];
  masterData = [];
  workmanCategory = [];
  plants = [];
  skills = [];
  // tslint:disable-next-line: variable-name
  StateName: string;
  public labourDetail: any;
  constructor(
    private formBuilder: FormBuilder,
    private ngbConfig: NgbModalConfig,
    private renderer: Renderer2,
    private gatePassRequestsService: GatePassRequestsService,
    private service: LaboursService,
    private ngxSidebarService: NgxSidebarService,
    private modalService: NgbModal,
    public router: Router,
    public sharedService: SharedService,
    private logHelperService: LogHelperService,
  ) {
    this.getRowNodeId = function (data) {
      return data.id;
    };
    this.role = 111;
    this.curruntRole = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    this.isLicenceData = false;
    this.isAlert = false;
    this.isGeneral = false;
    this.isShutDown = false;
    this.isLicenseData = false;
    this.isEsic = false;
    this.isJobTypeSteps = false;
    this.isWcPolicy = false;
    this.isSelectedContractor = false;
    this.isSelectedContractorAndWorkOrder = false;
    this.isSelectedContractorWorkOrderAndLicense = false;
    this.closeModal = new EventEmitter();
    this.gridOptions = {
      context: {
        componentParent: this
      },
      onGridReady: (params) => {
        this.gatepassReqGridApi = params.api;
        this.gatepassReqGridApi.setDatasource(this.labourDataSource);
        this.gatepassReqGridApi.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        this.gatepassReqGridApi.sizeColumnsToFit();
      },
      pagination: true,
      paginationPageSize: 10,
      rowModelType: 'normal',
      maxConcurrentDatasourceRequests: 2,
      
      maxBlocksInCache: 2,
      cacheBlockSize: 20,
      headerHeight: 0,
      rowHeight: 190,
      cacheOverflowSize: 2
    };

    // this.gatepassReqGridOptions = {
    //   context: {
    //     componentParent: this
    //   },
    //   // onGridReady: (params) => {
    //   //   this.gatepassReqGridApi = params.api;
    //   //   this.gatepassReqGridApi.setDatasource(this.labourDataSource);
    //   //   this.gatepassReqGridApi.sizeColumnsToFit();
    //   // },
    //   onGridSizeChanged: (params) => {
    //     this.gatepassReqGridApi.sizeColumnsToFit();
    //   },
    //   pagination: true,
    //   paginationPageSize: 10,
    //   rowModelType: 'infinite',
    //   maxConcurrentDatasourceRequests: 2,
    //   infiniteInitialRowCount: 1,
    //   maxBlocksInCache: 50,
    //   cacheBlockSize: 50,
    //   headerHeight: 0,
    //   rowHeight: 200
    // };
  }

  // Harshil
  setAGGrid(){
    this.gatepassReqGridOptions = {
      context: {
        componentParent: this
      },
      // onGridReady: (params) => {
      //   this.gatepassReqGridApi = params.api;
      //   this.gatepassReqGridApi.setDatasource(this.labourDataSource);
      //   this.gatepassReqGridApi.sizeColumnsToFit();
      // },
      onGridSizeChanged: (params) => {
        this.gatepassReqGridApi.sizeColumnsToFit();
      },
      pagination: true,
      paginationPageSize: 10,
      rowModelType: 'infinite',
      maxConcurrentDatasourceRequests: 2,
      infiniteInitialRowCount: 1,
      maxBlocksInCache: 10, //original - 50
      cacheBlockSize: 10, // original - 50
      headerHeight: 0,
      rowHeight: 200
    };

    this.labourDataSource = {
      rowCount: null,
      getRows: async (params) => {
        console.log(`asking for ${params.startRow} to ${params.endRow}`);
        let gatePassStatus;
        if (!this.filter.gatePassStatus) {
          gatePassStatus = false;
        } else {
          gatePassStatus = true;
        }
        // console.log(`asking for ${params.startRow} to ${params.endRow}`);
        const reqParams = {
          Departmentcode: this.filter.departmentCode,
          Skilltypecode: this.filter.skillTypeCode,
          ContractorId: this.filter.contractorVendorCode || 0,
          FilterStartDate: this.filter.filterStartDate,
          FilterEndDate: this.filter.filterEndDate,
          GatePassStatus: gatePassStatus,
          LabourDemandType: this.filter.labourDemandType,
          License: 0,
          MultipleRole: GLOBAL.USER.ROLE_CODE,
          NatureOfWork: 0,
          RoleCodeForApproval: this.filter.roleCodeForApproval,
          Roleactioncode: this.filter.roleActionCode,
          Subcontractorcode: this.filter.subContractorCode || 0,
          Subworkordercode: this.filter.subWorkorderCode || 0,
          Sublicensecode: this.filter.subLicenseCode || 0,
          Subecpolicycode: this.filter.subeEcPolicyCode || 0,
          UserCode: localStorage.getItem('UserID'),
          UserRoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
          Licensecode: this.filter.licenseCode,
          WcPolicy: 0,
          Mainesic: this.filter.mainEsic,
          Ecpolicycode: this.filter.ecpolicyCode,
          Search: this.filter.search,
          Workordercode: this.filter.workorderCode || 0,
          WorkSiteCategory: this.filter.worksiteCategoryCode || 0,
          Statecode: this.filter.statusCode,
          endRow: params.startRow + 10,//params.endRow,
          requesttypecode: this.requestTypeCode || 103,
          startRow: params.startRow,
          Plantcode: this.filter.plantCode,
          Contractorvendorcode: this.filter.contractorVendorCode || 0,
          ULC: this.filter.ulc,
        };
        // const reqParams = {
        //   startRow: params.startRow,
        //   endRow: params.endRow,
        //   UserCode: String(GLOBAL.USER.LOGGED_IN_USER),
        //   requesttypecode: this.requestTypeCode,
        //   UserRoleCode: GLOBAL.USER.ROLE_CODE,
        //   MultipleRole: GLOBAL.USER.ROLE_CODE,
        //   LabourDemandType: this.filter.labourDemandType,
        //   Statuscode: this.filter.statusCode,
        //   GatePassStatus: gatePassStatus,
        //   RoleCodeForApproval: this.filter.roleCodeForApproval,
        //   Roleactioncode: this.filter.roleActionCode,
        //   Contractorvendorcode: this.filter.contractorVendorCode || '',
        //   Workordercode: this.filter.workorderCode || '',
        //   Licensecode: this.filter.licenseCode || '',
        //   Mainesic: this.filter.mainEsic,
        //   Ecpolicycode: this.filter.ecpolicyCode || '',
        //   Subcontractorcode: this.filter.subContractorCode || '',
        //   Subworkordercode: this.filter.subWorkorderCode || '',
        //   Sublicensecode: this.filter.subLicenseCode || '',
        //   Subesic: this.filter.subEsic,
        //   Subecpolicycode: this.filter.subeEcPolicyCode || '',
        //   Departmentcode: this.filter.departmentCode || '',
        //   Worksitecategorycode: this.filter.worksiteCategoryCode || '',
        //   Plantcode: this.filter.plantCode || '',
        //   Skillty pecode: this.filter.skillTypeCode || '',
        //   Search: this.filter.search,
        //   FilterStartDate: this.filter.filterStartDate,
        //   FilterEndDate: this.filter.filterEndDate
        // };
        this.setSelectedFilters();
        //
        await this.gatePassRequestsService.getAllGatePassRequests(reqParams).subscribe((res: any) => {
          this.rowData = res.Data.data;
          console.log(this.rowData.length)
          this.reqTypeRoleList = res.Data.reqTypeRoleList;
          this.reqTypePlantList = res.Data.reqTypePlantList;
          this.lastRow = res.Data.totalRow;
          // if (GLOBAL.USER.ROLE_CODE !== 119) {
          this.totalPendingGatePass = res.Data.allPendingGatePass;
          // }
          setTimeout(() => {
            this.gatepassReqGridApi.sizeColumnsToFit();
          }, 500);

          if (this.rowData && this.rowData.length > 0 && this.selectAll) {
            this.rowData.forEach((row) => {
              row.isSelected = this.selectAll;
            });
          }
          // this.ngxSidebarService.openSidebar();
          // this.ngxSidebarService.closeSidebar();
          params.successCallback(this.rowData, this.lastRow);
        }, (err) => {
          this.rowData = [];
          this.lastRow = null;
          params.successCallback(this.rowData, this.lastRow);
        });
      }
    };

    
  }

  onAGGridReady(params: any){
    this.gatepassReqGridApi = params.api;
        this.gatepassReqGridApi.setDatasource(this.labourDataSource);
        this.gatepassReqGridApi.sizeColumnsToFit();
  }

  // agInit(params: any): void {
  //   this.params = params;
  //   this.rowData = params.data;
  //   if (this.rowData.Photo) {
  //     this.rowData.Photo = `${GLOBAL.PHOTO_URL}${this.rowData.Photo}`;
  //   }
  // }

  async ngOnInit() {
    // this.openSalaryInfoModal(null);

    this.getContractorData();
    this.getSiteCodeByUserID();
    if (this.curruntRole === 119) {
      this.getContractorDetails();
    }

    this.setAGGrid();

    // this.labourDataSource = {
    //   rowCount: null,
    //   getRows: async (params) => {
    //     // console.log(`asking for ${params.startRow} to ${params.endRow}`);
    //     let gatePassStatus;
    //     if (!this.filter.gatePassStatus) {
    //       gatePassStatus = false;
    //     } else {
    //       gatePassStatus = true;
    //     }
    //     // console.log(`asking for ${params.startRow} to ${params.endRow}`);
    //     const reqParams = {
    //       Departmentcode: this.filter.departmentCode,
    //       Skilltypecode: this.filter.skillTypeCode,
    //       ContractorId: this.filter.contractorVendorCode || 0,
    //       FilterStartDate: this.filter.filterStartDate,
    //       FilterEndDate: this.filter.filterEndDate,
    //       GatePassStatus: gatePassStatus,
    //       LabourDemandType: this.filter.labourDemandType,
    //       License: 0,
    //       MultipleRole: GLOBAL.USER.ROLE_CODE,
    //       NatureOfWork: 0,
    //       RoleCodeForApproval: this.filter.roleCodeForApproval,
    //       Roleactioncode: this.filter.roleActionCode,
    //       Subcontractorcode: this.filter.subContractorCode || 0,
    //       Subworkordercode: this.filter.subWorkorderCode || 0,
    //       Sublicensecode: this.filter.subLicenseCode || 0,
    //       Subecpolicycode: this.filter.subeEcPolicyCode || 0,
    //       UserCode: localStorage.getItem('UserID'),
    //       UserRoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
    //       Licensecode: this.filter.licenseCode,
    //       WcPolicy: 0,
    //       Mainesic: this.filter.mainEsic,
    //       Ecpolicycode: this.filter.ecpolicyCode,
    //       Search: this.filter.search,
    //       Workordercode: this.filter.workorderCode || 0,
    //       WorkSiteCategory: this.filter.worksiteCategoryCode || 0,
    //       Statecode: this.filter.statusCode,
    //       endRow: 10,//params.endRow,
    //       requesttypecode: this.requestTypeCode || 103,
    //       startRow: params.startRow,
    //       Plantcode: this.filter.plantCode,
    //       Contractorvendorcode: this.filter.contractorVendorCode || 0,
    //       ULC: this.filter.ulc,
    //     };
    //     // const reqParams = {
    //     //   startRow: params.startRow,
    //     //   endRow: params.endRow,
    //     //   UserCode: String(GLOBAL.USER.LOGGED_IN_USER),
    //     //   requesttypecode: this.requestTypeCode,
    //     //   UserRoleCode: GLOBAL.USER.ROLE_CODE,
    //     //   MultipleRole: GLOBAL.USER.ROLE_CODE,
    //     //   LabourDemandType: this.filter.labourDemandType,
    //     //   Statuscode: this.filter.statusCode,
    //     //   GatePassStatus: gatePassStatus,
    //     //   RoleCodeForApproval: this.filter.roleCodeForApproval,
    //     //   Roleactioncode: this.filter.roleActionCode,
    //     //   Contractorvendorcode: this.filter.contractorVendorCode || '',
    //     //   Workordercode: this.filter.workorderCode || '',
    //     //   Licensecode: this.filter.licenseCode || '',
    //     //   Mainesic: this.filter.mainEsic,
    //     //   Ecpolicycode: this.filter.ecpolicyCode || '',
    //     //   Subcontractorcode: this.filter.subContractorCode || '',
    //     //   Subworkordercode: this.filter.subWorkorderCode || '',
    //     //   Sublicensecode: this.filter.subLicenseCode || '',
    //     //   Subesic: this.filter.subEsic,
    //     //   Subecpolicycode: this.filter.subeEcPolicyCode || '',
    //     //   Departmentcode: this.filter.departmentCode || '',
    //     //   Worksitecategorycode: this.filter.worksiteCategoryCode || '',
    //     //   Plantcode: this.filter.plantCode || '',
    //     //   Skillty pecode: this.filter.skillTypeCode || '',
    //     //   Search: this.filter.search,
    //     //   FilterStartDate: this.filter.filterStartDate,
    //     //   FilterEndDate: this.filter.filterEndDate
    //     // };
    //     this.setSelectedFilters();
    //     //
    //     await this.gatePassRequestsService.getAllGatePassRequests(reqParams).subscribe((res: any) => {
    //       this.rowData = res.Data.data;
    //       this.reqTypeRoleList = res.Data.reqTypeRoleList;
    //       this.reqTypePlantList = res.Data.reqTypePlantList;
    //       this.lastRow = res.Data.totalRow;
    //       // if (GLOBAL.USER.ROLE_CODE !== 119) {
    //       this.totalPendingGatePass = res.Data.allPendingGatePass;
    //       // }
    //       setTimeout(() => {
    //         this.gatepassReqGridApi.sizeColumnsToFit();
    //       }, 500);

    //       if (this.rowData && this.rowData.length > 0 && this.selectAll) {
    //         this.rowData.forEach((row) => {
    //           row.isSelected = this.selectAll;
    //         });
    //       }
    //       // this.ngxSidebarService.openSidebar();
    //       // this.ngxSidebarService.closeSidebar();
    //       params.successCallback(this.rowData, this.lastRow);
    //     }, (err) => {
    //       this.rowData = [];
    //       this.lastRow = null;
    //       params.successCallback(this.rowData, this.lastRow);
    //     });
    //   }
    // };


    await this.gatePassRequestsService.getALLDepartments().subscribe((res: any) => {
      this.departments = res;
      if (res.length > 0) {
        this.departments = res;
      }
      if (res.Data) {
        this.departments = res.Data.Table;
      }
    });
    await this.gatePassRequestsService.getALLWorkSiteCategories().subscribe((res: any[]) => {
      this.masterData = res;
      this.workmanCategory = this.masterData.filter(list => list.Parent_Code === 858);
    });
    await this.gatePassRequestsService.getALLPlants().subscribe((response: any) => {
      this.plants = response;
      if (response.length > 0) {
        this.plants = response;
      }
      if (response.Data) {
        this.plants = response;
      }
    });
    await this.gatePassRequestsService.getALLSkills().subscribe((response: any) => {
      //   this.skills = response;
      if (response.length > 0) {
        this.skills = response;
      }
      if (response.Data) {
        this.skills = response;
      }
    });
    // this.getAllcontractorData();
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    const userId = localStorage.getItem('UserID');
    if (roleCode === 119) {
      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getAllcontractorData();
    }
    // this.getStates();
    this.getLeaveType();
    this.getMonth();
    // if (this.selectedRowData.Photo) {
    //   this.selectedRowData.Photo = GLOBAL.PHOTO_URL + this.selectedRowData.Photo;
    // }
  }

  public ngAfterViewInit(): void {
    // console.log('newGetPass', this.newGetPass);
    // console.log('renewGetPass', this.renewGetPass);
    // console.log('cancelGetPass', this.cancelGetPass);
  }

  getContractorDetails() {
    this.gatePassRequestsService.getContractorDetails(this.userId).subscribe((response: any) => {
      this.contractorInformation = response[0];
    });
  }

  getContractorData() {
    this.gatePassRequestsService.getContractorVendorData().subscribe((response: any) => {
      this.contractorVendors = response.Data.Table;
    });
  }

  public onNextEvent(item: any, value: number): void {
    if (this.requestType !== value) { return; }
    if (item.formData.value.contractManType === 'CONTRACTORS_MAN') {
      this.isContractorOrSubContractor = 'companycontractor';
    }
    if (item.formData.value.contractManType === 'SUB_CONTRACTORS_MAN') {
      this.isContractorOrSubContractor = 'subcompanycontractor';
    }

    if (item.formData.value.contractManType === 'OWN_CONTRACTORS_MAN') {
      this.isContractorOrSubContractor = 'owncompanycontractor';
    }
    if (item.formData.value.labourCoverType === 'ESIC') {
      this.labourcovered = 'esic';
      console.log('onNextWorkLicense', this.isSelectedContractorWorkOrderAndLicense);
    }
    if (item.formData.value.labourCoverType === 'WC_POLICY') {
      this.labourcovered = 'wcPolicy';
    }

    if (item.formData.value.jobType === 'GENERAL') {
      this.jobType = 'general';
    }
    if (item.formData.value.jobType === 'SHUT_DOWN') {
      this.jobType = 'shutdown';
    }
    this.currentStep = item.arrayItem.length > 0 ? item.arrayItem[0].title : '';
    this.bodyParams = {
      Job: this.jobType,
      Labour_codes: this.labourCode,
      // Plant_code: this.plantCode,
      Plant_code: 100,
      Reqtype: this.requestType,
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      // State_Code: this.stateCode,
      State_Code: 111,
      UserEmail: GLOBAL.USER.EMAIL_ID,
      companypolicyCode: null,
      contractorcode: this.parentContractorCode,
      isLabour: this.labourValue,
      labourcovered: this.labourcovered,
      licensecode: this.licenseCode,
      subContractorcode: null,
      subPlant_Code: null,
      sublincensecode: null,
      subworkoderordercode: null,
      wcpolicycode: this.wcpolicycode,
      workoderordercode: this.selectedWorkOrderCode,
      UserId: localStorage.getItem('UserID')
    };
    if (item.arrayItem[0].type === 'radio') {
      if (item.arrayItem[0].name === 'Please Select Job type.') {
        this.bodyParamsSteps('general');
        this.getAllLabour(this.bodyParams, 'general');
        this.bodyParamsSteps('shutdown');
        this.getAllLabour(this.bodyParams, 'shutdown');
        this.getSelectionValueOfLabour(this.bodyParams);
        if (this.labourcovered === 'esic') {
          this.isEsic = true;
        } else if (this.labourcovered === 'wcPolicy') {
          this.isWcPolicy = true;
        }
      }
      console.log('onNext', this.newGetPassSteps);
      console.log('es', this.isEsic);
    }
    if ((item.arrayItem[0].title === 'Sub-Contractor Selection' || item.arrayItem[0].title === 'Select WC Policy') && this.role !== 119) {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    }
    if (item.arrayItem[0].title === 'Select WC Policy' && this.role === 119) {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    }

    if (item.arrayItem[0].title === 'Select Sub Work Order' && this.role === 119) {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    }
    if (item.arrayItem[0].title === 'Select Plants' && this.role === 119) {
      this.plantCode = item.formData.value.plantsType;
      /** Here get the license required data */
      this.getCheckLicenseRequired();
    }

    this.setTemplateConfigOnNext(item);
    // this.setTemplateConfig(item);
    console.log('onNextAlways', this.newGetPassSteps);
  }

  /** Hold */
  public openModalGetPass(getPassType: string): void {
    switch (getPassType) {
      case ('newGetPass1'):
        // this.requestType = 104;
        this.requestType = 103;
        // this.newGetPass.openModel();
        this.openRequestProcessModal();
        break;
      case ('newGetPass'):
        // this.requestType = 104;
        this.requestType = 103;
        // this.newGetPass.openModel();
        this.openRequestProcessModal();
        break;
      case ('renewGetPass'):
        // this.requestType = 105;
        this.requestType = 104;
        // this.renewGetPass.openModel();
        this.openRequestProcessModal();
        break;
      case ('cancelGetPass'):
        // this.requestType = 106;
        this.requestType = 105;
        // this.cancelGetPass.openModel();
        this.openRequestProcessModal();
        break;
    }
    if (this.curruntRole === 119) {
      this.getWorkOrderData();
    }
  }

  bindNewGatePassData() {
    this.requestType = 103;
    this.gatepassReqGridApi.setDatasource(this.labourDataSource);
  }
  bindRenewGatePassData() {
    this.requestType = 104;
    this.gatepassReqGridApi.setDatasource(this.labourDataSource);
  }
  bindCancelGatePassData() {
    this.requestType = 105;
    this.gatepassReqGridApi.setDatasource(this.labourDataSource);
  }

  /**
   * @description This method invoke when select on row.
   * @param item This parameter contain selected data.
   * @param prev
   */
  public getSelectedRowData(item: any, value: number): void {

    if (this.requestType !== value) { return; }
    if (item.arrayItem[0].title === 'Select Job type') {
      this.selectWorkOrder(item);
    } else if (item.arrayItem[0].title === 'Select License') {
      this.selectLicense(item);
    } else if (item.arrayItem[0].title === 'Select WC Policy') {
      this.selectWcPolicy(item);
    } else if (item.arrayItem[0].title === 'Sub-Contractor Selection') {
      this.selectSubContractor();
    } else if (item.arrayItem[0].title === 'Select Sub Work Order') {
      this.selectSubWorkOrder(item);
    } else if (item.arrayItem[0].title === 'Select Sub License') {
      this.selectSubLicense(item);
    } else {
      this.getWorkOrderData(item);
    }
    console.log('selectedRow', this.newGetPassSteps);
  }

  public onCloseAction(item: any): void {
    if (this.requestType === 103) {
      this.newGetPassSteps = [];
    } else if (this.requestType === 104) {
      this.renewGetPassSteps = [];
    } else if (this.requestType === 105) {
      this.cancelGetPassSteps = [];
    }
    this.setStepArray();
    this.licenseCode = null;
    this.wcPolicyData = null;
    this.isAlert = false;
    this.isEsic = false;
    this.labourBody = null;
    this.isLicenseData = false;
    this.currentStep = null;
    this.prevStep = null;
    this.jobType = null;
    this.isEsic = false;
    this.isWcPolicy = false;
    this.isGeneral = false;
    this.isShutDown = false;
    // this.generalData = null;
    this.shutDownData = null;
    this.labourcovered = null;
    this.bodyParams = null;
    this.plantData = null;
    this.labourCode = null;
    this.selectedContractor = null;
    this.selectedWorkerOrderNo = null;
    this.selectedLicense = null;
    this.isContractorOrSubContractor = null;
    this.parentContractorCodeofSub = null;
    this.selectedWorkData = null;
    this.subContractorData = null;
    this.subWorkOrderData = null;
    this.subWorkOrderCode = null;
    this.subLicense = null;
    this.selectionLabourCount = null;
    this.isSelectedContractor = false;
    this.isSelectedContractorAndWorkOrder = false;
    this.isSelectedContractorWorkOrderAndLicense = false;
  }

  /**
   * @description This is call.
   * Invoke this method when select on question.
   */
  public onSelectQuestion(item: any, requestType: number): void {
    if (item === 'ESIC' && this.requestType === requestType) {
      this.setNextStepArray('esic');
    } else if (item === 'WC_POLICY' && this.requestType === requestType) {
      this.setNextStepArray('wcPolicy');
    } else if (item === 'GENERAL' && this.requestType === requestType) {
      this.setNextStepArray('general');
    } else if (item === 'SHUT_DOWN' && this.requestType === requestType) {
      this.setNextStepArray('shutDown');
    } else if ((item === 'CONTRACTORS_MAN')
      && this.requestType === requestType && this.curruntRole === 119) {
      this.setNextStepArray('ownAndSubContractor');
    }
  }

  /**
   * This method invoke when change value od drop down in select plants step.
   * @param value These parameter contain value of selected plants
   * @param requestType This parameter contain request type.
   */
  public onChangePlants(value: any, requestType: number): void {
    if (this.requestType === requestType) {
      this.plantCode = value.id.toString();
      console.log('plant_code', this.plantCode);
    }
  }

  /**
   * Invoke this method and it is insert labour `get pass request`.
   * @param agGrid This parameter contain ag grid references.
   * @param requestType This parameter contain request type.
   */
  public onSubmitAction($event, requestType: number): void {
    const agGrid: AgGridNg2 = $event.agGrid;
    console.log('agGrid ------ >', agGrid);
    console.log('agGrid api------ >', agGrid.api);
    console.log('agGrid api getSelectedNodes------ >', agGrid.api.getSelectedRows());

    if (this.requestType === 103) {
      const selectedData = agGrid.api.getSelectedRows();
      // const selectedData = selectedNodes.map(node => node.data);
      this.labourCode = selectedData.map(node => node.Labour_Code);
      // this.bodyParamsSteps(this.labourValue);
      this.bodyParamsSteps(this.jobType);
      this.insertGetPassRequest(this.bodyParams);
      this.bodyParamsLabourManagement();
      this.getLabourManagement(this.labourBody);
    } else if (this.requestType === 104) {
      // const selectedNodes = agGrid.api.getSelectedNodes();
      const selectedData = agGrid.api.getSelectedRows();
      this.labourCode = selectedData.map(node => node.Labour_Code);
      this.bodyParamsSteps(this.labourValue);
      this.insertGetPassRequestRenew(this.bodyParams);
      this.bodyParamsLabourManagement();
      this.getLabourManagement(this.labourBody);
    } else if (this.requestType === 105) {
      // const selectedNodes = agGrid.api.getSelectedNodes();
      const selectedData = agGrid.api.getSelectedRows();
      this.labourCode = selectedData.map(node => node.Labour_Code);
      this.bodyParamsSteps(this.labourValue);
      this.insertGetPassRequestCancel(this.bodyParams);
      this.bodyParamsLabourManagement();
      this.getLabourManagement(this.labourBody);
    }
  }

  private bodyParamsSteps(value: string): void {
    this.bodyParams = {
      Job: value,
      Labour_codes: this.labourCode,
      // Plant_code: this.plantCode,
      Plant_code: null,
      Reqtype: this.requestType,
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      // State_Code: this.stateCode,
      State_Code: 111,
      UserEmail: GLOBAL.USER.EMAIL_ID,
      companypolicyCode: null,
      contractorcode: this.parentContractorCode,
      isLabour: this.labourValue,
      labourcovered: this.labourcovered,
      licensecode: this.licenseCode,
      subContractorcode: null,
      subPlant_Code: null,
      sublincensecode: null,
      subworkoderordercode: null,
      wcpolicycode: this.wcpolicycode,
      workoderordercode: this.selectedWorkOrderCode,
      UserId: localStorage.getItem('UserID')
    };
  }

  private bodyParamsLabourManagement(): void {
    this.labourBody = {
      Contractorvendorcode: '',
      Departmentcode: '',
      Ecpolicycode: '',
      FilterEndDate: '',
      FilterStartDate: '',
      GatePassStatus: 0,
      LabourDemandType: -1,
      Licensecode: '',
      Mainesic: '',
      MultipleRole: '118',
      // Plantcode: '',
      RoleCodeForApproval: 118,
      Roleactioncode: 555,
      Search: '',
      Skilltypecode: '',
      Statuscode: '',
      Subcontractorcode: '',
      Subecpolicycode: '',
      Subesic: '',
      Sublicensecode: '',
      Subworkordercode: '',
      UserCode: '102',
      UserRoleCode: 118,
      Workordercode: '',
      Worksitecategorycode: '',
      endRow: 50,
      requesttypecode: this.requestType,
      startRow: 0,
    };
  }

  /**
   * @descriptions Invoke this method when click on expire list.
   */
  private openAlert(): void {
    const alertRef = this.dialogerror.show();
    alertRef.then((e) => {
    });
  }

  /**
   * Invoke this method and it is set template config on next event.
   */
  private setTemplateConfigOnNext(item: any): void {

    if (item.arrayItem[0].title === 'Select Job type' && this.role === 119) {
      this.isSelectedContractor = true;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select Plants' && this.role === 119) {
      this.isSelectedContractor = true;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select Labour covered' && this.role === 119) {
      if (this.licenceData.length > 0) {
        this.isSelectedContractor = false;
        this.isSelectedContractorAndWorkOrder = false;
        this.isSelectedContractorWorkOrderAndLicense = true;
      } else {
        this.isSelectedContractor = false;
        this.isSelectedContractorAndWorkOrder = true;
        this.isSelectedContractorWorkOrderAndLicense = false;
      }
    } else if (item.arrayItem[0].title === 'Select License' && this.role === 119) {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    }
  }

  /**
   * When invoke this method it is add next step array.
   */
  private setNextStepArray(value: string): void {

    let jobTypeData: any;
    let generalLabour: any;
    let shutDownLabour: any;
    if (value === 'esic') {
      jobTypeData = {
        id: 5,
        type: 'radio',
        name: 'Please Select Job type.',
        title: 'Select Job type',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        formControlName: 'jobType',
        header: true,
        footer: true,
        inputs: [
          {
            label: `General`,
            value: 'GENERAL'
          },
          {
            label: 'Shut down',
            value: 'SHUT_DOWN'
          },
        ]
      };
      this.isEsic = true;
      if (this.isContractorOrSubContractor === 'subcompanycontractor' && (this.isWcPolicy || !this.isLicenseData)) {
        let step: any;
        if (this.role === 119) {
          step = {
            id: 5,
            type: 'grid',
            title: 'Select Sub Work Order',
            button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
            header: true,
            footer: true,
            templateRef: this.contractorRef,
            isAboveElementRef: true,
            isBelowElementRef: false,
            columnDef: this.subWorkOrderColumnDefs,
            rowData: this.subWorkOrderData,
            gridOptions: this.stepsGridOptions,
          };
        } else {
          step = {
            id: 5,
            type: 'grid',
            title: 'Sub-Contractor Selection',
            button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
            header: true,
            footer: true,
            columnDef: this.subContractorColumnDefs,
            templateRef: this.contractorRef,
            isAboveElementRef: true,
            isBelowElementRef: false,
            rowData: this.subContractorData,
            gridOptions: this.stepsGridOptions
          };
        }
        if (this.requestType === 103) {
          this.newGetPassSteps.pop();
          this.newGetPassSteps.push(step);
        } else if (this.requestType === 104) {
          this.newGetPassSteps.pop();
          this.renewGetPassSteps.push(step);
        } else if (this.requestType === 105) {
          this.cancelGetPassSteps.pop();
          this.cancelGetPassSteps.push(step);
        }
      } else if (this.isContractorOrSubContractor !== 'subcompanycontractor') {
        this.isJobTypeSteps = this.role === 119 ? true : false;
        this.setStepsArrayOfEsicAndWcPolicy(jobTypeData);
      }
      this.isWcPolicy = false;
    } else if (value === 'wcPolicy') {

      this.getWCPolicy();
    } else if (value === 'general') {

      generalLabour = {
        id: 6,
        type: 'grid',
        title: 'Select Associate',
        button: { skip: false, cancel: false, submit: true, prev: true, next: false, close: false },
        header: true,
        footer: true,
        rowSelection: 'multiple',
        templateRef: this.labourCountRef,
        isAboveElementRef: true,
        isBelowElementRef: false,
        columnDef: this.laboursColumnDefs,
        rowData: this.generalData,
        gridOptions: this.stepsGridSelectLabourOptions,
      };
      this.isGeneral = true;
      this.isShutDown = false;
      if (this.requestType === 103) {
        this.newGetPassSteps.pop();
        this.newGetPassSteps.push(generalLabour);
      } else if (this.requestType === 104) {
        this.renewGetPassSteps.pop();
        this.renewGetPassSteps.push(generalLabour);
      } else if (this.requestType === 105) {
        this.cancelGetPassSteps.pop();
        this.cancelGetPassSteps.push(generalLabour);
      }
      //  this.rowSelectedData = this.generalData;
    } else if (value === 'shutDown') {
      shutDownLabour = {
        id: 6,
        type: 'grid',
        title: 'Select Associate',
        button: { skip: false, cancel: false, submit: true, prev: true, next: false, close: false },
        rowSelection: 'multiple',
        header: true,
        footer: true,
        templateRef: this.labourCountRef,
        isAboveElementRef: true,
        isBelowElementRef: false,
        columnDef: this.laboursColumnDefs,
        rowData: this.shutDownData,
        gridOptions: this.stepsGridSelectLabourOptions,
      };
      if (this.requestType === 103) {
        this.newGetPassSteps.pop();
        this.newGetPassSteps.push(shutDownLabour);
      } else if (this.requestType === 104) {
        this.renewGetPassSteps.pop();
        this.renewGetPassSteps.push(shutDownLabour);
      } else if (this.requestType === 105) {
        this.cancelGetPassSteps.pop();
        this.cancelGetPassSteps.push(shutDownLabour);
      }
      this.isGeneral = false;
      this.isShutDown = true;
      // this.rowSelectedData = this.shutDownData;
    } else if (value === 'ownAndSubContractor') {
      const workOrerSteps = {
        id: 3,
        type: 'grid',
        title: 'Select Job type',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        header: true,
        footer: true,
        templateRef: this.contractorRef,
        isAboveElementRef: true,
        isBelowElementRef: false,
        columnDef: this.workOrderColumnDefs,
        rowData: this.selectedWorkData,
        gridOptions: this.stepsGridOptions,
      };
      if (this.requestType === 103) {
        this.newGetPassSteps.pop();
        this.newGetPassSteps.push(workOrerSteps);
      } else if (this.requestType === 104) {
        this.renewGetPassSteps.pop();
        this.renewGetPassSteps.push(workOrerSteps);
      } else if (this.requestType === 105) {
        this.cancelGetPassSteps.pop();
        this.cancelGetPassSteps.push(workOrerSteps);
      }
    }
  }

  /**
   * @description When invoke this method it is set selectWorkOrder data.
   * @param item this parameter contain selected data.
   */
  private selectWorkOrder(item: any): void {
    if (item.data.Status === 'Expired') {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Work Order already expired.<br>Please extend validity date for select other work order.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
    } else if (item.data.Status === 'Active') {
      this.selectedWorkOrderNo = item.data.WorkOrderNO;
      if (this.role !== 119) {
        this.selectedContractor = this.selectedCompanyName;
        this.selectedWorkerOrderNo = this.selectedWorkOrderNo;
        // this.contactorForm.patchValue({
        //   selectedContractor: this.selectedCompanyName,
        //   selectedWorkerOrderNo: item.data.WorkOrderNO
        // });
      } else {
        this.selectedContractor = this.rowData[0].Company;
        this.selectedWorkerOrderNo = this.selectedWorkOrderNo;
        // this.contactorForm.patchValue({
        //   selectedContractor: 'SPIKYARC',
        //   selectedWorkerOrderNo: item.data.WorkOrderNO
        // });
      }
      this.getLicenseByContractor(item);
      /** Here get the check plant data */
      // this.getCheckPlants(item);
      if (this.isContractorOrSubContractor === 'subcompanycontractor' && this.role !== 119) {
        const data = { parentContractorCode: this.parentContractorCodeofSub, workoderordercode: item.data.ParentWorkOrder_Code };
        this.getSubContractor(data);
      } else if (this.isContractorOrSubContractor === 'subcompanycontractor' && this.role === 119) {
        const data = { parentContractorCode: this.parentContractorCodeofSub, workoderordercode: item.data.ParentWorkOrder_Code };
        this.getSubContractor(data);
        // const data = {
        //   requestType: this.requestType,
        //   parentContractorCode: 317,
        //   subContractorCode: 317,
        //   workOrderCode: this.selectedWorkOrderCode
        // };
        // this.getSubWorkOrder(data);
      }
    }
  }

  /**
   * @description When invoke this method it is set selectLicense data.
   * @param item this parameter contain selected data.
   */
  private selectLicense(item: any): void {
    if (item.data.Status === 'Expired') {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>License is already expired.<br>Please extend validity date for select other license.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
    } else if (item.data.Status === 'Active') {
      const selectedCompanyName = this.role === 119 ? this.rowData[0].ContractoreVendor : this.selectedCompanyName;
      this.isLicenseData = true;
      this.selectedContractor = selectedCompanyName;
      this.selectedWorkerOrderNo = this.selectedWorkOrderNo;
      this.selectedLicense = item.data.LicenseNo;
      this.licenseCode = item.data.License_Code;
      // this.contactorForm.patchValue({
      //   selectedContractor: selectedCompanyName,
      //   selectedWorkerOrderNo: this.selectedWorkOrderNo,
      //   selectedLicense: item.data.LicenseNo
      // });
      let isSkip = false;
      isSkip = this.isContractorOrSubContractor === 'Sub-Contractor Selection' ? true : false;
      const isLicenceDataAvailable = {
        id: 4,
        type: 'radio',
        title: 'Associate Covered Under',
        name: 'Associate Social Security Applicability',
        button: { skip: isSkip, cancel: false, submit: false, prev: true, next: true, close: false },
        header: true,
        footer: true,
        inputs: [
          {
            label: 'ESIC',
            value: 'ESIC'
          },
          {
            label: 'WC Policy',
            value: 'WC_POLICY'
          },
        ],
        formControlName: 'labourCoverType',
        rowData: 'rowSelectedData',
        templateRef: this.contractorRef,
        isAboveElementRef: true,
        isBelowElementRef: false,
      };

      let isSubContractorOrWorkOrder: any;
      if (false) {
        isSubContractorOrWorkOrder = {
          id: 6,
          type: 'grid',
          title: 'Select Sub Work Order',
          button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
          header: true,
          footer: true,
          templateRef: this.contractorRef,
          isAboveElementRef: true,
          isBelowElementRef: false,
          columnDef: this.subWorkOrderColumnDefs,
          rowData: this.subWorkOrderData,
          gridOptions: this.stepsGridOptions,
        };
      } else {
        isSubContractorOrWorkOrder = {
          id: 6,
          type: 'grid',
          title: 'Sub-Contractor Selection',
          button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
          header: true,
          footer: true,
          columnDef: this.subContractorColumnDefs,
          templateRef: this.contractorRef,
          isAboveElementRef: true,
          isBelowElementRef: false,
          rowData: this.subContractorData,
          gridOptions: this.stepsGridOptions
        };
      }

      if (this.requestType === 103) {
        this.newGetPassSteps.push(isLicenceDataAvailable);
        (this.isContractorOrSubContractor === 'subcompanycontractor') ?
          this.newGetPassSteps.push(isSubContractorOrWorkOrder) : this.newGetPassSteps.push({});
      } else if (this.requestType === 104) {
        this.renewGetPassSteps.push(isLicenceDataAvailable);
        (this.isContractorOrSubContractor === 'subcompanycontractor') ?
          this.renewGetPassSteps.push(isSubContractorOrWorkOrder) : this.renewGetPassSteps.push({});
      } else if (this.requestType === 105) {
        this.cancelGetPassSteps.push(isLicenceDataAvailable);
        (this.isContractorOrSubContractor === 'subcompanycontractor') ?
          this.cancelGetPassSteps.push(isSubContractorOrWorkOrder) : this.cancelGetPassSteps.push({});
      }
      /** Here set the row selected data inside the grid field */
      this.rowSelectedData = ['rowSelected'];
      this.setTemplateConfig(item);
      /** Call https service and get data of esic  */
      // this.role === 119 ? this.getCheckEsic(item) : '';
      /** Call https service and get data of getCompanyPolicy  */
      this.getCompanyPolicy(item);
    }
  }

  /**
   * Invoke when click on wc policy row data.
   */
  private selectWcPolicy(item: any): void {
    if (item.data.Status === 'Expired') {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Wc Policy is already expired.<br>Please extend validity date for select other wc policy.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
    } else if (item.data.Status === 'Active') {
      // this.isLicenseData = true;
      const selectedCompanyName = this.role === 119 ? this.rowData[0].ContractoreVendor : this.selectedCompanyName;
      this.selectedContractor = selectedCompanyName;
      this.selectedWorkerOrderNo = this.selectedWorkOrderNo;
      this.selectedLicense = item.data.LicenseNo;
      this.licenseCode = item.data.License_Code;
      this.wcpolicycode = item.data.ECPolicy_Code;
      const jobTypeData = {
        id: 5,
        type: 'radio',
        name: 'Please Select Job type.',
        title: 'Select Criteria',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        formControlName: 'jobType',
        header: true,
        footer: true,
        inputs: [
          {
            label: `General`,
            value: 'GENERAL'
          },
          {
            label: 'Shut down',
            value: 'SHUT_DOWN'
          },
        ]
      };
      let isSubContractorOrWorkOrder: any;
      if (this.curruntRole === 119) {
        isSubContractorOrWorkOrder = {
          id: 7,
          type: 'grid',
          title: 'Select Sub Work Order',
          button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
          header: true,
          footer: true,
          templateRef: this.contractorRef,
          isAboveElementRef: true,
          isBelowElementRef: false,
          columnDef: this.subWorkOrderColumnDefs,
          rowData: this.subWorkOrderData,
          gridOptions: this.stepsGridOptions,
        };
      } else {
        isSubContractorOrWorkOrder = {
          id: 7,
          type: 'grid',
          title: 'Sub-Contractor Selection',
          button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
          header: true,
          footer: true,
          columnDef: this.subContractorColumnDefs,
          templateRef: this.contractorRef,
          isAboveElementRef: true,
          isBelowElementRef: false,
          rowData: this.subContractorData,
          gridOptions: this.stepsGridOptions
        };
      }
      if (this.requestType === 103) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.newGetPassSteps.push(isSubContractorOrWorkOrder);
        } else {
          this.isJobTypeSteps = this.role === 119 ? true : false;
          this.newGetPassSteps.push(jobTypeData);
          this.newGetPassSteps.push({});
        }
      } else if (this.requestType === 104) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.renewGetPassSteps.push(isSubContractorOrWorkOrder);
        } else {
          this.isJobTypeSteps = this.role === 119 ? true : false;
          this.renewGetPassSteps.push(jobTypeData);
          this.renewGetPassSteps.push({});
        }
      } else if (this.requestType === 105) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.cancelGetPassSteps.push(isSubContractorOrWorkOrder);
        } else {
          this.isJobTypeSteps = this.role === 119 ? true : false;
          this.cancelGetPassSteps.push(jobTypeData);
          this.cancelGetPassSteps.push({});
        }

      }
      /** Here set the row selected data inside the grid field */
      this.rowSelectedData = ['rowSelected'];
    }
  }

  /**
   * When invoke this method
   */
  private selectSubContractor() {
    const data = {
      requestType: this.requestType,
      parentContractorCode: this.parentContractorCode,
      subContractorCode: this.subContractorData[0].ContractorVendor_Code,
      workOrderCode: this.selectedWorkOrderCode
    };
    this.getSubWorkOrder(data);
  }

  /**
  * When invoke this method
  */
  private selectSubWorkOrder(item: any) {
    if (item.data.Status === 'Expired') {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Work Order already expired.<br>Please extend validity date for select other work order.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
    } else if (item.data.Status !== 'Active') {
      const data = {
        requestType: this.requestType,
        parentContractorCode: this.parentContractorCode,
        subContractorCode: this.subContractorData ? this.subContractorData[0].ContractorVendor_Code : '',
        subWorkOrderCode: this.subWorkOrderCode,
        workOrderCode: this.selectedWorkOrderCode
      };
      this.getSubLicense(data);
    }
  }

  /**
   * `Select Sub License`
   * @param item When invoke this method
   */
  private selectSubLicense(item: any): void {
    if (item.data.Status === 'Expired') {
      this.alertOption = {
        title: 'OOPS!!!',
        html: `<span class='customClass'>Wc Policy is already expired.<br>Please extend validity date for select other wc policy.</span>`,
        type: 'error'
      },
        setTimeout(() => {
          this.openAlert();
        }, 10);
    } else if (item.data.Status !== 'Active') {
      const jobTypeData = {
        id: 5,
        type: 'radio',
        name: 'Please Select Job type.',
        title: 'Select Job type',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        formControlName: 'jobType',
        header: true,
        footer: true,
        inputs: [
          {
            label: `General`,
            value: 'GENERAL'
          },
          {
            label: 'Shut down',
            value: 'SHUT_DOWN'
          },
        ]
      };
      if (this.requestType === 103) {
        this.isJobTypeSteps = this.role === 119 ? true : false;
        this.newGetPassSteps.push(jobTypeData);
        this.newGetPassSteps.push({});
      } else if (this.requestType === 104) {
        this.isJobTypeSteps = this.role === 119 ? true : false;
        this.renewGetPassSteps.push(jobTypeData);
        this.renewGetPassSteps.push({});
      } else if (this.requestType === 105) {
        this.isJobTypeSteps = this.role === 119 ? true : false;
        this.cancelGetPassSteps.push(jobTypeData);
        this.cancelGetPassSteps.push({});
      }
      /** Here set the row selected data inside the grid field */
      this.rowSelectedData = ['rowSelected'];
    }
  }

  /**
   * When invoke this method it is set template config.
   */
  private setTemplateConfig(item: any): void {

    if (item.arrayItem[0].title === 'Contractor Selection') {
      this.isSelectedContractor = true;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select Job type') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select License') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = true;
    } else if (item.arrayItem[0].title === 'Sub-Contractor Selection') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select WC Policy') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.arrayItem[0].title === 'Select Associate') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    }
  }

  /**
   * @method getWorkOrderData
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getWorkOrderData(item?: any): void {
    let contractorWorkVendorCode: any;
    // tslint:disable-next-line:max-line-length
    contractorWorkVendorCode = this.curruntRole === 119 ? this.contractorInformation.ContractorVendor_Code : item.data.ContractorVendor_Code;
    const workOrderData = { contractorVendorCode: contractorWorkVendorCode, requestType: this.requestType };
    this.gatePassRequestsService.getWorkOrderByContractr(workOrderData).subscribe((rowSelectedData: any) => {
      if (!rowSelectedData) { return; }
      if (this.curruntRole !== 119) {
        if (item) {
          this.setTemplateConfig(item);
        }
        this.rowSelectedData = rowSelectedData.Data;
        this.selectedWorkData = rowSelectedData.Data;
        /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.selectedCompanyName = item.data.Company;
        this.parentContractorCode = item.data.ContractorVendor_Code;
        this.parentContractorCodeofSub = item.data.ContractorVendor_Code;
        // this.contactorForm.patchValue({
        //   selectedC0ontractor: item.data.Company,
        // });
        this.selectedContractor = this.selectedCompanyName;
      }
      // tslint:disable-next-line:brace-style
      else {
        // this.rowSelectedData = rowSelectedData.Data;
        this.selectedWorkData = rowSelectedData.Data;
        this.selectedCompanyName = this.contractorInformation.Company;
        this.parentContractorCode = this.contractorInformation.ContractorVendor_Code;
        this.parentContractorCodeofSub = this.contractorInformation.ContractorVendor_Code;
        this.selectedContractor = this.selectedCompanyName;
      }
      if (this.role === 119) {
        this.setTemplateConfig(item);
        this.rowSelectedData = rowSelectedData.Data;
        /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.selectedCompanyName = item.data.Company;
        this.parentContractorCode = item.data.ContractorVendor_Code;
        this.parentContractorCodeofSub = item.data.ContractorVendor_Code;
        // this.contactorForm.patchValue({
        //   selectedContractor: item.data.Company,
        // });
        this.selectedContractor = this.selectedCompanyName;
        this.selectedWorkData = rowSelectedData.Data;
        // this.contactorForm.patchValue({
        //   selectedContractor: 'SPIKYARC',
        // });
        this.selectedContractor = this.rowData[0].Company;
      }
    });
  }

  /**
   * @method getLicenseByContractor
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getLicenseByContractor(item: any): void {

    const parentContractorVendorCd = this.role === 119 ? this.rowData[0].ContractoreVendor : item.data.ParentContractorVendor_Code;
    const workOrderData = {
      parentContractorVendorCode: parentContractorVendorCd,
      parentWorkOrderCode: item.data.ParentWorkOrder_Code, requestType: this.requestType
    };
    this.selectedWorkOrderCode = item.data.ParentWorkOrder_Code;
    this.gatePassRequestsService.getLicenseByContractor(workOrderData).subscribe((rowSelectedData: any) => {

      //   this.licenceData = rowSelectedData.Data;
      this.rowSelectedData = rowSelectedData.Data;
      this.isLicenceData = rowSelectedData.length > 0 ? true : false;
      if (this.role !== 119) {
        // this.licenseCode = rowSelectedData.length === 0 ? rowSelectedData : rowSelectedData[0].License_Code;
        this.setTemplateConfig(item);
        this.licenceData = rowSelectedData.Data.length === 0 ? rowSelectedData : rowSelectedData.Data;
        // this.setTemplateConfig(item);
        /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsData(rowSelectedData);
      } else {
        this.setTemplateConfig(item);
        this.licenceData = rowSelectedData.Data.length === 0 ? rowSelectedData : rowSelectedData.Data;
        // this.setTemplateConfig(item);
        /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        this.setStepsData(rowSelectedData);
      }
      this.getCheckPlants(item);
      this.gatePassRequestsService.getWCPolicy(this.selectedWorkOrderCode).subscribe((wcPolicyData: any) => {
        this.wcPolicyData = wcPolicyData.Data;
        // this.rowSelectedData = wcPolicyData.Data;
        // this.wcpolicycode = item.data.ECPolicy_Code;
      });
    });
  }

  /**
   * @method getLicenseByContractor
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getCheckPlants(item: any): void {

    const workOrderData = {
      parentContractorVendorCode: item.data.ParentContractorVendor_Code,
      parentWorkOrderCode: item.data.ParentWorkOrder_Code,
    };
    this.parentContractorCode = item.data.ParentContractorVendor_Code;
    this.gatePassRequestsService.getCheckPlants(workOrderData).subscribe((plantDataResponse: any) => {

      //   this.stateCode = plantDataResponse[0].State_Code;
      this.plantData = plantDataResponse.Data;
      if (this.role !== 119) {
        // this.stateCode = plantDataResponse[0].State_Code;
        this.plantData = plantDataResponse.Data;
      } else if (this.role === 119) {
        const plantData = [];
        // tslint:disable-next-line: no-increment-decrement
        // tslint:disable-next-line: curly
        // for (let i = 0; i < plantDataResponse.Data.length; i++) {
        //   plantData.push({ id: plantDataResponse[i].Plant_Code, name: plantDataResponse[i].Name });
        // }
        this.plantData = plantDataResponse.Data;
        // this.newGetPassForm.patchValue({
        //   plantsType: plantDataResponse.Data[0].Plant_Code
        // });
        // /** Here set the initial value of `selectedContractor` and `selectedWorkerOrderNo` form control */
        // // this.setStepsData(this.licenceData);
        // this.setStepsData(this.rowSelectedData);
      }
    });
  }

  /**
  * @method getLicenseByContractor.
  * Invoke this method and it is get work order data by contractor.
  * @param item This parameter contain row selecte data inside object `data`.
  */
  private getCheckLicenseRequired(item?: any): void {
    const workOrderData = {
      plantCode: this.plantCode, requestType: this.requestType,
      parentWorkOrderCode: this.selectedWorkOrderCode,
    };
    this.gatePassRequestsService.getCheckLicenseRequired(workOrderData).subscribe((licenseData: any) => {
      // console.log('licenseData', licenseData);
    });
  }

  /**
   * @method getCheckEsic
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getCheckEsic(item: any): void {
    const workOrderData = {
      plantCode: this.plantCode, parentContractorCode: this.parentContractorCode
    };
    this.gatePassRequestsService.getCheckEsic(workOrderData).subscribe((esicData: any) => {
      // console.log('esicData', esicData);
    });
  }

  /**
   * @method getCompanyPolicy
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getCompanyPolicy(item: any): void {
    const workOrderData = {
      parentWorkOrderCode: this.selectedWorkOrderCode
    };
    this.gatePassRequestsService.getCompanyPolicy(workOrderData).subscribe((esicData: any) => {
      console.log('esicData', esicData);
    });
  }

  /**
  * @method getSelectionValueOfLabour
  * Invoke this method and it is get work order data by contractor.
  * @param item This parameter contain row selecte data inside object `data`.
  */
  private getSelectionValueOfLabour(body: object): void {

    this.gatePassRequestsService.getSelectionValueOfLabour(body).subscribe((laboursResponse: any) => {
      //  console.log('esicData', esicData);
      this.selectionLabourCount = laboursResponse.Data;
    });
  }

  /**
   * @method getCompanyPolicy
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getAllLabour(allLabourBody: any, value: string): void {

    this.gatePassRequestsService.getAllLabour(allLabourBody).subscribe((labourResponse: any) => {

      if (value === 'general') {
        this.generalData = labourResponse;
      } else if (value === 'shutdown') {
        this.shutDownData = labourResponse;
      }
    });
  }

  /**
   * @method getSubContractor
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getSubContractor(data: object): void {
    this.gatePassRequestsService.getSubContractor(data).subscribe((subContractorRes: any) => {
      this.subContractorData = subContractorRes;
    });
  }

  /**
   * @method getSubWorkOrder
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getSubWorkOrder(data: object): void {
    this.gatePassRequestsService.getSubWorkOrder(data).subscribe((subWorkOrderRes: any) => {
      this.subWorkOrderData = subWorkOrderRes;
      if (subWorkOrderRes.length > 0) {
        this.subWorkOrderCode = subWorkOrderRes[0].WorkOrderRef_Code;
      }
      this.setSubWorkOrderSteps(subWorkOrderRes);
    });
  }

  /**
   * @method getSubLicense
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getSubLicense(data: object): void {
    this.gatePassRequestsService.getSubLicense(data).subscribe((subLicenseRes: any) => {
      this.subLicense = subLicenseRes;
      this.setLicenseSteps(subLicenseRes);
    });
  }

  /**
   * @method getCompanyPolicy
   * Invoke this method and it is get work order data by contractor.
   * @param item This parameter contain row selecte data inside object `data`.
   */
  private getWCPolicy(): void {
    this.rowSelectedData = this.wcPolicyData;
    const data = {
      id: 5,
      type: 'grid',
      title: 'Select WC Policy',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      templateRef: this.contractorRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.wcPolicyColumnDefs,
      rowData: this.rowSelectedData,
      gridOptions: this.stepsGridOptions,
    };
    this.setGridStepsArrayOfEsicAndWcPolicy(data);
    this.isEsic = false;
    this.isWcPolicy = true;
  }

  /**
   * @descriptions This method invoke and it is add the steps array.
   */
  private setStepsArrayOfEsicAndWcPolicy(jobTypeData: any): void {
    if (this.requestType === 103) {
      this.newGetPassSteps.pop();
      this.newGetPassSteps.push(jobTypeData);
      this.newGetPassSteps.push({});
    } else if (this.requestType === 104) {
      this.renewGetPassSteps.pop();
      this.renewGetPassSteps.push(jobTypeData);
      this.renewGetPassSteps.push({});
    } else if (this.requestType === 105) {
      this.cancelGetPassSteps.pop();
      this.cancelGetPassSteps.push(jobTypeData);
      this.cancelGetPassSteps.push({});
    }
  }

  /**
  * @descriptions This method invoke and it is add the steps array.
  */
  private setGridStepsArrayOfEsicAndWcPolicy(data: any): void {

    if (this.requestType === 103) {
      if (this.isContractorOrSubContractor === 'subcompanycontractor') {
        this.newGetPassSteps.pop();
        this.newGetPassSteps.push(data);
      } else {
        if (this.isEsic) {
          this.newGetPassSteps.pop();
        }
        this.newGetPassSteps.pop();
        this.newGetPassSteps.push(data);
      }
    } else if (this.requestType === 104) {
      if (this.isContractorOrSubContractor === 'subcompanycontractor') {
        this.renewGetPassSteps.pop();
        this.renewGetPassSteps.push(data);
      } else {
        if (this.isEsic) {
          this.renewGetPassSteps.pop();
        }
        this.renewGetPassSteps.pop();
        this.renewGetPassSteps.push(data);
      }
    } else if (this.requestType === 105) {
      if (this.isContractorOrSubContractor === 'subcompanycontractor') {
        this.cancelGetPassSteps.pop();
        this.cancelGetPassSteps.push(data);
      } else {
        if (this.isEsic) {
          this.cancelGetPassSteps.pop();
        }
        this.cancelGetPassSteps.pop();
        this.cancelGetPassSteps.push(data);
      }
    }
    console.log('wcPolic', this.newGetPassSteps);
  }

  /**
   * @method getAllcontractorData
   * @description Get All contractor data.
   */
  private getAllcontractorData(): void {
    this.gatePassRequestsService.getAllContractorData().subscribe((contractorResponse) => {
      //   this.gridRowData = contractorResponse.Data.Table;
      if (contractorResponse.length > 0) {
        this.gridRowData = contractorResponse;
      }
      if (contractorResponse.Data) {
        this.gridRowData = contractorResponse.Data.Table;
      }
      this.setStepArray();
    });
  }

  private getContractorsSelectedByDefault(userId: any, roleCode: any): void {
    this.gatePassRequestsService.getContractorsbydefault(userId, roleCode).subscribe((contractorResponse) => {
      if (contractorResponse.length > 0) {
        this.gridRowData = contractorResponse;
      }
      if (contractorResponse.Data) {
        this.gridRowData = contractorResponse.Data.Table;
      }
      this.setStepArray();
    });
  }

  private getStates(): void {

    this.gatePassRequestsService.getCommonSpUserPlantAcces().subscribe((response) => {
        this.lStates = _.uniqBy(response, 'State')
        console.log("lStates ----->",this.lStates)
    },(err) => {
        // console.log("err ------>",err)
    })

    // this.gatePassRequestsService.getAllState().subscribe((response: any) => {
    //   console.log("response --->",response)
    //   this.statelist = response;
    //   this.lStates = this.statelist
    // },(err) => {
    //   console.log("err ----->",err)
    // });
  }
  /**
   * @method insertGetPassRequest
   * @description Invoke this method it is insert get pass request.
   */
  private insertGetPassRequest(data: any): void {
    this.actions = 'Approved';
    const objgatepass = this.prepareReqForActionSave(this.actions);
    this.gatePassRequestsService.insertGatePassRequest(data).subscribe((insertGetPassRequestResponse: any) => {
      this.logHelperService.logSuccess({
        message: 'Onboarding Request submitted Successfully'
      });
      this.newGetPass.closeModel();
      this.router.navigate(['labour/gate-pass-requests']);
      this.gatePassRequestsService.sendMailForApproval(objgatepass);
    });
  }
  private insertGetPassRequestRenew(data: any): void {
    this.actions = 'Approved';
    const objgatepass = this.prepareReqForActionSave(this.actions);
    this.gatePassRequestsService.insertGatePassRequestRenew(data).subscribe((insertGetPassRequestResponse: any) => {
      this.logHelperService.logSuccess({
        message: 'GatePass Renewed successfully'
      });
      this.renewGetPass.closeModel();
      this.router.navigate(['labour/gate-pass-requests']);
      this.gatePassRequestsService.sendMailForApproval(objgatepass);
    });
  }
  private insertGetPassRequestCancel(data: any): void {
    this.actions = 'Approved';
    const objgatepass = this.prepareReqForActionSave(this.actions);
    this.gatePassRequestsService.insertGatePassRequestCancel(data).subscribe((insertGetPassRequestResponse: any) => {
      this.logHelperService.logSuccess({
        message: 'GatePass Cancelled successfully'
      });
      this.cancelGetPass.closeModel();
      this.router.navigate(['labour/gate-pass-requests']);
      this.gatePassRequestsService.sendMailForApproval(objgatepass);
    });
  }

  /**
   * @method getLabourManagement
   * @description Invoke this method it is insert get pass request.
   */
  private getLabourManagement(data: any): void {
    this.gatePassRequestsService.getLabourManagement(data).subscribe((labourManagementResponse: any) => {

    });
  }

  /**
   * @method setStepsData
   * @description When invoke this method it is set array steps based on licence data.
   */
  private setStepsData(rowSelectedData: any): void {
    // this.rowSelectedData = 'rowSelectedData'
    const licenseRowData = this.role === 119 ? this.licenceData : this.rowSelectedData;
    const isLicenceDataAvailable = {
      id: 4,
      type: 'grid',
      title: 'Select License',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      templateRef: this.contractorRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.licenceColumnDefs,
      rowData: licenseRowData,
      gridOptions: this.stepsGridOptions,
    };

    const checkPlants = {
      id: 4,
      type: 'dropDown',
      title: 'Select Plants',
      name: 'Please Select Plants',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      formControlName: 'plantsType',
      virtualScroll: false,
      dropDownSelection: false,
      dropDownData: this.plantData,
      placeholder: 'Select Plant',
      rowData: 'rowSelectedData',
      templateRef: this.contractorRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
    };

    const isNotLicenceDataAvailable = {
      id: 4,
      type: 'radio',
      title: 'Select Labour covered',
      name: 'Please select Labour covered',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      inputs: [
        {
          label: 'ESIC',
          value: 'ESIC'
        },
        {
          label: 'WC Policy',
          value: 'WC_POLICY'
        },
      ],
      formControlName: 'labourCoverType',
      rowData: 'rowSelectedData',
      templateRef: this.contractorRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
    };
    const config = {
      rowData: rowSelectedData,
      checkPlantSteps: checkPlants,
      licenceSteps: isLicenceDataAvailable,
      labourCoverSteps: isNotLicenceDataAvailable
    };
    this.setStepsDataOnSelectedRow(config);
  }

  /**
   * Invoke this method and it is set all steps array.
   * @param rowSelectedData This parameter contain selected row data.
   */
  private setStepsDataOnSelectedRow(config: any): void {

    if (this.requestType === 103) {
      if (config.rowData.Data.length > 0) {
        //   this.role === 119 ? this.newGetPassSteps.push(config.checkPlantSteps) : '';
        this.newGetPassSteps.push(config.licenceSteps);
      } else {
        //   this.role === 119 ? this.newGetPassSteps.push(config.checkPlantSteps) : '';
        this.newGetPassSteps.push(config.labourCoverSteps);
        this.newGetPassSteps.push({});
      }
    } else if (this.requestType === 104) {
      if (config.rowData.length > 0) {
        //   this.role === 119 ? this.renewGetPassSteps.push(config.checkPlantSteps) : '';
        this.renewGetPassSteps.push(config.licenceSteps);
      } else {
        //   this.role === 119 ? this.renewGetPassSteps.push(config.checkPlantSteps) : '';
        this.renewGetPassSteps.push(config.labourCoverSteps);
        this.renewGetPassSteps.push({});
      }
    } else if (this.requestType === 105) {
      if (config.rowData.length > 0) {
        // this.role === 119 ? this.cancelGetPassSteps.push(config.checkPlantSteps) : '';
        this.cancelGetPassSteps.push(config.licenceSteps);
      } else {
        // this.role === 119 ? this.cancelGetPassSteps.push(config.checkPlantSteps) : '';
        this.cancelGetPassSteps.push(config.labourCoverSteps);
        this.cancelGetPassSteps.push({});
      }
    }
    /** Here set the row selected data inside the grid field */
    this.rowSelectedData = config.rowData.Data.length > 0 ? config.rowData : ['rowSelected'];
  }

  /**
   * @method setSubWorkOrderSteps
   * @description When invoke this method it is set array steps based on licence data.
   */
  private setSubWorkOrderSteps(rowSelectedData: any): void {
    const subWorkOrder = {
      id: 7,
      type: 'grid',
      title: 'Select Sub Work Order',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      templateRef: this.contractorRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.subWorkOrderColumnDefs,
      rowData: 'rowSelectedData',
      gridOptions: this.stepsGridOptions,
    };

    if (this.requestType === 103) {
      this.newGetPassSteps.push(subWorkOrder);
    } else if (this.requestType === 104) {
      this.renewGetPassSteps.push(subWorkOrder);
    } else if (this.requestType === 105) {
      this.cancelGetPassSteps.push(subWorkOrder);
    }
    /** Here set the row selected data inside the grid field */
    this.rowSelectedData = rowSelectedData.length > 0 ? rowSelectedData : ['rowSelected'];
  }

  /**
   * When invoke this method
   */
  private setLicenseSteps(rowSelectedData: any): void {
    const subLicense = {
      id: 8,
      type: 'grid',
      title: 'Select Sub License',
      button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
      header: true,
      footer: true,
      templateRef: this.contractorRef,
      isAboveElementRef: true,
      isBelowElementRef: false,
      columnDef: this.subLicenceColumnDefs,
      rowData: 'rowSelectedData',
      gridOptions: this.stepsGridOptions,
    };
    if (this.requestType === 103) {
      this.newGetPassSteps.push(subLicense);
    } else if (this.requestType === 104) {
      this.renewGetPassSteps.push(subLicense);
    } else if (this.requestType === 105) {
      this.cancelGetPassSteps.push(subLicense);
    }
    /** Here set the row selected data inside the grid field */
    this.rowSelectedData = rowSelectedData.length > 0 ? rowSelectedData : ['rowSelected'];
  }

  /**
   * Invoke this method and it is set array list data of `AllContractor` for `rowData`.
   * This data is set for `newGetPassRequest`, `renewGetPassRequest` and `cancelGetPass`.
   */
  private setStepArray(): void {
    let firstSteps: object;
    let secondSteps: object;
    let thirdSteps: object;
    if (this.curruntRole === 119) {
      firstSteps = {
        id: 1,
        type: 'radio',
        name: 'Please Select Associate covered.',
        title: 'Select Criteria',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        formControlName: 'contractManType',
        header: true,
        footer: true,
        inputs: [
          {
            label: `Principal Contractor`,
            value: 'CONTRACTORS_MAN'
          },
          {
            label: 'Sub-Contractor',
            value: 'SUB_CONTRACTORS_MAN'
          },
        ]
      };
      secondSteps = {
        id: 3,
        type: 'grid',
        title: 'Select Job type',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        header: true,
        footer: true,
        templateRef: this.contractorRef,
        isAboveElementRef: true,
        isBelowElementRef: false,
        columnDef: this.workOrderColumnDefs,
        rowData: 'rowSelectedData',
        gridOptions: this.stepsGridOptions,
      };
      const stepIndex = { first: firstSteps, second: secondSteps };
      this.setStepsArrayAndConfigAndForm(stepIndex);
    } else {
      firstSteps = {
        id: 1,
        type: 'radio',
        name: 'Please Select Associate covered.',
        title: 'Select Criteria',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        formControlName: 'contractManType',
        header: true,
        footer: true,
        inputs: [
          {
            label: `Principal Contractor`,
            value: 'CONTRACTORS_MAN'
          },
          {
            label: 'Sub-Contractor',
            value: 'SUB_CONTRACTORS_MAN'
          },
        ]
      };

      secondSteps = {
        id: 2,
        type: 'grid',
        title: 'Contractor Selection',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        header: true,
        footer: true,
        columnDef: this.gridColumnDefs,
        rowData: this.gridRowData,
        gridOptions: this.stepsGridOptions
      };

      thirdSteps = {
        id: 3,
        type: 'grid',
        title: 'Select Job type',
        button: { skip: false, cancel: false, submit: false, prev: true, next: true, close: false },
        header: true,
        footer: true,
        templateRef: this.contractorRef,
        isAboveElementRef: true,
        isBelowElementRef: false,
        columnDef: this.workOrderColumnDefs,
        rowData: 'rowSelectedData',
        gridOptions: this.stepsGridOptions,
      };
      const stepIndex = { first: firstSteps, second: secondSteps, third: thirdSteps };
      this.setStepsArrayAndConfigAndForm(stepIndex);
    }

    // const secondStep = {
    //   id: 2,
    //   type: 'radio',
    //   name: 'Please select row selections.',
    //   title: 'Select Criteria',
    //   button: { skip: true, cancel: false, submit: false, prev: true, next: true, close: false },
    //   formControlName: 'gridRowSelection',
    //   header: true,
    //   footer: true,
    //   inputs: [
    //     {
    //       label: 'Single Select',
    //       value: 'SINGLE_SELECT'
    //     },
    //     {
    //       label: 'Multi Select',
    //       value: 'MULTI_SELECT'
    //     },
    //   ]
    // };
  }

  /**
   * This method invoke when prev button clicked.
   */
  public onPrevEvent(item: any, value: number): void {
    if (this.requestType !== value) { return; }
    /** Set some property value based on prev action click */
    this.setPropertyConfigOnPrevAction(item);
    /** Set some property value and remove data from the array based on prev action click */
    this.setPropertyValueAndRemoveDataFromArrayOfWorkOrder(item);
    /** Set some property value of license and remove data from the array based on prev action click */
    this.setPropertyValueAndRemoveDataFromArrayOfLicense(item);
    /** Set some property value of laboured cover and remove data from the array based on prev action click */
    this.setPropertyValueAndRemoveDataFromArrayOfLabouredCover(item);
    /** Set some property value of laboured cover and remove data from the array based on prev action click */
    this.setPropertyValueAndRemoveDataFromArrayOfWcPolicy(item);
    /** Set some property value of sub contractor and remove data from the array based on prev action click */
    this.setPropertyValueAndRemoveDataFromArrayOfSubContractor(item);
    /** Set some property value of sub worker and remove data from the array based on prev action click */
    this.setPropertyValueAndRemoveDataFromArrayOfSubWorkOrder(item);
    /** Set some property value of sub license and remove data from the array based on prev action click */
    this.setPropertyValueAndRemoveDataFromArrayOfSubLicense(item);
    // this.gatePassRequestsService.getContractorVendorData().subscribe((res: any[]) => {
    //   this.contractorVendors = res;
    // });
    this.prevStep = item.data[0].title;
    this.currentStep = item.data[0].title;
    console.log('onPrev', this.newGetPassSteps);
    console.log('es', this.isEsic);
  }

  private setStepsArrayAndConfigAndForm(stepIndex: any): void {
    if (this.curruntRole === 119) {
      this.newGetPassSteps = [stepIndex.first, stepIndex.second];
      this.renewGetPassSteps = [stepIndex.first, stepIndex.second];
      this.cancelGetPassSteps = [stepIndex.first, stepIndex.second];
    } else {
      this.newGetPassSteps = [stepIndex.first, stepIndex.second, stepIndex.third];
      this.renewGetPassSteps = [stepIndex.first, stepIndex.second, stepIndex.third];
      this.cancelGetPassSteps = [stepIndex.first, stepIndex.second, stepIndex.third];
    }
    this.newGetPassForm = this.formBuilder.group({
      contractManType: ['', Validators.required],
      labourCoverType: ['', Validators.required],
      jobType: ['', Validators.required],
      plantsType: [Validators.required],
      gridRowSelection: ['', Validators.required],
    });

    this.contactorForm = this.formBuilder.group({
      selectedContractor: ['', Validators.required],
      selectedWorkerOrderNo: ['', Validators.required],
      selectedLicense: ['', Validators.required],
    });
    this.contactorForm.controls.selectedContractor.disable();
    this.contactorForm.controls.selectedWorkerOrderNo.disable();
    this.contactorForm.controls.selectedLicense.disable();
  }

  /**
   * Invoke this method and it is set property value.
   * Like `isSelectedContractor` , `isSelectedContractorAndWorkOrder` and `isSelectedContractorWorkOrderAndLicense`
   */
  private setPropertyConfigOnPrevAction(item: any): void {
    if (item.data[0].title === 'Contractor Selection') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.data[0].title === 'Select Job type') {
      this.isSelectedContractor = true;
      this.isSelectedContractorAndWorkOrder = false;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.data[0].title === 'Select License') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    } else if (item.data[0].title === 'Select Associate covered') {
      this.isSelectedContractor = false;
      if (this.isLicenseData) {
        this.isSelectedContractorWorkOrderAndLicense = true;
        this.isSelectedContractorAndWorkOrder = false;
      } else {
        this.isSelectedContractorWorkOrderAndLicense = false;
        this.isSelectedContractorAndWorkOrder = true;
      }
    } else if (item.data[0].title === 'Sub-Contractor Selection') {
      this.isSelectedContractor = false;
      this.isSelectedContractorAndWorkOrder = true;
      this.isSelectedContractorWorkOrderAndLicense = false;
    }
  }

  /**
   * Invoke this method and it is set property value.
   * Like `isPrevCallOnce`.
   * And remove steps data from the array.
   * Like `newGetPassSteps`, `renewGetPassSteps`, and `cancelGetPassSteps`.
   */
  private setPropertyValueAndRemoveDataFromArrayOfWorkOrder(item: any): void {
    if (item.data[0].title === 'Select Job type') {
      if (this.isLicenseData) { this.isLicenseData = false; }
      if (this.requestType === 103) {
        if (this.prevStep === 'Select Associate covered' && this.isEsic) {
          if (this.isContractorOrSubContractor === 'subcompanycontractor') {
            this.newGetPassSteps.pop();
            this.newGetPassSteps.pop();
          } else {
            this.newGetPassSteps.pop();
            this.newGetPassSteps.pop();
            this.newGetPassSteps.pop();
          }
        } else if (this.prevStep === 'Select Associate covered' || this.currentStep === 'Select Associate covered') {
          this.newGetPassSteps.pop();
          this.newGetPassSteps.pop();
        } else if (this.prevStep === 'Select License' || this.currentStep === 'Select License') {
          this.newGetPassSteps.pop();
        } else if (this.role === 119 && (this.prevStep === 'Select Plants' || this.currentStep === 'Select Plants')) {
          !this.isLicenceData ? this.newGetPassSteps.pop() : '';
          this.isJobTypeSteps ? this.newGetPassSteps.pop() : '';
          this.newGetPassSteps.pop();
          this.newGetPassSteps.pop();
        }
      } else if (this.requestType === 104) {
        if (this.prevStep === 'Select Associate covered' && this.isEsic) {
          if (this.isContractorOrSubContractor === 'subcompanycontractor') {
            this.renewGetPassSteps.pop();
            this.renewGetPassSteps.pop();
          } else {
            this.renewGetPassSteps.pop();
            this.renewGetPassSteps.pop();
            this.renewGetPassSteps.pop();
          }
        } else if (this.prevStep === 'Select Associate covered' || this.currentStep === 'Select Associate covered') {
          this.renewGetPassSteps.pop();
          this.renewGetPassSteps.pop();
        } else if (this.prevStep === 'Select License' || this.currentStep === 'Select License') {
          this.renewGetPassSteps.pop();
        } else if (this.role === 119 && (this.prevStep === 'Select Plants' || this.currentStep === 'Select Plants')) {
          !this.isLicenceData ? this.renewGetPassSteps.pop() : '';
          this.isJobTypeSteps ? this.renewGetPassSteps.pop() : '';
          this.renewGetPassSteps.pop();
          this.renewGetPassSteps.pop();
        }
      } else if (this.requestType === 105) {
        if (this.prevStep === 'Select Associate covered' && this.isEsic) {
          if (this.isContractorOrSubContractor === 'subcompanycontractor') {
            this.cancelGetPassSteps.pop();
            this.cancelGetPassSteps.pop();
          } else {
            this.cancelGetPassSteps.pop();
            this.cancelGetPassSteps.pop();
            this.cancelGetPassSteps.pop();
          }
        } else if (this.prevStep === 'Select Associate covered' || this.currentStep === 'Select Associate covered') {
          this.cancelGetPassSteps.pop();
          this.cancelGetPassSteps.pop();
        } else if (this.prevStep === 'Select License' || this.currentStep === 'Select License') {
          this.cancelGetPassSteps.pop();
        } else if (this.role === 119 && (this.prevStep === 'Select Plants' || this.currentStep === 'Select Plants')) {
          !this.isLicenceData ? this.cancelGetPassSteps.pop() : '';
          this.isJobTypeSteps ? this.cancelGetPassSteps.pop() : '';
          this.cancelGetPassSteps.pop();
          this.cancelGetPassSteps.pop();
        }
      }
      this.wcPolicyData = null;
      this.isEsic = false;
      this.isWcPolicy = false;
      this.isJobTypeSteps = false;
      this.newGetPassForm.controls.labourCoverType.reset();
      this.role === 119 ? this.newGetPassForm.controls.plantsType.reset() : '';
    }
  }

  /**
   * Invoke this method and it is set property value.
   * Like `isSelectLicencePrevCallOnce`.
   * And remove steps data from the array.
   * Like `newGetPassSteps`, `renewGetPassSteps`, and `cancelGetPassSteps`
   */
  private setPropertyValueAndRemoveDataFromArrayOfLicense(item: any): void {
    if (item.data[0].title === 'Select License') {
      if (this.requestType === 103) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.newGetPassSteps.pop();
          this.newGetPassSteps.pop();
        } else {
          if (this.isWcPolicy) {
            this.newGetPassSteps.pop();
            this.newGetPassSteps.pop();
          } else if (this.isEsic) {
            this.newGetPassSteps.pop();
            this.newGetPassSteps.pop();
            this.newGetPassSteps.pop();
          } else {
            this.newGetPassSteps.pop();
            this.newGetPassSteps.pop();
          }
        }
      } else if (this.requestType === 104) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.renewGetPassSteps.pop();
          this.renewGetPassSteps.pop();
        } else {
          if (this.isWcPolicy) {
            this.renewGetPassSteps.pop();
            this.renewGetPassSteps.pop();
          } else if (this.isEsic) {
            this.renewGetPassSteps.pop();
            this.renewGetPassSteps.pop();
            this.renewGetPassSteps.pop();
          } else {
            this.renewGetPassSteps.pop();
            this.renewGetPassSteps.pop();
          }
        }
      } else if (this.requestType === 105) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.cancelGetPassSteps.pop();
          this.cancelGetPassSteps.pop();
        } else {
          if (this.isWcPolicy) {
            this.cancelGetPassSteps.pop();
            this.cancelGetPassSteps.pop();
          } else if (this.isEsic) {
            this.cancelGetPassSteps.pop();
            this.cancelGetPassSteps.pop();
            this.cancelGetPassSteps.pop();
          } else {
            this.cancelGetPassSteps.pop();
            this.cancelGetPassSteps.pop();
          }
        }
      }
      this.isEsic = false;
      this.isWcPolicy = false;
      this.newGetPassForm.controls.labourCoverType.reset();
    }
  }

  /**
   * Invoke this method and it is set property value.
   * And remove steps data from the array.
   * Like `newGetPassSteps`, `renewGetPassSteps`, and `cancelGetPassSteps`
   */
  private setPropertyValueAndRemoveDataFromArrayOfLabouredCover(item: any): void {
    if (item.data[0].title === 'Select Labour covered') {
      if (this.requestType === 103 && this.isContractorOrSubContractor === 'subcompanycontractor') {
        // this.newGetPassSteps.pop();
      } else if (this.requestType === 104 && this.isContractorOrSubContractor === 'subcompanycontractor') {
        // this.renewGetPassSteps.pop();
      } else if (this.requestType === 105 && this.isContractorOrSubContractor === 'subcompanycontractor') {
        //  this.cancelGetPassSteps.pop();
      }
      this.newGetPassForm.controls.jobType.reset();
    }
  }

  /**
   * @method setPropertyValueAndRemoveDataFromArrayOfWcPolicy.
   * @description Invoke this method and it is set property value.
   * And remove steps data from the array.
   * Like `newGetPassSteps`, `renewGetPassSteps`, and `cancelGetPassSteps`
   */
  private setPropertyValueAndRemoveDataFromArrayOfWcPolicy(item: any): void {
    if (item.data[0].title === 'Select WC Policy') {
      if (this.requestType === 103) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.newGetPassSteps.pop();
        } else {
          this.newGetPassSteps.pop();
          this.newGetPassSteps.pop();
        }
      } else if (this.requestType === 104) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.renewGetPassSteps.pop();
        } else {
          this.renewGetPassSteps.pop();
          this.renewGetPassSteps.pop();
        }
      } else if (this.requestType === 105) {
        if (this.isContractorOrSubContractor === 'subcompanycontractor') {
          this.cancelGetPassSteps.pop();
        } else {
          this.cancelGetPassSteps.pop();
          this.cancelGetPassSteps.pop();
        }
      }
      this.newGetPassForm.controls.jobType.reset();
    }
  }

  /**
   * @method setPropertyValueAndRemoveDataFromArrayOfSubWorkOrder.
   * @description Invoke this method and it is set property value.
   * And remove steps data from the array.
   * Like `newGetPassSteps`, `renewGetPassSteps`, and `cancelGetPassSteps`
   */
  private setPropertyValueAndRemoveDataFromArrayOfSubWorkOrder(item: any): void {
    if (item.data[0].title === 'Select Sub Work Order') {
      if (this.requestType === 103) {
        this.newGetPassSteps.pop();
      } else if (this.requestType === 104) {
        this.renewGetPassSteps.pop();
      } else if (this.requestType === 105) {
        this.cancelGetPassSteps.pop();
      }
    }
  }

  /**
   * @method setPropertyValueAndRemoveDataFromArrayOfSubContractor.
   * @description Invoke this method and it is set property value.
   * And remove steps data from the array.
   * Like `newGetPassSteps`, `renewGetPassSteps`, and `cancelGetPassSteps`
   */
  private setPropertyValueAndRemoveDataFromArrayOfSubContractor(item): void {
    if (item.data[0].title === 'Sub-Contractor Selection') {
      if (this.requestType === 103) {
        this.newGetPassSteps.pop();
      } else if (this.requestType === 104) {
        this.renewGetPassSteps.pop();
      } else if (this.requestType === 105) {
        this.cancelGetPassSteps.pop();
      }
    }
  }

  /**
  * @method setPropertyValueAndRemoveDataFromArrayOfSubLicense.
  * @description Invoke this method and it is set property value.
  * And remove steps data from the array.
  * Like `newGetPassSteps`, `renewGetPassSteps`, and `cancelGetPassSteps`
  */
  private setPropertyValueAndRemoveDataFromArrayOfSubLicense(item: any): void {
    if (item.data[0].title === 'Select Sub License') {
      if (this.requestType === 103) {
        this.newGetPassSteps.pop();
        this.newGetPassSteps.pop();
      } else if (this.requestType === 104) {
        this.renewGetPassSteps.pop();
        this.renewGetPassSteps.pop();
      } else if (this.requestType === 105) {
        this.cancelGetPassSteps.pop();
        this.cancelGetPassSteps.pop();
      }
    }
  }

  getGatePassRequests(requesttypecode) {
    this.requestType = requesttypecode;
    this.requestTypeCode = requesttypecode;
    // this.requestType = requesttypecode;
    this.gatepassReqGridApi.setDatasource(this.labourDataSource);
    this.gatepassReqGridApi.sizeColumnsToFit();
  }

  onTabChange(event) {
    this.getGatePassRequests(event.nextId);
  }

  toggleSelectAll(newVal: boolean) {
    console.log(newVal)
    if (newVal) {
      this.checkboxLable = 'Uncheck All';
    } else if (!newVal) {
      this.checkboxLable = 'Select All';
    }
    console.log(this.rowData)
    if (this.rowData && this.rowData.length > 0) {
      this.rowData.forEach((row) => {
        row.isSelected = newVal;
      });
      // debugger;
      console.log(this.rowData)
      this.gatepassReqGridApi.setRowData(this.rowData);
    }
  }

  toggleSelectSingle(params) {
    if (!params.data.isSelected && this.selectAll) {
      this.selectAll = false;
    }
    if (this.rowData.filter(row => row.isSelected).length === this.rowData.length) {
      this.selectAll = true;
    }
  }

  onPaginationChanged(event) {
    //debugger;
    this.toggleSelectAll(this.selectAll);
    //this.refreshGrid();
  }

  refreshGrid(){
    this.labourDataSource = {
      rowCount: null,
      getRows: async (params) => {
        // console.log(`asking for ${params.startRow} to ${params.endRow}`);
        let gatePassStatus;
        if (!this.filter.gatePassStatus) {
          gatePassStatus = false;
        } else {
          gatePassStatus = true;
        }
        // console.log(`asking for ${params.startRow} to ${params.endRow}`);
        const reqParams = {
          Departmentcode: this.filter.departmentCode,
          Skilltypecode: this.filter.skillTypeCode,
          ContractorId: this.filter.contractorVendorCode || 0,
          FilterStartDate: this.filter.filterStartDate,
          FilterEndDate: this.filter.filterEndDate,
          GatePassStatus: gatePassStatus,
          LabourDemandType: this.filter.labourDemandType,
          License: 0,
          MultipleRole: GLOBAL.USER.ROLE_CODE,
          NatureOfWork: 0,
          RoleCodeForApproval: this.filter.roleCodeForApproval,
          Roleactioncode: this.filter.roleActionCode,
          Subcontractorcode: this.filter.subContractorCode || 0,
          Subworkordercode: this.filter.subWorkorderCode || 0,
          Sublicensecode: this.filter.subLicenseCode || 0,
          Subecpolicycode: this.filter.subeEcPolicyCode || 0,
          UserCode: localStorage.getItem('UserID'),
          UserRoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
          Licensecode: this.filter.licenseCode,
          WcPolicy: 0,
          Mainesic: this.filter.mainEsic,
          Ecpolicycode: this.filter.ecpolicyCode,
          Search: this.filter.search,
          Workordercode: this.filter.workorderCode || 0,
          WorkSiteCategory: this.filter.worksiteCategoryCode || 0,
          Statecode: this.filter.statusCode,
          endRow: 10,//params.endRow,
          requesttypecode: this.requestTypeCode || 103,
          startRow: params.startRow,
          Plantcode: this.filter.plantCode,
          Contractorvendorcode: this.filter.contractorVendorCode || 0,
          ULC: this.filter.ulc,
        };
        // const reqParams = {
        //   startRow: params.startRow,
        //   endRow: params.endRow,
        //   UserCode: String(GLOBAL.USER.LOGGED_IN_USER),
        //   requesttypecode: this.requestTypeCode,
        //   UserRoleCode: GLOBAL.USER.ROLE_CODE,
        //   MultipleRole: GLOBAL.USER.ROLE_CODE,
        //   LabourDemandType: this.filter.labourDemandType,
        //   Statuscode: this.filter.statusCode,
        //   GatePassStatus: gatePassStatus,
        //   RoleCodeForApproval: this.filter.roleCodeForApproval,
        //   Roleactioncode: this.filter.roleActionCode,
        //   Contractorvendorcode: this.filter.contractorVendorCode || '',
        //   Workordercode: this.filter.workorderCode || '',
        //   Licensecode: this.filter.licenseCode || '',
        //   Mainesic: this.filter.mainEsic,
        //   Ecpolicycode: this.filter.ecpolicyCode || '',
        //   Subcontractorcode: this.filter.subContractorCode || '',
        //   Subworkordercode: this.filter.subWorkorderCode || '',
        //   Sublicensecode: this.filter.subLicenseCode || '',
        //   Subesic: this.filter.subEsic,
        //   Subecpolicycode: this.filter.subeEcPolicyCode || '',
        //   Departmentcode: this.filter.departmentCode || '',
        //   Worksitecategorycode: this.filter.worksiteCategoryCode || '',
        //   Plantcode: this.filter.plantCode || '',
        //   Skillty pecode: this.filter.skillTypeCode || '',
        //   Search: this.filter.search,
        //   FilterStartDate: this.filter.filterStartDate,
        //   FilterEndDate: this.filter.filterEndDate
        // };
        this.setSelectedFilters();
        //
        await this.gatePassRequestsService.getAllGatePassRequests(reqParams).subscribe((res: any) => {
          this.rowData = res.Data.data;
          this.reqTypeRoleList = res.Data.reqTypeRoleList;
          this.reqTypePlantList = res.Data.reqTypePlantList;
          this.lastRow = res.Data.totalRow;
          // if (GLOBAL.USER.ROLE_CODE !== 119) {
          this.totalPendingGatePass = res.Data.allPendingGatePass;
          // }
          setTimeout(() => {
            this.gatepassReqGridApi.sizeColumnsToFit();
          }, 500);

          if (this.rowData && this.rowData.length > 0 && this.selectAll) {
            this.rowData.forEach((row) => {
              row.isSelected = this.selectAll;
            });
          }
          // this.ngxSidebarService.openSidebar();
          // this.ngxSidebarService.closeSidebar();
          params.successCallback(this.rowData, this.lastRow);
        }, (err) => {
          this.rowData = [];
          this.lastRow = null;
          params.successCallback(this.rowData, this.lastRow);
        });
      }
    };

    this.gatepassReqGridApi.setDatasource(this.labourDataSource);
  }

  openSidebar() {
    this.ngxSidebarService.openSidebar();
  }

  onContractorChange(event) {
    // tslint:disable-next-line:max-line-length
    this.gatePassRequestsService.getWorkOrderByContractor(event.ContractorVendor_Code, this.filter.plantCode || 100).subscribe((res: any[]) => {
      this.workOrders = res;
    });
  }


  onWorkOrderChange(event) {
    this.gatePassRequestsService.getLicenseByWO(this.filter.contractorVendorCode, event.WorkOrder_Code)
      .subscribe((res: any) => {
        this.licensesByWo = res;
      });
    // this.gatePassRequestsService.getEcpolicyByWO(event.WorkOrder_Code)
    //   .subscribe((res: any[]) => {
    //     this.ecPoliciesByWo = res;
    //   });
    // this.gatePassRequestsService.getSubContractorByWOData(this.filter.contractorVendorCode, event.WorkOrder_Code)
    //   .subscribe((res: any[]) => {
    //     this.subContractorsByWo = res;
    //   });
    if (event) {
      const data = {
        contractorCode: this.filter.contractorVendorCode,
        plantCode: this.filter.plantCode,
        workOrderCode: this.filter.workorderCode
      };
      // this.selectedContractor = item.data.ContractorVendor_Code;
      this.gatePassRequestsService.getEcPolicyFilterDataSP(data).subscribe((rowSelectedData: any) => {
        this.ecPoliciesByWo = rowSelectedData;
        // .subscribe((res: any[]) => {
        //   this.licensesByWo = res;
      });
      this.gatePassRequestsService.getEcpolicyByWO(event.WorkOrder_Code)
        .subscribe((res: any[]) => {
          this.ecPoliciesByWo = res;
        });
      this.gatePassRequestsService.getSubContractorByWOData(this.filter.contractorVendorCode, event.WorkOrder_Code)
        .subscribe((res: any[]) => {
          this.subContractorsByWo = res;
        });


      const filterText = {
        plant_Code: this.filter.plantCode,
        WorkOrder_Code: event.WorkOrder_Code,
        UserId: localStorage.getItem('UserID'),
        contractors: this.filter.contractorVendorCode,
      };

      this.gatePassRequestsService
        .getContractorSubContractorData(filterText)
        .subscribe((response: any[]) => {
          this.subContractorsByWo = response;
          // this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors,
          //   this.filter.plantCode);
          // this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        });
    }
  }


  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.gatePassRequestsService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subWorkOrders = response;
    });
  }

  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.gatePassRequestsService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subLicences = response;
    });
  }

  getSubWorkOrderBySC(event) {
    // tslint:disable-next-line:max-line-length
    this.gatePassRequestsService.getWorkOrdersByContractorCode(event.ContractorVendor_Code, this.filter.plantCode).subscribe((response: any[]) => {
      this.subWorkOrders = response;
      this.getLicenseBySubWO(event.ContractorVendor_Code);
    });
  }


  getLicenseBySubWO(contractorvendorcode) {
    this.gatePassRequestsService.getLicenses(contractorvendorcode)
      .subscribe((res: any[]) => {
        this.subLicences = res;
      });
  }
  // getSubWorkOrderBySC() {
  //   this.gatePassRequestsService.getSubWorkOrderBySCData(this.filter.subContractorCode)
  //     .subscribe((res: any[]) => {
  //       this.subWorkOrders = res;
  //     });
  // }

  // getLicenseBySubWO() {
  //   this.gatePassRequestsService.getLicenseBySubWO(this.filter.subWorkorderCode)
  //     .subscribe((res: any[]) => {
  //       this.subLicences = res;
  //     });
  //   }
  // this.gatePassRequestsService.getSubEcpolicyByWO(this.filter.subWorkorderCode)
  //   .subscribe((res: any[]) => {
  //     this.subEcPoliciesByWo = res;
  //   });


  filtergrid() {
    this.opened = false;
    this.setSelectedFilters();
    this.gatepassReqGridApi.setDatasource(this.labourDataSource);
    this.gatepassReqGridApi.sizeColumnsToFit();
  }

  clearFilter() {
    this.opened = false;
    this.filter = new GatePassFilter();
    this.filtergrid();
  }

  filterRemoved(code: string) {
    const newFilter = new GatePassFilter();
    this.filter[code] = newFilter[code];
    this.filtergrid();
  }

  async openApproval(params) {

    const data = params.data;
    const modalData = {
      hrApproved: false,
      approvalData: {},
      labourDoc: [],
      labourData: {},
      RequestType_Code: data.RequestType_Code
    };
    // if (GLOBAL.USER.ROLE_CODE === 101) {
    this.gatePassRequestsService.getGateHrApprovalData(data.Labour_Code, data.RequestType_Code, GLOBAL.USER.ROLE_CODE)
      .subscribe((res: any) => {
        if (res.Data.HrApproved) {
          modalData.hrApproved = true;
        }
        this.gatePassRequestsService.getGateApprovalData(data.Labour_Code, false, data.Request_Code)
          .subscribe((approvalRes: any) => {
            if (approvalRes.Status) {
              modalData.approvalData = approvalRes.Data;
              this.gatePassRequestsService.getDocumentForLabour(data.Labour_Code).subscribe((docRes: any) => {
                modalData.labourDoc = docRes.Data;
                modalData.labourData = data;
                console.log({ modalData });

                const modalRef = this.modalService.open(ApprovalFlowComponent, { size: 'lg', backdrop: 'static' });
                modalRef.componentInstance.resolveObject = modalData;
                modalRef.componentInstance.requestType = this.requestType;
                modalRef.componentInstance.Request_Code = data.Request_Code;
                modalRef.result.then((result) => {
                  if (result.ULC) {
                    data.ULC = result.ULC;
                  }

                  if (result.ShiftGroup) {
                    data.ShiftGroup = result.ShiftGroup;
                  }

                  if (result.GatePassNo) {
                    data.GatePassNo = result.GatePassNo;
                  }

                  if (!result.isCancel) {
                    this.getGatePassRequests(this.requestTypeCode);
                  }

                }).catch(dismiss => console.log);
              });
            }
          });
      });
    // }
  }

  isFilterSelected() {
    return JSON.stringify(this.filter) !== JSON.stringify(new GatePassFilter());
  }

  setSelectedFilters() {
    this.selectedAdvanceFilters = [];
    for (const key in this.filter) {
      if (this.filter.hasOwnProperty(key)) {
        const element = this.filter[key];
        if (element !== -1 && element !== '' && element !== null && element !== false) {
          let name = '';
          switch (key) {
            case 'plantCode':
              name = 'Plant';
              break;
            case 'departmentCode':
              name = 'Department';
              break;
            case 'skillTypeCode':
              name = 'Skill';
              break;
            case 'worksiteCategoryCode':
              name = 'Work Site Category';
              break;

            case 'statusCode':
              name = 'Status';
              break;
            case 'roleCodeForApproval':
              name = 'Role';
              break;
            case 'roleActionCode':
              name = 'Status of Role Action';
              break;
            case 'labourDemandType':
              name = 'Labour Type';
              break;
            case 'gatePassStatus':
              name = 'Gatepass Status';
              break;
            case 'filterStartDate':
              name = 'Start Date';
              break;
            case 'filterEndDate':
              name = 'End Date';
              break;


            case 'contractorVendorCode':
              name = 'Contractor';
              break;
            case 'workorderCode':
              name = 'Workorder';
              break;
            case 'licenseCode':
              name = 'License';
              break;
            case 'mainEsic':
              name = 'Esic';
              break;
            case 'ecpolicyCode':
              name = 'EC Policy';
              break;


            case 'subContractorCode':
              name = 'Sub Contractor';
              break;
            case 'subWorkorderCode':
              name = 'Sub Workorder';
              break;
            case 'subLicenseCode':
              name = 'Sub License';
              break;
            case 'subEsic':
              name = 'Sub Esic';
              break;
            case 'subeEcPolicyCode':
              name = 'Sub EC Policy';
              break;
            case 'search':
              name = 'Search';
              break;
            case 'ulc':
              name = 'ULC';
              break;
            default:
              break;
          }
          if (name) {
            this.selectedAdvanceFilters.push({
              name,
              code: key
            });
          }
        }
      }
    }
  }
  public openStateSelectionPopup(): void {
    // tslint:disable-next-line: max-line-length
    this.getStates();
    const modalRef = this.modalService.open(this.templateRef, { size: 'sm', backdrop: 'static', keyboard: false });
  }
  public onPrintCloseAction(): void {

    this.closeModal.next('click');
    this.modalService.dismissAll(this.templateRef);
    this.selectedRowData.forEach((row) => {
      row.isSelected = false;
    });
    this.selectedRowData = [];
  }
  onPrint() {
    this.showWhilePrinting = true;
    console.log(this.selectedRowData)
    // tslint:disable-next-line:one-variable-per-declaration
    let printDetails, popupWin: Window;
    printDetails = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
    <html>
      <head>
        <style>
            table,
              th,
              td {
                  border-collapse: collapse;
                  font-family: "Trebuchet MS" !important;
              }
            .pass-title{
              background-color: rgb(188, 143, 143);
            }
            .company-name{
              background-color: deeppink
            }
            .status{
              background-color: yellow
            }
        </style>
      </head>
  <body onload="window.print();">${printDetails}</body>
    </html>`
    );
    popupWin.document.close();
    // tslint:disable-next-line: ter-indent
    setTimeout(() => {
      //
      this.btnPrint.nativeElement.click();
      localStorage.setItem('report-data', JSON.stringify(this.selectedRowData));
    }, 100);
    // this.modaliconRef = this.modalService.open(IcardPrintingComponent, {size: 'lg', backdrop: 'static' });
    // this.modaliconRef.componentInstance.chkChangeClick1(this.selectedRowData);
  }

  async getSelectedState(event){
      if(this.selectedRowData.length !== 0){
          for(let row of this.selectedRowData){
            row['selectedState']= event.State
          }
      }
  }
  
  async chkChangeClick(params, ischeck) {
    if (ischeck) {
      this.selectedRowData.push(params.data);
    } else {
      // tslint:disable-next-line:ter-arrow-parens
      const index = this.selectedRowData.map(x => {
        return x.Request_Code;
      }).indexOf(params.data.Request_Code);
      this.selectedRowData.splice(index, 1);
    }
    // this.selectedRowData = [];
    const selectedRowData = JSON.parse(localStorage.getItem('report-data'));
    // console.log('reportData-check', selectedRowData);
    // console.log('btnPrint', this.btnPrint);
    // if (selectedRowData) {
    //   setTimeout(() => {
    //     this.btnPrint.nativeElement.click();
    //     localStorage.removeItem('report-data');
    //   });
    // }
  }

  async addleavebalance(params) {
    const data = params.data;
    this.labourDetail = params.data;
    const modalRef = this.modalService.open(this.leavetemplate, { size: 'lg', backdrop: 'static', keyboard: false });
    this.addleave.reset();
    this.disablesavebutton = true;
    this.optionsChecked = [];
    this.hidesubgrid = false;
    this.getBalance();
  }


  async debarredLabour(params) {
    const data = params.data;
    this.labourDetail = params.data;
    const modalRef = this.modalService.open(DebarredLabourComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.params = params;
    modalRef.result.then((result) => {
      if (result) {
        console.log(result);
        this.filtergrid();
      }
    });
  }

  public onClose(): void {
    this.closeModal.next('click');
    this.modalService.dismissAll(this.leavetemplate);
    this.optionsChecked = [];
  }

  public getLeaveType(): void {
    this.gatePassRequestsService.getLeaveTypeData().subscribe((response: any) => {
      this.leavetypes = response;
    });
  }

  public getMonth(): void {
    this.gatePassRequestsService.getMonth().subscribe((response: any) => {
      this.months = response;
      // this.addleave.controls['PayrollMonth_Code'].setValue(this.months[0].PayrollMonth_Code);
    });
  }

  public getBalance(): void {
    this.gatepassno = this.labourDetail.GatePassNo;
    this.gatePassRequestsService.getBalance(null, this.userId, this.rolecode, this.gatepassno).subscribe((response: any) => {
      this.balance = response;
      if (response.length > 0) {
        this.hidesubgrid = true;
      } else {
        this.hidesubgrid = false;
      }
    });
  }

  // tslint:disable-next-line: function-name
  IsCheckLeave(leavetype, event) {
    this.leavetypes[leavetype] = event.target.checked;
    if (this.leavetypes[leavetype] === true) {
      if (this.optionsChecked.indexOf(leavetype.LeaveType_Code) === -1) {
        this.optionsChecked.push(leavetype.LeaveType_Code);
        this.disablesavebutton = true;
      } else {
        this.optionsChecked.splice(this.optionsChecked.indexOf(leavetype.LeaveType_Code), 1);
      }
    } else {
      this.optionsChecked.splice(this.optionsChecked.indexOf(leavetype.LeaveType_Code), 1);
      // this.disablesavebutton = false;
    }
  }

  // tslint:disable-next-line: function-name
  LeaveAmount(event, leavetype) {
    if (typeof event === 'string') {
      const amount = parseInt(event, 10);
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.LeaveBalance = amount;
    } else if (event) {
    }
  }

  // tslint:disable-next-line: function-name
  LeaveMonth(event, leavetype) {
    if (typeof event === 'string') {
    } else if (event) {
      const month = event.PayrollMonth_Code;
      // const month = this.months[0].PayrollMonth_Code;
      // tslint:disable-next-line: no-parameter-reassignment
      leavetype.LeaveMonth = month;
    }
  }


  public addLeaveBalance() {
    const addleave = this.addleave.getRawValue();
    if (this.optionsChecked.length > 0 && addleave.LeaveBalance !== null && addleave.PayrollMonth_Code !== null) {
      this.disablesavebutton = true;
      const leavebalancedata: any = {
        // LeaveType_Code: this.optionsChecked,
        // Balance: parseInt(addleave.LeaveBalance, 10),
        // MonthId: addleave.PayrollMonth_Code,
        // UserId: localStorage.getItem('UserID'),
        // GatepassNo: this.labourDetail.GatePassNo,
        // ULC: this.labourDetail.ULC
        leavebalance: this.leavetypes,
        UserId: localStorage.getItem('UserID'),
        optionsChecked: this.optionsChecked,
        GatepassNo: this.labourDetail.GatePassNo,
        ULC: this.labourDetail.ULC
      };
      this.gatePassRequestsService.addLeaveBalance(leavebalancedata).subscribe((response: any) => {
        if (response) {
          this.logHelperService.logSuccess({
            message: 'Leave Balance add successfully'
          });
          this.closeModal.next('click');
          this.modalService.dismissAll(this.leavetemplate);
          this.addleave.reset();
          this.disablesavebutton = true;
        } else {
          this.logHelperService.logError({
            message: 'Leave Balance not added'
          });
          this.closeModal.next('click');
          this.modalService.dismissAll(this.leavetemplate);
          this.addleave.reset();
          this.disablesavebutton = true;
        }
      });
    } else {
      if (this.optionsChecked.length === 0 && (addleave.LeaveBalance === null || addleave.LeaveBalance === '')
        && addleave.PayrollMonth_Code === null) {
        this.logHelperService.logError({
          message: 'Please fill details!'
        });
      } else if ((this.optionsChecked.length > 0 && (addleave.LeaveBalance === null || addleave.LeaveBalance === '')
        && addleave.PayrollMonth_Code === null)
        || (this.optionsChecked.length === 0 && addleave.LeaveBalance !== null && addleave.PayrollMonth_Code === null)
        || (this.optionsChecked.length === 0 && (addleave.LeaveBalance === null || addleave.LeaveBalance === '')
          && addleave.PayrollMonth_Code !== null)) {
        this.logHelperService.logError({
          message: 'Please fill other details!'
        });
      } else if (this.optionsChecked.length === 0 && addleave.LeaveBalance !== null && addleave.PayrollMonth_Code !== null) {
        this.logHelperService.logError({
          message: 'Please Select atleast one LeaveType!'
        });
      } else if (this.optionsChecked.length > 0 && (addleave.PayrollMonth_Code !== null || addleave.PayrollMonth_Code !== '')
        && (addleave.LeaveBalance === null || addleave.LeaveBalance === '')) {
        this.logHelperService.logError({
          message: 'Please Add Your Balance!'
        });
      } else if (this.optionsChecked.length > 0 && (addleave.PayrollMonth_Code === null || addleave.PayrollMonth_Code === '')
        && (addleave.LeaveBalance !== null || addleave.LeaveBalance !== '')) {
        this.logHelperService.logError({
          message: 'Please Select Month!'
        });
      }
      // this.disablesavebutton = false;
    }
  }

  prepareReqForActionSave(action: string) {
    const labourCodes = []; // "";
    labourCodes.push(this.labourCode);
    const objgatepass = {
      UserEmail: GLOBAL.USER.EMAIL_ID,
      RoleCode: JSON.parse(localStorage.getItem('Role'))[0].Role_Code,
      UserName: GLOBAL.USER.EMAIL_ID,
      requestTypeCode: this.requestType,
      Action: action,
      labourcodes: labourCodes.join(','),
      UserId: localStorage.getItem('UserID'),
      Comment: null,
      // ULC : ULC,
      // GatePassNo : GatePassNo,
      // EIC
      GatePassValidityDate: null,
      Section: null,
      ShiftGroupView: {
        ShiftGroupCode: null,
        ULC: null,
        Gatepassno: null,
        PlantCode: null
      },
      // OHC
      FitnessStatus: null,
      Checkupdate: null,
      NextDuedate: null,
      NextScheduledate: null,
      From32: null,
      From35: null,
      XrayExaminationrequired: null,
      TreatmentDetails: null,
      XrayValidityDate: null,
      ExaminationDetails: null,
      EyeTestingValidityDate: null,
      NonHazardousDetails: null,
      ReExamingDate: null,
      // FNS
      InductionTraining: null,
      FNSTraningStatus: null,
      FNSNextDuedate: null,
      FNSIsFireFighter: null,
      // Security
      Enrollment: null,
      Gatepassissue: null,
      Gatepassreturned: null,
      Gatepassdeactivated: null,
      GatePassValidityDateHR: null,
      IssueDate: null
    };
    return objgatepass;
  }

  openFilter() {
    const modalRef = this.modalService.open(FilterPopupComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.filter = this.filter;
    modalRef.result.then((result) => {
      if (result) {
        console.log(result);
        this.filtergrid();
      }
    });
  }

  multipleAproval() {
    const finalArray = [];
    // tslint:disable-next-line: variable-name
    const RoleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    // tslint:disable-next-line: ter-arrow-parens

    if (this.selectedRowData.length === 0) {
      this.logHelperService.logError({ message: `Please select at least one labour!` });
      return false;
    }
    // tslint:disable-next-line:ter-arrow-parens
    this.selectedRowData.forEach(element => {
      const obj = {
        UserEmail: null,
        // tslint:disable-next-line: object-literal-shorthand
        RoleCode: RoleCode,
        UserId: localStorage.getItem('UserID'),
        UserName: null,
        requestTypeCode: this.requestTypeCode,
        Action: 'Approved',
        LabourCode: element.Labour_Code,
        labourcodes: element.Labour_Code,
        ULC: element.ULC,
        GatePassNo: 3244544,
        GatePassValidityDate: element.ValidUptoDate,
        Section: 0,
        GatePassValidityDateHR: element.ValidUptoDate,
        Comment: 'Approved By Multiple',
        ShiftGroupView: {},
        verificationDate: null,
        ESICVerificationDate: null,
        IsVerifyEPF: false,
        IsVerifyESIC: false,
        FitnessStatus: 1,
        Checkupdate: null,
        NextDuedate: null,
        NextScheduledate: null,
        From32: null,
        From35: null,
        XrayExaminationrequired: null,
        TreatmentDetails: null,
        XrayValidityDate: null,
        ExaminationDetails: null,
        EyeTestingValidityDate: null,
        NonHazardousDetails: null,
        ReExamingDate: null,
        InductionTraining: false,
        FNSTraningStatus: false,
        FNSNextDuedate: null,
        FNSNextScheduledate: null,
        FNSIsFireFighter: false,
        Enrollment: false,
        Gatepassreturned: false,
        Gatepassissue: false,
        Gatepassdeactivated: false,
      };
      finalArray.push(obj);
    });
    this.gatePassRequestsService.approveMultipleData(finalArray).subscribe(() => {
      this.logHelperService.logSuccess({ message: `Data Saved Successfully!` });
      // finalArray.forEach(element => {
      //   const rowNode  = this.gridOptions.api.getRowNode(element.LabourCode);
      //   const data = this.rowData.find(a => a.Labour_Code == element.LabourCode);
      //   data.ReqApprovalInfo.push({
      //     Role_Code: element.RoleCode,
      //     StatusMasterList_Code: 256
      //   })
      //   rowNode.setData(data);
      // });
      // this.gatepassReqGridApi.setDatasource(this.rowData);
    });
  }

  public openEditRequestPopup(editObj: any): void {
    console.log('editObj', editObj);

    const requestObj = {
      UserID: localStorage.getItem('UserID'),
      GatePassNo: editObj.GatePassNo,
      Request_Code: editObj.Request_Code,
      RequestType: 1,
      Action: 1
    }
    this.gatePassRequestsService.getGateDetails(requestObj)
      .subscribe((res: any[]) => {
        if (res && res.length > 0) {
          const data = res[0];
          const modalRef = this.modalService.open(EditGatepassRequestModalComponent, { size: 'lg', backdrop: 'static' });
          const dates = [];
          // data.joiningDate = data.joiningDate ? moment(data.joiningDate, 'dd/MM/yyyy hh:mm:ss').toDate() : null;
          // data.ECPolicyValidityDat ? dates.push(new Date(data.ECPolicyValidityDate)) : null;
          // data.LicenseValidityDate ? dates.push(new Date(data.LicenseValidityDate)) : null;
          // data.POValidityDate ? dates.push(new Date(data.POValidityDate)) : null;
          // data.minValidityDate = _.min(dates);
          // data.gatepassValidityDate = data.minValidityDate;

          modalRef.componentInstance.data = data;
          // modalRef.componentInstance.editObj = editObj;
          modalRef.componentInstance.close.subscribe(() => {
            modalRef.close();
          });
          modalRef.componentInstance.saveObj.subscribe((res) => {
            this.saveEditGatepassRequestData(res, modalRef);
          });
        }
      });
  }

  public openSalaryInfoModal(editObj: any): void {
    const data = {
      UEC: editObj.ULC,
      // UEC:60600005,
      IsLabour: 1,
      RequireType: 0,
      ActionType: 0,
      // UserId: 142
      UserId: GLOBAL.USER_ID
    };

    this.gatePassRequestsService.WorkmanSalaryMaster(data).subscribe((res: any) => {
      if (res && res.length) {
        // this.gridRowDataAs = res;
        const modalRef = this.modalService.open(PopupWorkmanSalaryMasterComponent,
          { size: "lg", backdrop: "static", windowClass: "modal-panel modal-scroll overflow-hidden" }
        );

        modalRef.componentInstance.rowData = res;
      } else {
        this.logHelperService.logError({
          message: 'No data found.'
        })
      }
    });
  }

  private saveEditGatepassRequestData(data: any, modalRef: any): void {
    console.log('edit save obj', data);
    const apiCallList = [];
    const editRequestObj = this.createEditGatepassRequestObj(data);
    const editShiftRequestObj = this.createEditShiftGroupRequestObj(data);

    const saveGatePassRequest$ = this.gatePassRequestsService.editGatePassRequetsData(editRequestObj);
    apiCallList.push(saveGatePassRequest$);
    if (data.OldSiteShiftGroup_Code !== data.SiteShiftGroup_Code) {
      const saveShiftGroupFroEditRequest$ = this.gatePassRequestsService.saveShiftGroupFroEditRequest(editShiftRequestObj);
      apiCallList.push(saveShiftGroupFroEditRequest$);
    }

    forkJoin(apiCallList).subscribe((editResponse: [any, any]) => {
      if (apiCallList.length === 2 && apiCallList[0] && apiCallList[1]) {
        this.logHelperService.logSuccess({
          message: 'Data is saved successfully.'
        })
        modalRef.close();
      } else if (apiCallList[0]) {
        this.logHelperService.logSuccess({
          message: 'Data is saved successfully.'
        })
        modalRef.close();
      } else {
        this.logHelperService.logError({
          message: 'Failed to save data.'
        })
      }
    });
  }

  private createEditGatepassRequestObj(formValue: any): any {
    const { GatePassRequest_Code, IsGatepassDeactive, IsGatepassReturn, EnrollmentDate, CardStatus, CardDate,
      JoiningDate, ConfirmationDate, IsMobileAllow, MachineStatusMasterList_Code, RegisterNo, IsCommonAllow,
      CardNumber, ValidUptoDate, IsGatePassIssued, IsFirstAidTrainingTaken, IsFireFighter, IsEnrolled, Photo } = formValue;
    return {
      Role_Code: localStorage.getItem('RoleCode'),
      UserID: localStorage.getItem('UserID'),
      ActionType: 1,
      GatePassRequest_Code: GatePassRequest_Code, // need to add in API
      IsGatepassDeactive,
      IsGatepassReturn,
      EnrollmentDate,
      CardStatus,
      CardDate,
      JoiningDate: moment(JoiningDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
      ConfirmationDate,
      IsMobileAllow,
      MachineStatusMasterList_Code,
      RegisterNo,
      IsCommonAllow,
      CardNumber,
      ValidUptoDate: moment(ValidUptoDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
      IsGatePassIssued,
      IsFirstAidTrainingTaken,
      IsFireFighter,
      IsEnrolled,
      Photo
    }
  }

  private createEditShiftGroupRequestObj(formValue: any): any {
    return {
      ULC: formValue.ULC,
      GatePassNo: formValue.GatePassNo,
      UserID: localStorage.getItem('UserID'),
      Role_Code: localStorage.getItem('RoleCode'),
      ApplicableDate: moment(formValue.ApplicableDate).format(this.sharedService.dateOnlyPickerSettings.requestFormat),
      SiteShiftGroup_Code: formValue.SiteShiftGroup_Code,
      OldSiteShiftGroup_Code: formValue.OldSiteShiftGroup_Code,
      RequestType_Code: formValue.RequestType_Code,
      ActionType: 1
    }
  }

  private openRequestProcessModal(): void {
    const modalRef = this.modalService.open(GatePassRequestProcessComponent, {
      backdrop: 'static'
    })
    modalRef.componentInstance.requestType = this.requestType;
    modalRef.componentInstance.contractorList = this.gridRowData;
    modalRef.componentInstance.contractorInformation = this.contractorInformation;
    modalRef.componentInstance.userSiteCode = this.userSiteCode;

    modalRef.componentInstance.handleClose.subscribe(res => {
      if (res && res.refreshList) {
        this.getGatePassRequests(this.requestTypeCode);
      }
      modalRef.close();
    });
  }


  async onPrintData(data: any) {
    // this.params.onPrintData(this.params.data);
    // this.selectedRowData = data;
    // this.selectedRowData.push(params.data);
    this.service.getLabourDetailByCode(data.Labour_Code).subscribe((response) => {
      this.selectedRowDataForPrint = response[0];
      console.log('Data', this.selectedRowDataForPrint);
      if (this.selectedRowDataForPrint.Photo) {
        this.selectedRowDataForPrint.Photo = `${GLOBAL.PHOTO_URL}${this.selectedRowDataForPrint.Photo}`;
      }
      setTimeout(() => {                           // <<<---using ()=> syntax
        // tslint:disable-next-line: one-variable-per-declaration
        let printContents, popupWin: Window;
        printContents = document.getElementById('print-section22').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <style>
                table,
                  tr,
                  th,
                  td {
                      border: 0px solid black;
                      border-collapse: collapse;
                      font-family: "Trebuchet MS";
                      font-size: 12px;

                  }

                  tr,
                  th,
                  td {
                      border: 1px solid black;
                      padding: 0px;
                      text-align: left;

                  }
            </style>
          </head>
      <body onload="window.print();">${printContents}</body>

        </html>`

        );
        popupWin.document.close();
      }, 3000);
    });
    // tslint:disable-next-line:one-variable-per-declaration

    // this.showWhile = true;
    //   setTimeout(() => {
    //     //
    //     this.btnPrint.nativeElement.click();
    //     localStorage.setItem('report-data', JSON.stringify(this.LabourData));
    //     console.log(this.LabourData);
    //   }, 100);
    // }
  }

  async openPFForm(data: any) {
    // this.params.onPrintData(this.params.data);
    // this.selectedRowData = data;
    // this.selectedRowData.push(params.data);
    this.service.getLabourPfDetailByCode(data.Labour_Code).subscribe((response) => {
      this.selectedRowDataForPF = response[0];
      console.log('Data', this.selectedRowDataForPF);
      setTimeout(() => {                           // <<<---using ()=> syntax
        // tslint:disable-next-line: one-variable-per-declaration
        let printContents, popupWin: Window;
        printContents = document.getElementById('pfprint-section').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <style>
                table,
                  tr,
                  th,
                  td {
                     // border: 0px solid black;
                      border-collapse: collapse;
                      font-family: "Trebuchet MS";
                      font-size: 12px;
                  }

                  tr,
                  th,
                  td {
                     // border: 1px solid black;
                      padding: 0px;
                      text-align: left;

                  }
            </style>
          </head>
      <body onload="window.print();">${printContents}</body>

        </html>`

        );
        popupWin.document.close();
      }, 3000);
    });
    // tslint:disable-next-line:one-variable-per-declaration

    // this.showWhile = true;
    //   setTimeout(() => {
    //     //
    //     this.btnPrint.nativeElement.click();
    //     localStorage.setItem('report-data', JSON.stringify(this.LabourData));
    //     console.log(this.LabourData);
    //   }, 100);
    // }
  }

  async openESICForm(data: any) {
    // this.params.onPrintData(this.params.data);
    // this.selectedRowData = data;
    // this.selectedRowData.push(params.data);\
    const esicLabourDetails$ = this.service.getLabourEsicDetailByCode(data.Labour_Code);
    const nomineeList$ = this.service.getLabourNomineeListFormPrint(data.Labour_Code, 'ESIC');
    forkJoin(esicLabourDetails$, nomineeList$).subscribe(([response, nomineeList]: [any[], any[]]) => {
      // this.service.getLabourEsicDetailByCode(data.Labour_Code).subscribe((response) => {
      this.selectedRowDataForEsic = response[0];
      if (!nomineeList) {
        nomineeList = [];
      }
      const myArr = Array.from({
        length: nomineeList.length > 5 ? nomineeList.length : 5
      }, (_, index) => nomineeList[index] || {});
      console.log(myArr);

      this.selectedRowDataForEsic.nomineeList = myArr;
      console.log('Data', this.selectedRowDataForEsic);
      setTimeout(() => {                           // <<<---using ()=> syntax
        // tslint:disable-next-line: one-variable-per-declaration
        let printContents, popupWin: Window;
        printContents = document.getElementById('esicprint-section').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <style>
                table,
                  tr,
                  th,
                  td {
                     // border: 0px solid black;
                      border-collapse: collapse;
                      font-family: "Trebuchet MS";
                      font-size: 12px;
                  }

                  tr,
                  th,
                  td {
                     // border: 1px solid black;
                      padding: 0px;
                      text-align: left;

                  }
            </style>
          </head>
      <body onload="window.print();">${printContents}</body>

        </html>`

        );
        popupWin.document.close();
      }, 3000);
    });
    // tslint:disable-next-line:one-variable-per-declaration

    // this.showWhile = true;
    //   setTimeout(() => {
    //     //
    //     this.btnPrint.nativeElement.click();
    //     localStorage.setItem('report-data', JSON.stringify(this.LabourData));
    //     console.log(this.LabourData);
    //   }, 100);
    // }
  }

  async openEPFFormTwo(data: any) {
    // this.params.onPrintData(this.params.data);
    // this.selectedRowData = data;
    // this.selectedRowData.push(params.data);

    const epf2LabourDetails$ = this.service.getLabourPfDetailByCode(data.Labour_Code);
    const epfNomineeList$ = this.service.getLabourNomineeListFormPrint(data.Labour_Code, 'EPF');
    const epsNomineeList$ = this.service.getLabourNomineeListFormPrint(data.Labour_Code, 'EPS');
    forkJoin(epf2LabourDetails$, epfNomineeList$, epsNomineeList$).subscribe(([response, epfNomineeList, epsNomineeList]: [any[], any[], any[]]) => {
      // this.service.getLabourEsicDetailByCode(data.Labour_Code).subscribe((response) => {
      this.selectedRowDataForPFTwo = response[0];
      this.selectedRowDataForPFTwo.epfNomineeList = this.createArray(epfNomineeList, 4);
      this.selectedRowDataForPFTwo.epsNomineeList = this.createArray(epsNomineeList, 4);

      // this.service.getLabourPfDetailByCode(data.Labour_Code).subscribe((response) => {
      //   this.selectedRowDataForPFTwo = response[0];
      console.log('Data', this.selectedRowDataForPFTwo);
      setTimeout(() => {                           // <<<---using ()=> syntax
        // tslint:disable-next-line: one-variable-per-declaration
        let printContents, popupWin: Window;
        printContents = document.getElementById('epfform2-section').innerHTML;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
        <html>
          <head>
            <style>
                table,
                  tr,
                  th,
                  td {
                     // border: 0px solid black;
                      border-collapse: collapse;
                      font-family: "Trebuchet MS";
                      font-size: 12px;
                  }

                  tr,
                  th,
                  td {
                     // border: 1px solid black;
                      padding: 0px;
                      text-align: left;

                  }
            </style>
          </head>
      <body onload="window.print();">${printContents}</body>

        </html>`

        );
        popupWin.document.close();
      }, 3000);
    });
    // tslint:disable-next-line:one-variable-per-declaration

    // this.showWhile = true;
    //   setTimeout(() => {
    //     //
    //     this.btnPrint.nativeElement.click();
    //     localStorage.setItem('report-data', JSON.stringify(this.LabourData));
    //     console.log(this.LabourData);
    //   }, 100);
    // }
  }

  private createArray(array: any[], customLength: number): any[] {
    if (!array) {
      array = [];
    }

    return Array.from({
      length: array.length > customLength ? array.length : customLength
    }, (_, index) => array[index] || {});
  }

  private getSiteCodeByUserID(): Observable<any> {
    const requestObj = {
      UserID: GLOBAL.USER_ID,
      RequireType: 0,
      SiteMIL_Code: null,
      ActionType: null
    };

    return this.gatePassRequestsService.getUserSiteCode(requestObj);
  }
}
