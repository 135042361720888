import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { GridOptions, GridApi } from 'ag-grid-community';

@Component({
  selector: 'app-grid-wrapper',
  templateUrl: './grid-wrapper.component.html',
  styleUrls: ['./grid-wrapper.component.css'],
  styles: [`
    :host {
      height: calc(100vh - 200px);;
    }
  `]
})
export class GridWrapperComponent implements OnInit {

  @Input() showPageSizeDropdown = true;
  @Input() allowPagination = true;
  @Input() columnDefs = [];
  @Input() rowData = [];
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();
  @Output() updateClick: EventEmitter<any> = new EventEmitter();
  @Output() gridReady: EventEmitter<any> = new EventEmitter();
  @Output() viewDocClick: EventEmitter<any> = new EventEmitter();
  @Output() lockClick: EventEmitter<any> = new EventEmitter();
  @Output() activeClick: EventEmitter<any> = new EventEmitter();
  @Output() resetClick: EventEmitter<any> = new EventEmitter();
  @Output() openClick: EventEmitter<any> = new EventEmitter();
  @Output() deleterecordClick: EventEmitter<any> = new EventEmitter();
  @Output() departmentPermissionClick: EventEmitter<any> = new EventEmitter();
  @Output() accessPermissionClick: EventEmitter<any> = new EventEmitter();

  numberOfRowsPerPage = 10;
  pageList = [
    10, 20, 50, 100
  ];
  gridAPI: GridApi;
  gridOptions = <GridOptions>{
    context: {
      componentParent: this
    },
    getRowHeight: (params) => {
      return 37;
    },
    // getRowWidth: (params) => {
    //   return 40;
    // },
    onGridReady: (params) => {
      this.gridAPI = params.api;
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
      this.gridReady.next(params);
    },
    onGridSizeChanged: (params) => {
      params.api.resetRowHeights();
      params.api.sizeColumnsToFit();
    },
    onColumnResized: (params) => {
      params.api.resetRowHeights();
    },
    icons: {
      sortNone: '<i class="fa fa-sort"/>',
      sortAscending: '<i class="fa fa-sort-alpha-asc"/>',
      sortDescending: '<i class="fa fa-sort-alpha-desc"/>'
    },
    pagination: this.allowPagination,
    paginationPageSize: this.numberOfRowsPerPage,
    headerHeight: 50,
  };

  @Input('autoRowHeight')
  set autoRowHeight(value: boolean) {
    if (value) {
      this.gridOptions.getRowHeight = null;
    }
  }

  @Input('rowSelection')
  set rowSelection(value: string) {
    if (value) {
      this.gridOptions.rowSelection = 'multiple';
    }
  }

  updateNumberOfRowsPerPage() {
    this.gridAPI.paginationSetPageSize(this.numberOfRowsPerPage);
    this.gridAPI.sizeColumnsToFit();
  }

  constructor(
    public _elementRef: ElementRef<HTMLElement>
  ) { }

  ngOnInit() {
    this.gridOptions.pagination = this.allowPagination;
  }

  onEdit(params) {
    this.editClick.emit(params);
  }

  onDelete(params) {
    this.deleteClick.emit(params);
  }

  onUpdate(params) {
    this.updateClick.emit(params);
  }

  docClick(params) {
    this.viewDocClick.emit(params);
  }

  isLockUser(params) {
    this.lockClick.emit(params);
  }

  isActiveUser(params) {
    this.activeClick.emit(params);
  }

  resetPassword(params) {
    this.resetClick.emit(params);
  }

  modelOpen(params) {
    this.openClick.emit(params);
  }
  deleterecord(params) {
    this.deleterecordClick.emit(params);
  }
  departmentPermission(params) {
    this.departmentPermissionClick.emit(params);
  }
  accessPermission(params) {
    this.accessPermissionClick.emit(params);
  }
}
