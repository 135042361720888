import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from '../app.globals';

@Injectable()
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  public getAllDataSet(userId, startDate, endDate, lastUpdateTime?): Observable<any[]> {
    const model = {
      FDATE: startDate, // '2021-10-18', // Fix Date
      TDATE: endDate, // '2021-10-18', // Fix Date
      UserID: userId, // localStorage.getItem('UserID'), // userId // 142,
      // FDATE: '2021-10-18', // Fix Date
      // TDATE: '2021-10-18', // Fix Date
      // UserID: 142,
      Flag: 2,
    };

    let lastUpdatedTimeParam = '';

    if (lastUpdateTime) {
      lastUpdatedTimeParam = `&lastUpdateTime=${lastUpdateTime}`;
    }

    const headers = {};
    if (lastUpdateTime) {
      headers[GLOBAL.IGNORE_LOADER] = 'true';
    }
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_ALL_DATASET}?UserID=${model.UserID}&StartDate=${model.FDATE}&EndDate=${model.TDATE}` +
        `&Flag=${model.Flag}${lastUpdatedTimeParam}`;
    return this.http.get<any[]>(url, { headers }).pipe(
      map((response: any) => response.Data));

  }


  public getRealTimeHeadCount(selectedDate: string, lastUpdateTime?): Observable<any[]> {
    // Todo: Change it to dynamic date
    const model = {
      "pDate": selectedDate,
    };

    let lastUpdatedTimeParam = '';

    if (lastUpdateTime) {
      lastUpdatedTimeParam = `&lastUpdateTime=${lastUpdateTime}`;
    }

    const headers = {};
    if (lastUpdateTime) {
      headers[GLOBAL.IGNORE_LOADER] = 'true';
    }
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_REALTIME_HEADCOUNT}`;
    return this.http.post<any[]>(url, model, { headers });
    // .pipe(
    //   map((response: any) => response.Data));

  }

  // public getAllDataSet(userId, startDate, endDate): Observable<any> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_ALL_DATASET}`;
  //   const model = {
  //     FDATE: '2021-08-03',
  //     TDATE: '2021-09-02',
  //     UserID: 142,
  //     Flag: 2
  //   }
  //   return this.http.get(url, model). // RR
  //     pipe(
  //       map((response: any) => response));
  // }
  public getAllInHeadCount(startDate, endDate, userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_INHEADCOUNT}`;
    return this.http.post(url, { startDate, endDate, userId }).
      pipe(
        map((response: any) => response));
  }
  public getAllOutHeadCount(startDate, endDate, userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_OutHEADCOUNT}`;
    return this.http.post(url, { startDate, endDate, userId }).
      pipe(
        map((response: any) => response));
  }
  public getAllRequirementAndShortage(date, userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_REQUIREMENT_AND_SHORTAGE}`;
    return this.http.post(url, { date, userId }).
      pipe(
        map((response: any) => response));
  }
  // public getAllDataSetBudget(): Observable<any[]> {
  //   const url = `${GLOBAL.APIS.MASTER_DATA.GET_ALL_DATASET1}`;
  //   return this.http.get<any[]>(url).pipe(
  //     map((response: any) => response.Data.Table));
  // }
  public getAllDataSetBudget(userId, startDate, endDate): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_ALL_DATASET1}`;
    return this.http.post(url, { UserId: localStorage.getItem('UserID'), StartDate: startDate, EndDate: endDate }).
      pipe(
        map((response: any) => response));
  }

  public getNonComplianceLabourList(startDate, endDate) {
    const model = {
      StartDATE: startDate,
      EndDATE: endDate,
      UserID: localStorage.getItem('UserID'),
    };

    return this.http.post<any[]>(GLOBAL.APIS.MASTER_DATA.GET_DAS_NON_COMPLIANCE_LABOUR_LIST, model).pipe(
      map((response: any) => response));
  }


  public getTotalActiveGatePass() {
    const model = {
      UserID: localStorage.getItem('UserID'),
    };

    return this.http.post<any>(GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_TOTAL_ACTIVE_GATEPASS, model).pipe(
      map((response: any) => response[0].TotalActive));
  }

  public getWeeklyShiftData(startDate, endDate) {
    const model = {
      StartDATE: startDate,
    };

    return this.http.post<any[]>(GLOBAL.APIS.MASTER_DATA.GET_DASHBOARD_WEEKLY_SHIFT_DATA, model).pipe(
      map((response: any) => response));
  }

  public getIndiaJson() {
    return this.http.request(
      GLOBAL.HTTP_GET,
      `${GLOBAL.APIS.ASSETS.GET_INDIA_JSON}`
    );
  }
  // tslint:disable-next-line: function-name
  public GetEXPolicyDataForDashboard
    (userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_ECpolicyPolicy_For_Dashboard}?UserID=${userId}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  // tslint:disable-next-line: function-name
  public GetLicenceDataForDashboard
    (userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_Licence_For_Dashboard}?UserID=${userId}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
  // tslint:disable-next-line: function-name
  public GetWorkOrderDataForDashboard
    (userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_WorkOrder_For_Dashboard}?UserID=${userId}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }

  // tslint:disable-next-line: function-name
  public GetDailyRequirementForDashboard
    (userId): Observable<any[]> {
    const url = `${GLOBAL.APIS.MASTER_DATA.GET_DailyRequirement_For_Dashboard}?UserID=${userId}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => response.Data.Table));
  }
}
