import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GatePassAccociateInformation, GatepassRequestProcess } from '../gatepass-request-process.model';
import * as moment from 'moment';
import { AgNumberCountComponent, AgSelectActionCellRendererComponent } from 'src/app/core/components';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  selector: 'app-plant-selection',
  templateUrl: './plant-selection.component.html',
  styleUrls: ['./plant-selection.component.css']
})
export class PlantSelectionComponent implements OnInit {

  @Input() public stepData: GatepassRequestProcess;
  @Input() public globalSettings: any[];
  @Input() public alertMessages: any[];
  @Input() public plantList: any[] = [];
  
  @Output() public handleSave: EventEmitter<GatePassAccociateInformation> = new EventEmitter();
  @Output() public handleClose: EventEmitter<void> = new EventEmitter();
  @Output() public handlePrevAction: EventEmitter<void> = new EventEmitter();
  @Output() public selectedPlant : EventEmitter<string> = new EventEmitter();

  public headerTitle : string = 'Plant selection';
  public plantSelectCheck : boolean = false;
  public columnDefs: any[];
  public gridOptions: GridOptions;
  public gridAPI: GridApi;

  constructor() { }

  ngOnInit() {
    this.createGridOptions();
    this.createColumnDef();
  }

  public onPlantSelct(e) {
    this.plantSelectCheck = true;
    this.selectedPlant.emit(e.target.value);
    this.handleSave.emit(e.target.value);
  }

  public onPrevAction(){
    this.handlePrevAction.emit();
  }

  public onNextAction(): void {
  }

  public onCloseAction(): void {
    this.handleClose.emit();
  }

  checkCondition: any;
  public onRowClicked( plant : any): void {
    this.plantSelectCheck = true;
    this.selectedPlant.emit(plant.data);
    this.handleSave.emit(plant.data);
  }

  private createColumnDef() {
    this.columnDefs = [
      {
        headerName: '#', field: '',
        width: 20, filterParams: { applyButton: true }, cellRendererFramework: AgNumberCountComponent
      },
      {
        headerName: 'Plant', field: 'Plant',
        width: 80, sortable: true
      },
      {
        headerName: 'State', field: 'State',
        width: 50, sortable: true
      },
      {
        headerName: 'Without LIC Allowed ', field: 'LicenseMaxValue',
        sortable: true, width : 100
      },
      {
        headerName: 'SITE', field: 'Site', width: 60
      },
      {
        headerName: 'ESIC Covered', field: 'CoveredInESIC', width: 80
      },
      {
        headerName: 'ESIC Number', field: 'ESICNumber', width: 150
      }
    ];
  }

  private createGridOptions(): void {
    this.gridOptions = {
      context: {
        componentParent: this
      },
      getRowHeight: (params) => {
        return 37;
      },
      onGridReady: (params) => {
        this.gridAPI = params.api;
        params.api.sizeColumnsToFit();
      },
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      icons: {
        sortNone: '<i class="fa fa-sort" />',
        sortAscending: '<i class="fa fa-sort-alpha-asc" />',
        sortDescending: '<i class="fa fa-sort-alpha-desc" />'
      },
      pagination: true,
      paginationPageSize: 10,
      headerHeight: 40,
    };
  }

}
