import { Component, OnInit, ViewContainerRef, ComponentRef, TemplateRef, ViewChild, Input } from '@angular/core';
import { FilterTypeEnum, ReportFilterData } from 'src/app/entryforday/report-filter-test/report-filter.model';
import { Contractor } from './../attendance-approve.model';
import { isNullOrUndefined } from 'util';
import { SubContractorFilterComponent } from 'src/app/entryforday/sub-contractor-filter/sub-contractor-filter.component';
import { ContractorFilterComponent } from 'src/app/entryforday/contractor-filter/contractor-filter.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceApprovePrsenter } from '../attendance-approve-presenter/attendance-approve.presenter';
import * as moment from 'moment';
import { OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { SharedService } from 'src/app/core/services';
import { AttendanceService } from '../../attendance.service';
import { LogHelperService } from 'src/app/core/services/log-helper.service';
import { AttendanceApproveComponent } from '../attendance-approve.component';
import { CookieService } from 'ngx-cookie-service';

declare var $;
const dateFormat = 'DD-MM-YYYY';
export const MY_MOMENT_FORMATS = {
  parseInput: dateFormat,
  fullPickerInput: dateFormat,
  datePickerInput: dateFormat,
  timePickerInput: dateFormat,
  monthYearLabel: dateFormat,
  dateA11yLabel: dateFormat,
  monthYearA11yLabel: dateFormat,
};
@Component({
  selector: 'app-attendance-approve-filter',
  templateUrl: './attendance-approve-filter.component.html',
  styleUrls: ['./attendance-approve-filter.component.css'],
  viewProviders: [AttendanceApprovePrsenter],
  providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN' }]
})
export class AttendanceApproveFilterComponent implements OnInit {

  public formGroup: FormGroup;
  public filterTabList: any[] = [];
  public activeFilter: string;

  // public variables
  // it will content the list of filters pass by the developer
  @Input() public filters: ReportFilterData[];

  // it will content the list of custom filters pass by the developer
  @Input() public existingFilters: ReportFilterData[];

  public savedFilters: any[] = [];
  public groupByList: any[];
  public companyList: any[];
  public plantList: any[];
  public unitList: any[];
  public plantLit: any[];
  public departmentList: any[];
  public sectionList: any[];
  public contractors: any[] = [];
  public contractorWorkOrders: any[] = [];
  public contractorLicenseList: any[] = [];
  public contractorWCPolicyList: any[] = [];
  public subContractors: any[] = [];
  public subContractorWorkOrders: any[] = [];
  public subContractorLicenseList: any[] = [];
  public subContractorWCPolicyList: any[] = [];

  // it will store the ref. of container where attach the html pass by developer
  @ViewChild('contentRef', { read: ViewContainerRef }) private contentRef: ViewContainerRef;

  @ViewChild('templateRef') templateRef: TemplateRef<any>;

  public filterNameFormGroup: FormGroup;
  public resetForm: boolean;

  private contractorComponentRef: ComponentRef<ContractorFilterComponent>;
  private subContractorComponentRef: ComponentRef<SubContractorFilterComponent>;

  // private variables
  private selectedFilterData: any = { JSONText: {} };
  public selectedTabId: any;

  private filterType: FilterTypeEnum;

  // it will store the filter data
  private reportFilterData: any;
  private modalRef: NgbModalRef;
  public pagecode: any;
  sectionData: any;
  contractors2: Contractor[];
  departmentData: any[];

  constructor(
    private entryfordayService: AttendanceService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private attendanceApprovePrsenter: AttendanceApprovePrsenter,
    private cookieService: CookieService,
    public sharedService: SharedService,
    private logHelperService: LogHelperService) {
    this.formGroup = this.attendanceApprovePrsenter.buildForm();

    this.setDefaultData();
  }

  ngOnInit() {
    this.filterNameFormGroup = this.buildForm();
    this.filterType = FilterTypeEnum.ContractorFilter;
    this.getAllData();
    this.pagecode = 345;
    this.getALLSavedFilterDescripationUserWise(this.pagecode);
    this.createFilterTab();
    this.selectedTabId = this.filterTabList[0].id;
    this.activeFilter = this.filterTabList[0].name;

    this.manageVisibilityOfSteps();
    const userId = localStorage.getItem('UserID');
    const roleCode = JSON.parse(localStorage.getItem('Role'))[0].Role_Code;
    if (userId && roleCode === 119) {

      this.getContractorsSelectedByDefault(userId, roleCode);
    } else {
      this.getContractors();
    }
  }

  setDefaultData() {
    let userId = new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserID');
    userId = userId; // Todo: Remove static id
    this.formGroup.get('JSONText').get('UserID').setValue(userId);
    this.formGroup.get('JSONText').get('rdoDateType').setValue('1');
    this.formGroup.get('JSONText').get('groupBy').setValue('1');
  }

  onStartDateChange($event) {
    this.formGroup.get('JSONText').get('Sdate').setValue(moment($event.value._d).format('YYYY-MM-DD'));
    this.formGroup.get('JSONText').get('Edate').setValue(moment($event.value._d).format('YYYY-MM-DD'));
  }

  onDateRangeChange($event) {
    this.formGroup.get('JSONText').get('Sdate').setValue(moment($event.value[0]._d).format('YYYY-MM-DD'));
    this.formGroup.get('JSONText').get('Edate').setValue(moment($event.value[1]._d).format('YYYY-MM-DD'));
  }

  public onPlantChange(): void {
    this.unitList = [];
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (plantCode) {
      this.getUnits(plantCode);
    }
  }

  private getAllData() {
    this.setGroupByList();
    this.getCompany();
    // this.getSectionData();
    // this.getSections();
    const userId = localStorage.getItem('UserID');
    const roleCode = 107;
    // this.getContractors();
    this.getDepartmentData();
  }
  getContractorsSelectedByDefault(userId: any, roleCode: any) {

    this.entryfordayService.getContractorsbydefault(userId, roleCode).subscribe((response: Contractor[]) => {
      this.contractors = response;
      this.contractors2 = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      } else if (this.contractors.length === 1) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.contractors[0].ContractorVendor_Code);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.plantcode);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getCompany() {
    this.entryfordayService.getCompanies().subscribe((response: any[]) => {
      this.companyList = response;
      this.formGroup.get('JSONText').get('Company_Code').setValue(this.selectedFilterData.JSONText.Company_Code);
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Company_Code) {
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      } else if (this.companyList.length === 1) {
        this.formGroup.get('JSONText').get('Company_Code').setValue(this.companyList[0].Company_Code);
        this.getPlants(this.selectedFilterData.JSONText.Company_Code);
      }
    });
  }

  getContractors() {
    //
    this.entryfordayService.getContractors().subscribe((response: Contractor[]) => {
      this.contractors = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.Contractors) {
        this.formGroup.get('JSONText').get('Contractors').setValue(this.selectedFilterData.JSONText.Contractors);
        this.getWorkOrdersByContractorCode(this.selectedFilterData.JSONText.Contractors, this.selectedFilterData.JSONText.SubContractors);
        this.getLicensesByContractorCode(this.selectedFilterData.JSONText.Contractors);
        this.getWCPolicyByContractorCode(this.selectedFilterData.JSONText.Contractors);
      }
    });
  }

  getLicensesByContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.contractorLicenseList = response;
      this.formGroup.get('JSONText').get('License_Code').setValue(this.selectedFilterData.JSONText.License_Code);
    });
  }

  getWCPolicyByContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.contractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('WCPolicy').setValue(this.selectedFilterData.JSONText.WCPolicy);
    });
  }

  getPlants(companyCode: any): void {
    this.entryfordayService.getPlants(companyCode).subscribe((response: any[]) => {
      this.plantList = response;
      this.formGroup.get('JSONText').get('Plant_Code').setValue(this.selectedFilterData.JSONText.Plant_Code);
      if (this.selectedFilterData.JSONText.Plant_Code) {
        this.getUnits(this.selectedFilterData.JSONText.Plant_Code);
      }
    });
  }

  getUnits(plantCode: any): void {
    this.entryfordayService.getUnits(plantCode).subscribe((response: any[]) => {
      this.unitList = response;
      this.formGroup.get('JSONText').get('Unit_Code').setValue(this.selectedFilterData.JSONText.Unit_Code);
    });
  }

  getDepartmentData(unitCode: any = ''): void {
    this.entryfordayService.getDepartmentData(unitCode).subscribe((response: any[]) => {
      this.departmentData = response;
      // this.formGroup.get('JSONText').get('Department_Code').setValue(this.selectedFilterData.JSONText.Department_Code);
    });
  }

  getUnit(plantCode: any): void {
    this.entryfordayService.getUnits(plantCode).subscribe((response: any[]) => {
      this.unitList = response;
      this.formGroup.get('JSONText').get['Unit_Code'].setValue(this.selectedFilterData.JSONText.Unit_Code);
      if (this.selectedFilterData.JSONText.Unit_Code) {
        this.getDepartments(this.selectedFilterData.JSONText.Unit_Code);
      }
    });
  }

  public onUnitChange(): void {
    this.departmentData = [];
    this.formGroup.get('JSONText').get('Department_Code').setValue(this.selectedFilterData.JSONText.Department_Code);
    const plantCode = this.formGroup.get('JSONText').get('Plant_Code').value;
    const unitCode = this.formGroup.get('JSONText').get('Unit_Code').value;
    if (plantCode && unitCode) {

      this.getDepartments(unitCode);
    }
  }


  getDepartments(unitCode: any): void {
    this.entryfordayService.getDepartmentsbyUnit(unitCode).subscribe((response: any[]) => {
      this.departmentData = response;
      this.formGroup.get('JSONText').get('Department_Code').setValue(this.selectedFilterData.JSONText.Department_Code);
      if (this.selectedFilterData.JSONText.Department_Code) {
        const plantCode = this.selectedFilterData.JSONText.Plant_Code;
        const unitCode = this.selectedFilterData.JSONText.Unit_Code;
        const departmentCode = this.selectedFilterData.JSONText.Department_Code;
        // this.getSectionList(plantCode, unitCode, departmentCode);
      }
    });
  }

  // getDepartmentData() {

  //   // tslint:disable-next-line: max-line-length
  //   return this.service.getDepartmentData(this.organizationForm.controls['Unit_Code'].value || 100).subscribe((res: any) => {
  //     this.departmentData = res;
  //   });
  // }
  getSections(): void {
    this.entryfordayService.getSectionData().subscribe((response: any[]) => {
      this.sectionList = response;
      this.formGroup.get('JSONText').get('Section_Code').setValue(this.selectedFilterData.JSONText.Section_Code);
    });
  }
  getSectionData() {
    return this.entryfordayService.getSectionData().subscribe((response: any) => {
      this.sectionData = response;
      // this.sectionData1 = response;
      // this.sectionData2 = response;
    });
  }

  setGroupByList() {
    this.groupByList = [
      {
        value: '1',
        label: 'Contractor Wise'
      },
    ];
  }

  onCompanyChange() {
    const companyCode = this.formGroup.get('JSONText').get('Company_Code').value;
    this.formGroup.get('JSONText').get('Plant_Code').setValue(null);
    this.formGroup.get('JSONText').get('Unit_Code').setValue(null);

    this.selectedFilterData.JSONText.Plant_Code = null;
    this.selectedFilterData.JSONText.Unit_Code = null;

    if (companyCode) {
      this.getPlants(companyCode);
    }
  }

  onContractorChange() {
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('License_Code').setValue(null);
    this.formGroup.get('JSONText').get('WCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);

    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.License_Code = null;
    this.selectedFilterData.JSONText.WCPolicy = null;
    this.selectedFilterData.JSONText.WorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    this.contractorWorkOrders = [];
    this.contractorLicenseList = [];
    this.contractorWCPolicyList = [];
    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    const contractorCode = this.formGroup.get('JSONText').get('Contractors').value;
    if (contractorCode && contractorCode.length === 1) {
      if (this.formGroup.get('JSONText').get('WorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('License_Code').disabled) {
        this.formGroup.get('JSONText').get('License_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('WCPolicy').disabled) {
        this.formGroup.get('JSONText').get('WCPolicy').enable();
      }
      if (this.formGroup.get('JSONText').get('SubContractors').disabled) {
        this.formGroup.get('JSONText').get('SubContractors').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWorkOrder_Code').disabled) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubLicense_Code').disabled) {
        this.formGroup.get('JSONText').get('SubLicense_Code').enable();
      }
      if (this.formGroup.get('JSONText').get('SubWCPolicy').disabled) {
        this.formGroup.get('JSONText').get('SubWCPolicy').enable();
      }

      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
      this.getLicensesByContractorCode(contractorCode);
      this.getWCPolicyByContractorCode(contractorCode);
    } else {
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('License_Code').disable();
      this.formGroup.get('JSONText').get('WCPolicy').disable();
      this.formGroup.get('JSONText').get('WorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubContractors').disable();
      this.formGroup.get('JSONText').get('SubWorkOrder_Code').disable();
      this.formGroup.get('JSONText').get('SubLicense_Code').disable();
      this.formGroup.get('JSONText').get('SubWCPolicy').disable();
    }
  }

  public onWorkOrderChange() {
    const workOrderNo = this.formGroup.get('JSONText').get('WorkOrder_Code').value;
    this.formGroup.get('JSONText').get('SubContractors').setValue(null);
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.formGroup.get('JSONText').get('SubWCPolicy').setValue(null);
    this.formGroup.get('JSONText').get('SubLicense_Code').setValue(null);

    this.selectedFilterData.JSONText.SubContractors = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWCPolicy = null;
    this.selectedFilterData.JSONText.SubLicense_Code = null;

    this.subContractors = [];
    this.subContractorWorkOrders = [];
    this.subContractorLicenseList = [];
    this.subContractorWCPolicyList = [];

    if (workOrderNo) {
      this.getSubContractors(workOrderNo);
    }
  }

  public onSubContractorChange() {
    const subContractorCode = this.formGroup.get('JSONText').get('SubContractors').value;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(null);
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.selectedFilterData.JSONText.SubWorkOrder_Code = null;
    this.subContractorWorkOrders = [];
    if (subContractorCode) {
      this.getSubWorkOrdersBySubContractorCode(subContractorCode, plantcode);
      this.getWCPolicyBySubContractorCode(subContractorCode);
      this.getLicensesBySubContractorCode(subContractorCode);
    }
  }

  public onSubWorkOrderChangeEmitter(value: any) {
    this.reportFilterData.subContractorWorkOrderNo = value.workOrderNo;
  }

  getSubWorkOrderListEmitter(contractorCode: number) {
    if (isNullOrUndefined(this.reportFilterData)) {
      // this.reportFilterData = new ReportFilterModel();
    }
    this.reportFilterData.contractorCode = contractorCode;
    const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
    if (contractorCode) {
      this.getWorkOrdersByContractorCode(contractorCode, plantcode);
    }
  }

  public onSaveFilter(): void {
    console.log('form data', this.formGroup);
    this.formGroup.get('JSONText').get('id').setValue(this.selectedTabId);
    console.log('saved data', this.reportFilterData);
    this.formGroup.get('FilterName').setValidators(Validators.required);
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.openModal();
  }
  public onSubmit(): void {

    const formValue = this.formGroup.getRawValue();
    if (formValue.JSONText.dateRange && formValue.JSONText.dateRange.length > 0) {
      if (formValue.JSONText.Plant_Code > 0) {
        if (formValue.JSONText.Contractors > 0) {
          formValue.JSONText.Date = moment(formValue.JSONText.dateRange[0]).format(this.sharedService.datePickerSettings.requestFormat);
          formValue.JSONText.EDate = moment(formValue.JSONText.dateRange[1]).format(this.sharedService.datePickerSettings.requestFormat);
          console.log('StartDate', formValue.JSONText.Date);
          console.log('EndDate', formValue.JSONText.EDate);
          console.log('xyz', this.formGroup.getRawValue());

          let contCode = '';
          let ptCode = '';
          // let utCode = '';
          let subconcode = '';



          if (formValue.JSONText.Contractors) {
            contCode = formValue.JSONText.Contractors.join(',');
          } else {
            contCode = null;
          }
          if (formValue.JSONText.SubContractors) {
            subconcode = formValue.JSONText.SubContractors;
          } else {
            subconcode = null;
          }
          if (formValue.JSONText.Plant_Code) {
            ptCode = formValue.JSONText.Plant_Code;
          } else {
            ptCode = null;
          }
          // if (formValue.JSONText.Unit_Code) {
          //   utCode = formValue.JSONText.Unit_Code;
          // } else {
          //   utCode = null;
          // }


          formValue.filterText = {
            UserId: localStorage.getItem('UserID'),
            StartDate: formValue.JSONText.Date,
            EndDate: formValue.JSONText.EDate,
            Company_Code: null,
            plant_Code: ptCode,
            // unit_Code: utCode,
            Contractor: contCode,
            subContractors: subconcode,
            SectionPlant_ID: null,
            workOrder_Code: null,
            license_Code: null,
            WCPolicy: null,
            ESIC: null,
            subWorkOrder_Code: null,
            subLicense_Code: null,
            subWCPolicy: null,
            subESIC: null,
            IsApprove: 1
          };
          console.log('filter', formValue.filterText);
          this.entryfordayService.getApproveAttendanceList(formValue.filterText).subscribe((response: any[]) => {
            console.log(response);

            // this.entryfordayService.getPlantwiseTimeCardReportData(formValue.JSONText).subscribe((response: any[]) => {
            this.openReportViewModal(response);
          });
        } else {
          this.logHelperService.logError({
            message: 'Please Select Contractor'
          });
        }
      }
      // tslint:disable-next-line: brace-style
      else {
        this.logHelperService.logError({
          message: 'Please Select plant'
        });
      }
    }
    // tslint:disable-next-line: brace-style
    else {
      this.logHelperService.logError({
        message: 'Please Select Date'
      });
    }
  }

  // }
  // public onSubmit(): void {
  //   if (this.formGroup.valid) {
  //     const formValue = this.formGroup.getRawValue();
  //     formValue.JSONText.IsApprove = true;
  //     formValue.JSONText.UserID = localStorage.getItem('UserID');
  //     this.entryfordayService.getApproveAttendanceList(formValue.JSONText).subscribe((res: any[]) => {
  //       if (res.length === 0) {
  //         this.logHelperService.logError({
  //           message: 'No record found'
  //         });

  //       } else {
  //         this.openReportViewModal(res);
  //       }
  //     });
  //   }
  // }

  openReportViewModal(response: any[]): void {
    const formValue = this.formGroup.getRawValue();
    const modalRef = this.modalService.open(AttendanceApproveComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.showCloseButton();
    modalRef.componentInstance.reportData = response;
    modalRef.componentInstance.selectedReportData = formValue.JSONText;
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }

  public onFilterTab(filterObj: any): void {
    this.activeFilter = filterObj.name;
    this.selectedTabId = filterObj.id;
    this.manageVisibilityOfSteps();
  }

  public onAddfilter(): void {
    if (this.formGroup.valid) {
      const value = this.formGroup.getRawValue();
      const selectedFilterDataObj = { ...this.selectedFilterData.JSONText, ...value.JSONText };
      const filterTypeObj = this.savedFilters.find(item => item.FilterName === value.FilterName
        && item.Report_Code !== this.selectedFilterData.Report_Code);
      if (!filterTypeObj) {
        selectedFilterDataObj.id = this.selectedTabId;
        const data = {
          Report_Code: this.selectedFilterData.Report_Code || '',
          Page_Code: 345, // pending pass pagecode
          FilterName: value.FilterName,
          JSONTextDetails: JSON.stringify(selectedFilterDataObj)
        };
        if (this.selectedFilterData.Report_Code) {
          this.entryfordayService.updateFilter(data).subscribe((response: any) => {
            if (response.Status) {
              this.getALLSavedFilterDescripationUserWise(this.pagecode);
              this.modalRef.close();
              this.clearData();
            }
          });
        } else {
          this.entryfordayService.addFilter(data).subscribe((response: any) => {
            if (response.Status) {
              this.getALLSavedFilterDescripationUserWise(this.pagecode);
              this.modalRef.close();
              this.clearData();
            }
          });
        }
      } else {
        alert('This filter is already exist.');
      }
    }
  }

  public deleteFilterItem(filterObj: any): void {
    this.entryfordayService.deleteFilter(filterObj).subscribe((response: any[]) => {
      this.getALLSavedFilterDescripationUserWise(this.pagecode);
    });
  }

  private getALLSavedFilterDescripationUserWise(pagecode: number) {
    this.entryfordayService.getALLSavedFilterDescripationUserWise(this.pagecode).subscribe((response: any[]) => {
      this.savedFilters = response;
    });
  }

  private clearData() {
    this.formGroup.get('FilterName').clearValidators();
    this.formGroup.get('FilterName').updateValueAndValidity();

    this.formGroup.reset();
    this.selectedTabId = this.filterTabList[0].id;

    let userId = new URLSearchParams(this.cookieService.get('OceanSystem')).get('UserID');
    userId = '102'; // Todo: Remove static id
    this.selectedFilterData = {
      JSONText: {
        rdoDateType: '1',
        UserID: userId,
        groupBy: '1',
      }
    };
    this.setDefaultData();
    this.manageVisibilityOfSteps();
  }

  private buildForm() {
    return this.fb.group({
      filterTypeName: ['', Validators.required]
    });
  }

  public onFilterNameClick(filterObj: any) {
    this.selectedFilterData = { ...filterObj };
    console.log('this.selectedFilterData', filterObj);
    // this.onFilterTab(filterObj);
    this.selectedTabId = this.selectedFilterData.JSONText.id;
    const filterTab = this.filterTabList.find(item => item.id === this.selectedTabId);
    if (filterTab) {
      this.activeFilter = filterTab.name;
    }
    this.manageVisibilityOfSteps();
    // this.getAllData();
    this.getCompany();
    this.getContractors();
    this.attendanceApprovePrsenter.bindForm(this.formGroup, filterObj);
  }

  private openModal() {
    console.log('on save', this.selectedFilterData);
    this.formGroup.get('FilterName').setValue(this.selectedFilterData ? this.selectedFilterData.FilterName : '');
    this.modalRef = this.modalService.open(this.templateRef, { size: 'sm' });
  }

  private getWorkOrdersByContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.contractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
        this.formGroup.get('JSONText').get('WorkOrder_Code').setValue(this.selectedFilterData.JSONText.WorkOrder_Code);
        if (this.selectedFilterData && this.selectedFilterData.JSONText.WorkOrder_Code) {
          this.getSubContractors(this.selectedFilterData.JSONText.WorkOrder_Code);
        }
      }
    });
  }

  private getSubWorkOrdersBySubContractorCode(contractorCode: any, plantcode: any): void {
    this.entryfordayService.getWorkOrdersByContractorCode(contractorCode, plantcode).subscribe((response: any[]) => {
      this.subContractorWorkOrders = response;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubWorkOrder_Code) {
        this.formGroup.get('JSONText').get('SubWorkOrder_Code').setValue(this.selectedFilterData.JSONText.SubWorkOrder_Code);
      }
    });
  }

  getWCPolicyBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getWCPolicy(contractorCode).subscribe((response: any[]) => {
      this.subContractorWCPolicyList = response;
      this.formGroup.get('JSONText').get('SubWCPolicy').setValue(this.selectedFilterData.JSONText.SubWCPolicy);
    });
  }

  private getLicensesBySubContractorCode(contractorCode: any): void {
    this.entryfordayService.getLicenses(contractorCode).subscribe((response: any[]) => {
      this.subContractorLicenseList = response;
      this.formGroup.get('JSONText').get('SubLicense_Code').setValue(this.selectedFilterData.JSONText.SubLicense_Code);
    });
  }

  private getSubContractors(workOrderNo: any = ''): void {
    // this.entryfordayService.getSubContractorByWODataforDA(contractorCode, workOrderNo).subscribe((response: any[]) => {
    //   this.subContractors = response;
    // });
    this.entryfordayService.getContractors('', workOrderNo).subscribe((response: any[]) => {
      this.subContractors = response;
      this.formGroup.get('JSONText').get('SubContractors').setValue(this.selectedFilterData.JSONText.SubContractors);
      const plantcode = this.formGroup.get('JSONText').get('Plant_Code').value;
      if (this.selectedFilterData && this.selectedFilterData.JSONText.SubContractors) {
        this.getSubWorkOrdersBySubContractorCode(this.selectedFilterData.JSONText.SubContractors, plantcode);
        this.getLicensesBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
        this.getWCPolicyBySubContractorCode(this.selectedFilterData.JSONText.SubContractors);
      }
    });
  }

  private setWorkOrderList(response: any[]): void {
    if (this.filterType === FilterTypeEnum.ContractorFilter) {
      this.contractorComponentRef.instance.workOrders = response;
    } else if (this.filterType === FilterTypeEnum.SubContractorFilter) {
      this.subContractorComponentRef.instance.workOrders = response;
    } else {
      console.log('invalid tab');
    }
  }

  private createFilterTab(): void {
    this.filterTabList = [
      {
        id: 1,
        name: 'Criteria',
        active: true
      },
      {
        id: 2,
        name: 'Company',
        active: false
      },
      {
        id: 3,
        name: 'Contractor',
        active: false
      },
      {
        id: 4,
        name: 'Sub-Contractor',
        active: false
      }
    ];
  }

  private manageVisibilityOfSteps() {
    const children = $('#content-container').children();
    this.filterTabList.forEach((item: any, index: number) => {
      if (item.id === this.selectedTabId) {
        $(children[index]).css('display', '');
        item.active = true;
      } else {
        $(children[index]).css('display', 'none');
        item.active = false;
      }
    });
  }
}
