import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrievanceComponent } from './grievance.component';
import { SubGrievanceComponent } from './sub-grievance/sub-grievance.component';

const routes: Routes = [
  {
    path: 'grievances',
    component: GrievanceComponent
  },
  {
    path:'create-grievance',
    component: SubGrievanceComponent
  },
  {
    path: 'edit-create-grievance/:id',
    component: SubGrievanceComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GrievanceRoutingModule { }
