import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LabourRoutingModule } from './labour-routing.module';
import { BulkCancellationComponent } from './bulk-cancellation/bulk-cancellation.component';
import { LaboursComponent } from './labours/labours.component';
import { GatePassRequestsComponent } from './gate-pass-requests/gate-pass-requests.component';
import { CoreModule } from '../core/core.module';
import { LaboursService } from './labours/labours.service';
import { CreateSubLabourComponent } from './labours/create-sub-labour/create-sub-labour.component';
import { GatePassRequestsService } from './gate-pass-requests/gate-pass-requests.service';
import { FullRowRendererComponent } from './gate-pass-requests/ag-helper';
import { ApprovalFlowComponent } from './gate-pass-requests/approval-flow/approval-flow.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { VerifyComponent } from './gate-pass-requests/approval-flow/verify/verify.component';
import { VerificationHistoryComponent } from './gate-pass-requests/approval-flow/verification-history/verification-history.component';
import { LabourImportComponent } from './labour-import/labour-import.component';
import { AgInputEditCellRendererComponent } from './labour-import/ag-input-edit-cell-renderer/ag-input-edit-cell-renderer.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AgSelectEditCellRendererComponent } from './labour-import/ag-select-edit-cell-renderer/ag-select-edit-cell-renderer.component';
import { AgDatePickerEditCellRendererComponent }
  from './labour-import/ag-datepicker-edit-cell-renderer/ag-datepicker-edit-cell-renderer.component';
import { AgIconActionCellRendererComponent } from './labour-import/ag-icon-action-cell-renderer/ag-icon-action-cell-renderer.component';
import { NgxPrintModule } from 'ngx-print';
import { DepartmenttrainingService } from './department-training/department-training.service';
import { DepartmenttrainingAdapter } from './department-training/adapter/department-training.adapter';
// tslint:disable-next-line: max-line-length
import { DepartmentTrainingComponent } from './department-training/department-training.component';
import { ReportsGridWrapperComponent } from './department-training/reports-grid-wrapper/reports-grid-wrapper.component';
import { LabourDepartmentTrainingComponent } from './department-training/labour-department-training/labour-department-training.component';
// tslint:disable-next-line: max-line-length
import { BulkUploadWithIndividualDocComponent } from './department-training/bulk-upload-with-individual-doc/bulk-upload-with-individual-doc.component';
import { FilterPopupComponent } from './gate-pass-requests/filter-popup/filter-popup.component';
import { LabourProfileImageComponent } from './labours/create-sub-labour/labour-profile-image/labour-profile-image.component';
import { ToastUiImageEditorModule } from 'ngx-tui-image-editor';
import { WebcamModule } from 'ngx-webcam';
import { DateDetailsComponent } from './gate-pass-requests/approval-flow/date-details/date-details.component';
import { DebarredLabourComponent } from './gate-pass-requests/approval-flow/debarred-labour/debarred-labour.component';
import { LabourCgmpTrainingComponent } from './labour-cgmp-training/labour-cgmp-training.component';
import { LabourCGMPTrainingFilterComponent } from './labour-cgmp-training/labour-cgmp-training-filter/labour-cgmp-training-filter.component';
import { LabourCGMPTrainingBulkUploadWithIndividualDocComponent } from './labour-cgmp-training/bulk-upload-with-individual-doc/bulk-upload-with-individual-doc.component';
import { AssociateAccidentDetailsComponent } from './associate-accident-details/associate-accident-details.component';
import { AssociateAccidentFormComponent } from './associate-accident-details/associate-accident-form/associate-accident-form.component';
import { RegisterOfFineComponent } from './register-of-fine/register-of-fine.component';
import { LabourFamilyDetailComponent } from './labours/create-sub-labour/labour-family-detail/labour-family-detail.component';
import { EditGatepassRequestModalComponent } from './gate-pass-requests/edit-gatepass-request/edit-gatepass-request.component';
import { GatePassRequestProcessComponent } from './gate-pass-requests/gatepass-request-process/gatepass-request-process.component';
import { AssociateInformationSelectionComponent } from './gate-pass-requests/gatepass-request-process/associate-information-selection/associate-information-selection.component';
import { ContractorSelectionComponent } from './gate-pass-requests/gatepass-request-process/contractor-selection/contractor-selection.component';
import { WorkorderSelectionComponent } from './gate-pass-requests/gatepass-request-process/workorder-selection/workorder-selection.component';
import { LicenseSelectionComponent } from './gate-pass-requests/gatepass-request-process/license-selection/license-selection.component';
import { AssociateCoveredUnderInformationSelectionComponent } from './gate-pass-requests/gatepass-request-process/associate-covered-under/associate-covered-under.component';
import { GatepassJobTypeSelectionComponent } from './gate-pass-requests/gatepass-request-process/gatepass-job-type/gatepass-job-type.component';
import { GatepassAssociateSelectionComponent } from './gate-pass-requests/gatepass-request-process/associate-selection/associate-selection.component';
import { WCPolicySelectionComponent } from './gate-pass-requests/gatepass-request-process/wc-policy-selection/wc-policy-selection.component';
import { AssociateResignDateCellRendererComponent } from './gate-pass-requests/gatepass-request-process/associate-selection/associate-resign-date-cell-renderer/associate-resign-date-cell-renderer.component';
import { PopupWorkmanSalaryMasterComponent } from './gate-pass-requests/approval-flow/date-details/popup-workman-salary-master/popup-workman-salary-master.component';
import { AssociateEffectiveDateCellRendererComponent } from './gate-pass-requests/ag-helper/associate-resign-date-cell-renderer/associate-resign-date-cell-renderer.component';
import { editButtonRenderer } from './gate-pass-requests/ag-helper/htmlElement/buttonElement.component';
import { inputValueRenderer } from './gate-pass-requests/ag-helper/htmlElement/inputElement.component';
import { PlantSelectionComponent } from './gate-pass-requests/gatepass-request-process/plant-selection/plant-selection.component';
// import { PrintIndividualGatepassComponent } from './gate-pass-requests/print-individual-gatepass/print-individual-gatepass.component';
// import { AgUploadDocCellComponent } from './ag-upload-doc-cell/ag-upload-doc-cell.component';
@NgModule({
  declarations: [
    BulkCancellationComponent,
    LaboursComponent,
    GatePassRequestsComponent,
    BulkCancellationComponent,
    FullRowRendererComponent,
    CreateSubLabourComponent,
    ApprovalFlowComponent,
    VerifyComponent,
    VerificationHistoryComponent,
    LabourImportComponent,
    AgInputEditCellRendererComponent,
    AgSelectEditCellRendererComponent,
    AgDatePickerEditCellRendererComponent,
    // AgUploadDocCellComponent,
    AgIconActionCellRendererComponent,
    DepartmentTrainingComponent,
    ReportsGridWrapperComponent,
    LabourDepartmentTrainingComponent,
    BulkUploadWithIndividualDocComponent,
    FilterPopupComponent,
    LabourProfileImageComponent,
    DateDetailsComponent,
    PopupWorkmanSalaryMasterComponent,
    DebarredLabourComponent,
    LabourCgmpTrainingComponent,
    LabourCGMPTrainingFilterComponent,
    LabourCGMPTrainingBulkUploadWithIndividualDocComponent,
    AssociateAccidentDetailsComponent,
    AssociateAccidentFormComponent,
    RegisterOfFineComponent,
    LabourFamilyDetailComponent,
    EditGatepassRequestModalComponent,
    GatePassRequestProcessComponent,
    AssociateInformationSelectionComponent,
    ContractorSelectionComponent,
    WorkorderSelectionComponent,
    LicenseSelectionComponent,
    AssociateCoveredUnderInformationSelectionComponent,
    GatepassJobTypeSelectionComponent,
    GatepassAssociateSelectionComponent,
    WCPolicySelectionComponent,
    // PrintIndividualGatepassComponent,
    AssociateResignDateCellRendererComponent,
    PopupWorkmanSalaryMasterComponent,
    AssociateEffectiveDateCellRendererComponent,
    editButtonRenderer,
    inputValueRenderer,
    PlantSelectionComponent
  ],
  entryComponents: [
    FullRowRendererComponent,
    ApprovalFlowComponent,
    VerifyComponent,
    VerificationHistoryComponent,
    LabourImportComponent,
    AgInputEditCellRendererComponent,
    AgSelectEditCellRendererComponent,
    AgDatePickerEditCellRendererComponent,
    // AgUploadDocCellComponent,
    AgIconActionCellRendererComponent,
    LabourDepartmentTrainingComponent,
    BulkUploadWithIndividualDocComponent,
    FilterPopupComponent,
    LabourProfileImageComponent,
    DateDetailsComponent,
    PopupWorkmanSalaryMasterComponent,
    DebarredLabourComponent,
    LabourCGMPTrainingFilterComponent,
    LabourCGMPTrainingBulkUploadWithIndividualDocComponent,
    AssociateAccidentFormComponent,
    EditGatepassRequestModalComponent,
    GatePassRequestProcessComponent,
    AssociateResignDateCellRendererComponent,
    AssociateEffectiveDateCellRendererComponent,
    editButtonRenderer,
    inputValueRenderer
  ],
  imports: [
    CommonModule,
    NgxPrintModule,
    LabourRoutingModule,
    CoreModule,
    PopoverModule,
    SweetAlert2Module.forRoot(),
    ToastUiImageEditorModule,
    WebcamModule,
  ],
  providers: [
    LaboursService,
    GatePassRequestsService,
    DepartmenttrainingService,
    DepartmenttrainingAdapter
  ],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class LabourModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LabourModule,
      providers: [
        LaboursService,
        GatePassRequestsService,
        DepartmenttrainingService
      ]
    };
  }
}
