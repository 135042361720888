import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YearlyComponent } from './yearly/yearly.component';
import { MonthlyComponent } from './monthly/monthly.component';
import { DashboardComponent } from './dashboard.component';
import { DashboardStatisticsComponent } from './dashboard-statistics/dashboard-statistics.component';
import { ContractorDashboardComponent } from './contractor-dashboard/contractor-dashboard.component';
import { GLOBAL } from '../app.globals';

const routes: Routes = [
  {
    path: 'dash-board',
    component: DashboardComponent
  },
  {
    path: 'dash-board-statstics',
    component: DashboardStatisticsComponent
  },
  {
    path: 'yearly',
    component: YearlyComponent
  },
  {
    path: 'monthly',
    component: MonthlyComponent
  },
  {
    path: 'contractor-dashboard',
    component: ContractorDashboardComponent
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
