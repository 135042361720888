import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LabourImportHrComponent } from './labour-import-hr/labour-import-hr.component';
import { ImportManualPunchComponent } from './import-manual-punch/import-manual-punch.component';
import { SubcontractorLabourImportHrComponent } from './subcont-labour-import-hr/subcont-labour-import-hr.component';
import { UploadBulkEnrollmentDeleteComponent } from './upload-bulk-enrollment-delete/upload-bulk-enrollment-delete.component';
import { UploadExcelComponent } from "./upload-excel/upload-excel.component";
import { PayrollHandUploadComponent } from './payroll-excel-upload/payroll-excel-upload.component';

@Component({
  selector: 'app-import-excel',
  templateUrl: './import-excel.component.html',
  styleUrls: ['./import-excel.component.css']
})
export class ImportExcelComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }
  importLabourForHR(): void {
    const modalRef = this.modalService.open(LabourImportHrComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  importSubcontactorLabourForHR(): void {
    const modalRef = this.modalService.open(SubcontractorLabourImportHrComponent,
      { size: 'lg', backdrop: 'static', windowClass: 'modal-panel' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }

  public importUploadBulkEnrollmentDelete(): void {
    const modalRef = this.modalService.open(UploadBulkEnrollmentDeleteComponent,
      { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
  importExcelData(){
    const modalRef = this.modalService.open(PayrollHandUploadComponent,
      { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.closeModal.subscribe((value: string) => {
      modalRef.close(value);
    });
  }
}
